import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import ApplyLeaveModal from "../../modals/Hrms/ApplyLeaveModal";
import AssignLeaveModal from "../../modals/Hrms/AssignLeaveModal";
import {
  fetchLeaves,
  applyLeave,
  updateLeave,
  deleteLeave,
} from "../../redux/applyLeaveSlice";
import {
  fetchAssignedLeaves,
  assignLeave,
  updateAssignedLeave,
  deleteAssignedLeave,
} from "../../redux/assignLeaveSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../redux/employeeSlice";
const LeaveDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, setValue } = useForm();
  const [expandedCourseIds, setExpandedCourseIds] = useState([]);

  const { leaves, status, error } = useSelector((state) => state.leaves);

  const {
    assignedLeaves,
    status: assignStatus,
    error: assignError,
  } = useSelector((state) => state.assignLeave);

  console.log("assignedLeaves", assignedLeaves);
  const { employees } = useSelector((state) => state.employees || {});

  const [isApplyLeaveModalOpen, setIsApplyLeaveModalOpen] = useState(false);
  const [isAssignLeaveModalOpen, setIsAssignLeaveModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentLeave, setCurrentLeave] = useState(null);
  const [isMultipleDays, setIsMultipleDays] = useState(false);

  useEffect(() => {
    dispatch(fetchLeaves());
    dispatch(fetchEmployees());
    dispatch(fetchAssignedLeaves());
  }, [dispatch]);

  const onApplyLeaveSubmit = async (data) => {
    try {
      if (isEdit) {
        await dispatch(updateLeave({ id: currentLeave.id, updatedData: data }));
        toast.success("Leave updated successfully!");
      } else {
        await dispatch(applyLeave(data));
        toast.success("Leave applied successfully!");
      }
      setIsApplyLeaveModalOpen(false);
      reset();
    } catch (error) {
      toast.error("Error processing request.");
    }
  };

  const handleEditLeave = (leave) => {
    setIsEdit(true);
    setCurrentLeave(leave);
    setIsApplyLeaveModalOpen(true);
    setValue("employeeId", leave.employeeId);
    setValue("leaveType", leave.leaveType);
    setValue("dateFrom", leave.dateFrom ? leave.dateFrom.split("T")[0] : "");
    setValue("dateTo", leave.dateTo ? leave.dateTo.split("T")[0] : "");
    setValue("reason", leave.reason);

    setIsMultipleDays(leave.dateFrom !== leave.dateTo);
  };

  // Delete Leave
  const handleDeleteLeave = async (id) => {
    if (window.confirm("Are you sure you want to delete this leave?")) {
      await dispatch(deleteLeave(id));
      toast.success("Leave deleted successfully!");
    }
  };

  const onAssignLeaveSubmit = async (data) => {
    try {
      if (isEdit && currentLeave) {
        await dispatch(
          updateAssignedLeave({ id: currentLeave.id, updatedData: data })
        );
        toast.success("Assigned leave updated successfully!");
      } else {
        await dispatch(assignLeave(data));
        toast.success("Leave assigned successfully!");
      }
      setIsAssignLeaveModalOpen(false);
      reset();
    } catch (error) {
      toast.error("Error processing request.");
    }
  };

  // Handle Delete Assigned Leave
  const handleDeleteAssignedLeave = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this assigned leave?")
    ) {
      await dispatch(deleteAssignedLeave(id));
      toast.success("Assigned leave deleted successfully!");
    }
  };

  // Handle Edit Assigned Leave
  const handleEditAssignedLeave = (leave) => {
    setIsEdit(true);
    setCurrentLeave(leave);
    setIsAssignLeaveModalOpen(true);
    setValue("employeeId", leave.employeeId);
    setValue("leaveType", leave.leaveType);
    setValue("numberOfLeave", leave.numberOfLeave);
  };

  const toggleExpand = (id) => {
    setExpandedCourseIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((courseId) => courseId !== id)
        : [...prevIds, id]
    );
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Dashboard Cards */}

      <div className="flex flex-col sm:flex-row sm:items-start sm:justify-start sm:space-x-4 w-full mb-4 space-y-4 sm:space-y-0">
        <button
          className="bg-[#241763] text-white p-2 rounded flex items-center"
          onClick={() => {
            setIsEdit(false);
            setIsApplyLeaveModalOpen(true);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Apply Leave</span>
        </button>
        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsEdit(false);
            setIsAssignLeaveModalOpen(true);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Assign Leave</span>
        </button>
        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => setIsModalOpen(true)}
        >
          <span className="ml-2 text-sm">Show Assigned Leaves</span>
        </button>
        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => navigate("/employee_all_leaves")} // Navigate to AllLeaveDetails
        >
          <span className="ml-2 text-sm">View Request</span>
        </button>
      </div>

      {/* Filters and Add Button */}
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">Leave Type</option>
          {/* Add location options */}
        </select>

        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Departments</option>
          {/* Add location options */}
        </select>

        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Designation</option>
          {/* Add location options */}
        </select>

        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Locations</option>
          {/* Add location options */}
        </select>
      </div>

      {/* Warning List Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Emp. Name</th>
              <th>Leave Type</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Reason</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {leaves.map((leave, index) => (
              <React.Fragment key={leave.id}>
                <tr className="p-2 py-2 hover:bg-gray-200 space-y-2">
                  <td className="py-4">
                    {
                      employees.find((emp) => emp.id === leave.employeeId)
                        ?.firstName
                    }{" "}
                    {
                      employees.find((emp) => emp.id === leave.employeeId)
                        ?.lastName
                    }
                  </td>
                  <td className=" capitalize">
                    {leave.leaveType.replace(/([A-Z])/g, " $1").trim()}
                  </td>
                  <td>
                    {new Date(leave.dateFrom).toLocaleDateString("en-GB")}
                  </td>
                  <td>{new Date(leave.dateTo).toLocaleDateString("en-GB")}</td>

                  <td className="">{leave.reason || "N/A"}</td>
                  <td className="flex space-x-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEditLeave(leave)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteLeave(leave.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => toggleExpand(leave.id)}
                    >
                      <ChevronDown
                        className={`w-4 h-4 group-hover:text-white transition-transform ${
                          expandedCourseIds.includes(leave.id)
                            ? "rotate-180"
                            : ""
                        }`}
                      />
                    </button>
                  </td>
                </tr>
                {expandedCourseIds.includes(leave.id) && (
                  <tr>
                    <td colSpan="8" className="p-4 bg-gray-100">
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                        aa
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Warning Modal */}
      {/* Apply Leave Modal */}
      <ApplyLeaveModal
        isModalOpen={isApplyLeaveModalOpen}
        isEdit={isEdit}
        onSubmit={onApplyLeaveSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsApplyLeaveModalOpen}
        register={register}
        reset={reset}
        employees={employees}
        isMultipleDays={isMultipleDays}
        setIsMultipleDays={setIsMultipleDays}
      />

      {/* Assign Leave Modal */}
      <AssignLeaveModal
        isModalOpen={isAssignLeaveModalOpen}
        isEdit={isEdit}
        onSubmit={onAssignLeaveSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsAssignLeaveModalOpen}
        register={register}
        reset={reset}
        employees={employees}
      />
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center  items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 h-[80vh] max-w-4xl">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Assigned Leaves</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-600 hover:text-gray-900"
              >
                ✖
              </button>
            </div>

            {/* Assigned Leaves Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full border">
                <thead className="bg-gray-200">
                  <tr>
                    <th>Employee Name</th>
                    <th>Leave Type</th>
                    <th>Total Leaves</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {assignedLeaves.map((assigned) => {
                    const employee = employees.find(
                      (emp) => emp.id === assigned.employeeId
                    );
                    return (
                      <tr key={assigned.id} className="border">
                        <td className="p-2">
                          {employee?.firstName} {employee?.lastName}
                        </td>
                        <td className="p-2">{assigned.leaveType}</td>
                        <td className="p-2">
                         {assigned.numberOfLeave}
                        </td>
                        <td className="p-2 flex space-x-2">
                        <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEditAssignedLeave(assigned)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteAssignedLeave(assigned.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <Toaster />
    </div>
  );
};

export default LeaveDetails;
