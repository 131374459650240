import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { datePickerSvg } from "../../Constant/svgs";
import "react-datepicker/dist/react-datepicker.css";

const ReactStartEndDatePicker = ({
  placeholder,
  getDate,
  name,
  selectedDate,
  minDate = null, // Flexibly pass a minimum date
}) => {
  const datePickerRef = useRef();

  const handleCalenderOpen = () => {
    datePickerRef.current.setOpen(true);
  };

  return (
    <div className="dateInputWrapper">
      <DatePicker
        ref={datePickerRef}
        selected={selectedDate ? new Date(selectedDate) : null}
        onChange={(date) => getDate(name, date)}
        dateFormat="yyyy-MM-dd"
        className="DatePicker"
        placeholderText={placeholder}
        minDate={minDate} // Disable dates before minDate
      />
      <div className="iconbox" onClick={handleCalenderOpen}>
        {datePickerSvg}
      </div>
    </div>
  );
};

export default ReactStartEndDatePicker;
