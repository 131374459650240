import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/rosters`;

// Fetch Rosters
export const fetchRosters = createAsyncThunk("rosters/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Roster
export const addRoster = createAsyncThunk("rosters/add", async (rosterData) => {
  const response = await axios.post(API_URL, rosterData);
  return response.data;
});

// Update Roster
export const updateRoster = createAsyncThunk("rosters/update", async ({ id, updatedData }) => {
  const response = await axios.put(`${API_URL}/${id}`, updatedData);
  return response.data;
});

// Delete Roster
export const deleteRoster = createAsyncThunk("rosters/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const rosterSlice = createSlice({
  name: "rosters",
  initialState: {
    rosters: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRosters.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRosters.fulfilled, (state, action) => {
        state.status = "succeeded";
    
        // Parse shifts properly before storing in state
        state.rosters = action.payload.map(roster => ({
            ...roster,
            shifts: typeof roster.shifts === "string" ? JSON.parse(roster.shifts) : roster.shifts
        }));
    })
    
      .addCase(fetchRosters.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addRoster.fulfilled, (state, action) => {
        state.rosters.push(action.payload);
      })
      .addCase(updateRoster.fulfilled, (state, action) => {
        const index = state.rosters.findIndex((r) => r.id === action.payload.id);
        if (index !== -1) {
            state.rosters[index] = {
                ...action.payload,
                shifts: typeof action.payload.shifts === "string" 
                    ? JSON.parse(action.payload.shifts) 
                    : action.payload.shifts
            };
        }
    })
      .addCase(deleteRoster.fulfilled, (state, action) => {
        state.rosters = state.rosters.filter((r) => r.id !== action.payload);
      });
  },
});

export default rosterSlice.reducer;
