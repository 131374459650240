import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStipends,
  addStipend,
  updateStipend,
  deleteStipend,
} from "../../redux/stipendSlice";
import { fetchEmployees } from "../../redux/employeeSlice";
import { Pencil, Trash2 } from "lucide-react";
import StipendModal from "../../modals/Hrms/StipendModal";

const Stipend = () => {
  const dispatch = useDispatch();
  const { stipends, status } = useSelector((state) => state.stipends);
  const { employees } = useSelector((state) => state.employees);

  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [editingStipend, setEditingStipend] = useState(null);

  useEffect(() => {
    dispatch(fetchStipends());
    dispatch(fetchEmployees());
  }, [dispatch]);

  const handleApplyStipend = (data) => {
    if (editingStipend) {
      dispatch(updateStipend({ id: editingStipend.id, updatedData: data }));
      setEditingStipend(null);
    } else {
      dispatch(addStipend(data));
    }
    setIsApplyModalOpen(false);
  };

  const handleDeleteStipend = (id) => {
    dispatch(deleteStipend(id));
  };

  const handleEditStipend = (stipend) => {
    setEditingStipend(stipend);
    setIsApplyModalOpen(true);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Session Name: March-2024</h1>
        <button
          onClick={() => setIsApplyModalOpen(true)}
          className="bg-[#241763] text-white px-4 py-2 rounded"
        >
          Apply Stipend
        </button>
      </div>

      {status === "loading" ? <p>Loading...</p> : null}

      <div className="overflow-x-auto overflow-visible">
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6 text-sm">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>EMP. CODE</th>
                <th>EMPLOYEE NAME</th>
                <th>SESSION</th>
                <th>DATE</th>
                <th>AMOUNT</th>
                <th>DESCRIPTION</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {stipends.map((stipend) => (
                <tr key={stipend.id}>
                  <td>{stipend.Employee?.id || "N/A"}</td>
                  <td>
                    <div>
                      <div className="font-medium">
                        {stipend.Employee?.firstName}{" "}
                        {stipend.Employee?.lastName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {stipend.Employee?.department}
                      </div>
                    </div>
                  </td>
                  <td>{stipend.sessionName}</td>
                  <td>{stipend.applyDate}</td>
                  <td>{stipend.amount}</td>
                  <td>{stipend.description}</td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEditStipend(stipend)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteStipend(stipend.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <StipendModal
        isOpen={isApplyModalOpen}
        onClose={() => {
          setEditingStipend(null);
          setIsApplyModalOpen(false);
        }}
        onSubmit={handleApplyStipend}
        initialData={editingStipend}
        employees={employees} // Pass the fetched employees
      />
    </div>
  );
};

export default Stipend;
