import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "http://localhost:5001/api";
const API_URL = `${BASE_URL}/dues`;

// Fetch Dues
export const fetchDues = createAsyncThunk("dues/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Dues
export const addDues = createAsyncThunk(
  "dues/add",
  async (duesData) => {
    const response = await axios.post(API_URL, duesData);
    return response.data;
  }
);

// Update Dues
export const updateDues = createAsyncThunk(
  "dues/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Dues
export const deleteDues = createAsyncThunk("dues/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const duesSlice = createSlice({
  name: "dues",
  initialState: {
    dues: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDues.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDues.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dues = action.payload;
      })
      .addCase(fetchDues.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addDues.fulfilled, (state, action) => {
        state.dues.push(action.payload);
      })
      .addCase(updateDues.fulfilled, (state, action) => {
        const index = state.dues.findIndex(d => d.id === action.payload.id);
        if (index !== -1) {
          state.dues[index] = action.payload;
        }
      })
      .addCase(deleteDues.fulfilled, (state, action) => {
        state.dues = state.dues.filter(d => d.id !== action.payload);
      });
  },
});

export default duesSlice.reducer;