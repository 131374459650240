import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import UserModal from "../../modals/Hrms/UserModal";
import { Key, Pencil, Trash2 } from "lucide-react";
import { use } from "react";
import RolePermissionModal from "../../modals/Hrms/RolePermissionModal";

export default function UserList() {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: "SA Systems",
      email: "sasystems.solution@gmail.com",
      password: "12345678",
      employeeCode: "SAS-02",
      location: "New York",
      role: "Admin Manager",
      status: "Active",
    },
    {
      id: 2,
      name: "Oliver James",
      email: "oliver@sasystems.solutions",
      password: "oliver1234",
      employeeCode: "SAS-03",
      location: "New York",
      role: "Associate Software Engineer",
      status: "Active",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (editingUser) {
      reset(editingUser);
    }
  }, [editingUser, reset]);

  // Handle form submission (add or edit user)
  const handleFormSubmit = (data) => {
    if (editingUser) {
      setUsers(
        users.map((user) =>
          user.id === editingUser.id ? { ...editingUser, ...data } : user
        )
      );
    } else {
      setUsers([...users, { id: users.length + 1, ...data }]);
    }
    setIsOpen(false);
    reset();
  };

  const handleDeleteUser = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const handleGiveAccess = (user) => {
    setSelectedUser(user);
    setIsPermissionModalOpen(true);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen text-sm">
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-white shadow-md rounded-lg text-center">
          <h2 className="text-lg font-semibold">Total Users</h2>
          <p className="text-2xl font-bold">{users.length}</p>
        </div>
        <div className="p-4 bg-white shadow-md rounded-lg text-center">
          <h2 className="text-lg font-semibold">Active Users</h2>
          <p className="text-2xl font-bold">
            {users.filter((user) => user.status === "Active").length}
          </p>
        </div>
        <div className="p-4 bg-white shadow-md rounded-lg text-center">
          <h2 className="text-lg font-semibold">Not Active Users</h2>
          <p className="text-2xl font-bold">
            {users.filter((user) => user.status === "Not Active").length}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Users</h2>
        <button
          onClick={() => {
            setEditingUser(null);
            reset();
            setIsOpen(true);
          }}
          className="px-4 py-2 bg-[#241763] text-white rounded-lg"
        >
          Add New User
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg p-4">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 text-left">Name</th>
              <th className="p-2 text-left">Email</th>
              <th className="p-2 text-left">Location</th>
              <th className="p-2 text-left">Role</th>
              <th className="p-2 text-left">Status</th>
              <th className="p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="border-b">
                <td className="p-2">{user.name}</td>
                <td className="p-2">{user.email}</td>
                <td className="p-2">{user.location}</td>
                <td className="p-2">{user.role}</td>
                <td className="p-2">
                  <span
                    className={`px-2 py-1 rounded text-white ${
                      user.status === "Active" ? "bg-green-500" : "bg-red-500"
                    }`}
                  >
                    {user.status}
                  </span>
                </td>
                <td className="space-x-2 flex items-center py-2">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                    onClick={() => {
                      setEditingUser(user);
                      setIsOpen(true);
                    }}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 group"
                    onClick={() => handleGiveAccess(user)}
                  >
                    <Key className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isOpen && (
        <UserModal
          setIsOpen={setIsOpen}
          register={register}
          handleSubmit={handleSubmit}
          handleFormSubmit={handleFormSubmit}
          errors={errors}
          editingUser={editingUser}
        />
      )}
      {isPermissionModalOpen && (
        <RolePermissionModal
          user={selectedUser}
          setIsPermissionModalOpen={setIsPermissionModalOpen}
        />
      )}
    </div>
  );
}
