import React, { useState, useEffect } from "react";
import { getRequest } from "../../services";
import { Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const GradeTable = (props) => {

  const [currentPage, setCurrentPage] = useState(0);
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { studentGrade, detail } = examData; 
  const { filteredExamsId } = props;
  
  const handlePagination = (direction) => {
    setCurrentPage((prev) =>
      direction === "next" ? prev + 1 : prev - 1
    );
  };
  
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(`/api/Grades/StudentGrades?examId=${filteredExamsId[currentPage].id}&examType=Question`);
      const data = response.data;
      setExamData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, filteredExamsId]);
console.log("examData",examData)
  return (
    <>
      <Spin spinning={loading}>
        <div className="container mt-2">
          <table className="table-bordered mb-0 border text-center">
            <tbody>
              <tr>
                <th className="p-0 align-middle fw-bold">Subject</th>
                <td className="p-0 align-middle">{filteredExamsId[0]?.subjectDetails?.subjectName}</td>
                <th className="p-0 align-middle fw-bold">Exam Title</th>
                <td className="p-0 align-middle">{filteredExamsId[0]?.title}</td>
              </tr>
              <tr>
                <th className="p-0 align-middle fw-bold">Date</th>
                <td className="p-0 align-middle">{new Date(studentGrade?.createdAt).toLocaleDateString()}</td>
                <th className="p-0 align-middle fw-bold">Time</th>
                <td className="p-0 align-middle">{new Date(studentGrade?.createdAt).toLocaleTimeString()}</td>
              </tr>
              <tr>
                <th className="p-0 align-middle fw-bold">Total Marks</th>
                <td className="p-0 align-middle">{studentGrade?.totalMarks}</td>
                <th className="p-0 align-middle fw-bold">Obtained Marks</th>
                <td className="p-0 align-middle">{studentGrade?.marksObtained}</td>
              </tr>
              <tr>
                <th className="p-0 align-middle fw-bold">%age</th>
                <td className="p-0 align-middle">{studentGrade?.percentage}%</td>
                <th className="p-0 align-middle fw-bold">Remark</th>
                <td className="p-0 align-middle">{studentGrade?.remarks}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="container-table100 mt-3">
          <div className="wrap-table100">
            <div className="ver5 m-b-110">
              <div className="table100-body">
                <table>
                  <tbody>
                    {detail && detail.length > 0 ? (
                      <div className="bg-gray-50 rounded p-6">
                        {detail.map((item, index) => (
                          <div key={index} className="mb-4 border-b pb-4 border-gray-200">
                            <p> <strong>{index + 1}: {item.question.questionText}</strong>  </p>
                            <p><strong>Answer: </strong>{item.question.submissionAnswer}</p>
                            <p><strong>Marks: </strong>{`${item.question.marksObtained}/${item.question.marks}`}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center">No data available</div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      {filteredExamsId.length === 0 ? null :
        <div className="flex justify-center mt-4 pb-4 gap-3 paginationStyle">
          <button
            onClick={() => handlePagination("previous")}
            disabled={currentPage < 1}
            className={`px-3 py-1 bg-[#241763] text-white rounded ${currentPage < 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#241763] text-white hover:bg-[#241763]"}`
            }
          >
            <LeftOutlined />  
          </button>
          <span className="bg-[#241763] text-white px-3 py-1 mx-1 border rounded d-flex align-items-center">
            {`${currentPage + 1}/${filteredExamsId.length}`}
          </span>
          <button
            onClick={() => handlePagination("next")}
            disabled={currentPage >= filteredExamsId.length - 1}
            className={`px-4 py-2 rounded ${currentPage >= filteredExamsId.length - 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#241763] text-white hover:bg-[#241763]"}`
            }
          >
            <RightOutlined />
          </button>
        </div>
      }
    </>
  );
};

export default GradeTable;
