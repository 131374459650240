import React, { useState } from "react";
import HrApproval from "../../Components/tabs/hr/HrApproval";
import GeneratedSalaries from "../../Components/tabs/hr/GeneratedSalaries";
import FinancialApproval from "../../Components/tabs/hr/FinanacialApproval";
import Distribution from "../../Components/tabs/hr/Distribution";
import PendingSalaries from "../../Components/tabs/hr/PendingSalaries";

function Salaries() {
  // Tabs with emojis
  const tabs = [
    { name: "Genrated Salaries", emoji: "💰" },
    { name: "HR Approval", emoji: "✅" },
    { name: "Finance Approval", emoji: "💵" },
    { name: "Distributions", emoji: "📤" },
    { name: "Pending Salaries", emoji: "⏳" },
  ];

  const [activeTab, setActiveTab] = useState("Genrated Salaries"); // Default tab
  const [feeStructures, setFeeStructures] = useState([]);
  const [feeBills, setFeeBills] = useState([]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Tabs */}
    {/* Tabs */}
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 border-2 border-gray-100 shadow-xl mb-4 pb-3 pt-2 rounded-b-xl">
  {tabs.map((tab) => (
    <button
      key={tab.name}
      onClick={() => handleTabClick(tab.name)}
      className={`px-4 py-2 flex items-center text-sm justify-center space-x-2 ${
        activeTab === tab.name
          ? "text-white bg-[#241763]"
          : "bg-gray-200 text-gray-700"
      } rounded-md text-center`}
    >
      <span className="text-lg">{tab.emoji}</span>
      <span>{tab.name}</span>
    </button>
  ))}
</div>



      {/* Tab Content */}
      <div className="overflow-x-auto">
        {activeTab === "Genrated Salaries" && (
          <GeneratedSalaries
            feeStructures={feeStructures}
            setFeeStructures={setFeeStructures}
          />
        )}

        {activeTab === "HR Approval" && (
          <HrApproval feeBills={feeBills} setFeeBills={setFeeBills} />
        )}

        {activeTab === "Finance Approval" && <FinancialApproval />}

        {activeTab === "Distributions" && <Distribution />}

        {activeTab === "Pending Salaries" && <PendingSalaries />}
      </div>
    </div>
  );
}

export default Salaries;

