import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ApplyLeaveModal from "./ApplyLeaveModal";
import {
  fetchLeaves,
  applyLeave,
  updateLeave,
  deleteLeave,
} from "../../redux/applyLeaveSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../redux/employeeSlice";
const DashboardModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
   const { register, handleSubmit, reset, setValue } = useForm();
  const { leaves, status, error } = useSelector((state) => state.leaves);
  const { employees } = useSelector((state) => state.employees || {});
   
   const [isApplyLeaveModalOpen, setIsApplyLeaveModalOpen] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isEdit, setIsEdit] = useState(false);
   const [currentLeave, setCurrentLeave] = useState(null);
   const [isMultipleDays, setIsMultipleDays] = useState(false);

  useEffect(() => {
    dispatch(fetchLeaves());
    dispatch(fetchEmployees());
  }, [dispatch]);

  const onApplyLeaveSubmit = async (data) => {
    try {
      if (isEdit) {
        await dispatch(updateLeave({ id: currentLeave.id, updatedData: data }));
        toast.success("Leave updated successfully!");
      } else {
        await dispatch(applyLeave(data));
        toast.success("Leave applied successfully!");
      }
      setIsApplyLeaveModalOpen(false);
      reset();
    } catch (error) {
      toast.error("Error processing request.");
    }
  };

  const handleEditLeave = (leave) => {
    setIsEdit(true);
    setCurrentLeave(leave);
    setIsApplyLeaveModalOpen(true);
    setValue("employeeId", leave.employeeId);
    setValue("leaveType", leave.leaveType);
    setValue("dateFrom", leave.dateFrom ? leave.dateFrom.split("T")[0] : "");
    setValue("dateTo", leave.dateTo ? leave.dateTo.split("T")[0] : "");
    setValue("reason", leave.reason);

    setIsMultipleDays(leave.dateFrom !== leave.dateTo);
  };

  // Delete Leave
  const handleDeleteLeave = async (id) => {
    if (window.confirm("Are you sure you want to delete this leave?")) {
      await dispatch(deleteLeave(id));
      toast.success("Leave deleted successfully!");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl relative max-h-[90vh] overflow-y-auto">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-lg"
        >
          ✖
        </button>

        {/* Modal Content */}
        <div className="text-sm">
          <h2 className="text-lg font-semibold mb-4">
            Current Month Attendance
          </h2>

          <div className="grid grid-cols-5 gap-4 text-center bg-gray-100 p-4 rounded-lg mb-4">
            {[
              { icon: "📅", label: "Total Days", value: 0 },
              { icon: "🧑‍💼", label: "Present", value: 0 },
              { icon: "⏰", label: "Late", value: 0 },
              { icon: "📄", label: "Leave", value: 0 },
              { icon: "🚩", label: "Absent", value: 0 },
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <span className="text-2xl">{item.icon}</span>
                <span className="text-lg font-semibold">{item.value}</span>
                <span className="text-gray-500">{item.label}</span>
              </div>
            ))}
          </div>

          {/* Action Buttons */}
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
            {[
              { title: "Mark manual Attendance", button: "Mark Now" },
              { title: "My Profile", button: "View" },
              { title: "Apply Loan", button: "Apply Now" },
              {
                title: "Apply Leave",
                button: "Apply Now",
                action: () => setIsApplyLeaveModalOpen(true),
              },
              { title: "View Team Leaves", button: "View" },
              { title: "View Team Attendance", button: "View" },
              { title: "Time Adjustment", button: "Apply Now" },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-lg shadow-md text-center"
              >
                <h3 className="text-md font-semibold">{item.title}</h3>
                <button
                  className="mt-2 px-4 py-2 bg-purple-600 text-white rounded-lg"
                  onClick={item.action ? item.action : null}
                >
                  {item.button}
                </button>
              </div>
            ))}
          </div>

          {/* Leave Details */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {[
              { title: "Annual Leaves", used: 0, total: 0, emoji: "🎈" },
              { title: "Sick Leaves", used: 0, total: 0, emoji: "🩺" },
              { title: "Casual Leaves", used: 0, total: 0, emoji: "🎉" },
            ].map((item, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-md">
                <div className="flex justify-between items-center">
                  <h3 className="text-md font-semibold flex items-center">
                    <span className="mr-2">{item.emoji}</span> {item.title}
                  </h3>
                  <a href="#" className="text-blue-500 text-sm">
                    View Detail
                  </a>
                </div>
                <p className="text-gray-500 text-sm">
                  {item.used} Used / Out of {item.total}
                </p>
                <div className="w-full bg-gray-200 h-2 rounded-lg mt-2">
                  <div
                    className="bg-purple-600 h-2 rounded-lg"
                    style={{ width: "0%" }}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          {/* Attendance Table */}
          <div className="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
            <div className="flex justify-between text-gray-500 text-sm">
              <p>Avg Check In: Not available</p>
              <p>Avg Check Out: Not available</p>
            </div>
            <table className="w-full mt-4 border-collapse border bg-white">
              <thead>
                <tr className="bg-gray-200">
                  {[
                    "DATE",
                    "DAY",
                    "CHECK IN",
                    "LATE",
                    "CHECK OUT",
                    "EXCESS",
                    "MARKED BY",
                    "ACTIONS",
                  ].map((header, index) => (
                    <th key={index} className="border p-2 text-left">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="8" className="text-center p-4 text-gray-500">
                    No Data Available
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ApplyLeaveModal
        isModalOpen={isApplyLeaveModalOpen}
        isEdit={isEdit}
        onSubmit={onApplyLeaveSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsApplyLeaveModalOpen}
        register={register}
        reset={reset}
        employees={employees}
        isMultipleDays={isMultipleDays}
        setIsMultipleDays={setIsMultipleDays}
      />
    </div>
  );
};

export default DashboardModal;
