import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createInterviewsForCandidate } from "./interviewSlice";
import axios from "axios";

export const createCandidate = createAsyncThunk(
  "candidates/createCandidate",
  async (candidateData, { dispatch }) => {
    const response = await axios.post(API_URL, candidateData);
    
    // After creating the candidate, also create interviews for them
    dispatch(createInterviewsForCandidate(response.data.id));
    
    return response.data;
  }
);

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/candidates`;

// Fetch Candidates
export const fetchCandidates = createAsyncThunk("candidates/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Candidate
export const addCandidate = createAsyncThunk("candidates/add", async (candidateData) => {
  const response = await axios.post(API_URL, candidateData);
  return response.data;
});

// Update Candidate
export const updateCandidate = createAsyncThunk(
  "candidates/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Candidate
export const deleteCandidate = createAsyncThunk("candidates/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const candidateSlice = createSlice({
  name: "candidates",
  initialState: {
    candidates: [],
    loading: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidates.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchCandidates.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.candidates = action.payload;
      })
      .addCase(fetchCandidates.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(addCandidate.fulfilled, (state, action) => {
        state.candidates.push(action.payload);
      })
      .addCase(updateCandidate.fulfilled, (state, action) => {
        const index = state.candidates.findIndex(c => c.id === action.payload.id);
        if (index !== -1) {
          state.candidates[index] = action.payload;
        }
      })
      .addCase(deleteCandidate.fulfilled, (state, action) => {
        state.candidates = state.candidates.filter(c => c.id !== action.payload);
      });
  },
});

export default candidateSlice.reducer;