import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const ApplyArreasModal = ({ isOpen, onClose, onSubmit, initialData }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const handleFormSubmit = (data) => {
    onSubmit(data);
    reset();
    onClose();
  };

  useEffect(() => {
    if (initialData) {
      // Set form fields with initial data when editing
      Object.keys(initialData).forEach((key) => {
        setValue(key, initialData[key]);
      });
    } else {
      reset(); // Clear the form when no initial data is provided
    }
  }, [initialData, setValue, reset]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-medium">
            {initialData ? "Edit Dues for Employee" : "Apply Dues to Employee"}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            ×
          </button>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium">Session Name</label>
            <input
              type="text"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              defaultValue="March-2024"
              {...register("sessionName")}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Apply Date *</label>
            <input
              type="date"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("applyDate", { required: true })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Salary*</label>
            <input
              type="number"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("salary", { required: true })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Employee Name *</label>
            <select
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("employeeName", { required: true })}
            >
              <option value="" disabled>
                Select option
              </option>
              <option value="Hannah Luke">Hannah Luke</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Allownce Type *</label>
            <select
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("duesType", { required: true })}
            >
              <option value="" disabled>
                Select Type
              </option>
              <option value="Welfare">Welfare</option>
              <option value="Funeral">Funeral</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Amount *</label>
            <input
              type="number"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("duesAmount", { required: true })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Description</label>
            <textarea
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("description")}
              placeholder="Enter description"
            ></textarea>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#231763] text-white px-4 py-2 rounded"
            >
              {initialData ? "Update Arreas" : "Apply Arreas"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyArreasModal;


