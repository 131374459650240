import { configureStore } from "@reduxjs/toolkit";
import i18n from "../i18n";

import parentReducer from "../redux/ParentSlice";
import toggleSlice from "../redux/ToggleSlice";
import userSlice from "../redux/UserSlice";
import languageSlice from "../redux/languageSlice";
import adminCredSlice from "../redux/adminCredSlice";
import authSlice from "../redux/AuthSlice";
import teachersSlice from "../redux/teachersSlice";
import studentSlice from "../redux/studentSlice";
import classesSlice from "../redux/classSlice";
import booksSlice from "../redux/booksSlice";
import classSectionsSlice from "../redux/classSectionsSlice";
import subjectsSlice from "../redux/subjectsSlice";
import coursesSlice from "../redux/coursesSlice";
import lessonSlice from "../redux/lessonSlice";
import dashboardSlice from "../redux/dashboardSlice";
import campusesSlice from "../redux/campusesSlice";
import managementSlice from "../redux/managementSlice";
import feesStructuresSlice from "../redux/feeStructureSlice";
import jobsSlice from "../redux/jobsSlice";
import candidateSlice from "../redux/candidateSlice";
import employeeSlice from "../redux/employeeSlice";
import warningSlice from "../redux/warningSlice";
import applyLeaveSlice from "../redux/applyLeaveSlice";
import assignLeaveSlice from "../redux/assignLeaveSlice";
import attendanceSlice from "../redux/attendanceSlice";
import overtimeSlice from "../redux/overtimeSlice";
import timeAdjustmentSlice from "../redux/timeAdjustmentSlice";
import gracePeriodSlice from "../redux/gracePeriodSlice";
import rosterSlice from "../redux/rosterSlice";
import salarySlice from "../redux/salarySlice";
import welfareAllowanceSlice from "../redux/welfareAllowanceSlice";
import stipendSlice from "../redux/stipendSlice";
import taxSlice from "../redux/taxSlice";
import examSlice from "../redux/examSlice";
import loanAdvanceSlice from "../redux/loanAdvanceSlice";
import duesSlice from "../redux/duesSlice";
import interviewSlice from "../redux/interviewSlice"

export const store = configureStore({
  reducer: {
    toggler: toggleSlice,
    user: userSlice,
    language: languageSlice,
    admincredslice: adminCredSlice,
    auth: authSlice,
    teachers: teachersSlice,
    students: studentSlice,
    parents: parentReducer,
    classes: classesSlice,
    books :booksSlice,
    classSections: classSectionsSlice,
    subjects: subjectsSlice,
    courses: coursesSlice,
    lessons: lessonSlice,
    dashboard: dashboardSlice,
    campuses: campusesSlice,
    management: managementSlice,
    feeStructures: feesStructuresSlice,
    jobs: jobsSlice,
    candidates: candidateSlice,
    employees: employeeSlice,
    warnings: warningSlice,
    leaves: applyLeaveSlice,
    assignLeave: assignLeaveSlice,
    attendance: attendanceSlice,
    overtime: overtimeSlice,
    timeAdjustments: timeAdjustmentSlice,
    gracePeriods: gracePeriodSlice,
    rosters: rosterSlice,
    salaries: salarySlice,
    welfareAllowance: welfareAllowanceSlice,
    stipends: stipendSlice,
    taxes: taxSlice,
    exam: examSlice,
    loanAdvances: loanAdvanceSlice,
    dues: duesSlice,
    interviews: interviewSlice,
  },
});

let previousLanguageCode = store.getState().language.code;

store.subscribe(() => {
  const state = store.getState();
  const currentLanguageCode = state.language.code;

  if (previousLanguageCode !== currentLanguageCode) {
    i18n.changeLanguage(currentLanguageCode);
    previousLanguageCode = currentLanguageCode; 
  }
});
