import React, { useState } from "react";


import CashDistributionList from "../HrTabs/CashDistributionList";
import GenerateSlip from "../HrTabs/GenerateSlip";
import BankDistributionSlip  from "../HrTabs/BankDistributionSlip";

function Distribution() {
  const tabs = ["Generate Slip", "Bank Distribution Slip"]; // Tab Names
  const [activeTab, setActiveTab] = useState("Generate Slip"); // Default tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container mx-auto p-4 border border-gray-300 rounded-lg">
      {/* Tabs */}
      <div className="flex space-x-4 border-b border-gray-300 mb-4">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 font-medium ${
              activeTab === tab
                 ? "text-[#241763] border-b-2 border-[#241763]"
                  : "text-gray-500 hover:text-[#241763]"
            } rounded-t-md`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div>
        {activeTab === "Generate Slip" && <GenerateSlip />}
        {activeTab === "Bank Distribution Slip" && <BankDistributionSlip />}
        {activeTab === "Car Distribution List" && <CashDistributionList />}
      </div>
    </div>
  );
}

export default Distribution;
