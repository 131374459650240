import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSubjects = createAsyncThunk('subjects/fetchSubjects', async (sectionId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://lms-api.wiserbee.ca/api/ClassSubject/GetSubjects/${sectionId}`);
      return response.data?.data;  // Assuming `response.data` contains the subjects
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  
  const subjectsSlice = createSlice({
    name: 'subjects',
    initialState: {
      subjects: [],
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchSubjects.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchSubjects.fulfilled, (state, action) => {
          state.loading = false;
          state.subjects = action.payload;
        })
        .addCase(fetchSubjects.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default subjectsSlice.reducer;
  