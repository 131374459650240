import React, { useState, useEffect } from "react";
import { courses } from "../../Constant/courses";
import { X } from 'lucide-react';
import Chip from "./Chip";
import { User } from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Pagination } from "antd";
import FlexBtn from "../Common/FlexBtn";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../services";
import { Spin } from 'antd';

const Course = (prop) => {

  const { count = 4, useToggle = true, homeRender, suggested } = prop;
  const toggleFilter = useSelector((state) => state.toggler.toggleFilter);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [myData, setMyData] = useState();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const navigate = useNavigate();
  const [chunkSize, setChunkSize] = useState(3);

  // Determine chunk size based on screen width
  const updateChunkSize = () => {
    const width = window.innerWidth;
    if (width >= 992) {
      setChunkSize(3); // Large screens show 3
    } else if (width >= 768) {
      setChunkSize(2); // Medium screens show 2 
    } else {
      setChunkSize(1); // Small screens show 1
    }
  };

  useEffect(() => {
    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);
    return () => window.removeEventListener("resize", updateChunkSize);
  }, []);

  function getInitials(name) {
    const parts = name.split(" ");
    let initials = "";
    for (let part of parts) {
      initials += part[0];
    }
    return initials;
  }
  const withToggleClassName = `${toggleFilter ? "col-md-6 col-xl-4" : "col-md-4 col-xl-3"
    } col-sm-6 cursor-pointer `;
  const withOutToggleClassName = `${homeRender ? "col-12 col-md-4" : "col-4"
    }  px-2 cursor-pointer`;

  const showModal = (course) => {
    setSelectedCourse(course);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const coursesEntroll = {
    thumbnail:
      "https://img.freepik.com/free-psd/e-learning-template-design_23-2151081790.jpg?w=1380&t=st=1709102119~exp=1709102719~hmac=c668c8d052650f49fd1c9ab03acb099107f23b105a45d54d2890870b43296d59",
    bestSeller: 'Best Seller',
    onSale: '20%',
    courseTitle: "Adobe illustrator scretch course",
    instructor: "wiserbee studio",
    totalStar: "4.5",
    totalRatings: "5.3k",
    description:
      "More than 8yr Experience as Illustrator. Learn how to becoming professional Illustrator Now...",
    price: '$32.9',
    salesPrice: '$24.92',
  }

  const fetchDataCourses = async (page = 1, pageSize = 6) => {
    setLoading(true);
    try {
      const response = await getRequest(
        `/api/Course?pageNumber=${page}&pageSize=${pageSize}`
      );
      const data = response.data.data;
      setMyData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataCourses();
  }, []);

  // Group courses into chunks for carousel based on screen size
  const chunkArray = (arr, size) => {
    const chunkedArr = [];
    for (let i = 0; i < arr?.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };

  return (
    <Spin spinning={loading}>
      <div className="row pl-0">
        <h3 className={`myClassesHeading ${isArabic === "sa" ? "text-end" : ""}`}>{t('MyCourses')}</h3>
       
          {myData?.items.map((course, index) => (
            <div
              className={useToggle ? withToggleClassName : withOutToggleClassName}
              onClick={() => navigate(`/course/${course.id}`, { state: { ...course } })}
              key={index}
            >
              <div className="col-12 col-md-12 customShadow  courseCard px-2 py-2 position-relative d-flex flex-column gap-2 my-2">
                <div className="CourseThumbnail">
                  <img
                    src={course.coverImageUrl}
                    className="rounded h-100 w-100"
                    alt="course thumbnail"
                  />
                </div>
                <h6 className="mb-0 fw-bold">{course.name}</h6>
                <div className="d-flex align-items-center courseInstructor d-md-flex flex-md-row flex-column">
                  <div className="d-flex">
                    <User className="userIcon" />
                    <h4 className="p-0 m-0 text-uppercase fg-main">
                      {getInitials("Muhammad Kashif")}
                    </h4>
                  </div>
                  <div className="d-flex  align-items-center ">
                    {/* <Stars stars={5} /> */}
                    <div
                      className="Stars"
                      style={{ "--rating": course.rating, fontSize: "18px" }}
                      aria-label="Rating of this product is 2.3 out of 5."
                    ></div>
                    <h5 className="p-0 m-0">({course.rating})</h5>
                  </div>
                </div>
                <div className="courseDescription text-container">
                  <p>{course.description}</p>
                </div>
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-end mt-4">
            <Pagination
              current={myData?.pageNumber || 1}
              pageSize={myData?.pageSize || 10}
              total={myData?.totalCount || 0}
              onChange={(page, pageSize) => {
                fetchDataCourses(page, pageSize);
              }}
              showSizeChanger={false}
            />
          </div>

          {suggested && (
            <>
              <h4 className="fw-bold myClassesHeading my-3">{t("Suggested Courses")}</h4>
              <div id="suggestedCoursesCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  {chunkArray(myData?.items, chunkSize).map((_, index) => (
                    <button
                      key={index}
                      type="button"
                      data-bs-target="#suggestedCoursesCarousel"
                      data-bs-slide-to={index}
                      className={index === 0 ? "active" : ""}
                      aria-current={index === 0 ? "true" : "false"}
                      aria-label={`Slide ${index + 1}`}
                    ></button>
                  ))}
                </div>
                <div className="carousel-inner">
                  {chunkArray(myData?.items, chunkSize).map((chunk, chunkIndex) => (
                    <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                      <div className="row">
                        {chunk.map((course, index) => (
                          <div key={index} className={`col-12 ${chunkSize === 3 ? 'col-md-4' : chunkSize === 2 ? 'col-md-6' : ''}`}>
                            <div className="col-12 col-md-12 customShadow courseCard px-2 py-2 position-relative d-flex flex-column gap-2 my-2">
                              <div className="CourseThumbnail">
                                <img
                                  src={course.coverImageUrl}
                                  className="rounded h-100 w-100"
                                  alt="course thumbnail"
                                />
                              </div>
                              <div className="position-absolute d-flex mt-1 ms-2 gap-3">
                                <Chip text='Best Saler' bg="#fffa7d" color="black" />
                                <Chip text={`${(course.discountedPrice / (course.discountedPrice + course.price) * 100).toFixed(2)} %`} bg="#A04AE3" color="white" />
                              </div>
                              <h6 className="mb-0 fw-bold">{course.courseTitle}</h6>
                              <div className="d-flex align-items-center courseInstructor d-md-flex flex-md-row flex-column">
                                <div className="d-flex">
                                  <User className="userIcon" />
                                  <h4 className="p-0 m-0 text-uppercase fg-main">
                                    {getInitials('Muhammad Kashif')}
                                  </h4>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="Stars"
                                    style={{ "--rating": course.rating, fontSize: "18px" }}
                                    aria-label="Rating of this product is 2.3 out of 5."
                                  ></div>
                                  <h5 className="p-0 m-0">({course.rating})</h5>
                                </div>
                              </div>
                              <div className="courseDescription text-container">
                                <p>{course.description}</p>
                              </div>
                              <div className="d-flex gap-3 pricing">
                                <h6 className="m-0 p-0 fw-bold salesPrice">
                                  ${course.price}
                                </h6>
                                <h6 className="m-0 p-0 text-sm normalPrice align-self-center">
                                  ${(course.discountedPrice + course.price)}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#suggestedCoursesCarousel" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#suggestedCoursesCarousel" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </>
          )}
        
      </div>
      <Modal
        title={
          <div className="row d-flex justify-content-center p-0 m-0">
            <div className="col-md-12 examModalWrapper p-0 m-0">
              <div
                className={`d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader studentProfileHeading ${isArabic === "sa" ? "flex-row-reverse" : ""
                  }`}
              >
                <h4 style={{ color: "#060317" }} className="fw-bold">
                  {t("Course Entrollment")}
                </h4>
                <div className="iconWrapper cursor-pointer" onClick={handleCancel}>
                  <X />
                </div>
              </div>
            </div>
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={800}
      >
        <>
          <div className='row w-100 gx-0'>
            <div className="col-12 col-md-6  customShadow  courseCard px-2 py-2  d-flex flex-column gap-2 my-2">
              <div className="CourseThumbnail">
                <img
                  src={coursesEntroll.thumbnail}
                  className="rounded h-100 w-100"
                  alt="course thumbnail"
                />
              </div>
              <div className="position-absolute d-flex mt-1 ms-2 gap-3">
                {coursesEntroll.bestSeller && (
                  <Chip text={coursesEntroll.bestSeller} bg="#fffa7d" color="black" />
                )}
                {coursesEntroll.onSale && (
                  <Chip text={coursesEntroll.onSale} bg="#A04AE3" color="white" />
                )}
              </div>
              <h6 className="mb-0 fw-bold">{coursesEntroll.courseTitle}</h6>
              <div className="d-flex align-items-center courseInstructor d-md-flex flex-md-row flex-column">
                <div className="d-flex">
                  <User className="userIcon" />
                  <h4 className="p-0 m-0 text-uppercase fg-main">
                    {getInitials(coursesEntroll.instructor)}
                  </h4>
                </div>
                <div className="d-flex  align-items-center ">
                  <div
                    className="Stars"
                    style={{ "--rating": coursesEntroll.totalStar, fontSize: "18px" }}
                    aria-label="Rating of this product is 2.3 out of 5."
                  ></div>
                  <h5 className="p-0 m-0">({coursesEntroll.totalRatings})</h5>
                </div>
              </div>
              <div className="courseDescription text-container">
                <p>{coursesEntroll.description}</p>
              </div>
              <div className="d-flex gap-3 pricing ">
                <h6 className="m-0 p-0 fw-bold salesPrice">
                  {coursesEntroll.salesPrice}
                </h6>
                <h6 className="m-0 p-0 text-sm normalPrice align-self-center">
                  {coursesEntroll.price}
                </h6>
              </div>
            </div>
            <div className="col-12 col-md-6 p-4">
              <div className="taskDetails cursor-pointer mb-3">
                <h6>Machine Learning</h6><p>08.00 pm - 10.00 pm</p>
              </div>
              <div className="taskDetails cursor-pointer mb-3">
                <h6>Duration</h6><p>March 2025 - June 2025</p>
              </div>
              <div className="taskDetails cursor-pointer mb-3">
                <h6>Leacture Day</h6><p>Saturday , Sunday</p>
              </div>
              <div className="taskDetails cursor-pointer mb-3">
                <h6>Description</h6>
                <p className="mt-3">Machine learning is a type of artificial intelligence (AI) that teaches computers to learn from data and improve with experience. It's a method of data analysis that uses algorithms  </p>
              </div>
            </div>

          </div>
          <div className="d-flex gap-3 justify-content-end p-3">
            <span onClick={handleCancel}>
              <FlexBtn
                label={t("Cancel")}
                color="#463C77"
                bgColor="#EDEBF1"
              />
            </span>
            <span onClick={handleOk}>
              <FlexBtn
                label={t("Enroll")}
                color="white"
                bgColor="#463C77"
                style={{ fontSize: "14px" }}
              />
            </span>
          </div>
        </>
      </Modal>
      </Spin>
  );
};

export default Course;
