import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
    examReport: null,
    loading: false,
    error: null,
};

export const fetchExamReport = createAsyncThunk(
    "exam/fetchExamReport",
    async (studentId, { rejectWithValue }) => {
        try {
            const response = await getRequest(`/api/Reports/GetExamReport?studentId=${studentId}`);
            console.log("API Response:", response.data);
            if (response.status !== 200) throw new Error("Failed to fetch exam report");
            return response.data; // Return the array as is, we'll handle it in the component
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const examSlice = createSlice({
    name: "exam",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchExamReport.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchExamReport.fulfilled, (state, action) => {
                state.loading = false;
                state.examReport = action.payload;
            })
            .addCase(fetchExamReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                toast.error(action.payload || "Failed to fetch exam report");
            });
    },
});

export default examSlice.reducer;