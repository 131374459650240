import React, { useState } from "react";
import { X } from "lucide-react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Step1 from "./ExamSteps/step1";
import Step2 from "./ExamSteps/step2";
import Step3 from "./ExamSteps/step3";

const GenerateExam = ({ onRequestClose }) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(1);


  const handleClose = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4">
      <div className="row d-flex justify-contents-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">Select Syllabus</h4>
            <div className="iconWrapper cursor-pointer" onClick={handleClose}><X /></div>
          </div>
        </div>
      </div>
      <div className="modalBody p-4">
        <div className="mb-4">
          <div className="progressWrapper ">
            <div className="progressNumbering" style={{ width: `${(currentStep - 1) * 50}%` }}></div>
            <div className={`stepNumber ${currentStep >= 1 ? "active" : ""}`}>1</div>
            <div className={`stepNumber ${currentStep >= 2 ? "active" : ""}`}>2</div>
            <div className={`stepNumber ${currentStep >= 3 ? "active" : ""}`}>3</div>
          </div>
          {currentStep === 1 && (
            <Step1 />
          )}
          {currentStep === 2 && (
            <Step2 />
          )}
          {currentStep === 3 && (
            <Step3 />
          )}
        </div>
      </div>
      <div className="col-md-12 px-4 mt-3 py-3">
        <div className="d-flex gap-3 justify-content-end">
          <div onClick={handleClose}>
            <Button type="default">{t("Cancel")}</Button>
          </div>
          {currentStep > 1 && (
            <Button type="default" onClick={handlePreviousStep}>
              {t("Previous")}
            </Button>
          )}
          {currentStep < 3 ? (
            <Button type="primary" onClick={handleNextStep}>
              {t("Next")}
            </Button>
          ) : (
            <Button type="primary">
              {t("Save")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateExam;