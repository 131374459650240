import React, { useEffect, useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import { fetchSalaries } from "../../../redux/salarySlice";
import { useDispatch, useSelector } from "react-redux";

function GeneratedSalaries() {
  const dispatch = useDispatch();
  const { salaries } = useSelector((state) => state.salaries);
  console.log("Salaries:", salaries);

  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(fetchSalaries());
  }, [dispatch]);

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

    // Handle HR Approval Button Click
    const handleHRApproval = () => {
      // Example logic - Replace with actual functionality
      console.log("Proceeding for HR Approval...");
      alert("Proceeding for HR Approval...");
    };
  
    // Handle Salaries Detail Button Click
    const handleSalariesDetail = () => {
      // Example logic - Replace with actual functionality
      console.log("Viewing Salaries Detail...");
      alert("Viewing Salaries Detail...");
    };
  
    // Handle Search Input Change
    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
      console.log("Searching:", event.target.value);
    };
  

  return (
    <div>
       <div className="flex w-full items-center justify-between mt-4 space-x-2 space-y-4 md:space-y-0 flex-wrap sm:flex-col md:flex-row">
        <span className="text-gray-700 font-medium sm:mb-2 md:mb-0">
          Session: February-2024
        </span>
        <input
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search By Emp. Name / Code"
          className="border border-gray-300 rounded p-2 w-full sm:w-full md:w-1/4 sm:mb-2 md:mb-0"
        />
        <button
          onClick={handleHRApproval}
          className="px-4 py-2 bg-[#241763] text-white rounded sm:w-full md:w-auto sm:mb-2 md:mb-0"
        >
          Proceed for HR Approval
        </button>
        <button
          onClick={handleSalariesDetail}
          className="px-4 py-2 bg-[#241763] text-white rounded sm:w-full md:w-auto"
        >
          Salaries Detail
        </button>
      </div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-2 text-sm">
          <thead className="border-b font-bold">
            <tr className="border-b bg-[#241763]/10">
              <th className="p-2">Emp ID</th>
              <th>Session</th>
              <th>T. Days</th>
              <th>P</th>
              <th>A</th>
              <th>Late</th>
              <th>Leaves</th>
              <th>OT Hours</th>
              <th>B. Salary</th>
              <th>Deduction</th>
              <th>Total Salary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {salaries.length > 0 ? (
              salaries.map((salary, index) => (
                <React.Fragment key={salary.id}>
                  <tr className="hover:bg-gray-200">
                    <td>{salary.employeeId}</td>
                    <td>{salary.session}</td>
                    <td>{salary.totalDays}</td>
                    <td>{salary.present}</td>
                    <td>{salary.absent}</td>
                    <td>{salary.late}</td>
                    <td>{salary.leaves}</td>
                    <td>{salary.overtimeHours}</td>
                    <td>{salary.baseSalary}</td>
                    <td>{salary.deductionAmount}</td>
                    <td>{salary.totalSalary}</td>
                    <td className="space-x-2 flex items-center py-2">
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp size={16} className="group-hover:text-white" />
                        ) : (
                          <ChevronDown size={16} className="group-hover:text-white" />
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center p-4">
                  No Salary Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GeneratedSalaries;
