import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, putRequest, updateRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
    books: [],
    loading: false,
    error: null,
    pageSize: 100, // Default pageSize
    totalCount: 10,
    pageNumber: 1,
};

export const fetchBooks = createAsyncThunk('books/fetchBooks', async ({pageNumber, pageSize}, { rejectWithValue }) => {
    try {
        const response = await getRequest(`/api/Library/GetByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`);
        if (response.status !== 200) throw new Error('Failed to fetch books');
        return {
            books:response.data?.data?.items || [],
            totalCount: response.data?.data?.totalCount || 0,
            pageNumber,
            pageSize,

        }
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Add Book
export const addBook = createAsyncThunk('books/addBook', async (book, { rejectWithValue }) => {
    try {
        const response = await postRequest("/api/Library", book);
        if (response.status !== 200) {
            return rejectWithValue("Failed to add book");
          }
        return response.data?.data; // The payload is the book object returned by the API after it is added
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Update Book
export const updateBook = createAsyncThunk('books/updateBook', async ({ id, updatedBook }, { rejectWithValue }) => {
    try {
        const response = await updateRequest(`/api/Library/${id}`, updatedBook);
        if (response.status !== 200) {
            return rejectWithValue("Failed to add book");
          }
        return response.data?.data; // The payload is the updated book object returned by the API
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Delete Book
export const deleteBook = createAsyncThunk('books/deleteBook', async (id, { rejectWithValue }) => {
    try {
        await deleteRequest(`/api/Library/${id}`);
        return id; // The payload is just the id of the deleted book
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Initial state


// Books slice
const booksSlice = createSlice({
    name: 'books',
    initialState,
    reducers: {
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBooks.pending, (state) => { state.loading = true; })
            .addCase(fetchBooks.fulfilled, (state, action) => {
                state.loading = false;
                state.books = action.payload.books || [];
                state.totalCount = action.payload.totalCount;
                state.pageNumber = action.payload.pageNumber;
                state.pageSize = action.payload.pageSize; 
            })
            .addCase(fetchBooks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // Error message is in the payload
            })
            .addCase(addBook.fulfilled, (state, action) => {
                state.books.push(action.payload); // The added book object from the payload
            })
            .addCase(updateBook.fulfilled, (state, action) => {
                const index = state.books.findIndex(b => b.id === action.payload.id);
                if (index !== -1) {
                    state.books[index] = action.payload; // Update the parent in the state
                  }
                  toast.success("Book updated successfully!");
            })
            .addCase(deleteBook.fulfilled, (state, action) => {
                state.books = state.books.filter(book => book.id !== action.payload); // The deleted book id from the payload
            });
    },
});
export const {setPageNumber} = booksSlice.actions;
export default booksSlice.reducer;
