import React, { useState } from "react";

const LeaveDetailsModal = ({ isOpen, onClose, employee, onUpdateStatus }) => {
  const [status, setStatus] = useState("");

  if (!isOpen) return null;

  const handleUpdate = () => {
    onUpdateStatus(employee.empCode, status);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Sick Leave Leave Application</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
            &times;
          </button>
        </div>
        <div className="mt-4">
          <p><strong>Application ID:</strong> {employee.empCode}</p>
          <p><strong>Department:</strong> {employee.department}</p>
          <p><strong>Number of Leaves:</strong> {employee.numberOfDays}</p>
          <p><strong>Start Date:</strong> {employee.dateFrom}</p>
          <p><strong>To Date:</strong> {employee.dateTo}</p>
          <p><strong>Employee Name:</strong> {employee.name}</p>
          <p><strong>Designation:</strong> {employee.department.split(" - ")[1]}</p>
          <p><strong>Leave Type:</strong> {employee.leaveType}</p>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium mb-1">Change Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">Select</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button onClick={handleUpdate} className="px-4 py-2 bg-blue-500 text-white rounded">
            Update
          </button>
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 text-black rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const AllLeaveDetails = () => {
  const [dummyData, setDummyData] = useState([
    {
      empCode: "SAS-08",
      name: "Kevin Rodgers",
      department: "Procurement - Procurement Manager",
      leaveType: "Sick Leave",
      dateFrom: "2024-03-27",
      dateTo: "2024-03-26",
      numberOfDays: 0,
      managerStatus: "Pending",
      hrStatus: "Pending",
      status: "Pending",
    },
    {
      empCode: "SAS-015",
      name: "Hannah Luke",
      department: "Engineering & Production - Associate Software Engineer",
      leaveType: "Sick Leave",
      dateFrom: "2024-02-20",
      dateTo: "2024-02-05",
      numberOfDays: -14,
      managerStatus: "Pending",
      hrStatus: "Approved",
      status: "Approved",
    },
  ]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
  };

  const handleUpdateStatus = (empCode, newStatus) => {
    setDummyData((prevData) =>
      prevData.map((employee) =>
        employee.empCode === empCode ? { ...employee, hrStatus: newStatus } : employee
      )
    );
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <select className="p-2 border border-gray-300 rounded w-full md:w-2/6">
          <option value="">Leave Type</option>
        </select>
        <select className="p-2 border border-gray-300 rounded w-full md:w-2/6">
          <option value="">All Departments</option>
        </select>
        <select className="p-2 border border-gray-300 rounded w-full md:w-2/6">
          <option value="">All Designation</option>
        </select>
        <select className="p-2 border border-gray-300 rounded w-full md:w-2/6">
          <option value="">All Locations</option>
        </select>
      </div>

      <div className="overflow-x-auto overflow-visible">
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>Emp. Code</th>
                <th>Employee Name</th>
                <th>Leave Type</th>
                <th>Date</th>
                <th>Number of Days</th>
                <th>Manager Status</th>
                <th>HR Status</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {dummyData.map((employee, index) => (
                <tr key={index} className="p-2 py-2 hover:bg-gray-200 space-y-2">
                  <td>{employee.empCode}</td>
                  <td className="my-2">
                    <div>
                      <img
                        src="https://via.placeholder.com/40"
                        alt="Profile"
                        className="w-8 h-8 rounded-full inline-block mr-2"
                      />
                      <span>{employee.name}</span>
                      <br />
                      <span className="text-gray-500 text-xs">{employee.department}</span>
                    </div>
                  </td>
                  <td>{employee.leaveType}</td>
                  <td>
                    {employee.dateFrom} to {employee.dateTo}
                  </td>
                  <td>{employee.numberOfDays}</td>
                  <td>
                    <span
                      className={`px-2 py-1 rounded ${
                        employee.managerStatus === "Pending"
                          ? "bg-orange-100 text-orange-600"
                          : "bg-green-100 text-green-600"
                      }`}
                    >
                      {employee.managerStatus}
                    </span>
                  </td>
                  <td>
                    <button
                      className={`px-2 py-1 rounded ${
                        employee.hrStatus === "Pending"
                          ? "bg-orange-100 text-orange-600"
                          : "bg-green-100 text-green-600"
                      }`}
                      onClick={() => handleOpenModal(employee)}
                    >
                      {employee.hrStatus}
                    </button>
                  </td>
                  <td>
                    <span
                      className={`px-2 py-1 rounded ${
                        employee.status === "Pending"
                          ? "bg-orange-100 text-orange-600"
                          : "bg-green-100 text-green-600"
                      }`}
                    >
                      {employee.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <LeaveDetailsModal
        isOpen={!!selectedEmployee}
        onClose={handleCloseModal}
        employee={selectedEmployee}
        onUpdateStatus={handleUpdateStatus}
      />
    </div>
  );
};

export default AllLeaveDetails;
