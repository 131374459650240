import React from "react";
import { X } from "lucide-react";

const CampusesModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  setValue,
}) => {
  const handleFormSubmit = (data) => {
    onSubmit(data);
  };

  const handleClose = () => {
    reset();
    setIsModalOpen(false);
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleClose}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <X />
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Campus" : "Add Campus"}
        </h2>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block">Campus Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("name", { required: true })}
                placeholder="Enter campus name"
              />
            </div>
            <div>
              <label className="block">Location</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("location", { required: true })}
                placeholder="Enter campus location"
              />
            </div>
            <div>
              <label className="block">Campus Area</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                {...register("area", { required: true })}
                placeholder="Enter campus area"
              />
            </div>
            <div>
              <label className="block">Capacity</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                {...register("capacity", { required: true })}
                placeholder="Enter campus capacity"
              />
            </div>
            <div>
              <label className="block">Contact Number</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("contactNumber", { required: true })}
                placeholder="Enter contact number"
              />
            </div>
            <div>
              <label className="block">Contact Email</label>
              <input
                type="email"
                className="w-full p-2 border rounded"
                {...register("contactEmail", { required: true })}
                placeholder="Enter contact email"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4 mt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-[#241763] text-white rounded"
            >
              {isEdit ? "Update Campus" : "Add Campus"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CampusesModal;
