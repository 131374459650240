import React, { useState, useEffect } from "react";
import { Modal, Input } from "antd";
import { ChevronUp, ChevronDown } from "lucide-react";
import { getRequest, postRequest } from "../../services";
import { Spin } from 'antd';
import NameAvatar from "../Common/Avatar";
import { message } from 'antd';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const GradeTableMcQs = (props) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const { headingsGrades, filteredExamsId, setExamPublish, setPublishStatus } = props;
  const [currentPage, setCurrentPage] = useState(0); // Current question set
  const [selectedRows, setSelectedRows] = useState([]); // Selected rows
  const [selectAll, setSelectAll] = useState(false); // "Select all" toggle
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null); // Active row for modal
  const [remark, setRemark] = useState(""); // Remark input
  const [loading, setLoading] = useState(false);
  const [myMcQs, setMyMcQs] = useState([]);

  const toggleRow = (index) => {
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handlePagination = (direction) => {
    setCurrentPage((prev) =>
      direction === "next" ? prev + 1 : prev - 1
    );
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(myMcQs.map((_, index) => index)); // Select all
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const showModal = (index) => {
    setActiveRowIndex(index);
    setRemark(myMcQs[index]?.remark || "");
    setIsModalVisible(true);
  };

  const handleOk = async () => {

    const payload = {
      userId: myMcQs[activeRowIndex].student.studentId,
      subjectId: filteredExamsId[currentPage]?.subjectDetails?.subjectId || "",
      examId: filteredExamsId[currentPage]?.id || "",
      remarks: remark || "",
      mcqsAnswers: myMcQs[activeRowIndex].questions.map((question) => ({
        questionId: question.questionId || "",
        marks: question.marks || 0,
      })),
    };
    try {
      const response = await postRequest(`/api/Grades/evaluate-mcqs`, payload);
      if (response.status === 200 && response.statusText) {
        message.success(response.data.message || "Update successfully");
        fetchData();
      } else {
        message.error("Failed to save lesson");
      }
    } catch (error) {
      console.error("Error:", error.message);
      message.error("An error occurred while saving the lesson");

    }
    setIsModalVisible(false);
    setActiveRowIndex(null);
  };

  const handleCancel = () => {

    setIsModalVisible(false);
    setActiveRowIndex(null);
  };

  const fetchData = async () => {
    if (!filteredExamsId || !filteredExamsId[currentPage]?.id) {
      console.warn("filteredExamsId is null or undefined, or currentPage is invalid.");
      return;
    }
    setLoading(true);
    try {
      const response = await getRequest(
        `/api/Grades/mcqs-submission-details?examId=${filteredExamsId[currentPage].id}`
      );
      const status = await getRequest(`/api/Grades/ResultStatus?examId=${filteredExamsId[currentPage].id}`);
      setPublishStatus(status.data)
      const data = response.data;
      setExamPublish({
        Exam: filteredExamsId[currentPage],
        Question: data
      });
      setMyMcQs(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, [currentPage, filteredExamsId[currentPage]?.id]);
  // console.log("filteredExamsId",filteredExamsId[currentPage])
  return (
    <div className="container-table100">
      <div className="wrap-table100">
        <div className="table100 ver5 m-b-110">
          <div className="table100-head">
            <table>
              <thead>
                <tr className="row100 head">
                  <th className="cell100 column0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  {headingsGrades.map((head, index) => (
                    <th className={`cell100 column${index + 1}`} key={index}>
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <Spin spinning={loading}>
            <div className="table100-body">
              <table>
                <tbody>
                  {!loading && myMcQs?.length === 0 || myMcQs === null ? (
                    <tr className="row100 body">
                      <td colSpan={8} className="text-center">
                        No data available
                      </td>
                    </tr>
                  ) :
                    (
                      myMcQs.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr className="row100 body">
                            <td className="cell100 column0">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedRows.includes(index)}
                                onChange={() => handleRowSelect(index)}
                              />
                            </td>
                            <td className="cell100 column1">
                              <div className="d-flex align-items-center attendanceUser gap-2">
                                {item.student.profilePicture ? (
                                  <img src={item.student.profilePicture} alt="user" />
                                ) : (
                                  <NameAvatar name={item.student.name} rounded />
                                )}
                                {item.student.name}
                              </div>
                            </td>
                            <td className="cell100 column2">PP</td>
                            <td className="cell100 column3">Exam</td>
                            <td className="cell100 column4">{`${Math.round(
                              (item.questions.reduce((acc, q) => acc + q.marks, 0) /
                                item.questions.reduce((acc, q) => acc + q.totalMarks, 0)) * 100
                            )}%`}</td>
                            <td className="cell100 column5">Yes</td>
                            <td className="cell100 column6">
                              {!item.student.marksAlloted.alloted ? <button
                                className="btn btn-primary"
                                onClick={() => showModal(index)}
                              >
                                Add Remark
                              </button>
                                :
                                item.student.marksAlloted.remarks}
                            </td>
                            <td className="cell100 column7">
                              <div
                                onClick={() => toggleRow(index)}
                                className="cursor-pointer d-flex justify-content-end p-2"
                              >
                                {expandedRowIndex === index ? (
                                  <ChevronUp size={16} className="group-hover:text-white" />
                                ) : (
                                  <ChevronDown size={16} className="group-hover:text-white" />
                                )}
                              </div>
                            </td>
                          </tr>
                          {expandedRowIndex === index && (
                            <tr>
                              <td colSpan={8} style={{ background: "#fff", padding: "5px" }}>
                                <div className="bg-gray-50 rounded p-6">
                                  {item.questions.map((question, qIndex) => (
                                    <div key={qIndex} className="mb-4 border-b pb-4 border-gray-200">
                                      <p>
                                        <strong>{qIndex + 1}:</strong> {question.questionText}
                                      </p>
                                      <ul className="list-disc ml-5">
                                        {Object.entries(question.options).map(([key, value]) => (
                                          <li
                                            key={key}
                                            style={{
                                              color: question.correctOption.toLowerCase() === key.toLowerCase() ? "green" : "inherit",
                                            }}
                                          >
                                            <strong>{key.toUpperCase()}:</strong> {value}
                                          </li>
                                        ))}
                                      </ul>
                                      <p>
                                        <strong>Selected Option:</strong>{" "}
                                        <span
                                          className={
                                            question.selectedOption === question.correctOption
                                              ? "text-green-600"
                                              : "text-red-600"
                                          }
                                        >
                                          {question.selectedOption}{" "}
                                          {question.selectedOption === question.correctOption ? (
                                            <span>✔️</span>
                                          ) : (
                                            <span>❌</span>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  ))}

                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    )
                  }
                </tbody>
              </table>
              {filteredExamsId.length === 0 ? null :
                <div className="flex justify-center mt-4 pb-4 gap-3 paginationStyle">
                  <button
                    onClick={() => handlePagination("previous")}
                    disabled={currentPage < 1}
                    className={`px-3 py-1 bg-[#241763] text-white rounded ${currentPage < 1
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-[#241763] text-white hover:bg-[#241763]"
                      }`}
                  >
                    <LeftOutlined />
                  </button>
                  <span className="bg-[#241763] text-white px-3 py-1 mx-1 border rounded d-flex align-items-center">
                    {`${currentPage + 1}/${filteredExamsId.length}`}
                  </span>
                  <button
                    onClick={() => handlePagination("next")}
                    disabled={currentPage >= filteredExamsId.length - 1}
                    className={`px-4 py-2 rounded ${currentPage >= filteredExamsId.length - 1
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-[#241763] text-white hover:bg-[#241763]"
                      }`}
                  >
                    <RightOutlined />
                  </button>
                </div>
              }
            </div>
          </Spin>
        </div>
      </div>
      <Modal
        title="Add Remark"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="row p-3">
          <div className="col-md-12">
            <Input.TextArea
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              placeholder="Enter your remark here"
              rows={4}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GradeTableMcQs;
