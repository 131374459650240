import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs, deleteJob } from "../../redux/jobsSlice";
import { Edit, Trash2 } from "lucide-react";
import PostJobModal from "../../modals/Hrms/PostJobModal";

const JobsOpening = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isPostJobModalOpen, setIsPostJobModalOpen] = useState(false);
  const [editingJob, setEditingJob] = useState(null);

  const dispatch = useDispatch();
  const { jobs, status, error } = useSelector((state) => state.jobs);

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  const filteredJobs = jobs.filter((job) =>
    job.PostTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const openPostJobModal = (job = null) => {
    setEditingJob(job);
    setIsPostJobModalOpen(true);
  };

  const closePostJobModal = () => {
    setIsPostJobModalOpen(false);
    setEditingJob(null);
  };

  const handleDeleteJob = (jobId) => {
    dispatch(deleteJob(jobId));
  };

  if (status === 'loading') return <p>Loading jobs...</p>;
  if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <div className="px-6 py-8">
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Search for a job..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border border-gray-300 rounded-md w-1/2"
        />
        <button
          onClick={() => openPostJobModal()}
          className="bg-[#241763] text-white px-4 py-2 rounded-md shadow-md hover:bg-[#241763]"
        >
          Post Job
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {filteredJobs.map((job) => (
          <div
            key={job.id}
            className="p-4 bg-white shadow-md rounded-lg hover:shadow-lg relative"
          >
            <div className="absolute top-2 right-2 flex gap-2">
              <button
                onClick={() => openPostJobModal(job)}
                className="p-1 text-blue-500 hover:text-blue-700"
              >
                <Edit size={18} />
              </button>
              <button
                onClick={() => handleDeleteJob(job.id)}
                className="p-1 text-red-500 hover:text-red-700"
              >
                <Trash2 size={18} />
              </button>
            </div>
            <h2 className="text-lg font-bold text-gray-800">{job.PostTitle}</h2>
            <p className="text-sm text-gray-600">
              Total {job.NumberOfPost} vacancies
            </p>
            <p className="text-sm text-gray-500">
              Last date to apply: {new Date(job.PostingDate).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
      {isPostJobModalOpen && (
        <PostJobModal
          onClose={closePostJobModal}
          initialData={editingJob}
        />
      )}
    </div>
  );
};

export default JobsOpening;