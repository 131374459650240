import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import CustomDropdown from "../../Components/admin/CustomDropdown";

function Payments({ overDue, setFeeBills }) {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [filters, setFilters] = useState({
    studentName: "",
    feeCode: "",
    overDue: "",
    paymentStatus: "",
  });

  // Updated Dummy Data
  const dummyFeeBills = [
    {
      studentId: "001",
      studentName: "John Doe",
      feeCode: "FC101",
      billingCycleDate: "2024-01-15",
      overDue: "No delay",
      paymentStatus: "Paid",
    },
    {
      studentId: "002",
      studentName: "Jane Smith",
      feeCode: "FC102",
      billingCycleDate: "2024-02-15",
      overDue: "5 days delay",
      paymentStatus: "Pending",
    },
    {
      studentId: "003",
      studentName: "Sam Wilson",
      feeCode: "FC103",
      billingCycleDate: "2024-03-15",
      overDue: "15 days delay",
      paymentStatus: "Overdue",
    },
  ];

  const [feeBillsData, setFeeBillsData] = useState(dummyFeeBills);

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredFeeBills = feeBillsData.filter(
    (bill) =>
      (filters.studentName === "" ||
        bill.studentName.includes(filters.studentName)) &&
      (filters.feeCode === "" || bill.feeCode.includes(filters.feeCode)) &&
      (filters.overDue === "" || bill.overDue.includes(filters.overDue)) &&
      (filters.paymentStatus === "" ||
        bill.paymentStatus.includes(filters.paymentStatus))
  );

  return (
    <div>
      <div className="mb-4 flex gap-4 w-full">
        <div className="flex w-3/6">
          <input
            type="text"
            placeholder="Search Student Name..."
            className="border border-gray-300 rounded p-2 w-full"
            value={filters.studentName}
            onChange={(e) => handleFilterChange("studentName", e.target.value)}
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["FC101", "FC102", "FC103"]}
            value={filters.feeCode}
            onChange={(value) => handleFilterChange("feeCode", value)}
            placeholder="Select Fee Code"
            className="w-full"
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["No delay", "5 days delay", "15 days delay"]}
            value={filters.overDue}
            onChange={(value) => handleFilterChange("overDue", value)}
            placeholder="Select Overdue Status"
            className="w-full"
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["Paid", "Pending", "Overdue"]}
            value={filters.paymentStatus}
            onChange={(value) => handleFilterChange("paymentStatus", value)}
            placeholder="Select Payment Status"
            className="w-full"
          />
        </div>
      </div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr className="bg-gray-100 border-b">
              <th>Student Id</th>
              <th>Student Name</th>
              <th>Fee Code</th>
              <th>Billing Cycle Date</th>
              <th>Overdue</th>
              <th>Payment Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {filteredFeeBills.length > 0 ? (
              filteredFeeBills.map((bill, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{bill.studentId}</td>
                    <td>{bill.studentName}</td>
                    <td>{bill.feeCode}</td>
                    <td>{bill.billingCycleDate}</td>
                    <td>{bill.overDue}</td>
                    <td>{bill.paymentStatus}</td>
                    <td className="space-x-2 flex items-center py-2">
                     
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="7">
                        <div className="bg-gray-100 p-4">
                          <div>Additional details about the bill...</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Fee Bills Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Payments;
