import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/jobs`;

// Fetch Job Openings
export const fetchJobs = createAsyncThunk("jobs/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Job Opening
export const addJob = createAsyncThunk("jobs/add", async (jobData) => {
  const response = await axios.post(API_URL, jobData);
  return response.data;
});

// Update Job Opening
export const updateJob = createAsyncThunk(
  "jobs/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Job Opening
export const deleteJob = createAsyncThunk("jobs/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const jobSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addJob.fulfilled, (state, action) => {
        state.jobs.push(action.payload);
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        const index = state.jobs.findIndex(j => j.id === action.payload.id);
        if (index !== -1) {
          state.jobs[index] = action.payload;
        }
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.jobs = state.jobs.filter(j => j.id !== action.payload);
      });
  },
});

export default jobSlice.reducer;