import { useState } from "react";

export default function RolePermissionModal({ user, setIsPermissionModalOpen }) {
  const [permissions, setPermissions] = useState({
    "HR Management System": { read: false, write: false, overall: false, admin: false, user: false, attendance: false },
    "HR PAYROLL": { read: false, write: false, overall: false, admin: false, user: false, },
    "Accounts Management System": { read: false, write: false, overall: false, admin: false, user: false, },
    "Inventory Management System": { read: false, write: false, overall: false, admin: false, user: false, },
    "Audit": { read: false, write: false, overall: false, admin: false, user: false },
  });

  const handleCheckboxChange = (module, permission) => {
    setPermissions((prev) => ({
      ...prev,
      [module]: { ...prev[module], [permission]: !prev[module][permission] },
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg w-3/4 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-700">Role Permissions To User</h2>
          <button onClick={() => setIsPermissionModalOpen(false)} className="text-gray-500 hover:text-black text-xl">✖</button>
        </div>

        <table className="w-full border-collapse border border-gray-300 text-left">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border">Module</th>
              <th className="p-2 border text-center">Read</th>
              <th className="p-2 border text-center">Write</th>
              <th className="p-2 border text-center">Overall</th>
              <th className="p-2 border text-center">Admin</th>
              <th className="p-2 border text-center">User</th>
              <th className="p-2 border text-center">Attendance</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(permissions).map((module) => (
              <tr key={module} className="border">
                <td className="p-2 border">{module}</td>
                {Object.keys(permissions[module]).map((perm) => (
                  <td key={perm} className="p-2 border text-center">
                    <input
                      type="checkbox"
                      checked={permissions[module][perm]}
                      onChange={() => handleCheckboxChange(module, perm)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsPermissionModalOpen(false)}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
