import React, { useState } from "react";
import { Upload } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { X } from "lucide-react";
import { postRequest } from "../services";
import { message } from 'antd';

const ViewQuestionStudent = (props) => {
  const { onRequestClose, questionsData, selectedExamId, fetchDataExam } = props;
  const { t, i18n } = useTranslation();
  const [documentFile, setDocumentFile] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentQuestion = questionsData[currentIndex];

  const handleViewAttachment = () => {
    window.open(currentQuestion.attachment, "_blank");
  };

  const onSubmit = async () => {
    if (currentIndex + 1 === questionsData.length) {
      let attachment = null;
      if (documentFile?.length) {
        const formData = new FormData();
        documentFile.forEach((file) => formData.append("files", file)); 

        const docResponse = await postRequest("/api/Document/documents-upload", formData, true);
        attachment = docResponse?.data;
      }
      const payload = {
        examId: selectedExamId,
        fileUrl: attachment[0], 
        remarks: "ok",
      };
      console.log(payload)
      try {
        const response = await postRequest(`/api/Submissions/submit-assignment`, payload);
        if (response.status === 200 && response.data.success) {
          message.success(response.data.message || "Assignment submitted successfully");
          onRequestClose();
          fetchDataExam();
        } else {
          message.error("Failed to submit answers");
        }
      } catch (error) {
        console.error("Error submitting data:", error.message);
      }
    } else {
      setCurrentIndex((prev) => Math.min(prev + 1, questionsData.length - 1));
    }
  };
  return (
    <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">
              Question Details
            </h4>
            <div className="iconWrapper cursor-pointer" onClick={onRequestClose}><X /></div>
          </div>
        </div>
      </div>
      <div className="modalBody">
        <div className="row px-4 m-0 gap-5 mt-4">
          <div className="col-md-4 m-0 p-0">
            <label>{currentIndex + 1}/{questionsData.length}</label>
            <div className="questionBarProgress">
              {questionsData.map((_, index) => (
                <div className={`dot ${index <= currentIndex ? "dotWhite" : ""}`} key={index}></div>
              ))}
              <div
                className="progressBar"
                style={{ width: `${((currentIndex + 1) / questionsData.length) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className="col-md-7 d-flex justify-content-between align-items-end">
            <p>Marks: {currentQuestion.marks}</p>
          </div>
        </div>
        <div className="row px-4 mt-5">
          <div className="col-md-12 mb-4">
            <p className="fw-bold">{currentQuestion.title}</p>
          </div>

          <div className="col-md-12 mb-4">
            {/* Button to view attachment */}
            <Link onClick={handleViewAttachment}>
              {currentQuestion.attachment}
            </Link>
          </div>

          <div className="col-md-12 mb-4">
            <Upload
              accept=".pdf,.jpg,.jpeg,.png,.doc,.docx" // Restrict visible file types
              beforeUpload={(file) => {
                // Allow only specific file types (additional validation for safety)
                const allowedTypes = [
                  "application/pdf",
                  "image/jpeg",
                  "image/png",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ];
                if (!allowedTypes.includes(file.type)) {
                  alert(t("Invalid file type. Please select a PDF, image, or DOC file."));
                  return Upload.LIST_IGNORE;
                }
                // Set only the current file, replacing any existing one
                setDocumentFile([file]);
                return false;
              }}
              fileList={documentFile} // Bind file list to display
              onRemove={() => setDocumentFile([])} // Clear the uploaded file
              listType="picture"
            >
              <button className="btn btn-primary">{t("Upload File")}</button>
            </Upload>
          </div>
        </div>
      </div>
      <div className="row px-4 m-0 mt-3">
        <div className="col-md-12 d-flex justify-content-end gap-3">
          <button
            className="btn btn-primary px-4 py-2"
            onClick={onSubmit}
          >
            {currentIndex + 1 === questionsData.length ? "Finish" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewQuestionStudent;
