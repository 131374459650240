import React, { useState } from "react";
import FeeStructureManagement from "../../Components/tabs/FeeStructureManagement";
import FeeBills from "../../Components/tabs/FeeBills";
import Payments from "../../Components/tabs/Payments";
import Notifications from "../../Components/tabs/Notifications";

function FeeManagement() {
  const tabs = [
    "Fee Structure Management",
    "Fee Bills",
    "Payments",
    "Notifications",
  ];
  const [activeTab, setActiveTab] = useState("Fee Structure Management"); // Default tab
  const [feeStructures, setFeeStructures] = useState([]);
  const [feeBills, setFeeBills] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Tabs */}
      <div className="flex space-x-4 border-2 border-gray-100 shadow-xl mb-4 pb-3 pt-2 rounded-b-xl">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 ${
              activeTab === tab ? "text-white bg-[#241763]" : "bg-gray-200 text-gray-700"} rounded-t-md` }
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div>
        {activeTab === "Fee Structure Management" && (
          <FeeStructureManagement
            feeStructures={feeStructures}
            setFeeStructures={setFeeStructures}
          />
        )}

        {activeTab === "Fee Bills" && (
          <FeeBills feeBills={feeBills} setFeeBills={setFeeBills} />
        )}

        {activeTab === "Payments" && (
          <Payments/>
        )}

        {activeTab === "Notifications" && (
          <Notifications/>
        )}
      </div>
    </div>
  );
}

export default FeeManagement;
