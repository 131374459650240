import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest, updateRequest, deleteRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  courses: [],
  loading: false,
  error: null,
  pageSize: 100,
  totalCount: 0,
  pageNumber: 1,
};

// Async thunk to fetch courses with pagination
export const fetchCourses = createAsyncThunk(
  "courses/fetchCourses",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await getRequest(`/api/Course?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch courses data.");
      }
      return {
        courses: response.data?.data?.items || [],
        totalCount: response.data?.data?.totalCount || 0,
        pageNumber,
        pageSize,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to add a new course
export const addCourse = createAsyncThunk(
  "courses/addCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/Course", payload);
      if (response.status !== 200) {
        return rejectWithValue("Failed to add course.");
      }
      toast.success("Course added successfully!");
      return response.data?.data;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to update a course
export const updateCourse = createAsyncThunk(
  "courses/updateCourse",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/Course/${id}`, payload);
      if (response.status !== 200) {
        return rejectWithValue("Failed to update course.");
      }
      toast.success("Course updated successfully!");
      return { id, ...payload };
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to delete a course
export const deleteCourse = createAsyncThunk(
  "courses/deleteCourse",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/Course/${id}`);
      toast.success("Course deleted successfully!");
      return id;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// Slice
const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload.courses;
        state.totalCount = action.payload.totalCount;
        state.pageNumber = action.payload.pageNumber;
        state.pageSize = action.payload.pageSize;
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addCourse.fulfilled, (state, action) => {
        state.courses.push(action.payload);
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        const index = state.courses.findIndex((course) => course.id === action.payload.id);
        if (index !== -1) {
          state.courses[index] = { ...state.courses[index], ...action.payload };
        }
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.courses = state.courses.filter((course) => course.id !== action.payload);
      });
  },
});

export const { setPageNumber } = coursesSlice.actions;

export default coursesSlice.reducer;
