import React, { useEffect, useState } from "react";
import { fetchCandidates, addCandidate, deleteCandidate, updateCandidate } from "../../redux/candidateSlice";
import { fetchJobs } from "../../redux/jobsSlice";
import CandidateModal from "../../modals/Hrms/CandidateModal";
import { ChevronDown, Pencil, Trash2, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";

const Candidates = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state.jobs);
  const { candidates, loading, error } = useSelector((state) => state.candidates);

  useEffect(() => {
    dispatch(fetchJobs());
    dispatch(fetchCandidates());
  }, [dispatch]);

  const handleAddCandidate = (data) => {
    // Transform form data to match API requirements
    const candidateData = {
      name: data.candidateName,
      fatherName: data.fatherName,
      address: data.address,
      mobileNumber: data.mobileNumber,
      email: data.email,
      highestQualification: data.highestQualification,
      skillSet: data.skillSet,
      currentSalary: data.currentSalary,
      expectedSalary: data.expectedSalary,
      rating: data.rating,
      currentDesignation: data.currentDesignation,
      experience: data.experience,
      jobId: jobs.find(job => job.title === data.position)?.id,
      status: "Applied" // Default status for new candidates
    };
    
    dispatch(addCandidate(candidateData));
    setModalOpen(false);
  };

  const handleUpdateCandidate = (data) => {
    if (!selectedCandidate) return;
    
    const updatedData = {
      name: data.candidateName,
      fatherName: data.fatherName,
      address: data.address,
      mobileNumber: data.mobileNumber,
      email: data.email,
      highestQualification: data.highestQualification,
      skillSet: data.skillSet,
      currentSalary: data.currentSalary,
      expectedSalary: data.expectedSalary,
      rating: data.rating,
      currentDesignation: data.currentDesignation,
      experience: data.experience,
      jobId: jobs.find(job => job.title === data.position)?.id,
      candidateStatus: data.candidateStatus // Include the candidateStatus in the update
    };
    
    dispatch(updateCandidate({ id: selectedCandidate.id, updatedData }));
    setSelectedCandidate(null);
    setIsEditing(false);
    setModalOpen(false);
  };

  const handleDeleteCandidate = (id) => {
    if (window.confirm("Are you sure you want to delete this candidate?")) {
      dispatch(deleteCandidate(id));
    }
  };

  const openDetailModal = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const closeDetailModal = () => {
    setSelectedCandidate(null);
  };

  const openEditModal = (candidate) => {
    setSelectedCandidate(candidate);
    setIsEditing(true);
    setModalOpen(true);
  };

  if (loading === "loading") return <div className="p-6">Loading candidates...</div>;
  if (error) return <div className="p-6 text-red-500">Error: {error}</div>;

  return (
    <div className="p-6">
      <button
        onClick={() => {
          setIsEditing(false);
          setSelectedCandidate(null);
          setModalOpen(true);
        }}
        className="bg-[#241763] text-white py-2 px-4 rounded mb-4"
      >
        + Add New Candidate
      </button>

      {/* Candidate Table */}
      <table className="w-full border-collapse border border-gray-300 text-left">
        <thead className="bg-gray-200">
          <tr>
            <th className="border border-gray-300 p-2">Candidate Name</th>
            <th className="border border-gray-300 p-2">Post Applied</th>
            <th className="border border-gray-300 p-2">Qualification</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Approval Status</th>
            <th className="border border-gray-300 p-2">Experience</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {candidates.map((candidate) => (
            <tr key={candidate.id} className="hover:bg-gray-100">
              <td className="p-2">{candidate.name}</td>
              <td>{candidate.JobOpening?.PostTitle}</td>
              <td>{candidate.highestQualification}</td>
              <td>{candidate.status}</td>
              <td>
                <span className={`px-2 py-1 rounded text-xs ${
                  candidate.candidateStatus === 'Approved' ? 'bg-green-100 text-green-800' :
                  candidate.candidateStatus === 'Rejected' ? 'bg-red-100 text-red-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {candidate.candidateStatus || 'Pending'}
                </span>
              </td>
              <td>{candidate.experience}</td>
              <td className="space-x-2 flex items-center py-2">
                <button
                  onClick={() => openEditModal(candidate)}
                  className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                >
                  <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                </button>
                <button
                  onClick={() => handleDeleteCandidate(candidate.id)}
                  className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                >
                  <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                </button>
                <div
                  onClick={() => openDetailModal(candidate)}
                  className="cursor-pointer hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                >
                  <ChevronDown size={16} className="group-hover:text-white" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {isModalOpen && (
        <CandidateModal
          onSubmit={isEditing ? handleUpdateCandidate : handleAddCandidate}
          onClose={() => {
            setModalOpen(false);
            setIsEditing(false);
            setSelectedCandidate(null);
          }}
          jobs={jobs}
          candidate={isEditing ? selectedCandidate : null}
        />
      )}

      {/* Candidate Details Modal */}
      {selectedCandidate && !isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{selectedCandidate.name}</h2>
              <button onClick={closeDetailModal} className="text-gray-500 hover:text-gray-800">
                <X size={24} />
              </button>
            </div>
            <p>
              <strong>Status:</strong> {selectedCandidate.status}
            </p>
            <p>
              <strong>Approval Status:</strong> 
              <span className={`ml-2 px-2 py-1 rounded text-xs ${
                selectedCandidate.candidateStatus === 'Approved' ? 'bg-green-100 text-green-800' :
                selectedCandidate.candidateStatus === 'Rejected' ? 'bg-red-100 text-red-800' :
                'bg-yellow-100 text-yellow-800'
              }`}>
                {selectedCandidate.candidateStatus || 'Pending'}
              </span>
            </p>
            <p>
              <strong>Father's Name:</strong> {selectedCandidate.fatherName}
            </p>
            <p>
              <strong>Address:</strong> {selectedCandidate.address}
            </p>
            <p>
              <strong>Mobile Number:</strong> {selectedCandidate.mobileNumber}
            </p>
            <p>
              <strong>Email:</strong> {selectedCandidate.email}
            </p>
            <p>
              <strong>Qualification:</strong> {selectedCandidate.highestQualification}
            </p>
            <p>
              <strong>Skills:</strong> {selectedCandidate.skillSet || "N/A"}
            </p>
            <p>
              <strong>Current Salary:</strong> {selectedCandidate.currentSalary || "N/A"}
            </p>
            <p>
              <strong>Expected Salary:</strong> {selectedCandidate.expectedSalary || "N/A"}
            </p>
            <p>
              <strong>Rating:</strong> {selectedCandidate.rating || "N/A"}
            </p>
            <p>
              <strong>Designation:</strong> {selectedCandidate.currentDesignation || "N/A"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Candidates;