import React, { useState } from 'react';
import { BarChart, Bar, Line, LineChart, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const barData = [
  { name: 'Year 1', Scholastic: 50, Behavioral: 45, NeuroCognitive: 55, Overall: 60 },
  { name: 'Year 2', Scholastic: 55, Behavioral: 50, NeuroCognitive: 60, Overall: 65 },
  { name: 'Year 3', Scholastic: 60, Behavioral: 55, NeuroCognitive: 65, Overall: 70 },
];

const pieData = [
  { name: 'Satisfied', value: 70 },
  { name: 'Neutral', value: 20 },
  { name: 'Dissatisfied', value: 10 },
];

const COLORS = ['#4682B4', '#FFA500', '#DC143C'];

const WellnessDashboard = () => {
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
  
  return (
    <div className="min-h-screen text-white p-4 md:p-6">
      {/* Header */}
      <div className="text-center text-[#241763] text-xl md:text-2xl font-bold mb-4">Wellness Suite Dashboard</div>

       {/* Filters */}
       <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <select className="bg-[#2c2c75] text-white p-2 rounded-md w-full"
          value={selectedCampus} onChange={(e) => setSelectedCampus(e.target.value)}>
          <option value="">Select Campus</option>
          <option value="Campus 1">Campus 1</option>
          <option value="Campus 2">Campus 2</option>
        </select>

        <select className="bg-[#2c2c75] text-white p-2 rounded-md w-full"
          value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
          <option value="">Select Class</option>
          <option value="Class 1">Class 1</option>
          <option value="Class 2">Class 2</option>
        </select>

        <select className="bg-[#2c2c75] text-white p-2 rounded-md w-full"
          value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)}>
          <option value="">Select Section</option>
          <option value="A">Section A</option>
          <option value="B">Section B</option>
        </select>

        <select className="bg-[#2c2c75] text-white p-2 rounded-md w-full"
          value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
          <option value="">Select Subject</option>
          <option value="Math">Math</option>
          <option value="Science">Science</option>
        </select>
      </div>

      {/* Dashboard Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Neuro Cognitive Assessment */}
        <div className="bg-[#2c2c75] p-4 rounded-xl">
          <h3 className="text-center text-lg mb-2">Neuro Cognitive Assessment</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={barData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Scholastic" fill="#4682B4" />
              <Bar dataKey="Behavioral" fill="#FF4500" />
              <Bar dataKey="NeuroCognitive" fill="#32CD32" />
              <Line type="monotone" dataKey="Overall" stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Teacher Satisfaction */}
        <div className="bg-[#2c2c75] p-4 rounded-xl">
          <h3 className="text-center text-lg mb-2">Teacher Satisfaction</h3>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie data={pieData} cx="50%" cy="50%" outerRadius={70} label>
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Neuro Cognitive Assessment for Teachers */}
        <div className="bg-[#2c2c75] p-4 rounded-xl">
          <h3 className="text-center text-lg mb-2">Neuro Cognitive Assessment</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={barData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Scholastic" fill="#4682B4" />
              <Bar dataKey="Behavioral" fill="#FF4500" />
              <Bar dataKey="NeuroCognitive" fill="#32CD32" />
              <Line type="monotone" dataKey="Overall" stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Parent Survey */}
        <div className="bg-[#2c2c75] p-4 rounded-xl">
          <h3 className="text-center text-lg mb-2">Parents Survey</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={barData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Scholastic" fill="#FFA500" />
              <Bar dataKey="Behavioral" fill="#DC143C" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Teacher's Classroom Observation */}
        <div className="bg-[#2c2c75] p-4 rounded-xl">
          <h3 className="text-center text-lg mb-2">Teacher's Classroom Observation</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={barData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Scholastic" fill="#FFD700" />
              <Bar dataKey="Behavioral" fill="#8A2BE2" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* SNE Inclusion */}
        <div className="bg-[#2c2c75] p-4 rounded-xl">
          <h3 className="text-center text-lg mb-2">SNE Inclusion</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={barData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Scholastic" fill="#32CD32" />
              <Bar dataKey="Behavioral" fill="#FF69B4" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default WellnessDashboard;
