import React, { useState } from "react";
import PerformanceModal from "../../modals/Hrms/report/PerformanceModal";
import PayrollModal from "../../modals/Hrms/report/PayrollModal";

const reportCategories = [
  {
    title: "Employee Performance Reports",
    links: ["Employee Appraisal Report", "Training & Development Report", "Task Completion Report", "Attendance & Punctuality"],
  },
  {
    title: "Payroll & Compensation Reports",
    links: ["Salary Slip Report", "Overtime & Allowances", "Payroll Summary"],
  },
  {
    title: "Leave & Absence Reports",
    links: ["Employee Leave Balance", "Leave Approval Trends", "Absenteeism Report"],
  },
  {
    title: "Recruitment & Onboarding Reports",
    links: ["Candidate Screening Report", "Employee Onboarding Status", "Hiring Trends"],
  },
  {
    title: "Regulatory Compliance Reports",
    links: ["Workplace Safety Compliance", "Labor Law Compliance", "Policy Adherence Report"],
  },
  {
    title: "HR Analytics & Insights",
    links: ["Employee Retention Analysis", "Diversity & Inclusion Report", "HR Budget Utilization"],
  },
];

const Reports = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (report) => {
    setOpenModal(report);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {reportCategories.map((category, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-bold text-indigo-700 mb-2">{category.title}</h3>
            <ul className="space-y-1">
              {category.links.map((link, i) => (
                <li key={i}>
                  <button
                    onClick={() => handleOpenModal(link)}
                    className="text-blue-600 hover:underline text-sm"
                  >
                    {link}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Conditionally Render Modals */}
      {openModal === "Employee Appraisal Report" && <PerformanceModal isOpen={true} onClose={handleCloseModal} />}
      {openModal === "Salary Slip Report" && <PayrollModal isOpen={true} onClose={handleCloseModal} />}
    </div>
  );
};

export default Reports;
