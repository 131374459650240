import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchClassSections = createAsyncThunk('classSections/fetchClassSections', async (classId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://lms-api.wiserbee.ca/api/ClassSection/GetClassSection/${classId}`);
      return response.data?.data;  // Assuming `response.data` contains the class sections
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  
  const classSectionsSlice = createSlice({
    name: 'classSections',
    initialState: {
      classSections: [],
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchClassSections.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchClassSections.fulfilled, (state, action) => {
            state.loading = false;
            state.classSections = action.payload;  // Adjust path based on API response
          })
          
        .addCase(fetchClassSections.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default classSectionsSlice.reducer;
  