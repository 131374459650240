import React, { useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import DashboardModal from "../../modals/Hrms/DashboardModal";

const data = [
  { month: "Jan", Hiring: 0, Firing: 0 },
  { month: "Feb", Hiring: 0, Firing: 0 },
  { month: "Mar", Hiring: 0, Firing: 0 },
  { month: "Apr", Hiring: 0, Firing: 0 },
  { month: "May", Hiring: 0, Firing: 0 },
  { month: "Jun", Hiring: 2, Firing: 1 },
  { month: "Jul", Hiring: 0, Firing: 0 },
  { month: "Aug", Hiring: 0, Firing: 0 },
  { month: "Sep", Hiring: 0, Firing: 0 },
  { month: "Oct", Hiring: 0, Firing: 0 },
  { month: "Nov", Hiring: 0, Firing: 0 },
  { month: "Dec", Hiring: 0, Firing: 0 },
];
const employeeData = [
  { department: "", total: 0, registered: 0, resigned: 0, terminated: 0 },
  { department: "", total: 3, registered: 1, resigned: 0, terminated: 0 },
  { department: "Accounts & Finance", total: 1, registered: 0, resigned: 1, terminated: 0 },
  { department: "Administration", total: 2, registered: 2, resigned: 0, terminated: 0 },
  { department: "Engineering & Production", total: 4, registered: 4, resigned: 0, terminated: 0 },
  { department: "Human Resource", total: 1, registered: 0, resigned: 0, terminated: 1 },
  { department: "Marketing", total: 3, registered: 3, resigned: 0, terminated: 0 },
  { department: "Procurement", total: 1, registered: 1, resigned: 0, terminated: 0 },
];

const leaveData = [
  { type: "Annual", value: 28 },
  { type: "Casual", value: 10 },
  { type: "Sick", value: 8 },
  { type: "Maternity", value: 62 },
  { type: "Paternity", value: 8 },
  { type: "Hajj/Ziarat", value: 41 },
];

const HrmsDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="p-6 bg-gray-100 min-h-screen text-sm">
      {/* Header Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
        {[
          { label: "Total Emp.", value: 16, color: "bg-green-100", icon: "👥" },
          { label: "Registered", value: 11, color: "bg-blue-100", icon: "🧑‍💼" },
          { label: "Contractual", value: 0, color: "bg-purple-100", icon: "📝" },
          { label: "Suspended", value: 0, color: "bg-yellow-100", icon: "⏸" },
          { label: "Terminated", value: 1, color: "bg-orange-100", icon: "❌" },
          { label: "Resigned", value: 1, color: "bg-red-100", icon: "🚪" },
        ].map((stat, index) => (
          <div key={index} className={`p-4 rounded-xl shadow-md flex items-center `}>
            <span className={`text-xl mr-3 p-2 rounded-full ${stat.color}`}>{stat.icon}</span>
            <div>
              <p className="text-lg font-semibold">{stat.value}</p>
              <p className="text-gray-600">{stat.label}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Main Sections */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Profile Section */}
        <div className="bg-white p-6 rounded-xl shadow-md col-span-1">
          <h3 className="text-lg font-bold">Welcome Back 🎉</h3>
          <p className="text-gray-600">View your overall report</p>
          <button
          onClick={() => setIsModalOpen(true)}
          className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-lg"
        >
          User Dashboard
        </button>
        </div>

        {/* Attendance Section */}
        <div className="bg-white p-6 rounded-xl shadow-md col-span-2">
          <h3 className="text-lg font-bold">Today's Employee Attendance</h3>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 mt-4 space-x-6">
            {[
              { label: "Total Employees", value: 0, icon: "👥" },
              { label: "Present", value: 0, icon: "✅" },
              { label: "Absent", value: 0, icon: "❌" },
              { label: "Late", value: 0, icon: "⏳" },
            ].map((item, i) => (
              <div key={i} className="flex items-center space-x-2">
                <span className="text-xl p-2 bg-red-50 rounded-full">{item.icon}</span>
                <div>
                  <p className="text-lg font-semibold">{item.value}</p>
                  <p className="text-gray-600">{item.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Events Section */}
        <div className="bg-white p-6 rounded-xl shadow-md col-span-1 flex flex-col">
          <h3 className="text-lg font-bold">HR Events</h3>
          <p className="text-gray-600">View your scheduled events</p>
          <div className="flex items-center justify-between mt-4">
            <p className="text-lg font-semibold">THU</p>
            <p className="text-xl font-bold">24</p>
          </div>
        </div>

        {/* Chart Section */}
        <div className="bg-white p-6 rounded-xl shadow-md col-span-2">
          <h3 className="text-lg font-bold">Month-wise Employees Report</h3>
          <ResponsiveContainer width="100%" height={450}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Hiring" fill="#3b82f6" />
              <Bar dataKey="Firing" fill="#22c55e" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
        {/* Employee Status Table */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">
            Department-wise Employees status ({employeeData.length})
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm bg-white border border-gray-300 rounded-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-700 text-sm">
                  <th className="p-3 text-left">DEPARTMENT NAME</th>
                  <th className="p-3">TOTAL EMPLOYEES</th>
                  <th className="p-3">REGISTERED</th>
                  <th className="p-3">RESIGNED</th>
                  <th className="p-3">TERMINATED</th>
                </tr>
              </thead>
              <tbody>
                {employeeData.map((item, index) => (
                  <tr key={index} className="border-t border-gray-300">
                    <td className="p-2">{item.department || "-"}</td>
                    <td className="p-2 text-center">{item.total}</td>
                    <td className="p-2 text-center">{item.registered}</td>
                    <td className="p-2 text-center">{item.resigned}</td>
                    <td className="p-2 text-center">{item.terminated}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Annual Leaves Chart */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Annual Leaves Data</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart layout="vertical" data={leaveData} margin={{ left: 20 }}>
              <XAxis type="number" hide />
              <YAxis dataKey="type" type="category" width={100} />
              <Tooltip />
              <Bar dataKey="value" fill="#3498db" barSize={20} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <DashboardModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default HrmsDashboard;
