import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { datePickerSvg } from "../../Constant/svgs";
import "react-datepicker/dist/react-datepicker.css";

const ReactDatePicker = ({ placeholder, getDate, name, selectedDate, day }) => {
  const [date, setDate] = useState(selectedDate || null);
  const datePickerRef = useRef();

  const handleCalenderOpen = () => {
    datePickerRef.current.setOpen(true);
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    if (getDate) {
      getDate(name, selectedDate); // Pass the date object directly, not the formatted string
    }
  };

  const isDateValid = (date) => {
    if (!day) return true; // If no day is selected, allow any date
    
    const selectedDay = day.toLowerCase(); // Normalize to lowercase for comparison
    const currentDay = date.toLocaleString("en-US", { weekday: "long" }).toLowerCase(); // Get day of the week

    return currentDay === selectedDay;
  };

  // Disable past dates by setting minDate to today
  const minDate = new Date(); // Today's date

  return (
    <div className="dateInputWrapper">
      <DatePicker
        ref={datePickerRef}
        selected={date || null}
        onChange={(date) => handleDateChange(date)}
        dateFormat="yyyy-MM-dd"
        className="DatePicker"
        placeholderText={placeholder}
        filterDate={isDateValid} // Disable dates that don't match the selected day
        minDate={minDate} // Disable past dates
      />
      <div className="iconbox" onClick={handleCalenderOpen}>
        {datePickerSvg}
      </div>
    </div>
  );
};


export default ReactDatePicker;
