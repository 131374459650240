import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
import { UserContextProvider } from "./ContextAPI/useUserContext";
import Routes from "./Router";

const App = () => {
  return (
    <UserContextProvider>
      <Routes />
    </UserContextProvider>
    
  );
};
export default App;

