import React, { useEffect, useState } from "react";
import { fetchCandidates } from "../../redux/candidateSlice";
import { 
  fetchInterviews, 
  fetchInterviewsByCandidate,
  scheduleInterview,
  updateInterviewStatus,
  hireCandidate
} from "../../redux/interviewSlice";
import { useDispatch, useSelector } from "react-redux";
import InterviewScheduleModal from "../../modals/Hrms/InterviewScheduleModal";
import InterviewStatusModal from "../../modals/Hrms/InterviewStatusModal";

const Interviews = () => {
  const dispatch = useDispatch();
  const { candidates } = useSelector((state) => state.candidates);
  const { candidateInterviews, loading } = useSelector((state) => state.interviews);

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);

  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCandidates());
    dispatch(fetchInterviews());
  }, [dispatch]);

  // Fetch interviews for each candidate
  useEffect(() => {
    candidates.forEach(candidate => {
      if (!candidateInterviews[candidate.id]) {
        dispatch(fetchInterviewsByCandidate(candidate.id));
      }
    });
  }, [candidates, candidateInterviews, dispatch]);

  // Get interview by stage for a candidate
  const getInterviewByStage = (candidateId, stage) => {
    if (!candidateInterviews[candidateId]) return null;
    return candidateInterviews[candidateId].find(interview => interview.stage === stage);
  };

  // Get status of a specific stage for a candidate
  const getStageStatus = (candidateId, stage) => {
    const interview = getInterviewByStage(candidateId, stage);
    return interview ? interview.status : "Not Scheduled";
  };

  // Open the correct modal based on interview stage and status
  const openModal = (candidate, stage) => {
    setSelectedCandidate(candidate);
    setCurrentStage(stage);

    const stageStatus = getStageStatus(candidate.id, stage);
    if (stageStatus === "Scheduled" || stageStatus === "Passed" || stageStatus === "Failed") {
      setUpdateModalOpen(true);
    } else {
      setScheduleModalOpen(true);
    }
  };

  const closeScheduleModal = () => {
    setScheduleModalOpen(false);
    setSelectedCandidate(null);
    setCurrentStage(null);
  };

  const closeUpdateModal = () => {
    setUpdateModalOpen(false);
    setSelectedCandidate(null);
    setCurrentStage(null);
  };

  // Handle interview scheduling
  const handleScheduleInterview = (candidateId, scheduleData) => {
    const interview = getInterviewByStage(candidateId, currentStage);
    if (interview) {
      dispatch(scheduleInterview({
        id: interview.id,
        data: {
          interviewer: scheduleData.interviewer,
          location: scheduleData.location,
          date: scheduleData.date,
          startTime: scheduleData.startTime,
          endTime: scheduleData.endTime
        }
      }));
    }
  };

  // Handle updating interview status
  const handleUpdateStatus = (candidateId, newStatus) => {
    const interview = getInterviewByStage(candidateId, currentStage);
    if (interview) {
      dispatch(updateInterviewStatus({
        id: interview.id,
        status: newStatus
      }));
    }
  };

  // Function to check if candidate is eligible for hiring (all passed)
  const isEligibleForHiring = (candidateId) => {
    if (!candidateInterviews[candidateId]) return false;
    
    return ['first', 'second', 'final'].every(stage => 
      getStageStatus(candidateId, stage) === "Passed"
    );
  };

  // Function to hire candidate
  const handleHireCandidate = (candidateId) => {
    dispatch(hireCandidate(candidateId));
  };

  // Get stage display name for UI
  const getStageName = (stage) => {
    const stageNames = {
      first: "1st Interview",
      second: "2nd Interview", 
      final: "Final Interview"
    };
    return stageNames[stage] || stage;
  };

  if (loading) {
    return <div className="p-8 text-center">Loading interviews data...</div>;
  }

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="bg-white p-6 rounded-lg shadow-md overflow-x-auto">
        <h2 className="text-2xl font-semibold mb-4">Search & Filter</h2>
        <div className="grid grid-cols-6 gap-4 mb-6">
          <input
            type="text"
            placeholder="First name"
            className="col-span-1 p-2 border rounded-md"
          />
          <select className="col-span-1 p-2 border rounded-md">
            <option>Any</option>
            <option>Designation 1</option>
          </select>
          <select className="col-span-1 p-2 border rounded-md cursor-pointer">
            <option>1st Interview Status</option>
            <option>Hired</option>
            <option>Not Scheduled</option>
          </select>
          <select className="col-span-1 p-2 border rounded-md cursor-pointer">
            <option>2nd Interview Status</option>
            <option>Hired</option>
            <option>Not Scheduled</option>
          </select>
          <select className="col-span-1 p-2 border rounded-md cursor-pointer">
            <option>Final Interview Status</option>
            <option>Hired</option>
            <option>Failed</option>
          </select>
        </div>

        <table className="table-auto w-full border-collapse">
          <thead className="bg-gray-200 text-gray-600">
            <tr>
              <th className="p-2">Applicant Name</th>
              <th className="p-2">Post Applied</th>
              <th className="p-2">1st Interview Status</th>
              <th className="p-2">2nd Interview Status</th>
              <th className="p-2">Final Interview Status</th>
              <th className="p-2">Hire</th>
              <th className="p-2">Rating</th>
              <th className="p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {candidates.map((candidate) => (
              <tr key={candidate.id} className="border-b">
                <td className="p-2">{candidate.name}</td>
                <td className="p-2">{candidate.currentDesignation}</td>
                {["first", "second", "final"].map((stage) => (
                  <td
                    key={stage}
                    className={`p-2 cursor-pointer ${
                      getStageStatus(candidate.id, stage) === "Scheduled"
                        ? "text-green-600"
                        : getStageStatus(candidate.id, stage) === "Passed"
                        ? "text-blue-600"
                        : getStageStatus(candidate.id, stage) === "Failed"
                        ? "text-red-600"
                        : "text-gray-600"
                    }`}
                    onClick={() => openModal(candidate, stage)}
                  >
                    {getStageStatus(candidate.id, stage)}
                  </td>
                ))}
                <td className="p-2">
                  <button
                    className={`px-4 py-2 rounded-md ${
                      isEligibleForHiring(candidate.id)
                        ? "bg-green-600 text-white"
                        : "bg-gray-400 text-white cursor-not-allowed"
                    }`}
                    disabled={!isEligibleForHiring(candidate.id)}
                    onClick={() => handleHireCandidate(candidate.id)}
                  >
                    Hire
                  </button>
                </td>
                <td className="p-2">{candidate.rating ? "⭐".repeat(Math.round(candidate.rating)) : ""}</td>
                <td className="p-2">...</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Interview Scheduling Modal */}
      <InterviewScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={closeScheduleModal}
        candidate={selectedCandidate}
        stageName={getStageName(currentStage)}
        stage={currentStage}
        onSchedule={handleScheduleInterview}
      />

      {/* Update Interview Status Modal */}
      <InterviewStatusModal
        isOpen={isUpdateModalOpen}
        onClose={closeUpdateModal}
        candidate={selectedCandidate}
        stageName={getStageName(currentStage)}
        onUpdateStatus={handleUpdateStatus}
      />
    </div>
  );
};

export default Interviews;