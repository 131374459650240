import React, { useState } from "react";
import HrApproval from "../../Components/tabs/hr/HrApproval";
import FinancialApproval from "../../Components/tabs/hr/FinanacialApproval";
import ArreasDetail from "../../Components/tabs/hr/ArreasDetail";
import BonusDetail from "../../Components/tabs/hr/BonusDetail";
import AllowanceDetail from "../../Components/tabs/hr/AllowanceDetail";

function ArreasBonus() {
  // Tabs with emojis
  const tabs = [
    { name: "Arrears Detail", emoji: "💰" },
    { name: "Bonuses Detail", emoji: "✅" },
    { name: "Allowance Detail", emoji: "💵" }
  ];

  const [activeTab, setActiveTab] = useState("Arrears Detail"); // Default tab
  const [feeStructures, setFeeStructures] = useState([]);
  const [feeBills, setFeeBills] = useState([]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Tabs */}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-2 border-2 border-gray-100 shadow-xl mb-4 pb-3 pt-2 rounded-b-xl">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => handleTabClick(tab.name)}
            className={`px-4 py-2 justify-center flex items-center space-x-2 ${
              activeTab === tab.name
                ? "text-white bg-[#241763]"
                : "bg-gray-200 text-gray-700"
            } rounded-t-md`}
          >
            <span className="text-lg">{tab.emoji}</span>
            <span>{tab.name}</span>
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="overflow-x-auto">
        {activeTab === "Arrears Detail" && (
          <ArreasDetail
            feeStructures={feeStructures}
            setFeeStructures={setFeeStructures}
          />
        )}

        {activeTab === "Bonuses Detail" && (
          <BonusDetail feeBills={feeBills} setFeeBills={setFeeBills} />
        )}

        {activeTab === "Allowance Detail" && <AllowanceDetail />}

      
      </div>
    </div>
  );
}

export default ArreasBonus;


