import { Pencil, Trash2 } from 'lucide-react'
import React from 'react'

const Feedback = () => {
  return (
    <div className="overflow-x-auto mt-6">
    <table className="table-auto w-full mt-6">
      <thead className="py-4 border-b font-bold">
        <tr className="bg-gray-100 border-b">
          <th>Student ID</th>
          <th>Student Name</th>
          <th>Parent Name</th>
          <th>Teacher Name</th>
          <th>FeedBack Details</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      {/* <tbody className="text-sm">
        {feeStructures.length > 0 ? (
          feeStructures.map((fee, index) => (
            <React.Fragment key={index}>
              <tr className="hover:bg-gray-200">
                <td>{fee.feeStructureName}</td>
                <td>{fee.description}</td>
                <td>{fee.feeCode}</td>
                <td>{fee.tuitionFee}</td>
                <td>{fee.labFee}</td>
                <td>{fee.academicYear}</td>
                <td className="space-x-2 flex items-center py-2">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDelete(index)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
           
                </td>
              </tr>
            
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan="7" className="text-center p-4">
              No Fee Structures Found
            </td>
          </tr>
        )}
      </tbody> */}
    </table>
  </div>
  )
}

export default Feedback
