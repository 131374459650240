import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import {
  fetchEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee,
} from "../../redux/employeeSlice";
import AddEmployeeModal from "../../modals/Hrms/AddEmployeeModal";
import EmployeeDetailsCard from "../../modals/Hrms/EmployeeDetailsCard";

const cardsData = [
  { title: "Total Emp.", value: 16, icon: "👥", bgColor: "bg-green-100" },
  { title: "Registered", value: 10, icon: "🛡️", bgColor: "bg-blue-100" },
  { title: "Contractual", value: 0, icon: "✍️", bgColor: "bg-purple-100" },
  { title: "Suspended", value: 0, icon: "🚫", bgColor: "bg-orange-100" },
  { title: "Terminated", value: 1, icon: "❌", bgColor: "bg-red-100" },
  { title: "Resigned", value: 1, icon: "🚶‍♂️", bgColor: "bg-gray-300" },
];

const EmloyeeDetails = () => {
  const dispatch = useDispatch();
  const { employees, status } = useSelector((state) => state.employees);

  const { register, handleSubmit, reset, setValue } = useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  const [currentPicture, setCurrentPicture] = useState(null);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const onSubmit = async (data) => {
    const newEmployee = {
      firstName: data.firstName,
      lastName: data.lastName,
      fatherName: data.fatherName,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      email: data.email,
      phone: data.phone,
      location: data.location,
      cnic: data.cnic,
      dob: data.dob,
      emergencyContact: data.emergencyContact,
      contactRelationShip: data.contactRelationShip,
      address: data.address,
      religion: data.religion,
      profilePicture: currentPicture || "https://via.placeholder.com/150",
      isActive: data.isActive,
    };

    if (isEdit) {
      dispatch(
        updateEmployee({ id: currentEmployeeId, updatedData: newEmployee })
      );
      toast.success("Employee updated successfully");
    } else {
      dispatch(addEmployee(newEmployee));
      toast.success("Employee added successfully");
    }

    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const employee = employees.find((emp) => emp.id === id);
    if (!employee) {
      console.error("Employee not found for ID:", id);
      return;
    }

    Object.keys(employee).forEach((key) => setValue(key, employee[key]));

    setCurrentEmployeeId(id);
    setCurrentPicture(employee.profilePicture);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteEmployee(id));
    toast.success("Employee deleted successfully");
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const filteredEmployees = employees.filter((employee) => {
    const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
    return (
      (fullName.includes(searchTerm.toLowerCase()) ||
        employee.id.toString().includes(searchTerm)) &&
      (locationFilter === "" || employee.location === locationFilter)
    );
  });

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-4 rounded shadow`}
          >
            <div className="flex flex-col space-y-2">
              <span className="text-2xl font-bold">{card.value}</span>
              <span className="text-sm text-gray-700">{card.title}</span>
            </div>
            <div className={`text-xl p-2 rounded-full ${card.bgColor}`}>
              {card.icon}
            </div>
          </div>
        ))}
      </div>

      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded w-full md:w-3/6"
          placeholder="Search by name or ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Locations</option>
          {[...new Set(employees.map((e) => e.location))].map((loc, i) => (
            <option key={i} value={loc}>
              {loc}
            </option>
          ))}
        </select>
        <button
          className="w-full md:w-1/6 flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2">Add Employee</span>
        </button>
      </div>

      <AddEmployeeModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        setValue={setValue}
        reset={reset}
        currentPicture={currentPicture}
      />

      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>DOB</th>
              <th>Location</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {filteredEmployees.map((employee, index) => (
              <React.Fragment key={employee.id}>
                <tr className="p-2 py-2 hover:bg-gray-200 space-y-2">
                  <td>
                    <img
                      src={
                        employee.profilePicture ||
                        "https://via.placeholder.com/150"
                      }
                      alt="Employee Profile"
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full object-cover"
                    />
                  </td>
                  <td>{`${employee.firstName} ${employee.lastName}`}</td>
                  <td>{employee.gender}</td>
                  <td>{employee.email}</td>
                  <td className="text-blue-500">{employee.phone}</td>
                  <td>{new Date(employee.dob).toLocaleDateString()}</td>
                  <td>{employee.location}</td>
                  <td>{employee.isActive ? "Yes" : "No"}</td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEdit(employee.id)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDelete(employee.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <div
                      onClick={() => toggleView(index)}
                      className="cursor-pointer hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                    >
                      {expandedRowIndex === index ? (
                        <ChevronUp
                          size={16}
                          className="group-hover:text-white"
                        />
                      ) : (
                        <ChevronDown
                          size={16}
                          className="group-hover:text-white"
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {expandedRowIndex === index && (
                  <tr>
                    <td colSpan="9">
                      <div className="p-4 border rounded-lg bg-gray-50 shadow-inner mt-2">
                        <EmployeeDetailsCard />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <Toaster />
    </div>
  );
};

export default EmloyeeDetails;
