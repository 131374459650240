import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import BookDetails from "../../modals/BookDetails";
import { pageSvg, libraryCardUserSvg, headSetSvg } from "../../Constant/svgs";
import { useSelector } from "react-redux";
import { Spin } from 'antd';


const LibraryCards = (props) => {

  const toggleFilter = useSelector((state) => state.toggler.toggleFilter);
  const [book, setBook] = useState({});
  const {loading, myBookList} = props;
  const [open, setOpen] = useState(false);


  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      border: "none",
    },
  };

  const handleCardClick = (book) => {
    setBook(book);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <Modal
        isOpen={open}
        style={customStyles}
        contentLabel="Book Details"
        onRequestClose={handleClose}
      >
        <BookDetails onRequestClose={handleClose} book={book} />
      </Modal>
      <Spin spinning={loading}>
        <div className="row">
          {loading ? null : myBookList.length === 0 ? (
            // Show "No Data Available" only when loading is complete and list is empty
            <div className="col-12 text-center mt-5">
              <h4>No Data Available</h4>
            </div>
          ) : (
            // Render book cards if data is available
            myBookList.map((book, index) => (
              <div
                className={`${toggleFilter ? "col-md-6 col-xl-4" : "col-md-4 col-xl-3"
                  } col-sm-6 cursor-pointer libraryCard mb-3 px-2`}
                key={index}
                onClick={() => handleCardClick(book)}
              >
                <div className="borderRadius_15 px-2 py-2 customShadow h-100 d-flex flex-column">
                  <div className="libraryImageWrapper">
                    <img src={book.coverImageUrl} alt={book.bookName} />
                  </div>
                  <div className="content mt-2 d-flex flex-column">
                    <h6 className="fw-bold text-capitalize">{book.bookName}</h6>
                    <div className="d-flex align-items-center justify-content-between authorSection">
                      <div className="d-flex align-items-center">
                        <span>{libraryCardUserSvg}</span>
                        <label htmlFor="" className="text-capitalize">
                          {book.author}
                        </label>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        {book.format === "eBook" ? (
                          <div className="d-flex align-items-center gap-2 text-capitalize">
                            <span>{pageSvg}</span> <span>{book.pages}Pg</span>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center gap-2">
                            {headSetSvg} <span>{book.duration}Hr</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2 authorSection">
                      <div
                        className="Stars"
                        style={{ "--rating": book.rating }}
                        aria-label={`Rating of this product is ${book.rating} out of 5.`}
                      ></div>
                      <span className="m-0 p-0">({book.reviewCount}K)</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </Spin>
    </>
  );
};

export default LibraryCards;
