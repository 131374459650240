import React from "react";
import { X } from "lucide-react";

const ApplyLoanModal = ({
  isModalOpen,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  employees,
  setValue,
}) => {
  const handleEmployeeChange = (e) => {
    const selectedEmployee = e.target.value;
    if (employees[selectedEmployee]) {
      const { stipend, basicSalary, deductions, maximumLimit } =
        employees[selectedEmployee];
      setValue("stipend", stipend); // Programmatically set value
      setValue("basicSalary", basicSalary);
      setValue("deductions", deductions);
      setValue("maximumLimit", maximumLimit);
    } else {
      // Clear values if no employee selected
      setValue("stipend", "");
      setValue("basicSalary", "");
      setValue("deductions", "");
      setValue("maximumLimit", "");
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-11/12 md:w-2/3 lg:w-1/3 h-[80vh] overflow-y-auto rounded shadow-lg relative">
        <div className="flex justify-between items-center border-b pb-2 px-4">
          <h2 className="text-xl font-semibold">Apply for Loan or Advance</h2>
          <button
            className="p-2 hover:bg-gray-200 rounded-full"
            onClick={() => {
              setIsModalOpen(false);
              reset();
            }}
          >
            <X />
          </button>
        </div>

        <div className="px-6 py-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* First Row: Employee Code - Name */}
            <div className="flex justify-between gap-4">
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Employee Code - Name
                </label>
                <select
                  {...register("employeeCodeName", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  onChange={handleEmployeeChange}
                >
                  <option value="">Select Employee</option>
                  {Object.keys(employees).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Stipend
                </label>
                <input
                  type="number"
                  {...register("stipend")}
                  className="mt-2 w-full border rounded p-2"
                  disabled
                />
              </div>
            </div>

            {/* Second Row: Basic Salary & Deductions */}
            <div className="flex justify-between gap-4 mt-4">
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Basic Salary
                </label>
                <input
                  type="number"
                  {...register("basicSalary", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Basic Salary"
                  disabled
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Deductions
                </label>
                <input
                  type="number"
                  {...register("deductions", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Deductions"
                  disabled
                />
              </div>
            </div>

            {/* Third Row: Maximum Limit & Select Type */}
            <div className="flex justify-between gap-4 mt-4">
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Maximum Limit
                </label>
                <input
                  type="number"
                  {...register("maximumLimit", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Maximum Limit"
                  disabled
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Select Type
                </label>
                <select
                  {...register("selectType", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                >
                  <option value="">Select Type</option>
                  <option value="loan">Loan</option>
                  <option value="advance">Advance</option>
                </select>
              </div>
            </div>

            {/* Fourth Row: Amount & Number of Installments */}
            <div className="flex justify-between gap-4 mt-4">
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Amount
                </label>
                <input
                  type="number"
                  {...register("amount", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Amount"
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 font-medium">
                  Number of Installments
                </label>
                <select
                  {...register("installments")}
                  className="mt-2 w-full border rounded p-2"
                  disabled
                >
                  {[...Array(12)].map((_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Reason */}
            <div className="mt-4">
              <label className="block text-gray-700 font-medium">Reason</label>
              <textarea
                {...register("reason", { required: true })}
                className="mt-2 w-full border rounded p-2"
                rows="3"
                placeholder="Reason"
              />
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-4 mt-6">
              <button
                type="button"
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                onClick={() => {
                  setIsModalOpen(false);
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#241763] text-white px-4 py-2 rounded"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplyLoanModal;
