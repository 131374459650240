import { CheckCircle } from "lucide-react";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Recruitment = () => {
  const totalPosts = 15;
  const totalApplications = 0;
  const ongoingInterviews = 0;
  const shortListed = 0;

  const totalCandidates = 10;
  const inProgress = 0;
  const hired = 5;

  const candidatesRatio = totalCandidates > 0 ? (inProgress / totalCandidates) * 100 : 0;
  const hiringRatio = totalCandidates > 0 ? (hired / totalCandidates) * 100 : NaN;

  const jobPosts = [
    { name: "Business Development Manager", applications: 2, interviews: 0, offered: 0 },
    { name: "Content Writing Internee", applications: 1, interviews: 0, offered: 0 },
    { name: "Customer Relationship Manager", applications: 1, interviews: 0, offered: 0 },
    { name: "Engineering Manager", applications: 1, interviews: 0, offered: 0 },
    { name: "Mobile Designer", applications: 2, interviews: 0, offered: 0 },
    { name: "Office Help", applications: 1, interviews: 0, offered: 0 },
    { name: "UX/UI Designer", applications: 2, interviews: 0, offered: 0 },
  ];

    const activities = [
    { type: "Candidate hired", description: "Candidate hired.", date: "2025-01-28 09:16:53 PM", color: "text-green-500" },
    { type: "Interview scheduled", description: "Final interview of Sophia Daniel scheduled.", date: "2025-01-28 03:47:51 PM", color: "text-green-500" },
    { type: "Status changed", description: "First interview status and remarks of Anderson Stuart changed.", date: "2025-01-28 12:40:11 AM", color: "text-orange-400" },
    { type: "Interview scheduled", description: "First interview of Anderson Stuart scheduled.", date: "2025-01-28 12:34:41 AM", color: "text-green-500" },
    { type: "Candidate hired", description: "Candidate hired.", date: "2023-11-14 02:34:01 PM", color: "text-green-500" },
    { type: "Candidate updated", description: "Updated candidate Amelia Mathew.", date: "2023-11-14 02:33:18 PM", color: "text-orange-400" },
    { type: "Candidate hired", description: "Candidate hired.", date: "2023-06-04 02:35:05 PM", color: "text-green-500" },
  ];


  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        {[
          { label: "Total posts", value: totalPosts },
          { label: "Applications", value: totalApplications },
          { label: "Ongoing interviews", value: ongoingInterviews },
          { label: "Short listed", value: shortListed },
        ].map((item, index) => (
          <div key={index} className="p-4 bg-white rounded-lg shadow-md text-center">
            <p className="text-2xl font-semibold">{item.value}</p>
            <p className="text-gray-500">{item.label}</p>
          </div>
        ))}
      </div>

      {/* Ratio Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="p-6 bg-white rounded-lg shadow-md text-center">
          <p className="mb-2 text-gray-500">Candidates ratio</p>
          <div className="w-28 mx-auto">
            <CircularProgressbar
              value={candidatesRatio}
              text={`${candidatesRatio.toFixed(0)}%`}
              styles={buildStyles({ textColor: "#333", pathColor: "#34D399" })}
            />
          </div>
          <div className="flex justify-between mt-3 text-gray-500 text-sm">
            <span>Total: {totalCandidates}</span>
            <span>In Progress: {inProgress}</span>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md text-center">
          <p className="mb-2 text-gray-500">Hiring ratio</p>
          <div className="w-28 mx-auto">
            <CircularProgressbar
              value={hiringRatio}
              text={isNaN(hiringRatio) ? "NaN%" : `${hiringRatio.toFixed(0)}%`}
              styles={buildStyles({ textColor: "#333", pathColor: "#34D399" })}
            />
          </div>
          <div className="flex justify-between mt-3 text-gray-500 text-sm">
            <span>Total: {totalCandidates}</span>
            <span>Hired: {hired}</span>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md text-center">
          <p className="mb-2 text-gray-500">Hired this month</p>
          <p className="text-lg font-semibold mt-6">-</p>
        </div>
      </div>

      {/* Job Listing Table */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <table className="w-full text-left">
          <thead>
            <tr className="border-b">
              <th className="py-2">Post Name</th>
              <th className="py-2">Applications Received</th>
              <th className="py-2">Ongoing Interviews</th>
              <th className="py-2">Offered</th>
            </tr>
          </thead>
          <tbody>
            {jobPosts.map((job, index) => (
              <tr key={index} className="border-b">
                <td className="py-3">{job.name}</td>
                <td className="py-3 text-center">{job.applications}</td>
                <td className="py-3 text-center">{job.interviews}</td>
                <td className="py-3 text-center">{job.offered}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      
       {/* <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Activities Performed</h2>
        <div className="relative border-l-2 border-gray-300 pl-6">
          {activities.map((activity, index) => (
            <div key={index} className="mb-6 relative">
              <div className="absolute -left-3 top-0">
                <CheckCircle className={`w-6 h-6 ${activity.color}`} />
              </div>
              <p className="text-lg font-semibold text-gray-700">{activity.type}</p>
              <p className="text-gray-500">{activity.description}</p>
              <p className="text-gray-400 text-sm">{activity.date}</p>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Recruitment;
