import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/overtime`;

// Fetch Overtime Records
export const fetchOvertime = createAsyncThunk("overtime/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Overtime Record
export const addOvertime = createAsyncThunk("overtime/add", async (overtimeData) => {
  const response = await axios.post(API_URL, overtimeData);
  return response.data;
});

// Approve or Reject Overtime
export const updateOvertimeStatus = createAsyncThunk(
    "overtime/updateStatus",
    async ({ id, status, approvedBy }) => {
      let endpoint = status === "Approved" ? "approve" : "reject";
      const response = await axios.put(`${BASE_URL}/overtime/${endpoint}`, { id, approvedBy });
      return response.data;
    }
  );
  
  

const overtimeSlice = createSlice({
  name: "overtime",
  initialState: {
    overtimeRecords: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOvertime.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOvertime.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.overtimeRecords = action.payload;
      })
      .addCase(fetchOvertime.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addOvertime.fulfilled, (state, action) => {
        state.overtimeRecords.push(action.payload);
      })
      .addCase(updateOvertimeStatus.fulfilled, (state, action) => {
        const index = state.overtimeRecords.findIndex((o) => o.id === action.payload.id);
        if (index !== -1) {
          state.overtimeRecords[index] = action.payload;
        }
      });
  },
});

export default overtimeSlice.reducer;
