import React from "react";
import { useForm } from "react-hook-form";

const FineModal = ({ isOpen, onClose, onSubmit }) => {
  const { register, handleSubmit, reset } = useForm();

  const handleFormSubmit = (data) => {
    onSubmit(data);
    reset(); // Reset the form after submission
    onClose(); // Close the modal
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Add Fine</h2>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Emp Code</label>
            <input
              type="text"
              {...register("empCode", { required: true })}
              className="w-full border rounded px-3 py-2 text-sm"
              placeholder="Employee Code"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Emp Name</label>
            <input
              type="text"
              {...register("empName", { required: true })}
              className="w-full border rounded px-3 py-2 text-sm"
              placeholder="Employee Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Fine Reason</label>
            <input
              type="text"
              {...register("fineReason", { required: true })}
              className="w-full border rounded px-3 py-2 text-sm"
              placeholder="Reason for Fine"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Fine Amount</label>
            <input
              type="number"
              {...register("fineAmount", { required: true })}
              className="w-full border rounded px-3 py-2 text-sm"
              placeholder="Fine Amount"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Date</label>
            <input
              type="date"
              {...register("fineDate", { required: true })}
              className="w-full border rounded px-3 py-2 text-sm"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#241763] text-white px-4 py-2 rounded"
            >
              Add Fine
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FineModal;
