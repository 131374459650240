import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { ChevronDown, ChevronUp, Pencil, Plus, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  fetchLoanAdvances, 
  applyLoanAdvance, 
  updateLoanAdvance, 
  deleteLoanAdvance 
} from "../../redux/loanAdvanceSlice";
import { fetchEmployees } from "../../redux/employeeSlice";
import ApplyLoanModal from "../../modals/Hrms/ApplyLoanMOdal";

const LoanAdvance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, setValue } = useForm();
  const [isApplyLoanModalOpen, setIsApplyLoanModalOpen] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [editLoanId, setEditLoanId] = useState(null);
  
  const { loanAdvances, status: loanStatus } = useSelector(state => state.loanAdvances);
  const { employees, status: employeesStatus } = useSelector(state => state.employees);
  
  // Format employees for the dropdown
  const formattedEmployees = {};
  if (employees.length > 0) {
    employees.forEach(emp => {
      const key = `${emp.id} - ${emp.firstName} ${emp.lastName}`;
      formattedEmployees[key] = {
        stipend: emp.stipend || 0,
        basicSalary: emp.basicSalary || 0,
        deductions: emp.deductions || 0,
        maximumLimit: emp.maximumLimit || 0
      };
    });
  }

  useEffect(() => {
    dispatch(fetchLoanAdvances());
    dispatch(fetchEmployees());
  }, [dispatch]);
  
  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleEdit = (loanId) => {
    const loanToEdit = loanAdvances.find(loan => loan.id === loanId);
    if (loanToEdit) {
      // Get employee info safely
      const firstName = loanToEdit.Employee?.firstName || "";
      const lastName = loanToEdit.Employee?.lastName || "";
      
      // Format employee code name
      const employeeCodeName = `${loanToEdit.employeeId} - ${firstName} ${lastName}`;
      
      // Set form values
      setValue("employeeCodeName", employeeCodeName);
      setValue("stipend", loanToEdit.stipend);
      setValue("basicSalary", loanToEdit.basicSalary);
      setValue("deductions", loanToEdit.deductions);
      setValue("maximumLimit", loanToEdit.maximumLimit);
      setValue("selectType", loanToEdit.type?.toLowerCase() || "loan");
      setValue("amount", loanToEdit.amount);
      setValue("installments", loanToEdit.installments);
      setValue("reason", loanToEdit.reason);
      
      setEditLoanId(loanId);
      setIsApplyLoanModalOpen(true);
    }
  };

  const handleDelete = (loanId) => {
    if (window.confirm("Are you sure you want to delete this loan application?")) {
      dispatch(deleteLoanAdvance(loanId))
        .unwrap()
        .then(() => {
          toast.success("Loan application deleted successfully!");
        })
        .catch((error) => {
          toast.error(`Failed to delete: ${error.message || "Unknown error"}`);
        });
    }
  };

  const onSubmit = (data) => {
    // Extract employeeId from the selected option
    const employeeId = parseInt(data.employeeCodeName.split(" - ")[0]);
    
    const loanData = {
      employeeId,
      type: data.selectType.charAt(0).toUpperCase() + data.selectType.slice(1), // Capitalize first letter
      amount: parseFloat(data.amount),
      installments: parseInt(data.installments),
      reason: data.reason,
      stipend: parseFloat(data.stipend),
      basicSalary: parseFloat(data.basicSalary),
      deductions: parseFloat(data.deductions),
      maximumLimit: parseFloat(data.maximumLimit)
    };

    if (editLoanId) {
      dispatch(updateLoanAdvance({ id: editLoanId, updatedData: loanData }))
        .unwrap()
        .then(() => {
          toast.success("Loan application updated successfully!");
          setIsApplyLoanModalOpen(false);
          reset();
          setEditLoanId(null);
          // Refresh data
          dispatch(fetchLoanAdvances());
        })
        .catch((error) => {
          toast.error(`Failed to update: ${error.message || "Unknown error"}`);
        });
    } else {
      dispatch(applyLoanAdvance(loanData))
        .unwrap()
        .then(() => {
          toast.success("Loan application submitted successfully!");
          setIsApplyLoanModalOpen(false);
          reset();
          // Refresh data
          dispatch(fetchLoanAdvances());
        })
        .catch((error) => {
          toast.error(`Failed to submit: ${error.message || "Unknown error"}`);
        });
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Find employee by ID (for display purposes)
  const findEmployeeById = (id) => {
    return employees.find(emp => emp.id === id) || { firstName: "Unknown", lastName: "" };
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <h1 className="text-2xl font-bold">Loan & Advance Management</h1>
      </div>
      
      {/* Filters and Add Button */}
      <div className="mb-4 flex flex-wrap gap-4 items-center w-full">
        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsApplyLoanModalOpen(true);
            reset();
            setEditLoanId(null);
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Apply For Loan</span>
        </button>
        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => navigate("/duesDetail")}
        >
          <span className="ml-2 text-sm">Dues Details</span>
        </button>
      </div>

      {/* Loading state */}
      {loanStatus === "loading" && (
        <div className="text-center py-4">Loading loan data...</div>
      )}
      
      {/* Error state */}
      {loanStatus === "failed" && (
        <div className="text-center py-4 text-red-500">Failed to load loan data. Please try again later.</div>
      )}

      {/* Loan Details Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold bg-gray-100">
            <tr>
              <th className="px-4 py-2">Emp. ID</th>
              <th className="px-4 py-2">Emp. Name</th>
              <th className="px-4 py-2">Created At</th>
              <th className="px-4 py-2">Manager Approval</th>
              <th className="px-4 py-2">HR Approval</th>
              <th className="px-4 py-2">Installments</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {loanAdvances.map((loan, index) => {
              // Handle missing Employee data after create/update
              const employeeName = loan.Employee 
                ? `${loan.Employee.firstName} ${loan.Employee.lastName}`
                : findEmployeeById(loan.employeeId).firstName + " " + findEmployeeById(loan.employeeId).lastName;
                
              return (
                <React.Fragment key={loan.id}>
                  <tr className="p-2 py-2 hover:bg-gray-200">
                    <td className="px-4 py-2 text-center">{loan.employeeId}</td>
                    <td className="px-4 py-2">{employeeName}</td>
                    <td className="px-4 py-2">{formatDate(loan.createdAt)}</td>
                    <td className="px-4 py-2">{loan.managerApproval || "Pending"}</td>
                    <td className="px-4 py-2">{loan.hrApproval || "Pending"}</td>
                    <td className="px-4 py-2 text-center">{loan.installments}</td>
                    <td className="px-4 py-2 text-right">{loan.amount?.toLocaleString()}</td>
                    <td className="px-4 py-2 space-x-2 flex items-center">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(loan.id)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(loan.id)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp size={16} className="group-hover:text-white" />
                        ) : (
                          <ChevronDown size={16} className="group-hover:text-white" />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="8" className="bg-gray-100 p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <p><strong>Reason:</strong> {loan.reason || "N/A"}</p>
                          <p><strong>Type:</strong> {loan.type || "N/A"}</p>
                          <p><strong>Department:</strong> {
                            loan.Employee?.department || 
                            findEmployeeById(loan.employeeId)?.department || 
                            "N/A"
                          }</p>
                          <p><strong>Designation:</strong> {
                            loan.Employee?.designation || 
                            findEmployeeById(loan.employeeId)?.designation || 
                            "N/A"
                          }</p>
                          <p><strong>Basic Salary:</strong> {loan.basicSalary?.toLocaleString() || "N/A"}</p>
                          <p><strong>Maximum Limit:</strong> {loan.maximumLimit?.toLocaleString() || "N/A"}</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
            {loanAdvances.length === 0 && loanStatus === "succeeded" && (
              <tr>
                <td colSpan="8" className="text-center py-4">
                  No Loan Applications Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Apply Loan Modal */}
      <ApplyLoanModal
        isModalOpen={isApplyLoanModalOpen}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsApplyLoanModalOpen}
        register={register}
        reset={reset}
        employees={formattedEmployees}
        setValue={setValue}
      />

      <Toaster />
    </div>
  );
};

export default LoanAdvance;