import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchRosters, addRoster, updateRoster, deleteRoster } from "../../../redux/rosterSlice";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";

const Shifts = () => {
  const dispatch = useDispatch();
  const { rosters, status, error } = useSelector((state) => state.rosters);
  const [editingRoster, setEditingRoster] = useState(null);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const { handleSubmit, register, reset, setValue } = useForm();

  // Fetch rosters on mount
  useEffect(() => {
    dispatch(fetchRosters());
  }, [dispatch]);

  // Submit form (Add or Update Roster)
  const onSubmit = (data) => {
    const formattedData = {
      rosterName: data.rosterName,
      shifts: JSON.stringify(
        Object.keys(data)
          .filter((key) => key.includes("Enabled"))
          .map((key) => ({
            day: key.replace("Enabled", ""),
            enabled: data[key],
            startTime: data[`${key.replace("Enabled", "")}StartTime`],
            endTime: data[`${key.replace("Enabled", "")}EndTime`],
          }))
      ),
    };

    if (editingRoster !== null) {
      dispatch(updateRoster({ id: editingRoster, updatedData: formattedData }));
    } else {
      dispatch(addRoster(formattedData));
    }

    reset();
    setEditingRoster(null);
  };

  // Edit existing roster
  const editRoster = (roster) => {
    setEditingRoster(roster.id);
    setValue("rosterName", roster.rosterName);

    JSON.parse(roster.shifts).forEach((shift) => {
      setValue(`${shift.day}Enabled`, shift.enabled);
      setValue(`${shift.day}StartTime`, shift.startTime);
      setValue(`${shift.day}EndTime`, shift.endTime);
    });
  };

  // Delete roster
  const handleDelete = (id) => {
    dispatch(deleteRoster(id));
  };

  // Toggle roster expansion
  const toggleExpand = (id) => {
    setExpandedIndexes((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  };

  return (
    <div className="flex flex-col md:flex-row p-6 gap-6">
      {/* Add/Edit Roster Form */}
      <div className="bg-white shadow-md p-6 rounded-md w-full md:w-2/3">
        <h2 className="text-xl font-semibold mb-4">
          {editingRoster !== null ? "Edit Roster" : "Add New Roster"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Roster Name */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Roster Name</label>
            <input
              {...register("rosterName", { required: "Roster Name is required" })}
              placeholder="Must Be Unique"
              className="w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>

          {/* Roster Timing Details */}
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2">Roster Timing Detail</label>
            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, index) => (
              <div key={index} className="flex items-center gap-4 mb-3 w-full text-sm">
                <label className="flex items-center gap-2">
                  <input type="checkbox" {...register(`${day}Enabled`)} />
                  <span className="w-14 md:w-20 text-sm">{day}</span>
                </label>
                <input type="time" defaultValue="08:00" {...register(`${day}StartTime`)} className="border border-gray-300 rounded-md px-3 py-2 w-20 md:w-40" />
                <input type="time" defaultValue="18:00" {...register(`${day}EndTime`)} className="border border-gray-300 rounded-md px-3 py-2 w-20 md:w-40" />
              </div>
            ))}
          </div>

          {/* Buttons */}
          <div className="flex gap-4">
            <button type="submit" className="bg-[#241763] text-white px-4 py-2 rounded-md hover:bg-purple-700">
              {editingRoster !== null ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              onClick={() => {
                reset();
                setEditingRoster(null);
              }}
              className="bg-gray-100 border border-gray-300 px-4 py-2 rounded-md hover:bg-gray-200"
            >
              Reset
            </button>
          </div>
        </form>
      </div>

      {/* Roster Details */}
      <div className="bg-white shadow-md p-6 rounded-md w-full md:w-1/3">
        <h2 className="text-xl font-semibold mb-4">Roster Details</h2>

        {status === "loading" && <p>Loading...</p>}
        {status === "failed" && <p className="text-red-500">{error}</p>}

        {rosters.map((roster) => (
          <div key={roster.id} className="border border-gray-300 rounded-md mb-3">
            <div className="flex justify-between items-center p-3 bg-gray-100 text-sm">
              <span>{roster.rosterName}</span>
              <div className="flex gap-2">
                <button onClick={() => editRoster(roster)} className="text-blue-500 text-sm hover:underline">
                  <Pencil className="text-[#241763] w-4 h-4" />
                </button>
                <button onClick={() => handleDelete(roster.id)} className="text-red-500 text-sm hover:underline">
                  <Trash2 className="text-[#241763] w-4 h-4" />
                </button>
                <button onClick={() => toggleExpand(roster.id)} className="text-gray-500 hover:underline text-sm">
                  {expandedIndexes.includes(roster.id) ? "▲" : "▼"}
                </button>
              </div>
            </div>

            {expandedIndexes.includes(roster.id) && (
              <div className="p-3">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      <th className="border-b py-1">Day Name</th>
                      <th className="border-b py-1">Day Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JSON.parse(roster.shifts).map((day, idx) => (
                      <tr key={idx}>
                        <td className="py-1">{day.day}</td>
                        <td className="py-1">{day.enabled ? `${day.startTime}-${day.endTime}` : "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shifts;
