import React, { useState } from "react";

const GenerateSlip = () => {
  const [expandedSection, setExpandedSection] = useState(null); // Track expanded section

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  const sections = [
    {
      name: "Software Development",
      data: [
        { empCode: "EMP001", empName: "John Doe", session: "2025", tDays: 30, p: 28, a: 2, late: 1, leaves: 0, ded: 100, ot: 5, dedAmount: 200, bSalary: 5000, total: 5200 },
        { empCode: "EMP002", empName: "Jane Smith", session: "2025", tDays: 30, p: 29, a: 1, late: 0, leaves: 0, ded: 50, ot: 2, dedAmount: 100, bSalary: 4800, total: 4900 },
      ],
    },
    {
      name: "Audit Controls",
      data: [
        { empCode: "EMP003", empName: "Alice Johnson", session: "2025", tDays: 30, p: 27, a: 3, late: 2, leaves: 1, ded: 150, ot: 0, dedAmount: 300, bSalary: 5100, total: 4950 },
      ],
    },
    {
      name: "Procurement",
      data: [], // No data for this section
    },
    // Add more sections as needed
  ];

  return (
    <div className="space-y-2">
      {sections.map((section, index) => (
        <div key={index} className="border border-gray-300 rounded-md">
          {/* Dropdown Header */}
          <div
            className="flex justify-between items-center px-4 py-2 bg-gray-100 cursor-pointer"
            onClick={() => toggleSection(index)}
          >
            <span className="font-medium text-gray-800">{section.name}</span>
            <button className="text-gray-500 hover:text-gray-700">
              {expandedSection === index ? "▲" : "▼"}
            </button>
          </div>

          {/* Dropdown Content (Table) */}
          {expandedSection === index && (
            <div className="p-4">
              {section.data.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="table-auto w-full text-sm">
                    <thead className="border-b font-bold ">
                      <tr className="bg-[#241763]/10 border-b ">
                        <th className="p-2">Emp Code</th>
                        <th>Emp Name</th>
                        <th>Session</th>
                        <th>T.Days</th>
                        <th>P</th>
                        <th>A</th>
                        <th>Late</th>
                        <th>Leaves</th>
                        <th>Ded.</th>
                        <th>OT</th>
                        <th>Ded. Amount</th>
                        <th>B.Salary</th>
                        <th>Total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {section.data.map((row, rowIndex) => (
                        <tr key={rowIndex} className="hover:bg-gray-200">
                          <td>{row.empCode}</td>
                          <td>{row.empName}</td>
                          <td>{row.session}</td>
                          <td>{row.tDays}</td>
                          <td>{row.p}</td>
                          <td>{row.a}</td>
                          <td>{row.late}</td>
                          <td>{row.leaves}</td>
                          <td>{row.ded}</td>
                          <td>{row.ot}</td>
                          <td>{row.dedAmount}</td>
                          <td>{row.bSalary}</td>
                          <td>{row.total}</td>
                          <td className="space-x-2 flex items-center">
                         
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center text-gray-500">No data available for {section.name}</div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GenerateSlip;
