import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import AddWarningModal from "../../modals/Hrms/AddWarningModal";
import { fetchEmployees } from "../../redux/employeeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWarnings,
  addWarning,
  updateWarning,
  deleteWarning,
} from "../../redux/warningSlice";

const WarningDetails = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentWarning, setCurrentWarning] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  // Get warnings and employees from Redux store
  const { warnings, status } = useSelector((state) => state.warnings || { warnings: [] });

  const { employees } = useSelector((state) => state?.employees || []);


  useEffect(() => {
    dispatch(fetchEmployees());
    dispatch(fetchWarnings());
  }, [dispatch]);

  const onSubmit = async (data) => {
    if (isEdit && currentWarning) {
      await dispatch(updateWarning({ id: currentWarning.id, updatedData: data }));
      toast.success("Warning updated successfully!");
    } else {
      await dispatch(addWarning(data));
      toast.success("Warning added successfully!");
    }

    setIsModalOpen(false);
    reset();
  };

  const handleEdit = (id) => {
    const warningToEdit = warnings.find((warning) => warning.id === id);
    if (warningToEdit) {
      setCurrentWarning(warningToEdit);
      setIsEdit(true);
      setIsModalOpen(true);

      // Populate modal fields
      Object.keys(warningToEdit).forEach((key) => {
        setValue(key, warningToEdit[key]);
      });
    }
  };

  const handleDelete = async (id) => {
    await dispatch(deleteWarning(id));
    toast.success("Warning deleted successfully!");
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const filteredWarnings = (warnings || []).filter((warning) => {
    const searchMatch = searchTerm
      ? `${warning.name}`.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    const locationMatch = locationFilter ? warning.location === locationFilter : true;
    return searchMatch && locationMatch;
  });
  

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Dashboard Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        {/* Cards Data */}
      </div>

      {/* Filters and Add Button */}
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded w-full md:w-3/6"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Locations</option>
        </select>
        <button
          className="w-full md:w-1/6 flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsEdit(false);
            setCurrentWarning(null);
            setIsModalOpen(true);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Add Warning</span>
        </button>
      </div>

      {/* Warning List Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Emp. Name</th>
              <th>Location</th>
              <th>Warning Sub</th>
              <th>Warning Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
  {filteredWarnings.map((warning, index) => {
    const employee = employees.find((emp) => emp.id === warning.employeeId);
    return (
      <React.Fragment key={warning.id}>
        <tr className="hover:bg-gray-200">
          <td>{employee ? `${employee.firstName} ${employee.lastName}` : "N/A"}</td>
          <td>{employee ? employee.location : "N/A"}</td>
          <td>{warning.subject}</td>
          <td>{warning.warningDate}</td>
          <td>{warning.isActive ? "Active" : "Inactive"}</td>
          <td className="space-x-2 flex items-center py-2">
            <button
              className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
              onClick={() => handleEdit(warning.id)}
            >
              <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
            </button>
            <button
              className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
              onClick={() => handleDelete(warning.id)}
            >
              <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
            </button>
            <div
              onClick={() => toggleView(index)}
              className="cursor-pointer hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
            >
              {expandedRowIndex === index ? (
                <ChevronUp size={16} className="group-hover:text-white" />
              ) : (
                <ChevronDown size={16} className="group-hover:text-white" />
              )}
            </div>
          </td>
        </tr>
        {expandedRowIndex === index && (
          <tr>
            <td colSpan="5">
              <div className="p-4 bg-gray-100">Details... {warning.description}</div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  })}
</tbody>

        </table>
      </div>

      {/* Warning Modal */}
      <AddWarningModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        employees={employees}
      />

      <Toaster />
    </div>
  );
};

export default WarningDetails;
