import { createSlice } from "@reduxjs/toolkit";

const session = JSON.parse(localStorage.getItem("user")) || "";

const initialState = {
   permissions: session.permissions || [],
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{
        clearAuth:(state)=>{
            state.permissions = [];
        },
        setPermissions: (state, action) => {
          state.permissions = action.payload;
        },
    }
})

export const {clearAuth, setPermissions} = authSlice.actions;

export default authSlice.reducer;