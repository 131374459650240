import { useEffect } from "react";
import { useForm } from "react-hook-form";

const ApplyWelfareModal = ({ isOpen, onClose, onSubmit, initialData, employees }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((key) => {
        setValue(key, initialData[key]);
      });
    } else {
      reset();
    }
  }, [initialData, setValue, reset]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-medium">
            {initialData ? "Edit Welfare Allowance" : "Apply Welfare Allowance"}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            ×
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-4 space-y-4">
          {/* <label>Employee Name *</label>
          <select {...register("employeeId", { required: true })} className="w-full border px-3 py-2">
            <option value="" disabled>Select an employee</option>
            {employees.map((emp) => (
              <option key={emp.id} value={emp.id}>
                {emp.firstName} {emp.lastName}
              </option>
            ))}
          </select> */}

<div>
            <label className="block text-sm font-medium">Session Name</label>
            <input type="text" className="mt-1 w-full border rounded px-3 py-2" defaultValue="March-2024" {...register("sessionName")} readOnly />
          </div>
          <div>
            <label className="block text-sm font-medium">Apply Date *</label>
            <input type="date" className="mt-1 w-full border rounded px-3 py-2" {...register("applyDate", { required: true })} />
          </div>
          <div>
            <label className="block text-sm font-medium">Salary*</label>
            <input type="number" className="mt-1 w-full border rounded px-3 py-2" {...register("salary", { required: true })} />
          </div>
          <div>
            <label className="block text-sm font-medium">Employee Name *</label>
            <select className="mt-1 w-full border rounded px-3 py-2" {...register("employeeId", { required: true })}>
              <option value="" disabled>Select Employee</option>
              {employees.map((emp) => (
                <option key={emp.id} value={emp.id}>{emp.firstName} {emp.lastName}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Allowance Type *</label>
            <select className="mt-1 w-full border rounded px-3 py-2" {...register("allowanceType", { required: true })}>
              <option value="" disabled>Select Type</option>
              <option value="Welfare">Welfare</option>
              <option value="Funeral">Funeral</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Amount *</label>
            <input type="number" className="mt-1 w-full border rounded px-3 py-2" {...register("amount", { required: true })} />
          </div>
          <div>
            <label className="block text-sm font-medium">Description</label>
            <textarea className="mt-1 w-full border rounded px-3 py-2" {...register("description")} placeholder="Enter description"></textarea>
          </div>

          <button type="submit" className="bg-[#231763] text-white px-4 py-2 rounded">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ApplyWelfareModal