import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchExamReport } from "../../../redux/examSlice";
import { fetchCampusDetails } from "../../../redux/campusesSlice";
import ViewReportModal from "./ViewReportModal";

const ExamsModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [viewReportOpen, setViewReportOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedCampusId, setSelectedCampusId] = useState("");
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  
  const { campusDetails, loading } = useSelector((state) => state.campuses);
  
  // Find the selected campus data
  const selectedCampus = campusDetails.find(campus => campus.id === selectedCampusId) || { classes: [] };
  const availableClasses = selectedCampus.classes || [];
  
  // Find the selected class data
  const selectedClass = availableClasses.find(cls => cls.id === selectedClassId) || { sections: [] };
  const availableSections = selectedClass.sections || [];
  
  // Find the selected section data
  const selectedSection = availableSections.find(section => section.id === selectedSectionId) || { students: [] };
  const availableStudents = selectedSection.students || [];

  useEffect(() => {
    if (isOpen) {
      // Fetch campus details with classes, sections, and students
      dispatch(fetchCampusDetails());
    }
  }, [isOpen, dispatch]);
  
  useEffect(() => {
    // Reset dependent dropdowns when parent selection changes
    if (selectedCampusId === "") {
      setSelectedClassId("");
      setSelectedSectionId("");
      setSelectedStudentId("");
    }
  }, [selectedCampusId]);
  
  useEffect(() => {
    // Reset dependent dropdowns when parent selection changes
    if (selectedClassId === "") {
      setSelectedSectionId("");
      setSelectedStudentId("");
    }
  }, [selectedClassId]);
  
  useEffect(() => {
    // Reset dependent dropdowns when parent selection changes
    if (selectedSectionId === "") {
      setSelectedStudentId("");
    }
  }, [selectedSectionId]);


  const handleViewReport = () => {
    if (selectedStudentId) {
      dispatch(fetchExamReport(selectedStudentId));
      setViewReportOpen(true);
    }
  };

  console.log("Selected Student ID:", selectedStudentId);
  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-[60%] relative">
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <label htmlFor="campus" className="block mb-2">
                Campus
              </label>
              <select 
                id="campus" 
                className="border p-2 rounded w-full"
                value={selectedCampusId}
                onChange={(e) => setSelectedCampusId(e.target.value)}
                disabled={loading}
              >
                <option value="" disabled>
                  Choose a campus
                </option>
                {campusDetails.map((campus) => (
                  <option key={campus.id} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="grade" className="block mb-2">
                Grade
              </label>
              <select 
                id="grade" 
                className="border p-2 rounded w-full"
                value={selectedClassId}
                onChange={(e) => setSelectedClassId(e.target.value)}
                disabled={!selectedCampusId || loading}
              >
                <option value="" disabled>
                  Choose a grade
                </option>
                {availableClasses.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.className}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <label htmlFor="section" className="block mb-2">
                Section
              </label>
              <select 
                id="section" 
                className="border p-2 rounded w-full"
                value={selectedSectionId}
                onChange={(e) => setSelectedSectionId(e.target.value)}
                disabled={!selectedClassId || loading}
              >
                <option value="" disabled>
                  Choose a section
                </option>
                {availableSections.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.sectionName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="studentName" className="block mb-2">
                Student Name
              </label>
              <select 
                id="studentName" 
                className="border p-2 rounded w-full"
                value={selectedStudentId}
                onChange={(e) => setSelectedStudentId(e.target.value)}
                disabled={!selectedSectionId || loading}
              >
                <option value="" disabled>
                  Choose a student
                </option>
                {availableStudents.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.fullName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              onClick={onClose}
              className="bg-gray-400 px-4 py-2 rounded text-white"
            >
              Close
            </button>
            <button
              onClick={handleViewReport}
              className="bg-[#241763] px-4 py-2 rounded text-white"
              disabled={!selectedStudentId || loading}
            >
              View Report
            </button>
          </div>
        </div>
      </div>

      <ViewReportModal
        isOpen={viewReportOpen}
        onClose={() => setViewReportOpen(false)}
        studentId={selectedStudentId}
      />
    </>
  );
};

export default ExamsModal;