import React, { useEffect } from "react";

import {
  fetchFeeStructures,
  addFeeStructure,
  updateFeeStructure,
  deleteFeeStructure,
} from "../../redux/feeStructureSlice";
import { fetchClassesV1 } from "../../redux/classSlice";
import { fetchCourses } from "../../redux/coursesSlice";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import FeeStructureManagementModal from "../../modals/admin/FeeStructureManagementModal";
import { useDispatch, useSelector } from "react-redux";

function FeeStructureManagement() {
  const dispatch = useDispatch();
  const { feeStructures, loading } = useSelector((state) => state.feeStructures);
 
   const classes = useSelector((state) => state.classes.classes || []);

     const { courses } = useSelector(
       (state) => state.courses
     );

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editingData, setEditingData] = React.useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = React.useState(null);

  useEffect(() => {
    dispatch(fetchFeeStructures());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchClassesV1({ pageNumber: 1, pageSize: 100 }));
  }, [dispatch]);


    useEffect(() => {
      dispatch(fetchCourses({ pageNumber:1, pageSize:100 }));
    }, [dispatch]);
  
 
 
  const handleAddNew = () => {
    setEditingData(null);
    setIsModalOpen(true);
  };

  const handleSave = (data) => {
    if (editingData) {
      dispatch(updateFeeStructure({ id: editingData.id, feeStructure: data }));
    } else {
      dispatch(addFeeStructure(data));
    }
    setIsModalOpen(false);
    setEditingData(null);
  };

  const handleEdit = (index) => {
    setEditingData({ ...feeStructures[index], index });
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteFeeStructure(id));
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="flex w-full items-center justify-between mt-4 space-x-2">
        <input
          type="text"
          placeholder="Search..."
          className="border border-gray-300 rounded p-2 w-3/4"
        />
        <button
          onClick={handleAddNew}
          className="px-4 py-2 bg-[#241763] w-1/4 text-white rounded"
        >
          + New Fee Structure
        </button>
      </div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr className="bg-gray-100 border-b">
              <th>Name</th>
              <th>Description</th>
              <th>Fee Code</th>
              <th>Tuition Fee</th>
              <th>Lab Fee</th>
              <th>Academic Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {feeStructures.length > 0 ? (
              feeStructures.map((fee, index) => (
                <React.Fragment key={fee.id}>
                  <tr className="hover:bg-gray-200">
                    <td>{fee.name}</td>
                    <td>{fee.description}</td>
                    <td>{fee.feeCode}</td>
                    <td>{fee.tutionFee}</td>
                    <td>{fee.labFee}</td>
                    <td>{fee.createdDateTime}</td>
                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(index)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(fee.id)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                           <tr>
                           <td colSpan="4">
                             <div className="bg-gray-100 p-4">
                               <p><strong>Monthly/One Time:</strong> {fee.mode}</p>
                               <p><strong>Billing Cycle:</strong> {fee.billingCycle}</p>
                               <p><strong>Lab Fee:</strong> {fee.labFee}</p>
                               <p><strong>Library Fee:</strong> {fee.libraryFee}</p>
                               <p><strong>Sports Fee:</strong> {fee.sportsFee}</p>
                               <p><strong>Software Charges:</strong> {fee.lmsFee}</p>
                               <p><strong>Admission Fee:</strong> {fee.admissionFee}</p>
                               <p><strong>Security Deposit:</strong> {fee.securityFee}</p>
                               <p><strong>Optional Fee:</strong> {fee.optionalFee}</p>
                               <p><strong>Meal Plan:</strong> {fee.mealFee}</p>
                               <p><strong>Fines:</strong> {fee.fines}</p>
                               <p><strong>Total Fee:</strong> {fee.totalFee}</p>
                               <p><strong>Late Payment Fine:</strong> {fee.latePaymentFine}</p>
                               <p><strong>School Fines:</strong> {fee.schoolFines}</p>
                               <p><strong>Taxes:</strong> {fee.taxesFee}</p>
                               <p><strong>Total Payable:</strong> {fee.totalPaybale}</p>
                               <h3 className="font-bold mt-2">Discounts:</h3>
                               {fee.discounts &&
                                 fee.discounts.map((discount, i) => (
                                   <p key={i}>
                                     {discount.name}: {discount.discountPercentage}%
                                   </p>
                                 ))}
                               <h3 className="font-bold mt-2">Scholarships:</h3>
                               {fee.scholarship &&
                                 fee.scholarship.map((scholarship, i) => (
                                   <p key={i}>
                                     {scholarship.scholarshipName}: {scholarship.scholarshipPercentage}%
                                   </p>
                                 ))}
                             </div>
                           </td>
                         </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Fee Structures Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <FeeStructureManagementModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
          editingData={editingData}
          classes={classes}
          courses={courses}
        />
      )}
    </div>
  );
}

export default FeeStructureManagement;
