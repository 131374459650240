import React, { useState } from "react";
import LiveAttendance from "../../Components/tabs/hr/LiveAttendance";
import OverAllAttendance from "../../Components/tabs/hr/OverAllAttendance";
import EmployeesOverTime from "../../Components/tabs/hr/EmployeesOverTime";
import TimeAdjustment from "../../Components/tabs/hr/TimeAdjustment";
import GracePeriod from "../../Components/tabs/hr/GracePeriod";
import Shifts from "../../Components/tabs/hr/Shifts";

function Attendance() {
  // Tabs with emojis
  const tabs = [
    { name: "Live Attendance", emoji: "📋" },
    { name: "Overall Attendance", emoji: "👨‍💻" },
    { name: "Employees OverTime", emoji: "⏰" },
    { name: "Time Adjustment", emoji: "🕟" },
    { name: "Grace Period", emoji: "🕒" },
    { name: "Shifts", emoji: "🔄" },
  ];

  const [activeTab, setActiveTab] = useState("Live Attendance");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Tabs - Responsive Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 border-2 border-gray-100 shadow-xl mb-4 pb-3 pt-2 rounded-b-xl overflow-x-auto">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => handleTabClick(tab.name)}
            className={`px-4 py-2 flex items-center text-sm justify-center space-x-2 ${
              activeTab === tab.name
                ? "text-white bg-[#241763]"
                : "bg-gray-200 text-gray-700"
            } rounded-md text-center`}
          >
            <span className="text-lg">{tab.emoji}</span>
            <span>{tab.name}</span>
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="overflow-x-auto">
        {activeTab === "Live Attendance" && <LiveAttendance />}
        {activeTab === "Overall Attendance" && <OverAllAttendance />}
        {activeTab === "Employees OverTime" && <EmployeesOverTime />}
        {activeTab === "Time Adjustment" && <TimeAdjustment />}
        {activeTab === "Grace Period" && <GracePeriod />}
        {activeTab === "Shifts" && <Shifts />}
      </div>
    </div>
  );
}

export default Attendance;
