import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Pencil, Trash2 } from "lucide-react";

const HrDepartment = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [departments, setDepartments] = useState([
    { id: 1, name: "Audit Controls", company: "Wiser Bee", status: "Disabled" },
    { id: 2, name: "Software House", company: "Wiser Bee", status: "Active" },
    { id: 3, name: "Procurement", company: "Wiser Bee", status: "Active" },
    { id: 4, name: "IT Support", company: "Wiser Bee", status: "Disabled" },
  ]);
  const [companies, setCompanies] = useState(["Wiser Bee", "XYZ Corp"]);
  const [newCompany, setNewCompany] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const onSubmit = (data) => {
    if (isEditing) {
      setDepartments((prev) =>
        prev.map((dept) =>
          dept.id === editId
            ? { ...dept, name: data.departmentName, company: data.companyName }
            : dept
        )
      );
      setIsEditing(false);
      setEditId(null);
    } else {
      setDepartments((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          name: data.departmentName,
          company: data.companyName,
          status: "Disabled",
        },
      ]);
    }
    reset();
  };

  const handleDelete = (id) => {
    setDepartments((prev) => prev.filter((dept) => dept.id !== id));
  };

  const handleEdit = (id) => {
    const dept = departments.find((dept) => dept.id === id);
    if (dept) {
      setValue("departmentName", dept.name);
      setValue("companyName", dept.company);
      setIsEditing(true);
      setEditId(id);
    }
  };

  const handleActivate = (id) => {
    setDepartments((prev) =>
      prev.map((dept) =>
        dept.id === id
          ? { ...dept, status: dept.status === "Active" ? "Disabled" : "Active" }
          : dept
      )
    );
  };

  const handleAddCompany = () => {
    if (newCompany && !companies.includes(newCompany)) {
      setCompanies([...companies, newCompany]);
      setNewCompany("");
    }
  };

  return (
    <div className="p-6">
      <div className="bg-white shadow-md rounded-lg p-4 mb-6">
        <h2 className="text-lg font-semibold mb-4">
          {isEditing ? "Edit Department" : "Add New Department"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 text-sm">
        <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
  {/* Department Name Field */}
  <div className="flex-1">
    <label className="block text-sm font-medium mb-1">Department Name *</label>
    <input
      type="text"
      {...register("departmentName", { required: true })}
      className="w-full border border-gray-300 rounded-md p-2"
      placeholder="Must be Unique"
    />
  </div>
  
  {/* Add New Company Field */}
  <div className="flex-1">
    <label className="block text-sm font-medium mb-1">Add Company</label>
    <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
      <input
        type="text"
        value={newCompany}
        onChange={(e) => setNewCompany(e.target.value)}
        placeholder="Enter new company"
        className="border border-gray-300 rounded-md p-2 w-full"
      />
      <button
        type="button"
        onClick={handleAddCompany}
        className="bg-blue-500 text-white px-4 py-2 rounded-md w-full sm:w-auto"
      >
        +
      </button>
    </div>
  </div>
</div>

{/* Company Dropdown Field */}
<div className="flex-1 mt-4">
  <label className="block text-sm font-medium mb-1">Select Company Name *</label>
  <select
    {...register("companyName", { required: true })}
    className="w-full border border-gray-300 rounded-md p-2"
  >
    <option value="">Select a Company</option>
    {companies.map((company, index) => (
      <option key={index} value={company}>
        {company}
      </option>
    ))}
  </select>
</div>

          <div className="flex space-x-4">
            <button type="submit" className="bg-[#241763] text-white px-4 py-2 rounded-md">
              {isEditing ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              onClick={() => {
                reset();
                setIsEditing(false);
                setEditId(null);
              }}
              className="bg-[#241763]/10 text-black px-4 py-2 rounded-md"
            >
              Reset
            </button>
          </div>
        </form>
      </div>

      <div className="bg-white shadow-md rounded-lg p-4 overflow-x-auto text-sm">
        <h2 className="text-lg font-semibold mb-4">Departments</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="py-2">DEPARTMENT NAME</th>
              <th>COMPANY NAME</th>
              <th>DEPT. STATUS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {departments.map((dept) => (
              <tr key={dept.id} className="hover:bg-gray-50">
                <td>{dept.name}</td>
                <td>{dept.company}</td>
                <td>
                  <span
                    className={`px-2 py-1 rounded-md text-sm ${
                      dept.status === "Active"
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    }`}
                  >
                    {dept.status}
                  </span>
                </td>
                <td className="flex space-x-2 py-3">
                  <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group" onClick={() => handleEdit(dept.id)}>
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group" onClick={() => handleDelete(dept.id)}>
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button onClick={() => handleActivate(dept.id)} className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600">
                    {dept.status === "Active" ? "Disable" : "Activate"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HrDepartment;
