import React from "react";
import { useForm } from "react-hook-form";

const LeaveModal = ({ isOpen, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (data) => {
    onSubmit(data);
    reset(); // Clear the form after submission
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-[90%] max-w-md">
        <h2 className="text-xl font-bold mb-4">Add Leave Type</h2>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mb-4">
            <label htmlFor="leaveType" className="block text-sm font-medium mb-1">
              Leave Type
            </label>
            <input
              id="leaveType"
              type="text"
              {...register("leaveType", { required: "Leave type is required" })}
              className="w-full px-3 py-2 border rounded"
            />
            {errors.leaveType && (
              <p className="text-red-500 text-sm mt-1">{errors.leaveType.message}</p>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-black rounded mr-2"
            >
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-[#241763] text-white rounded">
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaveModal;
