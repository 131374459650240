import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

const SurveyModal = ({
  isOpen,
  onClose,
  onSave,
  editingData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // const { fields: questionFields, append: addQuestion, remove: removeQuestion } = useFieldArray({
  //   control,
  //   name: "questions",
  // });

 
 
  useEffect(() => {
    if (editingData) {
      reset(editingData);
    }
  }, [editingData, reset]);


  const onSubmit = (data) => {
    console.log("Complete Data:", data);
    onSave(data);
    reset();
    onClose();
  };



  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-2/4 max-h-[80vh] overflow-y-auto rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">
          Add Surveys
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block mb-1 font-medium">
                Survey Name
              </label>
              <input
                {...register("surveyName", {
                  required: "This field is required",
                })}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter survey name"
              />
            </div>
            <div className="">
              <label className="block mb-1 font-medium">Objective</label>
              <input
                {...register("objective")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter objectives"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Select audience</label>
              <select
                className="w-full border border-gray-300 rounded p-2"
                {...register("audience")}
              >
                <option>Parent</option>
                <option>Teacher</option>
                <option>Learner</option>
              </select>
            </div>
            <div>
              <label className="block mb-1 font-medium">Select Campus</label>
              <select
                className="w-full border border-gray-300 rounded p-2"
                {...register("campus")}
              >
                <option>A</option>
                <option>B</option>
                <option>C</option>
              </select>
            </div>
            <div>
              <label className="block mb-1 font-medium">Select Class</label>
              <select
                className="w-full border border-gray-300 rounded p-2"
                {...register("cls")}
              >
                <option>X</option>
                <option>Y</option>
                <option>Z</option>
              </select>
            </div>
          </div>
          {/* <div className="mt-6">
            <h3 className="text-lg font-medium mb-4">Questions</h3>
            {questionFields?.map((item, index) => (
              <div key={item.id} className="mb-4">
                <label className="block mb-1 font-medium">Question {index + 1}</label>
                <input
                  {...register(`questions.${index}.question`)}
                  className="w-full border border-gray-300 p-2 rounded"
                  placeholder="Enter question"
                />
                <label className="block mt-2 mb-1 font-medium">Answer</label>
                <input
                  {...register(`questions.${index}.answer`)}
                  className="w-full border border-gray-300 p-2 rounded"
                  placeholder="Enter answer"
                />
                <button
                  type="button"
                  onClick={() => removeQuestion(index)}
                  className="mt-2 text-red-500 text-sm"
                >
                  Remove
                </button>
              </div>
            ))}
            {questionFields?.length < 5 && (
              <button
                type="button"
                onClick={() => addQuestion({ question: "", answer: "" })}
                className="text-blue-500 text-sm"
              >
                Add Question
              </button>
            )}
          </div> */}

  
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white px-6 py-2 rounded"
            >
              Close
            </button>
            <button
              type="submit"
              className="bg-[#241763] text-white px-6 py-2 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SurveyModal;
