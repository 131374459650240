import React, { useState, useEffect } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp, Eye, CreditCard } from "lucide-react";
import FeeBillsModal from "../../modals/admin/FeeBillsModal";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnpaidOrOverdueBills } from "../../redux/feeStructureSlice";
import { format } from "date-fns";

function FeeBills({ feeBills, setFeeBills }) {
  const dispatch = useDispatch();
  const { unpaidOrOverdueBills = [], loading } = useSelector((state) => state.feeStructures);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [filters, setFilters] = useState({
    studentName: "",
    status: "",
  });

  useEffect(() => {
    dispatch(fetchUnpaidOrOverdueBills());
  }, [dispatch]);

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(new Date(dateString), "yyyy-MM-dd");
    } catch (error) {
      return dateString;
    }
  };

  const openPaymentModal = (bill) => {
    setPaymentData({
      studentId: bill.studentId,
      studentName: bill.fullName,
      pendingAmount: bill.pendingAmount,
      billId: bill.id || "BILL-" + Math.floor(Math.random() * 1000),
      dueDate: bill.dueDate
    });
    setIsPaymentModalOpen(true);
  };

  // Ensure we have an array to filter, even if API hasn't returned yet
  const filteredFeeBills = Array.isArray(unpaidOrOverdueBills) 
    ? unpaidOrOverdueBills.filter(
        (bill) =>
          (filters.studentName === "" ||
            (bill.fullName && bill.fullName.toLowerCase().includes(filters.studentName.toLowerCase()))) &&
          (filters.status === "" || (bill.status && bill.status.includes(filters.status)))
      )
    : [];

  return (
    <div>
      <div className="mb-4 flex gap-4 w-full">
        <div className="flex w-4/6">
          <input
            type="text"
            placeholder="Search Student Name..."
            className="border border-gray-300 rounded p-2 w-full"
            value={filters.studentName}
            onChange={(e) => handleFilterChange("studentName", e.target.value)}
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["Pending", "Overdue"]}
            value={filters.status}
            onChange={(value) => handleFilterChange("status", value)}
            placeholder="Select Status"
            className="w-full"
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["1001", "1002"]}
            value={filters.status}
            onChange={(value) => handleFilterChange("status", value)}
            placeholder="Select FeeCode"
            className="w-full"
          />
        </div>
      </div>

      <div className="overflow-x-auto mt-6">
        {loading ? (
          <div className="text-center p-4">Loading...</div>
        ) : (
          <table className="table-auto w-full mt-6">
            <thead className="py-4 border-b font-bold">
              <tr className="bg-gray-100 border-b">
                <th>Student Name</th>
                <th>Billing Cycle Start</th>
                <th>Billing Cycle End</th>
                <th>Due Date</th>
                <th>Total Fee</th>
                <th>Discount</th>
                <th>Final Amount</th>
                <th>Pending Amount</th>
                <th>Status</th>
                <th>Invoice</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {filteredFeeBills.length > 0 ? (
                filteredFeeBills.map((bill, index) => (
                  <React.Fragment key={index}>
                    <tr className="hover:bg-gray-200">
                      <td>{bill.fullName}</td>
                      <td>{formatDate(bill.billingCycleStartDate)}</td>
                      <td>{formatDate(bill.billingCycleEndDate)}</td>
                      <td>{formatDate(bill.dueDate)}</td>
                      <td>${bill.totalFee?.toFixed(2)}</td>
                      <td>${bill.discountAmount?.toFixed(2)}</td>
                      <td>${bill.finalAmount?.toFixed(2)}</td>
                      <td>${bill.pendingAmount?.toFixed(2)}</td>
                      <td>{bill.status}</td>
                      <td className="text-center">
                        {bill.picture && (
                          <span
                            onClick={() => 
                              window.open(
                                bill.picture,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                            className="text-[#241763] cursor-pointer"
                          >
                            <Eye className="w-5 h-5 ml-2" />
                          </span>
                        )}
                      </td>

                      <td className="space-x-2 flex items-center py-2">
                        <button 
                          onClick={() => openPaymentModal(bill)}
                          className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                          title="Receive Payment"
                        >
                          <CreditCard className="text-[#241763] w-4 h-4 group-hover:text-white" />
                        </button>
                        {/* <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                          <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                        </button>
                        <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                          <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                        </button> */}
                        <div
                          onClick={() => toggleView(index)}
                          className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                        >
                          {expandedRowIndex === index ? (
                            <ChevronUp
                              size={16}
                              className="group-hover:text-white"
                            />
                          ) : (
                            <ChevronDown
                              size={16}
                              className="group-hover:text-white"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    {expandedRowIndex === index && (
                      <tr>
                        <td colSpan="12">
                          <div className="bg-gray-100 p-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <p><strong>Student ID:</strong> {bill.studentId}</p>
                                <p><strong>Student Name:</strong> {bill.fullName}</p>
                                <p><strong>Status:</strong> {bill.status}</p>
                                <p><strong>Due Date:</strong> {formatDate(bill.dueDate)}</p>
                              </div>
                              <div>
                                <p><strong>Total Fee:</strong> ${bill.totalFee?.toFixed(2)}</p>
                                <p><strong>Discount:</strong> ${bill.discountAmount?.toFixed(2)}</p>
                                <p><strong>Final Amount:</strong> ${bill.finalAmount?.toFixed(2)}</p>
                                <p><strong>Pending Amount:</strong> ${bill.pendingAmount?.toFixed(2)}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center p-4">
                    {loading ? "Loading..." : "No Fee Bills Found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {isModalOpen && (
        <FeeBillsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={editingData}
        />
      )}

      {isPaymentModalOpen && (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          data={paymentData}
        />
      )}
    </div>
  );
}

// Dummy Payment Modal Component
function PaymentModal({ isOpen, onClose, data }) {
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [amount, setAmount] = useState(data?.pendingAmount || 0);
  const [transactionId, setTransactionId] = useState("");
  const [notes, setNotes] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Payment submitted:", {
      studentId: data.studentId,
      studentName: data.studentName,
      billId: data.billId,
      amount,
      paymentMethod,
      transactionId,
      notes,
      paymentDate: new Date().toISOString(),
    });
    
    // Here you would typically dispatch an action to submit the payment
    alert("Payment received successfully!");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Receive Payment</h2>
        
        <div className="mb-4">
          <p><strong>Student:</strong> {data.studentName}</p>
          <p><strong>Bill ID:</strong> {data.billId}</p>
          <p><strong>Pending Amount:</strong> ${data.pendingAmount?.toFixed(2)}</p>
          <p><strong>Due Date:</strong> {data.dueDate ? new Date(data.dueDate).toLocaleDateString() : 'N/A'}</p>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="block mb-1">Payment Method</label>
            <select 
              className="w-full p-2 border rounded"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="cash">Cash</option>
              <option value="creditCard">Credit Card</option>
              <option value="bankTransfer">Bank Transfer</option>
              <option value="check">Check</option>
            </select>
          </div>
          
          <div className="mb-3">
            <label className="block mb-1">Amount</label>
            <input
              type="number"
              step="0.01"
              className="w-full p-2 border rounded"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              required
            />
          </div>
          
          <div className="mb-3">
            <label className="block mb-1">Transaction ID (Optional)</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
          </div>
          
          <div className="mb-4">
            <label className="block mb-1">Notes (Optional)</label>
            <textarea
              className="w-full p-2 border rounded"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows="3"
            ></textarea>
          </div>
          
          <div className="flex justify-end space-x-2">
            <button 
              type="button" 
              onClick={onClose}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="px-4 py-2 bg-[#241763] text-white rounded hover:bg-[#241763]/80"
            >
              Process Payment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeeBills;