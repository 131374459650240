import React, { useState, useEffect } from "react";
import FlexBtn from "../../Components/Common/FlexBtn";
import { importSvg, exportSvg } from "../../Constant/svgs";
import { headingsGrades } from "../../Constant/grades";
import { Modal as AntdModal, notification } from "antd";
import GradeTableMcQs from "../../Components/Teacher/GradeTableMcQs";
import GradeTableQuestion from "../../Components/Teacher/GradeTableQuestion";
import GradeTableAssignment from "../../Components/Teacher/GradeTableAssignment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRequest, updateRequest } from "../../services";
import { Spin } from 'antd';
import * as XLSX from "xlsx";

const GradeResult = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [tab, setTab] = useState("McQs");
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [publishStatus, setPublishStatus] = useState(false);
  const [examData, setExamData] = useState([]);
  const [examPublish, setExamPublish] = useState();

  // Filter data based on search query, class filter, and exam filter
  // const filteredData = listResult.filter(item => {
  //   const matchesSearchQuery = item.studentName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
  //     item.rollno?.toLowerCase()?.includes(searchQuery?.toLowerCase());
  //   return matchesSearchQuery;
  // });

  // Fetch data and set examData
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(`/api/Grades/subject-Grades?subjectId=${state.subjectId}`);
      const data = response.data.data;
      setExamData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("examPublish", examPublish)

  const filteredExamsId = examData.filter(exam => exam.examType === tab);

  const confirPublish = () => {
    console.log("examPublish", examPublish)
    AntdModal.confirm({
      title: `${examPublish.Exam.title}`,
      content: `Are you sure you want to publish this Exam?`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await publisResult(examPublish.Exam.id);
      },
      onCancel: () => {
        notification.info({
          message: "Publish Canceled",
          description: "You have canceled the publishing of the Exam.",
        });
      },
    });
  };

  const publisResult = async (id) => {
    try {
      const response = await updateRequest(`/api/Grades/PublishResult?examId=${id}`);
      if (response.statusText === 'OK') {
        fetchData();
        notification.success({
          message: "Success",
          description: response.data,
        });
      } else {
        throw new Error("Failed to publish the exam.");
      }
    } catch (error) {
      console.error("Error publishing Exam:", error.message);
      notification.error({
        message: "Error",
        description: "An error occurred while publishing the Exam. Please try again.",
      });
    }
  };
  const exportFile = () => {

    // Prepare data for export
    const headings = [["Name", "Marks", "Obtained Marks", "Percentage"]];
    let tableData = [];

    if (examPublish.Exam.examType === "Assignment") {
      // Handle Assignment type data
      tableData = examPublish.Question.submissions.map((submission) => {
        const studentName = submission.student.studentName;
        const totalMarks = examPublish.Question.marks;
        const obtainedMarks = submission.marksObtained;
        const percentage = ((obtainedMarks / totalMarks) * 100).toFixed(0);
        return [studentName, totalMarks, obtainedMarks, `${percentage}%`];
      });
    } 
    else if (examPublish.Exam.examType === "Question") {
      // Handle Assignment type data
      tableData = examPublish.Question.map((data) => {
        const studentName = data.student.studentName;
        const totalMarks = data.questions.reduce((sum, question) => sum + question.marks, 0);
        const obtainedMarks = data.questions.reduce((sum, question) => sum + question.marksObtained, 0);
        const percentage = ((obtainedMarks / totalMarks) * 100).toFixed(0);
        return [studentName, totalMarks, obtainedMarks, `${percentage}%`];
      });
    } 
    else {
      // Handle other exam types (existing logic)
      tableData = examPublish.Question.map((data) => {
        const studentName = data.student.name;
        const totalMarks = data.questions.reduce((sum, question) => sum + question.totalMarks, 0);
        const obtainedMarks = data.questions.reduce((sum, question) => sum + question.marks, 0);
        const percentage = ((obtainedMarks / totalMarks) * 100).toFixed(0);
        return [studentName, totalMarks, obtainedMarks, `${percentage}%`];
      });
    }
    // Calculate totals
    const totalStudents = tableData.length;
    const passStudents = tableData.filter(row => {
      const percentage = parseInt(row[3]); // Remove % and convert to number
      return percentage >= 40;
    }).length;
    const failStudents = totalStudents - passStudents;
    const overallTotalMarks = tableData.reduce((sum, row) => sum + row[1], 0);
    const overallObtainedMarks = tableData.reduce((sum, row) => sum + row[2], 0);
    const overallPercentage = ((overallObtainedMarks / overallTotalMarks) * 100).toFixed(0);

    // Add summary rows
    const summaryData = [
      ["", "", "", ""],  // Empty row for spacing
      ["Total Students", totalStudents, "", ""],
      ["Pass Students", passStudents, "", ""],
      ["Fail Students", failStudents, "", ""],
      ["Overall Result", `${overallPercentage}%`, "", ""]
    ];

    const worksheetData = headings.concat(tableData, summaryData);
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    
    // Set column widths
    worksheet["!cols"] = [
      { wch: 20 }, 
      { wch: 10 }, 
      { wch: 15 },
      { wch: 12 },  
    ];

    // Apply bold formatting to summary rows
    const lastRows = worksheetData.length;
    ["A", "B", "C", "D"].forEach(col => {
      worksheet[`${col}${lastRows}`].s = { font: { bold: true } };
      worksheet[`${col}${lastRows-1}`].s = { font: { bold: true } };
      worksheet[`${col}${lastRows-2}`].s = { font: { bold: true } };
      worksheet[`${col}${lastRows-3}`].s = { font: { bold: true } };
    });
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exam Data");
    XLSX.writeFile(workbook, `${examPublish.Exam.title}-${examPublish.Exam.examType}.xlsx`);
    

  }
  return (
    <div className="container-fluid px-2 py-2 shadow-md bg-white rounded">
      <div className={`d-flex justify-content-between ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
        <div className="col-md-6">
          <input
            type="text"
            placeholder={t('searchHear')}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className={`w-full p-2 border rounded ${isArabic === "sa" ? "text-end" : "text-start"}`}
          />
        </div>
        <div className="d-flex justiify-content-end gap-3">
          {/* <FlexBtn
            icon={importSvg}
            label="Import"
            color="#463C77"
            bgColor="#EDEBF1"
          /> */}
          <span onClick={() => exportFile()}>
            <FlexBtn
              icon={importSvg}
              label={t("Export")}
              color="#463C77"
              bgColor="#EDEBF1"
            />
          </span>
          {publishStatus ?
            <span >
              <FlexBtn
                icon={exportSvg}
                label={t("Already Publish")}
                color="white"
                bgColor="#241763"
              />
            </span>
            :
            <span onClick={() => confirPublish()}>
              <FlexBtn
                icon={exportSvg}
                label={t("Publish Result")}
                color="white"
                bgColor="#241763"
              />
            </span>
          }
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="d-flex justify-content-between p-3">
          <h3 className="heading-class fw-bold mt-2"><span className="text-secondary fw-light mr-3">Class:</span>{` ${state.className}`}</h3>
          <h3 className="heading-class fw-bold mt-2"><span className="text-secondary fw-light mr-3">Section:</span>{`${state.sectionName}`}</h3>
          <h3 className="heading-class fw-bold mt-2"><span className="text-secondary fw-light mr-3">Subject:</span>{`${state.subjectName}`}</h3>
        </div>
        <div className="row mt-3">
          <div className="d-flex align-items-center gap-3 mb-3">
            <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "McQs" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("McQs")}>McQs</button>
            <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "Question" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("Question")}>Question</button>
            <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "Assignment" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("Assignment")}>Assignment</button>
          </div>
        </div>

      </Spin>
      <div className="row">
        <div className="col-md-12">
          {tab === "McQs" ?
            <GradeTableMcQs headingsGrades={headingsGrades} filteredExamsId={filteredExamsId} setExamPublish={setExamPublish} setPublishStatus={setPublishStatus} />
            :
            tab === "Question" ?
              <GradeTableQuestion headingsGrades={headingsGrades} filteredExamsId={filteredExamsId} setExamPublish={setExamPublish} setPublishStatus={setPublishStatus} />
              :
              <GradeTableAssignment headingsGrades={headingsGrades} filteredExamsId={filteredExamsId} setExamPublish={setExamPublish} setPublishStatus={setPublishStatus} />
          }
        </div>
      </div>
    </div>
  );
};

export default GradeResult;
