import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { calanderSvg, clockSvg } from "../../Constant/svgs";
import { formatTime } from "../../Constant/date";
import { getRequest } from "../../services";
import { Spin } from "antd";

const MyClasses = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const [loading, setLoading] = useState(false);
  const [assignedClasses, setAssignedClasses] = useState([]);

  const handleCardClick = (lessonId) => {
    navigate(`/lesson/${lessonId.subjectId}`, { state: { ...lessonId } });
  };

  const fetchAssignedClasses = async () => {
    setLoading(true); // Start loading
    try {
      const response = await getRequest(
        '/api/ClassAssignment/MyClasses'
      );
      const data = response.data.data;
      const restructuredData = [];

      data.forEach((classItem) => {
        classItem.subjects.forEach((subject) => {
          restructuredData.push({
            classId: classItem.classId,
            sectionId: classItem.sectionId,
            sectionName: classItem.sectionName,
            className: `${classItem.className}`,
            subjectName: subject.subjectName,
            subjectId: subject.id,
            startTime: subject.startTime,
            endTime: subject.endTime,
            startedSession: classItem.startedSession,
            endSession: classItem.endSession,
            schedules: subject.schedules
          });
        });
      });
      setAssignedClasses(restructuredData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchAssignedClasses();
  }, []);
  
  return (
    <div className="container-fluid">
      <Spin spinning={loading}>
        <div
          className={`row text-capitalize py-2 ${isArabic === "sa" ? "flex-row-reverse" : ""
            }`}
        >
          {loading ? null : assignedClasses.length === 0 ? (
            <div className="text-center w-100 py-5">
              <h4>{t("No Data Available")}</h4>
            </div>
          ) : (
            assignedClasses.map((tile, index) => (
              <div
                className="col-md-6 col-lg-4 cursor-pointer py-2"
                key={index}
                onClick={() => handleCardClick(tile)}
              >
                <div className="d-flex flex-column align-items-between bg-white examDataWrapper px-2 py-3 borderRadius_15">
                  <div
                    className={`d-flex justify-content-between px-2 ${isArabic === "sa" ? "flex-row-reverse" : ""
                      }`}
                  >
                    <h4
                      className={`fw-bold p-0 m-0 fs-6 cursor-pointer ${isArabic === "sa" ? "text-end" : "text-start"
                        }`}
                    >
                      {tile.subjectName}
                    </h4>
                  </div>
                  <div
                    className={`d-flex justify-content-start gap-3 examChipsWrapper mt-3 px-2 ${isArabic === "sa" ? "flex-row-reverse" : ""
                      }`}
                  >
                    <div className={`examChip ${isArabic === "sa" ? "text-end" : "text-start"}`}>
                      {tile.className}
                    </div>
                    <div className={`examChip ${isArabic === "sa" ? "text-end" : "text-start"}`}>
                      {tile.sectionName}
                    </div>
                    <div className={`examChip ${isArabic === "sa" ? "text-end" : "text-start"}`}>
                    {tile.subjectName}
                    </div>
                  </div>
                  <div className={`d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2 ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                    <span className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                      {calanderSvg}{" "}
                      <span className="p-0 m-0">
                        {tile.schedules.map((item, index) => {
                          const dayAbbreviation = item.day.slice(0, 2).toUpperCase();
                          return index < tile.schedules.length - 1 ? `${dayAbbreviation}, ` : dayAbbreviation;
                        })}
                      </span>
                    </span>
                    <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                      {clockSvg}
                       <p className="p-0 m-0 ">{formatTime(tile.schedules[0].startTime, tile.schedules[0].endTime)} {t("Mins")}</p>
                    </div>
                  </div>
                  <div
                    className={`d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2 ${isArabic === "sa" ? "flex-row-reverse" : ""
                      }`}
                  >
                    <span className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                      {calanderSvg}{" "}
                      <span className="p-0 m-0">
                        {`${tile.startedSession?.split("T")[0]} - ${tile.endSession?.split("T")[0]}`}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </Spin>
    </div>
  );
};

export default MyClasses;
