import React, { useState, useEffect } from "react";
import { Input, Spin } from "antd";
import { ChevronUp, ChevronDown, Download } from "lucide-react";
import { getRequest, postRequest } from "../../services";
import { message } from 'antd';
import NameAvatar from "../Common/Avatar";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const GradeTableAssignment = (props) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const { headingsGrades, filteredExamsId, setExamPublish, setPublishStatus } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [myQuestion, setMyQuestion] = useState([]);
  const [mytotalMarks, setMytotalMarks] = useState();
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(
        `/api/Grades/assignment-submission-details?examId=${filteredExamsId[currentPage]?.id}`
      );
      const status = await getRequest(`/api/Grades/ResultStatus?examId=${filteredExamsId[currentPage].id}`);
      setPublishStatus(status.data)
      setExamPublish({
        Exam: filteredExamsId[currentPage],
        Question: response.data[0]
      });
      setMytotalMarks(response.data[0]);
      setMyQuestion(response.data[0]?.submissions || []);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, filteredExamsId[currentPage]?.id]);

  const handlePagination = (direction) => {
    setCurrentPage((prev) =>
      direction === "next" ? prev + 1 : prev - 1
    );
  };

  const toggleRow = (index) => {
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(myQuestion.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleInputChange = (index, value) => {
    const updatedQuestions = [...myQuestion];
    updatedQuestions[index].marks = value;
    setMyQuestion(updatedQuestions);
  };

  const handleInputReview = (value) => {
    setReview(value);
  };

  const handleSaveMarks = async (item) => {

    const payload = {
      userId: item.student.studentId,
      subjectId: filteredExamsId[0]?.subjectDetails?.subjectId || "",
      examId: filteredExamsId[currentPage].id || "",
      remarks: review || "",
      marks: item.marks || "",
    };

    try {
      const response = await postRequest(`/api/Grades/evaluate-assignment`, payload);
      if (response.status === 200 && response.statusText) {
        message.success(response.data.message || "Update successfully");
        fetchData();
      } else {
        message.error("Failed to save lesson");
      }
    } catch (error) {
      console.error("Error:", error.message);
      message.error("An error occurred while saving the lesson");

    }
  };


  return (
    <div className="container-table100">
      <div className="wrap-table100">
        <div className="table100 ver5 m-b-110">
          <div className="table100-head">
            <table>
              <thead>
                <tr className="row100 head">
                  <th className="cell100 column0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  {headingsGrades.map((head, index) => (
                    <th className={`cell100 column${index + 1}`} key={index}>
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <Spin spinning={loading}>
            <div className="table100-body">
              <table>
                <tbody>
                  {!loading && (myQuestion?.length === 0 || myQuestion === null) ? (
                    <tr className="row100 body">
                      <td colSpan={8} className="text-center">
                        No data available
                      </td>
                    </tr>
                  ) : (
                    myQuestion.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className="row100 body">
                          <td className="cell100 column0">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={selectedRows.includes(index)}
                              onChange={() => handleRowSelect(index)}
                            />
                          </td>
                          <td className="cell100 column1">
                            <div className="d-flex align-items-center attendanceUser gap-2">
                              {item.student.profilePicture ? (
                                <img src={item.student.profilePicture} alt="user" />
                              ) : (
                                <NameAvatar name={item.student.name} rounded />
                              )}
                              {item.student.name}
                            </div>
                          </td>
                          <td className="cell100 column2">PP</td>
                          <td className="cell100 column3">-</td>
                          <td className="cell100 column4">{`${Math.round(
                            (item.marksObtained / mytotalMarks.marks) * 100
                          )}%`}
                          </td>

                          <td className="cell100 column5">
                            {item.submittedAt}
                          </td>
                          <td className="cell100 column6">
                            {!item.student.marksAlloted.alloted ?
                              '-'
                              :
                              item.remarks}
                          </td>
                          <td className="cell100 column7">
                            <div
                              onClick={() => toggleRow(index)}
                              className="cursor-pointer d-flex justify-content-end p-2"
                            >
                              {expandedRowIndex === index ? (
                                <ChevronUp size={16} />
                              ) : (
                                <ChevronDown size={16} />
                              )}
                            </div>
                          </td>
                        </tr>
                        {expandedRowIndex === index && (
                          <tr>
                            <td colSpan={8} style={{ background: "#fff", padding: "5px" }}>
                              <div className="bg-gray-50 rounded p-6">
                                <p>
                                  <strong>File:</strong>{" "}
                                  <a
                                    href={item.fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-primary-600 "
                                  >
                                    Download <Download size={16} className="inline-block ml-1" />
                                  </a>
                                </p>
                                <p>
                                  <strong>Submitted At:</strong> {item.submittedAt}
                                </p>
                                <p>
                                  <strong>Total Marks: {mytotalMarks.marks}</strong>
                                </p>
                                {!item.student.marksAlloted ?
                                  <>
                                    <p className="w-25 mt-3">
                                      <Input
                                        placeholder="Enter marks"
                                        value={item.marks || ""}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                      />
                                    </p>
                                    <p className="w-25">
                                      <Input
                                        placeholder="Review"
                                        value={review}
                                        onChange={(e) => handleInputReview(e.target.value)}
                                        className="mt-2"
                                      />
                                    </p>
                                    <button
                                      className="btn btn-primary mt-2"
                                      onClick={() => handleSaveMarks(item)}
                                    >
                                      Save Marks
                                    </button>
                                  </>
                                  :
                                  <>
                                    <p>  <strong>Obtained Marks: {item.marksObtained}</strong> </p>
                                  </>
                                }
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
              {filteredExamsId.length > 0 && (
                <div className="flex justify-center mt-4 pb-4 gap-3 paginationStyle">
                  <button
                    onClick={() => handlePagination("previous")}
                    disabled={currentPage < 1}
                    className={`px-3 py-1 rounded ${currentPage < 1 ? "bg-gray-300 cursor-not-allowed"
                      : "bg-[#241763] text-white hover:bg-[#241763]"
                      }`}
                  >
                    <LeftOutlined />
                  </button>
                  <span className="px-3 py-1 mx-1 border rounded">{`${currentPage + 1}/${filteredExamsId.length}`}</span>
                  <button
                    onClick={() => handlePagination("next")}
                    disabled={currentPage >= filteredExamsId.length - 1}
                    className={`px-4 py-2 rounded ${currentPage >= filteredExamsId.length - 1
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-[#241763] text-white hover:bg-[#241763]"
                      }`}
                  >
                    <RightOutlined />
                  </button>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default GradeTableAssignment;
