import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/employees`;

// Fetch Employees
export const fetchEmployees = createAsyncThunk("employees/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Employee
export const addEmployee = createAsyncThunk(
  "employees/add",
  async (employeeData) => {
    const response = await axios.post(API_URL, employeeData);
    return response.data;
  }
);

// Update Employee
export const updateEmployee = createAsyncThunk(
  "employees/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Employee
export const deleteEmployee = createAsyncThunk("employees/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const employeeSlice = createSlice({
  name: "employees",
  initialState: {
    employees: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employees = action.payload;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.employees.push(action.payload);
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        const index = state.employees.findIndex((emp) => emp.id === action.payload.id);
        if (index !== -1) {
          state.employees[index] = action.payload;
        }
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.employees = state.employees.filter((emp) => emp.id !== action.payload);
      });
  },
});

export default employeeSlice.reducer;
