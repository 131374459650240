import React, { useState } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { Calendar, Wallet, Users, PiggyBank, FileText } from "lucide-react";



const payrollData = [
  { name: "Employee Payroll Run", value: 200, color: "#4CAF50" },
  { name: "Employee Payroll Remain", value: 50, color: "#FFA500" },
];

const departmentData = [
  { name: "Graphics Design", value: 20000, color: "#4285F4" },
  { name: "Angular.js", value: 25000, color: "#EA4335" },
  { name: "Writer", value: 18000, color: "#34A853" },
  { name: "Management", value: 22000, color: "#FBBC05" },
];

const locationData = [
  { name: "Lahore)", value: 25000, color: "#FF5733" },
  { name: "DHA", value: 20000, color: "#33FF57" },
  { name: "Johartown", value: 30000, color: "#337BFF" },
];

const payDistributionData = [
  { name: "Fix Pay", value: 1530000, color: "#D32F2F" },
  { name: "Overtime", value: 500000, color: "#1976D2" },
  { name: "Bonus", value: 250000, color: "#FBC02D" },
  { name: "Others", value: 100000, color: "#388E3C" },
];

const Payroll = () => {
  const [selectedMonth, setSelectedMonth] = useState("March");

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
   
      {/* Payroll Summary */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white p-4 shadow-md rounded-lg flex items-center gap-4">
          <Users size={30} className="text-blue-600" />
          <div>
            <p className="text-lg font-semibold">250</p>
            <p className="text-gray-500 text-sm">Total Employees</p>
          </div>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg flex items-center gap-4">
          <Wallet size={30} className="text-red-600" />
          <div>
            <p className="text-lg font-semibold">$25,000</p>
            <p className="text-gray-500 text-sm">TDS</p>
          </div>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg flex items-center gap-4">
          <PiggyBank size={30} className="text-orange-600" />
          <div>
            <p className="text-lg font-semibold">$30,000</p>
            <p className="text-gray-500 text-sm">PT</p>
          </div>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg flex items-center gap-4">
          <FileText size={30} className="text-green-600" />
          <div>
            <p className="text-lg font-semibold">$2,84,4450</p>
            <p className="text-gray-500 text-sm">Total Compensation</p>
          </div>
        </div>
      </div>

      {/* Payroll Date & Buttons */}
      <div className="bg-white mt-6 p-4 shadow-md rounded-lg flex flex-col md:flex-row md:justify-between items-center">
        <div className="flex items-center gap-2">
          <Calendar size={24} className="text-yellow-500" />
          <p className="text-gray-700">
            <span className="font-semibold">Next Payroll Date: </span>
            <span className="text-yellow-600">15 April, 2022</span>
          </p>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0 ">
          <button className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2">
            Payroll History
          </button>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg">
            Run Payroll
          </button>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
        {/* Payroll Analytics */}
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Payroll Analytics</h3>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={payrollData}
                dataKey="value"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {payrollData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Compensation by Department */}
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-lg font-semibold mb-4">
            Compensation Distribution by Department
          </h3>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={departmentData}
                dataKey="value"
                outerRadius={80}
                fill="#82ca9d"
                label
              >
                {departmentData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Compensation by Location */}
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-lg font-semibold mb-4">
            Compensation Distribution by Location
          </h3>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={locationData}
                dataKey="value"
                outerRadius={80}
                fill="#ffc658"
                label
              >
                {locationData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Average Pay Distribution */}
      <div className="bg-white mt-6 p-4 shadow-md rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Average Pay Distribution</h3>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie
              data={payDistributionData}
              dataKey="value"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {payDistributionData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Payroll;
