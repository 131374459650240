import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const CandidateModal = ({ onSubmit, onClose, jobs, candidate }) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const isEditing = !!candidate;

  useEffect(() => {
    if (candidate) {
      // Populate form with candidate data for editing
      setValue("candidateName", candidate.name);
      setValue("fatherName", candidate.fatherName);
      setValue("mobileNumber", candidate.mobileNumber);
      setValue("email", candidate.email);
      setValue("address", candidate.address);
      setValue("currentDesignation", candidate.currentDesignation);
      setValue("experience", candidate.experience);
      setValue("rating", candidate.rating);
      setValue("currentSalary", candidate.currentSalary);
      setValue("jobId", candidate.JobOpening?.PostTitle);
      setValue("highestQualification", candidate.highestQualification);
      setValue("skillSet", candidate.skillSet);
      setValue("expectedSalary", candidate.expectedSalary);
      setValue("candidateStatus", candidate.candidateStatus || "Pending");
    }
  }, [candidate, setValue]);

  const submitHandler = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">
          {isEditing ? "Edit Candidate" : "Add New Candidate"}
        </h2>
        <form onSubmit={handleSubmit(submitHandler)} className="grid grid-cols-2 gap-4">
          {/* Candidate Name */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Candidate Name *</label>
            <input
              type="text"
              {...register("candidateName", { required: true })}
              className="border p-2 rounded w-full"
              placeholder="Enter full name"
            />
          </div>

          {/* Father Name */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Father Name *</label>
            <input
              type="text"
              {...register("fatherName", { required: true })}
              className="border p-2 rounded w-full"
              placeholder="Father name"
            />
          </div>

          {/* Mobile Number */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Mobile Number *</label>
            <input
              type="text"
              {...register("mobileNumber", { required: true })}
              className="border p-2 rounded w-full"
              placeholder="Mobile number"
            />
          </div>

          {/* Email */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Email *</label>
            <input
              type="email"
              {...register("email", { required: true })}
              className="border p-2 rounded w-full"
              placeholder="abc@xyz.com"
            />
          </div>

          {/* Address */}
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Address</label>
            <input
              type="text"
              {...register("address")}
              className="border p-2 rounded w-full"
              placeholder="Complete address"
            />
          </div>

          {/* Current Designation */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Current Designation</label>
            <input
              type="text"
              {...register("currentDesignation")}
              className="border p-2 rounded w-full"
              placeholder="Current designation"
            />
          </div>

          {/* Experience */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Experience *</label>
            <select
              {...register("experience", { required: true })}
              className="border p-2 rounded w-full"
            >
              <option value="">Select</option>
              <option value="Fresher">Fresher</option>
              <option value="0-1 year">0-1 year</option>
              <option value="1-3 years">1-3 years</option>
              <option value="5+ years">5+ years</option>
            </select>
          </div>

          {/* Rating */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Rating</label>
            <input
              type="number"
              {...register("rating")}
              className="border p-2 rounded w-full"
              placeholder="Enter rating (1-5)"
              min={1}
              max={5}
            />
          </div>

          {/* Resume */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Resume</label>
            <input
              type="file"
              {...register("resume")}
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer focus:outline-none"
            />
            {isEditing && <p className="text-xs mt-1 text-gray-500">Leave empty to keep current resume</p>}
          </div>

          {/* Current Salary */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Current Salary</label>
            <input
              type="text"
              {...register("currentSalary")}
              className="border p-2 rounded w-full"
              placeholder="Current salary"
            />
          </div>

          {/* Position Applying For */}
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Position Applying For *</label>
            <select
              {...register("jobId", { required: true })}
              className="border p-2 rounded w-full"
            >
              <option value="">Select</option>
              {jobs.map((job) => (
                <option key={job.id} value={job.title || job.PostTitle}>
                  {job.title || job.PostTitle}
                </option>
              ))}
            </select>
          </div>

          {/* Candidate Status - Only shown in edit mode */}
          {isEditing && (
            <div className="col-span-2">
              <label className="block text-sm font-medium mb-1">Candidate Status</label>
              <select
                {...register("candidateStatus")}
                className="border p-2 rounded w-full"
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
          )}

          {/* Highest Qualification */}
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Highest Qualification *</label>
            <textarea
              {...register("highestQualification", { required: true })}
              className="border p-2 rounded w-full"
              placeholder="Add Educational Requirements"
            ></textarea>
          </div>

          {/* Skill Set */}
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Skill Set</label>
            <textarea
              {...register("skillSet")}
              className="border p-2 rounded w-full"
              placeholder="Add skills"
            ></textarea>
          </div>

          {/* Expected Salary */}
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Expected Salary</label>
            <input
              type="text"
              {...register("expectedSalary")}
              className="border p-2 rounded w-full"
              placeholder="Expected salary"
            />
          </div>

          {/* Submit and Cancel */}
          <div className="col-span-2 flex justify-end space-x-2 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#241763] text-white py-2 px-4 rounded"
            >
              {isEditing ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CandidateModal;