import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp, MonitorPlay, FileText } from "lucide-react";
import { Spin, Progress } from "antd";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { logoFrame as viserbe } from "../Constant/images";
import { greenPlayBtn, purplePlayBtn, lightPlayBtn, } from "../Constant/svgs";

const CourseDetails = () => {
  const { state } = useLocation();
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [asset, setAsset] = useState(null);


  useEffect(() => {
    if (state?.plans) {
      const lessonData = state.plans.map((plan) => ({
        id: plan.id,
        name: plan.weekName,
        activities: plan.activities.map((activity) => ({
          id: activity.id,
          name: activity.activityName,
          type: activity.activityType,
          filePath: activity.filePath,
        })),
      }));

      setCourse(lessonData);

      if (lessonData.length > 0) {
        const firstLesson = lessonData[0];
        setSelectedLesson(firstLesson);

        if (firstLesson.activities.length > 0) {
          const firstAsset = firstLesson.activities[0];
          setAsset({
            type: firstAsset.type.toLowerCase(),
            url: firstAsset.filePath,
          });
        }
      }
    }
  }, [state]);

  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);
    if (lesson.activities.length > 0) {
      const firstAsset = lesson.activities[0];
      setAsset({
        type: firstAsset.type.toLowerCase(),
        url: firstAsset.filePath,
      });
    } else {
      setAsset(null);
    }
  };

  const handleAssetClick = (type, url, event) => {
    event.stopPropagation();
    setAsset(null);
    setTimeout(() => {
      setAsset({
        type: type,
        url: url
      });
    }, 100);
  };

  const renderAssetContent = () => {
    if (!asset) {
      return <p>Please select an activity to view content.</p>;
    }

    const fileExtension = asset.url.split('.').pop().toLowerCase();

    // Handle video files
    if (asset.type === "video") {
      return (
        <video controls className="rounded-md w-100" key={asset.url}>
          <source src={asset.url} />
        </video>
      );
    }

    // Handle PDF files
    if (fileExtension === "pdf") {
      return (
        <iframe
          src={asset.url}
          className="document-viewer"
          title="PDF Viewer"
          style={{ width: "100%", height: "600px", border: "none" }}
          key={asset.url}
        ></iframe>
      );
    }

    // Handle image files
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={asset.url}
          alt="Activity"
          className="img-fluid rounded"
          key={asset.url}
          style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "contain" }}
        />
      );
    }

    // Handle Word documents
    if (["doc", "docx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(asset.url)}`}
          className="document-viewer"
          title="Document Viewer"
          style={{ width: "100%", height: "600px", border: "none" }}
          key={asset.url}
        ></iframe>
      );
    }

    return <p>Unsupported file type. Please select another activity.</p>;
  };
  const activitiesValues = state.plans.flatMap(plan => plan);

  return (
    <div className="container-fluid">
      <Spin spinning={loading}>
        <div className="row mt-3">
          {/* Content Viewer */}
          <div
            className="col-md-12 col-lg-8"
            style={{ height: "90vh", overflowY: "auto", padding: "0px 10px" }}
          >
            {renderAssetContent()}

            {selectedLesson && (
              // <div className="mt-4">
              //   <h3>About Lesson</h3>
              //   <p>{selectedLesson.name}</p>
              // </div>
              <div className="reviewAboutChannel position-relative top-4">
              <div className="bottom-part d-flex justify-content-between flex-wrap ">
                <div className=" d-flex flex-wrap gap-2 w-50">
                  <div className="img-viserbe ">
                    <img src={viserbe} className="img-fluid" alt="" />
                  </div>
                  <div className="viserbe-text d-flex flex-column">
                    <span className="viser-helper fw-bold">Course</span>
                    <span className="digital-studio">Design Studio</span>
                  </div>
                </div>
              </div>
              <hr className="line w-100 mt-1" />
              <div className="container-fluid">
                <h3 className="fw-bold pt-3">About Lesson</h3>
                <p>{selectedLesson?.detail}</p>
              </div>
            </div>
            )}
          </div>

          {/* Lessons Sidebar */}
          <div
            className="col-md-12 col-lg-4 p-4 rounded-4 shadow-sm"
            style={{
              background: "#f9f9f9",
              border: "1px solid #ddd",
            }}
          >
            <button className="btn btn-primary w-100 mb-4" disabled>
              {t("Course Details")}
            </button>
            <div className="mb-3">
              <Progress percent={50} status="active" strokeColor="#241763" style={{all: 'unset'}}/>
            </div>
            <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {course.map((lesson, indx) => (
              <div
                key={lesson.id}
                className={`mb-3 p-3 rounded shadow-sm ${selectedLesson?.id === lesson.id ? "bg-white" : "bg-white"}`}
                style={{ cursor: "pointer" }}
                onClick={() => handleLessonClick(lesson)}
              >
                <div className="d-flex justify-content-between align-items-center" style={{ fontSize: "16px", color: '#241763' }}>
                  <h5>{`${lesson.name} (${lesson.activities.length})`}</h5>
                  {selectedLesson?.id === lesson.id ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>

                {selectedLesson?.id === lesson.id && (
                  <ul
                    className="mt-3"
                  >
                    {lesson.activities.map((activity, index) => (
                      <li key={activity.id} className="py-2">
                        <div className="d-flex justify-content-start gap-0 align-items-center ml-1">
                          <span style={{ transform: "scale(2)" }}>
                            {index === 0 && greenPlayBtn}
                            {index === 1 && purplePlayBtn}
                            {index === 2 && lightPlayBtn}
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAssetClick(activity.type.toLowerCase(), activity.filePath, e);
                            }}
                            className="btn"
                            style={{ fontSize: "15px", textDecoration: "none", color: '#241763' }}
                          >
                            {activity.name}
                          </span>
                        </div>

                        <div className="d-flex justify-content-start align-items-center gap-2">
                          {activitiesValues[indx].activities[index].activityType === 'Video' && <><MonitorPlay size={14} className="ms-1 text-primary" /> <span className="text-muted" style={{ fontSize: "13px" }}>3min</span></>}
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-2">
                          {activitiesValues[indx].activities[index].activityType === 'Document' && <><FileText size={14} className="ms-1 text-primary" /> <span className="text-muted" style={{ fontSize: "13px" }}>3min</span></>}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default CourseDetails;
