import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/warnings`;

// Fetch Warnings
export const fetchWarnings = createAsyncThunk("warnings/fetch", async () => {
  const response = await axios.get(API_URL);
  console.log("API Response:", response.data); 
  return response.data;
});

// Add Warning
export const addWarning = createAsyncThunk(
  "warnings/add",
  async (warningData) => {
    const response = await axios.post(API_URL, warningData);
    return response.data;
  }
);

// Update Warning
export const updateWarning = createAsyncThunk(
  "warnings/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Warning
export const deleteWarning = createAsyncThunk("warnings/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const warningSlice = createSlice({
  name: "warnings",
  initialState: {
    warnings: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarnings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWarnings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.warnings = action.payload;
      })
      .addCase(fetchWarnings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addWarning.fulfilled, (state, action) => {
        state.warnings.push(action.payload);
      })
      .addCase(updateWarning.fulfilled, (state, action) => {
        const index = state.warnings.findIndex((w) => w.id === action.payload.id);
        if (index !== -1) {
          state.warnings[index] = action.payload;
        }
      })
      .addCase(deleteWarning.fulfilled, (state, action) => {
        state.warnings = state.warnings.filter((w) => w.id !== action.payload);
      });
  },
});

export default warningSlice.reducer;
