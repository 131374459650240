import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";

function FinancialApproval({ overDue, setFeeBills }) {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [filters, setFilters] = useState({
    studentName: "",
    feeCode: "",
    overDue: "",
    paymentStatus: "",
  });

  // Updated Dummy Data
  const dummyFeeBills = [
    {
      studentId: "001",
      studentName: "John Doe",
      feeCode: "FC101",
      billingCycleDate: "2024-01-15",
      overDue: "No delay",
      paymentStatus: "Paid",
    },
    {
      studentId: "002",
      studentName: "Jane Smith",
      feeCode: "FC102",
      billingCycleDate: "2024-02-15",
      overDue: "5 days delay",
      paymentStatus: "Pending",
    },
    {
      studentId: "003",
      studentName: "Sam Wilson",
      feeCode: "FC103",
      billingCycleDate: "2024-03-15",
      overDue: "15 days delay",
      paymentStatus: "Overdue",
    },
  ];

  const [feeBillsData, setFeeBillsData] = useState(dummyFeeBills);
  const [searchText, setSearchText] = useState("");
  
    // Handle HR Approval Button Click
    const handleHRApproval = () => {
      // Example logic - Replace with actual functionality
      console.log("Proceeding for HR Approval...");
      alert("Proceeding for HR Approval...");
    };
  
    // Handle Salaries Detail Button Click
    const handleSalariesDetail = () => {
      // Example logic - Replace with actual functionality
      console.log("Viewing Salaries Detail...");
      alert("Viewing Salaries Detail...");
    };
  
    // Handle Search Input Change
    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
      console.log("Searching:", event.target.value);
    };
  

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredFeeBills = feeBillsData.filter(
    (bill) =>
      (filters.studentName === "" ||
        bill.studentName.includes(filters.studentName)) &&
      (filters.feeCode === "" || bill.feeCode.includes(filters.feeCode)) &&
      (filters.overDue === "" || bill.overDue.includes(filters.overDue)) &&
      (filters.paymentStatus === "" ||
        bill.paymentStatus.includes(filters.paymentStatus))
  );

  return (
    <div>
     <div className="flex flex-col md:flex-row w-full items-center justify-between mt-4 space-y-2 md:space-y-0 md:space-x-2">
  <span className="text-gray-700 font-medium">Session: February-2024</span>

  <input
    type="text"
    value={searchText}
    onChange={handleSearchChange}
    placeholder="Search By Emp. Name / Code"
    className="border border-gray-300 rounded p-2 w-full md:w-1/4"
  />

  <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
    <button
      onClick={handleHRApproval}
      className="px-4 py-2 bg-[#241763] text-white rounded w-full md:w-auto"
    >
      Proceed for Distribution
    </button>

    {/* <button
      onClick={handleSalariesDetail}
      className="px-4 py-2 bg-[#241763] text-white rounded w-full md:w-auto"
    >
      Apply inst. & Fine
    </button> */}
  </div>
</div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
          <tr className=" border-b bg-[#241763]/10 ">
              <th className="p-2">Emp code</th>
              <th>Emp Name</th>
              <th>Att. Detail</th>
              <th>Pending Dues</th>
              <th>Appears&Allownce</th>
              <th>Payable Salary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {/* {filteredFeeBills.length > 0 ? (
              filteredFeeBills.map((bill, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{bill.studentId}</td>
                    <td>{bill.studentName}</td>
                    <td>{bill.feeCode}</td>
                    <td>{bill.billingCycleDate}</td>
                    <td>{bill.overDue}</td>
                    <td>{bill.paymentStatus}</td>
                    <td className="space-x-2 flex items-center py-2">
                     
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="7">
                        <div className="bg-gray-100 p-4">
                          <div>Additional details about the bill...</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Fee Bills Found
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FinancialApproval;
