import React, { useState } from "react";
import { X } from "lucide-react";

const ApplyLeaveModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  employees,
  isMultipleDays,
  setIsMultipleDays
}) => {
  

  if (!isModalOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-11/12 md:w-2/3 lg:w-1/3 overflow-hidden rounded shadow-lg relative">
        <div className="flex justify-between items-center border-b pb-2 px-4">
          <h2 className="text-xl font-semibold">Apply For Leave</h2>
          <button
            className="p-2 hover:bg-gray-200 rounded-full"
            onClick={() => {
              setIsModalOpen(false);
              reset();
            }}
          >
            <X />
          </button>
        </div>

        <div className="overflow-y-auto px-6 py-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Select Employee
              </label>
              <select
                {...register("employeeId", { required: true })} 
                className="mt-2 w-full border rounded p-2"
              >
                <option value="">Select Employee</option>
                {employees.map((emp) => (
                  <option key={emp.id} value={emp.id}>
                    {emp.firstName} {emp.lastName}
                  </option>
                ))}
              </select>
            </div>

            {/* Leave Type */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Leave Type
              </label>
              <select
                {...register("leaveType", { required: true })}
                className="mt-2 w-full border rounded p-2"
              >
                <option value="">Select Leave Type</option>
                <option value="casualLeave">Casual Leave</option>
                <option value="sickLeave">Sick Leave</option>
                <option value="annualLeave">Annual Leave</option>
              </select>
            </div>

            {/* Date Selection */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Select Days
              </label>
              <div className="flex gap-4 mt-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="oneDay"
                    checked={!isMultipleDays}
                    onChange={() => setIsMultipleDays(false)}
                    className="mr-2"
                  />
                  One Day
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="multipleDays"
                    checked={isMultipleDays}
                    onChange={() => setIsMultipleDays(true)}
                    className="mr-2"
                  />
                  Multiple Days
                </label>
              </div>
            </div>

            {/* Date From */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Date From
              </label>
              <input
                type="date"
                {...register("dateFrom", { required: true })}
                className="mt-2 w-full border rounded p-2"
              />
            </div>

            {/* Date To */}
            {isMultipleDays && (
              <div className="mb-4">
                <label className="block text-gray-700 font-medium">
                  Date To
                </label>
                <input
                  type="date"
                  {...register("dateTo", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                />
              </div>
            )}

            {/* Reason */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Reason</label>
              <textarea
                {...register("reason")}
                className="mt-2 w-full border rounded p-2"
                rows="3"
                placeholder="Reason for leave"
              />
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                onClick={() => {
                  setIsModalOpen(false);
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#241763] text-white px-4 py-2 rounded"
              >
                Apply Leave
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplyLeaveModal;
