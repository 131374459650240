import React, { useState, useEffect } from 'react';
import { X } from "lucide-react";
import Counter from "../../Components/Common/Counter";
import { useTranslation } from "react-i18next";
import { updateRequest } from '../../services';
import { message } from 'antd';

const UpdateQuestionModal = ({ onRequestClose, updateData, fetchData, questionID }) => {
    const [click, setClick] = useState(0);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language;

    const [questionData, setQuestionData] = useState(updateData);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [question, setQuestion] = useState(questionData[currentQuestionIndex]?.question || "");
    const [myMarks, setMyMarks] = useState(questionData[currentQuestionIndex]?.marks);
    const totalQuestions = questionData.length;
    const percentage = ((currentQuestionIndex + 1) * 100) / totalQuestions;

    useEffect(() => {
        setQuestion(questionData[currentQuestionIndex]?.question || "");
    }, [currentQuestionIndex]);

    // Update question data whenever question changes
    const handleQuestionChange = (e) => {
        const newQuestion = e.target.value;
        setQuestion(newQuestion);
        
        const updatedData = [...questionData];
        updatedData[currentQuestionIndex] = {
            ...updatedData[currentQuestionIndex],
            question: newQuestion,
        };
        setQuestionData(updatedData);
    };

    const handleClickNext = async (action) => {
        if (action === 'Next') {
            setClick((prev) => prev + 1);
            setMyMarks(questionData[currentQuestionIndex]?.marks);
    
            if (currentQuestionIndex < totalQuestions - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }
        } else {
            const payload = questionData.map((item) => ({
                examId: questionID,
                id: item.id,
                question: item.question,
                answer: '',
                marks: item.marks,
                time: item.time,
            }));
    
            try {
                const response = await updateRequest(`/api/Exam/update-questions`, payload);
                if (response.data.success) {
                    if (action === 'Publish') {
                        await updateRequest(`/api/Exam/examStatus?id=${questionID}`);
                        message.success("Exam is published successfully");
                    } else {
                        message.success("Exam is updated successfully");
                    }
                    onRequestClose();
                    fetchData();
                }
            } catch (error) {
                message.error("An error occurred while saving the exam");
            }
        }
    };

    const handleClickPrevious = () => {
        setClick((prev) => prev - 1);
        setMyMarks(questionData[currentQuestionIndex]?.marks);
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleCounterClick = (counter, actionType) => {
        const updatedData = [...questionData];
        if (counter === 'marks') {
            let updatedMarks = updatedData[currentQuestionIndex].marks;
            if (actionType === 'increment') {
                updatedMarks++;
            } else if (actionType === 'decrement') {
                updatedMarks = Math.max(0, updatedMarks - 1); // Ensure marks don't go below 0
            }
            updatedData[currentQuestionIndex].marks = updatedMarks;
            setMyMarks(updatedMarks);
        } else if (counter === 'time') {
            let updatedTime = updatedData[currentQuestionIndex].time;
            if (actionType === 'increment') {
                updatedTime++;
            } else if (actionType === 'decrement') {
                updatedTime = Math.max(0, updatedTime - 1); // Ensure time doesn't go below 0
            }
            updatedData[currentQuestionIndex].time = updatedTime;
        }
        setQuestionData(updatedData);
    };
console.log("myMarks",questionData)
    return (
        <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
            <div className="row d-flex justify-content-center p-0 m-0">
                <div className="col-md-12 examModalWrapper p-0 m-0">
                    <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
                        <h4 style={{ color: "#060317" }} className="fw-bold">
                            {t("Question Details")}
                        </h4>
                        <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
                            <X />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row px-4 m-0 gap-5 mt-4">
                <div className="col-md-4 m-0 p-0">
                    <div className="w-100 d-flex flex-column gap-2 justify-content-center">
                        <label>
                            {currentQuestionIndex + 1}/{totalQuestions}
                        </label>
                        <div className="questionBarProgress">
                            {Array.from({ length: totalQuestions }).map((_, index) => (
                                <div
                                    className={`dot ${index <= currentQuestionIndex ? "dotWhite" : ""}`}
                                    key={index}
                                ></div>
                            ))}
                            <div className="progressBar" style={{ width: `${percentage}%` }}></div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>{t("Marks")}</label>
                        <Counter
                            value={myMarks}
                            isFullWidth={true}
                            counter="marks"
                            handleClick={(actionType) => handleCounterClick('marks', actionType)}
                        />
                    </div>
                </div>

                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>{t("Time")}</label>
                        <Counter
                            value={questionData[currentQuestionIndex]?.time}
                            isFullWidth={true}
                            counter="time"
                            handleClick={(actionType) => handleCounterClick('time', actionType)}
                        />
                    </div>
                </div>
            </div>

            <div className="row px-4 mt-5">
                <div className="col-md-12 mb-4">
                    <textarea
                        rows={3}
                        placeholder={t("Please provide question")}
                        onChange={handleQuestionChange}
                        className="form-control"
                        value={question}
                    ></textarea>
                </div>

                <div className="row px-4 m-0 mt-3">
                    <div className="col-md-12 d-flex justify-content-end gap-3">
                        <button
                            onClick={handleClickPrevious}
                            disabled={click === 0}
                            className="btn btn-secondary"
                        >
                            {t("Previous")}
                        </button>
                        <button
                            className="btn btn-primary px-4 py-2"
                            onClick={() => handleClickNext(currentQuestionIndex + 1 === totalQuestions ? "Save" : "Next")}
                        >
                            {currentQuestionIndex + 1 === totalQuestions ? t("Save") : t("Next")}
                        </button>
                        {click + 1 === questionData.length && (
                        <button
                            className="btn btn-primary px-4 py-2"
                            onClick={() => handleClickNext('Publish')}
                        >
                            {t("Publish")}
                        </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateQuestionModal;
