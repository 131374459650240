import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/salaries`;

// Fetch Salaries
export const fetchSalaries = createAsyncThunk("salaries/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Salary
export const addSalary = createAsyncThunk("salaries/add", async (salaryData) => {
  const response = await axios.post(API_URL, salaryData);
  return response.data;
});

// Update Salary
export const updateSalary = createAsyncThunk(
  "salaries/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Salary
export const deleteSalary = createAsyncThunk("salaries/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const salarySlice = createSlice({
  name: "salaries",
  initialState: {
    salaries: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalaries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSalaries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.salaries = action.payload;
      })
      .addCase(fetchSalaries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSalary.fulfilled, (state, action) => {
        state.salaries.push(action.payload);
      })
      .addCase(updateSalary.fulfilled, (state, action) => {
        const index = state.salaries.findIndex((s) => s.id === action.payload.id);
        if (index !== -1) {
          state.salaries[index] = action.payload;
        }
      })
      .addCase(deleteSalary.fulfilled, (state, action) => {
        state.salaries = state.salaries.filter((s) => s.id !== action.payload);
      });
  },
});

export default salarySlice.reducer;
