import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { attendanceData } from '../../../Constant/charts';

const AttendanceChart = ({ title, filter }) => {
  // Filter data based on selected filter
  const filteredData = attendanceData.map(item => {
    if (filter === 'all') {
      return item;
    } else {
      return { name: item.name, [filter]: item[filter] };
    }
  });

  return (
    <div className="bg-white shadow-md p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-4">{ title }</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {filter === 'all' || filter === 'teachersAttendance' ? <Bar dataKey="teachersAttendance" fill="#8884d8" /> : null}
          {filter === 'all' || filter === 'learnersAttendance' ? <Bar dataKey="learnersAttendance" fill="#82ca9d" /> : null}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AttendanceChart;
