import React, { useState, useEffect } from 'react';
import { X } from "lucide-react";
import Counter from "../../Components/Common/Counter";
import { useTranslation } from "react-i18next";
import { updateRequest, postRequest } from '../../services';
import { message } from 'antd';
import { DeleteOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';

const UpdateQuestionModal = ({ onRequestClose, updateData, fetchData, questionID }) => {
    const [click, setClick] = useState(0);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language;
    const [loading, setLoading] = useState(false);

    const [questionData, setQuestionData] = useState(updateData);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [question, setQuestion] = useState(questionData[currentQuestionIndex] || {});
    const [myMarks, setMyMarks] = useState(questionData[currentQuestionIndex]?.marks);
    const totalQuestions = questionData.length;
    const percentage = ((currentQuestionIndex + 1) * 100) / totalQuestions;
    const [documentFile, setDocumentFile] = useState(null);

    console.log(questionData);
    const saveCurrentQuestion = () => {
        const updatedData = [...questionData];
        updatedData[currentQuestionIndex] = {
            ...updatedData[currentQuestionIndex],
            ...question
        };
        setQuestionData(updatedData);
        return updatedData;
    };

    useEffect(() => {
        setQuestion(questionData[currentQuestionIndex] || {});
    }, [currentQuestionIndex]);

    const handleClickNext = async (action) => {
        let updatedData = saveCurrentQuestion();
    
        if (action === 'Next') {
            setClick((prev) => prev + 1);
            setMyMarks(updatedData[currentQuestionIndex]?.marks);
    
            if (currentQuestionIndex < totalQuestions - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }
        } else {
            try {
                setLoading(true);
                let attachment = null;
                if (documentFile) {
                    const formData = new FormData();
                    formData.append("files", documentFile);
                    const docResponse = await postRequest("/api/Document/documents-upload", formData, true);
                    attachment = docResponse?.data[0];
                }

                const payload = updatedData.map((item, index) => ({
                    examId: questionID,
                    id: item.id,
                    title: item.title,
                    instructions: '',
                    attachment: index === currentQuestionIndex ? 
                        (documentFile ? attachment : item.attachment) : 
                        item.attachment,
                    marks: item.marks,
                }));
    
                const response = await updateRequest(`/api/Exam/update-assignment`, payload);
                if (response.data.success) {
                    if (action === 'Publish') {
                        await updateRequest(`/api/Exam/examStatus?id=${questionID}`);
                        message.success("Exam is published successfully");
                    } else {
                        message.success("Exam is updated successfully");
                    }
                    onRequestClose();
                    fetchData();
                }
            } catch (error) {
                message.error("An error occurred while saving the exam");
            } finally {
                setLoading(false);
            }
        }
    };

    const handleClickPrevious = () => {
        setClick((prev) => prev - 1);
        setMyMarks(questionData[currentQuestionIndex]?.marks);
        saveCurrentQuestion();
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleCounterClick = (counter, actionType) => {
        const updatedData = [...questionData];
        if (counter === 'marks') {
            let updatedMarks = updatedData[currentQuestionIndex].marks;
            if (actionType === 'increment') {
                updatedMarks++;
            } else if (actionType === 'decrement') {
                updatedMarks = Math.max(0, updatedMarks - 1);
            }
            updatedData[currentQuestionIndex].marks = updatedMarks;
        } 
        setQuestionData(updatedData);
    };

    const handleDeleteAttachment = () => {
        const updatedData = [...questionData];
        updatedData[currentQuestionIndex] = {
            ...updatedData[currentQuestionIndex],
            attachment: null
        };
        setQuestionData(updatedData);
        setQuestion({...question, attachment: null});
        setDocumentFile(null);
        message.success('File deleted successfully.');
    };

    const trimValue = (url) => {
        if (url) {
            return url.replace('https://lms-api.wiserbee.ca/Upload/', '');
        }
        return '';
    };

    return (
        <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
            <div className="row d-flex justify-content-center p-0 m-0">
                <div className="col-md-12 examModalWrapper p-0 m-0">
                    <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
                        <h4 style={{ color: "#060317" }} className="fw-bold">
                            {t("Assignment Details")}
                        </h4>
                        <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
                            <X />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row px-4 m-0 gap-5 mt-4">
                <div className="col-md-4 m-0 p-0">
                    <div className="w-100 d-flex flex-column gap-2 justify-content-center">
                        <label>
                            {currentQuestionIndex + 1}/{totalQuestions}
                        </label>
                        <div className="questionBarProgress">
                            {Array.from({ length: totalQuestions }).map((_, index) => (
                                <div
                                    className={`dot ${index <= currentQuestionIndex ? "dotWhite" : ""}`}
                                    key={index}
                                ></div>
                            ))}
                            <div className="progressBar" style={{ width: `${percentage}%` }}></div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>{t("Marks")}</label>
                        <Counter
                            value={myMarks}
                            isFullWidth={true}
                            counter="marks"
                            handleClick={(actionType) => handleCounterClick('marks', actionType)}
                        />
                    </div>
                </div>
            </div>

            <div className="row px-4 mt-5">
                <div className="col-md-12 mb-4">
                    <textarea
                        rows={3}
                        placeholder={t("Please provide title")}
                        onChange={(e) => setQuestion({...question, title: e.target.value})}
                        className="form-control"
                        value={question.title || ""}
                    ></textarea>
                </div>
                {!question.attachment && !documentFile && (
                    <div className="col-md-12 mb-4">
                        <Upload
                            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                            beforeUpload={(file) => {
                                const allowedTypes = [
                                    "application/pdf",
                                    "image/jpeg",
                                    "image/png",
                                    "application/msword",
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                ];
                                if (!allowedTypes.includes(file.type)) {
                                    message.error(t("Invalid file type. Please select a PDF, image, or DOC file."));
                                    return Upload.LIST_IGNORE;
                                }
                                setDocumentFile(file);
                                return false;
                            }}
                            listType="picture"
                        >
                            <Button type="primary" icon={<UploadOutlined />}>
                                {t("ChooseFile")}
                            </Button>
                        </Upload>
                    </div>
                )}
                {(question.attachment || documentFile) && (
                    <div className="col-md-12 mb-4">
                        <div className="d-flex justify-content-between align-items-center gap-3 uploadOutline p-2">
                            <FileOutlined style={{ fontSize: '1.5em', color: '#1677ff' }} />
                            {documentFile ? (
                                <span style={{ flex: 1 }}>{documentFile.name}</span>
                            ) : (
                                <a href={question.attachment} target="_blank" rel="noopener noreferrer" style={{ flex: 1 }}>
                                    {trimValue(question.attachment)}
                                </a>
                            )}
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={handleDeleteAttachment}
                                style={{ marginLeft: '8px' }}
                            />
                        </div>
                    </div>
                )}

                <div className="row px-4 m-0 mt-3">
                    <div className="col-md-12 d-flex justify-content-end gap-3">
                        <button
                            onClick={handleClickPrevious}
                            disabled={click === 0}
                            className="btn btn-secondary"
                        >
                            {t("Previous")}
                        </button>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => handleClickNext(currentQuestionIndex + 1 === totalQuestions ? "Save" : "Next")}
                        >
                            {currentQuestionIndex + 1 === totalQuestions ? t("Save") : t("Next")}
                        </Button>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => handleClickNext('Publish')}
                        >
                            {t("Publish")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateQuestionModal;
