import React from "react";
import { useForm } from "react-hook-form";

const InterviewScheduleModal = ({ isOpen, onClose, candidate, stageName, stage, onSchedule }) => {
  const { register, handleSubmit, reset } = useForm();
  
  if (!isOpen || !candidate) return null;
  
  const onSubmit = (data) => {
    onSchedule(candidate.id, data);
    reset(); // Clear form fields after submission
    onClose();
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">{stageName} Details</h2>
        
        {/* Selected Candidate Name */}
        <div className="mb-2">
          <label className="block text-gray-700">Candidate Name</label>
          <input
            type="text"
            value={candidate.name}
            readOnly
            className="w-full p-2 border rounded-md bg-gray-100"
          />
        </div>
        
        {/* Position Applied */}
        <div className="mb-2">
          <label className="block text-gray-700">Position Applied</label>
          <input
            type="text"
            value={candidate.currentDesignation || "N/A"}
            readOnly
            className="w-full p-2 border rounded-md bg-gray-100"
          />
        </div>
        
        {/* Current Interview Stage */}
        <div className="mb-2">
          <label className="block text-gray-700">Interview Stage</label>
          <input
            type="text"
            value={stageName}
            readOnly
            className="w-full p-2 border rounded-md bg-gray-100"
          />
        </div>
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <label className="block text-gray-700">Interviewer Name</label>
            <input
              type="text"
              {...register("interviewer")}
              className="w-full p-2 border rounded-md"
              placeholder="Enter interviewer name"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Location</label>
            <input
              type="text"
              {...register("location")}
              className="w-full p-2 border rounded-md"
              placeholder="Enter location"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Date</label>
            <input
              type="date"
              {...register("date")}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>
          <div className="flex gap-2">
            <div className="flex-1">
              <label className="block text-gray-700">Start Time</label>
              <input
                type="time"
                {...register("startTime")}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            <div className="flex-1">
              <label className="block text-gray-700">End Time</label>
              <input
                type="time"
                {...register("endTime")}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 text-white rounded-md mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md"
            >
              Schedule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InterviewScheduleModal;