import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import PushNotification from "./PushNotification";
import IconWrapper from "../Components/Common/IconWrapper";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { postRequest } from "../services/index";

const NotificationAlert = ({ onRequestClose }) => {
  // Use localStorage to persist notifications
  const getStoredAlerts = () => {
    try {
      const storedAlerts = localStorage.getItem('wiserbee_notifications');
      return storedAlerts ? JSON.parse(storedAlerts) : [];
    } catch (error) {
      console.error("Error retrieving stored notifications:", error);
      return [];
    }
  };

  // Initialize alerts from localStorage
  const [alerts, setAlerts] = useState(getStoredAlerts);

  // Update localStorage whenever alerts change and dispatch event
  useEffect(() => {
    localStorage.setItem('wiserbee_notifications', JSON.stringify(alerts));
    // Dispatch custom event to notify other components
    const event = new CustomEvent('notificationUpdate', { detail: { count: alerts.length } });
    window.dispatchEvent(event);
  }, [alerts]);

  // Function to send FCM token to backend
  const sendTokenToBackend = async (token) => {
    try {
      const response = await postRequest(
        `/api/Auth/update-firebase-token?fcmToken=${encodeURIComponent(token)}`, 
        {}
      );
      
      console.log("Token sent to backend:", response);

      if (response?.data?.success) {
        console.log("FCM Token successfully registered with backend:", response.data.message);
      } else {
        console.warn("Failed to register FCM token with backend:", response?.data?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error sending FCM token to backend:", error);
    }
  };

  // Format notification based on payload content
  const formatNotification = (payload) => {
    const { notification, data } = payload;
    
    // Determine notification type based on data or default to info
    let type = "info";
    if (data) {
      if (data.type === "error" || data.type === "danger") {
        type = "error";
      } else if (data.type === "warning" || data.type === "warn") {
        type = "warn";
      } else if (data.type === "success") {
        type = "success";
      }
    }
    
    return {
      id: Date.now(),
      icons: type,
      type: type,
      message: notification?.body || "New notification received",
      title: notification?.title || "Notification",
      timestamp: new Date().toLocaleString()
    };
  };

  useEffect(() => {
    // Define Firebase configuration
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Validate Firebase config
    if (!firebaseConfig.apiKey || !firebaseConfig.projectId || !firebaseConfig.messagingSenderId) {
      console.warn("Firebase config is missing or incomplete. Notifications may not work correctly.");
      return;
    }

    const setupFCM = async () => {
      try {
        // Register service worker first
        if ("serviceWorker" in navigator) {
          const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
          console.log("Service Worker registered:", registration);
        }

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);

        // Request notification permission
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          console.warn("Notification permission not granted.");
          return;
        }

        // Get FCM token
        const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
        if (!vapidKey) {
          console.warn("VAPID key is missing. FCM may not work correctly.");
          return;
        }

        const token = await getToken(messaging, { vapidKey });
        if (!token) {
          console.warn("No FCM token received.");
          return;
        }
        
        console.log("FCM Token:", token);
        
        // Send token to backend
        await sendTokenToBackend(token);
        
        // Listen for foreground messages
        const unsubscribe = onMessage(messaging, (payload) => {
          console.log("Foreground message received:", payload);
          
          // Format and add notification
          const newAlert = formatNotification(payload);
          setAlerts(prevAlerts => {
            const updatedAlerts = [newAlert, ...prevAlerts];
            localStorage.setItem('wiserbee_notifications', JSON.stringify(updatedAlerts));
            // Trigger notification update event
            const event = new CustomEvent('notificationUpdate', { detail: { count: updatedAlerts.length } });
            window.dispatchEvent(event);
            return updatedAlerts;
          });
        });

        // Add listener for service worker messages (for background notifications)
        navigator.serviceWorker.addEventListener('message', (event) => {
          console.log('Received message from service worker:', event.data);
          if (event.data.type === 'NOTIFICATION_RECEIVED') {
            const newAlert = formatNotification({
              notification: event.data.notification,
              data: event.data.notification.data
            });
            
            setAlerts(prevAlerts => {
              const updatedAlerts = [newAlert, ...prevAlerts];
              localStorage.setItem('wiserbee_notifications', JSON.stringify(updatedAlerts));
              // Trigger notification update event
              const event = new CustomEvent('notificationUpdate', { detail: { count: updatedAlerts.length } });
              window.dispatchEvent(event);
              return updatedAlerts;
            });
          }
        });
        
        return unsubscribe;
      } catch (error) {
        console.error("Error setting up FCM:", error);
      }
    };
    
    const unsubscribeFn = setupFCM();
    
    // Clean up
    return () => {
      if (typeof unsubscribeFn === 'function') {
        unsubscribeFn();
      }
    };
  }, []);

  // Function to clear all notifications
  const clearAllAlerts = () => {
    setAlerts([]);
    localStorage.removeItem('wiserbee_notifications');
    // Trigger notification update event
    const event = new CustomEvent('notificationUpdate', { detail: { count: 0 } });
    window.dispatchEvent(event);
  };

  // Function to remove a single notification
  const removeNotification = (id) => {
    setAlerts(prevAlerts => {
      const updatedAlerts = prevAlerts.filter(alert => alert.id !== id);
      localStorage.setItem('wiserbee_notifications', JSON.stringify(updatedAlerts));
      // Trigger notification update event
      const event = new CustomEvent('notificationUpdate', { detail: { count: updatedAlerts.length } });
      window.dispatchEvent(event);
      return updatedAlerts;
    });
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4">
  <div className="flex justify-center p-0 m-0">
    <div className="flex py-1 items-center px-4 justify-between w-full">
      <h3 className="font-semibold">Notifications</h3>
      <div className="bg-[#463c77] text-white rounded cursor-pointer p-1" onClick={onRequestClose}>
        <X size={18} />
      </div>
    </div>
  </div>
  
  <div className="mt-3 p-0 m-0 px-2">
    <div className="max-h-[50vh] overflow-y-auto pr-1">
      {alerts.length > 0 ? (
        alerts.map((alert) => (
          <PushNotification 
            key={alert.id} 
            alert={alert} 
            onRemove={() => removeNotification(alert.id)}
          />
        ))
      ) : (
        <div className="text-center m-auto py-4">No New Notifications</div>
      )}
    </div>
  </div>
  
  <div className="flex justify-end gap-2 px-2 mt-3">
    <button
      type="button"
      className="px-4 py-2 border border-gray-300 rounded-md bg-white hover:bg-gray-50"
      onClick={onRequestClose}
    >
      Cancel
    </button>
    <button
      type="button"
      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
      onClick={clearAllAlerts}
      disabled={alerts.length === 0}
    >
      Clear All
    </button>
  </div>
</div>
  );
};

export default NotificationAlert;