import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import AddEmployeeModal from "../../modals/Hrms/AddEmployeeModal";
import { uploadImage } from "../../utils";
import EmployeeDetailsCard from "../../modals/Hrms/EmployeeDetailsCard";

const FinalSettlement = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  const cardsData = [
    { title: "Total ", value: 0, icon: "👥", bgColor: "bg-green-100" },
    { title: "Pending", value: 0, icon: "🛡️", bgColor: "bg-blue-100" },
    { title: "Approved", value: 0, icon: "✍️", bgColor: "bg-purple-100" },
    { title: "Paid", value: 0, icon: "💸", bgColor: "bg-orange-100" },
    { title: "Rejected", value: 0, icon: "❌", bgColor: "bg-red-100" },
    { title: "Deleted", value: 0, icon: "✂️", bgColor: "bg-gray-300" },
  ];

  // const [employees, setEmployees] = useState([
    
  // ]);
  // const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  // const [currentPicture, setCurrentPicture] = useState(null);

  // const onSubmit = async (data, selectedFile) => {
  //   let fileUrl = null;
  //   if (selectedFile) {
  //     fileUrl = await uploadImage(selectedFile);
  //   } else if (isEdit) {
  //     fileUrl = currentPicture;
  //   }

  //   const newEmployee = {
  //     id: isEdit ? currentEmployeeId : Date.now(),
  //     firstName: data.firstName,
  //     lastName: data.lastName,
  //     fatherName: data.fatherName,
  //     gender: data.gender,
  //     maritalStatus: data.maritalStatus,
  //     email: data.email,
  //     phone: data.phone,
  //     location: data.location,
  //     cnic: data.cnic,
  //     dob: data.dob,
  //     emergencyContact: data.emergencyContact,
  //     contactRelationShip: data.contactRelationShip,
  //     address: data.address,
  //     religion: data.religion,
  //     profilePicture: fileUrl || "https://via.placeholder.com/150",
  //     isActive: data.isActive,
  //   };

  //   if (isEdit) {
  //     setEmployees(
  //       employees.map((emp) =>
  //         emp.id === currentEmployeeId ? newEmployee : emp
  //       )
  //     );
  //   } else {
  //     setEmployees([...employees, newEmployee]);
  //   }

  //   reset();
  //   setIsModalOpen(false);
  //   setIsEdit(false);
  // };

  // const handleEdit = (id) => {
  //   const employee = employees.find((emp) => emp.id === id);
  //   if (!employee) {
  //     console.error("Employee not found for ID:", id);
  //     return;
  //   }

  //   // Pre-fill the form fields for editing
  //   setValue("firstName", employee.firstName);
  //   setValue("lastName", employee.lastName);
  //   setValue("fatherName", employee.fatherName);
  //   setValue("gender", employee.gender);
  //   setValue("maritalStatus", employee.maritalStatus);
  //   setValue("email", employee.email);
  //   setValue("phone", employee.phone);
  //   setValue("location", employee.location);
  //   setValue("cnic", employee.cnic);
  //   setValue("dob", employee.dob);
  //   setValue("emergencyContact", employee.emergencyContact);
  //   setValue("contactRelationShip", employee.contactRelationShip);
  //   setValue("address", employee.address);
  //   setValue("religion", employee.religion);
  //   setValue("isActive", employee.isActive);

  //   // Set the current employee ID for the edit operation
  //   setCurrentEmployeeId(id);
  //   setCurrentPicture(employee.profilePicture);
  //   setIsEdit(true);
  //   setIsModalOpen(true);
  // };

  // const handleDelete = (id) => {
  //   setEmployees(employees.filter((emp) => emp.id !== id));
  //   toast.success("Employee deleted successfully");
  // };

  // const toggleView = (index) => {
  //   setExpandedRowIndex(expandedRowIndex === index ? null : index);
  // };

  // const filteredEmployees = employees.filter((employee) => {
  //   const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
  //   const searchMatch =
  //     fullName.includes(searchTerm.toLowerCase()) ||
  //     employee.id.toString().includes(searchTerm);
  //   const locationMatch =
  //     locationFilter === "" || employee.location === locationFilter;
  //   return searchMatch && locationMatch;
  // });

  // Extract unique locations for the dropdown
  // const uniqueLocations = [
  //   ...new Set(employees.map((employee) => employee.location)),
  // ];

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-4 rounded shadow`}
          >
            <div className="flex flex-col space-y-2">
              <span className="text-2xl font-bold">{card.value}</span>
              <span className="text-sm text-gray-700">{card.title}</span>
            </div>
            <div className={`text-xl p-2 rounded-full ${card.bgColor}`}>
              {card.icon}
            </div>
          </div>
        ))}
      </div>

      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">Start Date</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">End Date</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Departments</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Designation</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Locations</option>
          {/* Add location options */}
        </select>
     
      </div>


      {/* Modal for Adding/Editing employee */}
      {/* <AddEmployeeModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        setValue={setValue}
        reset={reset}
        currentPicture={currentPicture}
      /> */}

      <div className="overflow-x-auto overflow-visible">
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>Emp. Code</th>
                <th>Emp. Name</th>
                <th>Leaving Date</th>
                <th>Total Payable</th>
                <th>Overall Status</th>
                <th>Hr Status</th>
                <th>Finance Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-sm">
             
            </tbody>
          </table>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default FinalSettlement;

