import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useChild } from "../../../ContextAPI/ChildContext";
import { getRequest } from "../../../services/index";

const Chart = () => {
  const { selectedChildId } = useChild();
  const [performanceData, setPerformanceData] = useState([]);
  const [performanceYear, setPerformanceYear] = useState(null);
  const [studentName, setStudentName] = useState(null);

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        const response = await getRequest(`/api/Parent/getPerformance?studentId=${selectedChildId}`);
        setPerformanceData(response.data.data.attendanceAnalytics);
        setPerformanceYear(response.data.data.attendanceAnalytics[0].year);
        setStudentName(response.data.data.name);
      } catch (error) {
        console.error("Error fetching performance data:", error);
      }
    };

    if (selectedChildId) {
      fetchPerformanceData();
    }
  }, [selectedChildId]);
  
  return (
    <>
      <div className="d-flex justify-content-between ">
        <div>
          <h4>Student Performance</h4>
          <p className="text-secondary">{performanceYear}</p>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="dotGraph"></div>
          <h4 className="m-0 p-0 fs-6">{studentName}</h4>
        </div>
      </div>
      <div style={{ height: "300px", width: "100%" }}>
        <ResponsiveContainer>
          <LineChart data={performanceData}>
            <Line
              type="monotone"
              dataKey="attendancePercentage"
              stroke="#241763"
              strokeWidth={4}
              dot={{ fill: "white" }}
            />
            <XAxis dataKey="month" />
            <YAxis ticks={[0, 20, 40, 60, 80, 100]} />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Chart;
