import React, { useState } from "react";

const EmployeeDetailsCard = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("Employment");

  // Tab data for dynamic rendering
  const tabs = ["Employment", "Leaves", "Attendance", "Financial"];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* Personal Details Section */}
      <div className="bg-white col-span-1 rounded-lg shadow p-4">
        <div className="flex items-center space-x-4">
          <img
            src="https://via.placeholder.com/100"
            alt="User avatar"
            className="w-20 h-20 rounded-full"
          />
          <div>
            <h2 className="text-xl font-semibold">Luke Micheal</h2>
            <p className="text-sm text-gray-500">0 Year Completed</p>
            <p className="text-sm text-blue-500">46% Profile Completed</p>
          </div>
        </div>
        <hr className="my-4" />
        <h3 className="font-bold text-lg">Personal Details</h3>
        <ul className="text-sm text-gray-600 space-y-2 mt-2">
          <li>Father Name: Micheal Kings</li>
          <li>Email: luk.m@gmail.com</li>
          <li>Date Of Birth: 2030-03-12</li>
          <li>Gender: Male</li>
          <li>Religion: Christian</li>
          <li>Contact: 0332-4343434</li>
          <li>City: Sydney</li>
          <li>Country: Pakistan</li>
        </ul>
        <div className="mt-4 flex gap-2">
          <button className="bg-blue-500 text-white px-4 py-2 rounded">Register</button>
          <button className="bg-orange-500 text-white px-4 py-2 rounded">Suspend</button>
          <button className="bg-red-500 text-white px-4 py-2 rounded">Resign</button>
        </div>
        <div className="mt-4 bg-gray-100 p-4 rounded">
          <p className="text-blue-500 font-bold">Salary</p>
          <p className="text-xl font-semibold">Rs.0/month</p>
        </div>
        <p className="mt-2 text-gray-700">Job Description</p>
      </div>

      {/* Details Section with Tabs */}
      <div className="bg-white col-span-2 h-auto rounded-lg shadow p-4">
        <h3 className="font-bold text-lg mb-4">Details</h3>

        {/* Tabs */}
        <div className="flex border-b mb-4 ">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 text-sm font-medium focus:outline-none ${
                activeTab === tab
                  ? "text-[#241763] border-b-2 border-[#241763]"
                  : "text-gray-500 hover:text-[#241763]"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="p-2">
          {activeTab === "Employment" && (
            <div>
              <h3 className="font-bold text-lg">Employment Details</h3>
              <div className="grid grid-cols-2 text-sm text-gray-600 gap-4 mt-2">
              <div>
              <li>Employee Code: SAS-016</li>
                <li>Department: Not Assigned</li>
                <li>Reporting To: Not Assigned</li>
                <li>Joining Date: -</li>
                <li>Probation End: -</li>
              </div>
              <div>
              <li>Blood Group: AB-</li>
                <li>CNIC: 34543-4445345-3</li>
                <li>CNIC Expiry: 2030-03-04</li>
                <li>Phone Number: 0334-5344554</li>
                <li>Complete Address: Flat 36, New York, Sydney</li>
              </div>
              </div>
              <button className="mt-4 bg-purple-500 text-white px-4 py-2 rounded">CV Builder</button>
            </div>
          )}

          {activeTab === "Leaves" && (
            <div>
              <h3 className="font-bold text-lg">Leave Details</h3>
              <ul className="text-sm text-gray-600 space-y-2 mt-2">
                <li>Annual Leaves: 10</li>
                <li>Sick Leaves: 5</li>
                <li>Casual Leaves: 3</li>
                <li>Remaining Leaves: 7</li>
              </ul>
            </div>
          )}

          {activeTab === "Attendance" && (
            <div>
              <h3 className="font-bold text-lg">Attendance Details</h3>
              <ul className="text-sm text-gray-600 space-y-2 mt-2">
                <li>Present Days: 20</li>
                <li>Absent Days: 2</li>
                <li>Late Arrivals: 1</li>
                <li>Early Departures: 0</li>
              </ul>
            </div>
          )}

          {activeTab === "Financial" && (
            <div>
              <h3 className="font-bold text-lg">Financial Details</h3>
              <ul className="text-sm text-gray-600 space-y-2 mt-2">
                <li>Salary: Rs. 50,000/month</li>
                <li>Bonuses: Rs. 5,000</li>
                <li>Tax Deduction: Rs. 2,000</li>
                <li>Net Salary: Rs. 53,000</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsCard;
