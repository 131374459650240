import React from "react";
 
import WelcomeSection from "../Components/Common/WelcomeSection";
import { useLocation } from "react-router-dom";
import { ChildProvider } from "../ContextAPI/ChildContext";

const ParentLayout = ({ children }) => {
  const location = useLocation();
  const pathName = location.pathname;
  const hideWelcomeSection = pathName === "/account-setting";
  return (
    <ChildProvider>
        {hideWelcomeSection ? null : <WelcomeSection />}

      {children}
    </ChildProvider>
  );
};

export default ParentLayout;