import React from "react";

const ApprovalBonusModal = ({ isOpen, onClose, onApprove }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm p-4">
        <h2 className="text-lg font-medium mb-4">
          Are you sure you want to approve the Bonus for the selected employee?
        </h2>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
          >
            No
          </button>
          <button
            onClick={onApprove}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalBonusModal;


