import React, { useEffect, useState } from "react";
import { Pencil } from "lucide-react";
import { fetchSalaries, updateSalary } from "../../../redux/salarySlice";
import { useDispatch, useSelector } from "react-redux";
import HRApprovalModal from "../../../modals/payroll/HRApprovalModal";

function HrApproval() {
  const dispatch = useDispatch();
  const { salaries, status } = useSelector((state) => state.salaries);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSalary, setSelectedSalary] = useState(null);

  useEffect(() => {
    dispatch(fetchSalaries());
  }, [dispatch]);

  const handleOpenModal = (salary) => {
    setSelectedSalary(salary);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSalary(null);
  };

  const handleUpdateStatus = (newStatus) => {
    if (selectedSalary) {
      dispatch(
        updateSalary({
          id: selectedSalary.id,
          updatedData: { hrApproval: newStatus },
        })
      );
      handleCloseModal();
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">HR Approval</h2>

      <div className="overflow-x-auto">
      <table className="table-auto w-full mt-2 text-sm">
          <thead className="border-b font-bold">
            <tr className="border-b bg-[#241763]/10">
              <th className="p-2">Emp ID</th>
              <th>Session</th>
              <th>T. Days</th>
              <th>P</th>
              <th>A</th>
              <th>Late</th>
              <th>Leaves</th>
              <th>OT Hours</th>
              <th>B. Salary</th>
              <th>Deduction</th>
              <th>Total Salary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {salaries.length > 0 ? (
              salaries.map((salary, index) => (
                <React.Fragment key={salary.id}>
                  <tr className="hover:bg-gray-200">
                    <td>{salary.employeeId}</td>
                    <td>{salary.session}</td>
                    <td>{salary.totalDays}</td>
                    <td>{salary.present}</td>
                    <td>{salary.absent}</td>
                    <td>{salary.late}</td>
                    <td>{salary.leaves}</td>
                    <td>{salary.overtimeHours}</td>
                    <td>{salary.baseSalary}</td>
                    <td>{salary.deductionAmount}</td>
                    <td>{salary.totalSalary}</td>
                    <td className="space-x-2 flex items-center py-2">
                    <button
                      onClick={() => handleOpenModal(salary)}
                      className={`px-3 py-1 rounded text-white ${
                        salary.hrApproval === "Approved"
                          ? "bg-green-500"
                          : salary.hrApproval === "Rejected"
                          ? "bg-red-500"
                          : "bg-yellow-500"
                      }`}
                    >
                      {salary.hrApproval || "Pending"}
                    </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center p-4">
                  No Salary Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <HRApprovalModal
          salary={selectedSalary}
          onClose={handleCloseModal}
          onUpdateStatus={handleUpdateStatus}
        />
      )}
    </div>
  );
}

export default HrApproval;
