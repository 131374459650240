// src/redux/interviewSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "http://localhost:5001/api/interviews";

// Async thunks
export const fetchInterviews = createAsyncThunk(
  "interviews/fetchInterviews",
  async () => {
    const response = await axios.get(API_URL);
    return response.data;
  }
);

export const fetchInterviewsByCandidate = createAsyncThunk(
    "interviews/fetchInterviewsByCandidate",
    async (candidateId, { dispatch, getState }) => {
      try {
        const response = await axios.get(`${API_URL}/candidate/${candidateId}`);
        return response.data;
      } catch (error) {
        // If interviews don't exist, create them
        if (error.response && error.response.status === 404) {
          dispatch(createInterviewsForCandidate(candidateId));
        }
        throw error;
      }
    }
  );

export const scheduleInterview = createAsyncThunk(
  "interviews/scheduleInterview",
  async ({ id, data }) => {
    const response = await axios.patch(`${API_URL}/${id}/schedule`, data);
    return response.data;
  }
);

export const updateInterviewStatus = createAsyncThunk(
  "interviews/updateInterviewStatus",
  async ({ id, status }) => {
    const response = await axios.patch(`${API_URL}/${id}/status`, { status });
    return response.data;
  }
);

export const hireCandidate = createAsyncThunk(
  "interviews/hireCandidate",
  async (candidateId) => {
    const response = await axios.patch(`${API_URL}/hire/${candidateId}`);
    return response.data;
  }
);

export const createInterviewsForCandidate = createAsyncThunk(
  "interviews/createInterviewsForCandidate",
  async (candidateId) => {
    // Create the three interview stages for a candidate
    const stages = ["first", "second", "final"];
    const interviews = [];
    
    for (const stage of stages) {
      const response = await axios.post(API_URL, {
        candidateId,
        stage,
        status: "Not Scheduled"
      });
      interviews.push(response.data);
    }
    
    return interviews;
  }
);

// Initial state
const initialState = {
  interviews: [],
  loading: false,
  error: null,
  candidateInterviews: {}
};

const interviewSlice = createSlice({
  name: "interviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInterviews.fulfilled, (state, action) => {
        state.loading = false;
        state.interviews = action.payload;
      })
      .addCase(fetchInterviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchInterviewsByCandidate.fulfilled, (state, action) => {
        const candidateId = action.meta.arg;
        state.candidateInterviews[candidateId] = action.payload;
      })
      .addCase(scheduleInterview.fulfilled, (state, action) => {
        const updatedInterview = action.payload;
        const index = state.interviews.findIndex(i => i.id === updatedInterview.id);
        if (index !== -1) {
          state.interviews[index] = updatedInterview;
        }
        
        // Update in candidate interviews if it exists
        const candidateId = updatedInterview.candidateId;
        if (state.candidateInterviews[candidateId]) {
          const idx = state.candidateInterviews[candidateId].findIndex(i => i.id === updatedInterview.id);
          if (idx !== -1) {
            state.candidateInterviews[candidateId][idx] = updatedInterview;
          }
        }
      })
      .addCase(updateInterviewStatus.fulfilled, (state, action) => {
        const updatedInterview = action.payload;
        const index = state.interviews.findIndex(i => i.id === updatedInterview.id);
        if (index !== -1) {
          state.interviews[index] = updatedInterview;
        }
        
        // Update in candidate interviews if it exists
        const candidateId = updatedInterview.candidateId;
        if (state.candidateInterviews[candidateId]) {
          const idx = state.candidateInterviews[candidateId].findIndex(i => i.id === updatedInterview.id);
          if (idx !== -1) {
            state.candidateInterviews[candidateId][idx] = updatedInterview;
          }
        }
      })
      .addCase(createInterviewsForCandidate.fulfilled, (state, action) => {
        const newInterviews = action.payload;
        state.interviews = [...state.interviews, ...newInterviews];
        
        // Also update candidate interviews
        const candidateId = newInterviews[0].candidateId;
        state.candidateInterviews[candidateId] = newInterviews;
      });
  }
});

export default interviewSlice.reducer;