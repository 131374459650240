import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/timeAdjustment`;

// Fetch Time Adjustments
export const fetchTimeAdjustments = createAsyncThunk(
  "timeAdjustments/fetch",
  async () => {
    const response = await axios.get(API_URL);
    return response.data;
  }
);

// Update Time Adjustment Status
export const updateTimeAdjustmentStatus = createAsyncThunk(
    "timeAdjustments/updateStatus",
    async ({ id, newStatus }) => {
      const response = await axios.put(`${API_URL}/${id}/hr-status`, { hrStatus: newStatus });
      return response.data;
    }
  );
  

const timeAdjustmentSlice = createSlice({
  name: "timeAdjustments",
  initialState: {
    adjustments: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeAdjustments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTimeAdjustments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adjustments = action.payload;
      })
      .addCase(fetchTimeAdjustments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateTimeAdjustmentStatus.fulfilled, (state, action) => {
        const index = state.adjustments.findIndex(
          (adj) => adj.id === action.payload.id
        );
        if (index !== -1) {
          state.adjustments[index] = action.payload;
        }
      });
  },
});

export default timeAdjustmentSlice.reducer;
