import React, { useState } from "react";
import { X } from "lucide-react";
import { observation } from "../../Constant/observation";
import FlexBtn from "../../Components/Common/FlexBtn";

const TeachersObservation = ({ onRequestClose }) => {
  // Form state for each observation
  const [formData, setFormData] = useState(
    observation.map(() => ({ selectedOption: "", comment: "" }))
  );

  const handleOptionChange = (index, option) => {
    setFormData((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, selectedOption: option } : item
      )
    );
  };

  const handleCommentChange = (index, comment) => {
    setFormData((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, comment } : item
      )
    );
  };

  const handleSubmit = () => {
    console.log("Form Data:", formData);
    // Add save logic here
    onRequestClose();
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4 position-relative">
      <div className="row bg-primary">
        <div className="col-md-12 bg-secondary">
          <div className="observationModalheader bgMain d-flex align-items-center w-100 p-3 justify-content-between text-white">
            <h6 className="m-0 p-0">Teacher's Observation</h6>
            <X className="cursor-pointer" onClick={onRequestClose} />
          </div>
        </div>
      </div>

      <div className="row px-3 observationContent modalBody">
        <div className="col-md-12 px-3 py-3 ">
          {observation.map((obs, index) => (
            <div className="row mb-3" key={index}>
              <div className="col-md-3">
                <label className="observationText fw-bold">{obs.title}</label>
              </div>
              <div className="col-md-9">
                <div className="observationOptions d-flex flex-column gap-2 justify-content-center">
                  <div className="options d-flex gap-2 align-items-center">
                    {obs.options.map((item, i) => (
                      <div
                        className="form-check align-items-center d-flex gap-2"
                        key={i}
                      >
                        <input
                          className="form-check-input reviewForm"
                          type="radio"
                          name={`observation-${index}`}
                          id={`observation-${index}-${i}`}
                          value={item}
                          checked={formData[index]?.selectedOption === item}
                          onChange={() => handleOptionChange(index, item)}
                        />
                        <label
                          className="form-check-label formTeacherRep"
                          htmlFor={`observation-${index}-${i}`}
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write your comments here"
                    value={formData[index]?.comment}
                    onChange={(e) =>
                      handleCommentChange(index, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="observationFooter d-flex justify-content-end px-3 py-2">
        <div className="d-flex gap-3 justify-content-end">
          <FlexBtn
            label="Cancel"
            color="#463C77"
            bgColor="#EDEBF1"
            handleClick={onRequestClose}
          />
          <FlexBtn
            label="Save"
            color="white"
            bgColor="#241763"
            style={{ fontSize: "14px" }}
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default TeachersObservation;
