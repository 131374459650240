import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { users as usersAnalytics } from "../Constant/usersChild";
import { deleteRequest, getRequest, postRequest, updateRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  parents: [],
  users: usersAnalytics,
  loading: false,
  error: null,
  pageSize: 100, // Default pageSize
  totalCount: 10,
  pageNumber: 1,
};

export const fetchParents = createAsyncThunk(
  "parents/fetchParents",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await getRequest(
        `/api/Parent?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      if (!response.status === 200) {
        return rejectWithValue("Failed to fetch parents");
      }
      return response.data?.data; // Include pagination data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteParent = createAsyncThunk(
  "parents/deletedParent",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/User/DeleteUser/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addParent = createAsyncThunk(
  "parents/addedParent",
  async (parent, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/Parent", parent);
      if (response.status !== 200) {
        return rejectWithValue("Failed to add parent");
      }
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateParent = createAsyncThunk(
  "parents/updateParent",
  async ({ id, updatedParent }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/Parent?id=${id}`, updatedParent);
      if (response.status !== 200 || !response.data?.data) {
        throw new Error("Failed to update parent");
      }
      return response.data?.data; // Return the updated parent object
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const auth = JSON.parse(localStorage.getItem("user"));
      const userId = auth.userId;
      const response = await getRequest(`/api/Parent/${userId}`);
      const data = response.data?.data?.childerns || [];
      
      const formattedUsers = data.map((child, index) => ({
        id: child.id,
        name: child.name,
        avatar: child.profilePicture,
        class: `class ${index}`,
        percentage: "0",
        isActive: index === 0,
        singleData: Array.from({ length: 12 }, (_, i) => ({
          month: new Date(0, i).toLocaleString("en", { month: "short" }),
          percentage: 0,
        })),
        fees: [
          {
            name: "Jennifer Markus",
            id: "01234",
            payable: "1,500.00",
            paid: "500.00",
            pending: "3,500.00",
            description: "Fees",
          },
          {
            name: "Jennifer Markus",
            id: "01234",
            payable: "4,500.00",
            paid: "5,500.00",
            pending: "6,500.00",
            description: "Fine",
          },
          {
            name: "Jennifer Markus",
            id: "01234",
            payable: "4,500.00",
            paid: "2,500.00",
            pending: "2,500.00",
            description: "Charges",
          },
        ],
        graphData: Array.from({ length: 12 }, (_, i) => ({
          month: new Date(0, i).toLocaleString("en", { month: "short" }),
          assesment: Math.floor(Math.random() * 100),
          attendance: Math.floor(Math.random() * 100),
          performance: Math.floor(Math.random() * 100),
        })),
        pieData: [
          { name: "English", value: 400 },
          { name: "Maths", value: 300 },
          { name: "Biology", value: 300 },
          { name: "Physics", value: 200 },
        ],
      }));

      return formattedUsers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const ParentSlice = createSlice({
  name: "parents",
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    addParentLocally: (state, action) => {
      state.parents.push(action.payload);
    },
    setUser: (state, action) => {
      state.users = state.users.map((user, index) => ({
        ...user,
        isActive: index === action.payload,
      }));
    },
    updateParent: (state, action) => {
      const index = state.parents.findIndex((t) => t.id === action.payload.id);
      if (index !== -1) {
        state.parents[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteParent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteParent.fulfilled, (state, action) => {
        state.loading = false;
        state.parents = state.parents.filter(
          (parent) => parent.id !== action.payload
        );
      })
      .addCase(deleteParent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchParents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchParents.fulfilled, (state, action) => {
        state.loading = false;
        state.parents = action.payload.parents;
        state.totalCount = action.payload.totalCount;
        state.pageNumber = action.payload.pageNumber;
        state.pageSize = action.payload.pageSize;
      })
      .addCase(fetchParents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addParent.pending, (state) => {
        state.loading = true;
      })
      .addCase(addParent.fulfilled, (state, action) => {
        state.loading = false;
        state.parents.push(action.payload);
        toast.success("Parent added successfully!");
      })
      .addCase(addParent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateParent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateParent.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.parents.findIndex(
          (parent) => parent.id === action.payload.id
        );
        if (index !== -1) {
          state.parents[index] = action.payload; // Update the parent in the state
        }
        toast.success("Parent updated successfully!");
      })
      .addCase(updateParent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update parent due to missing ID.";
      });

  },
});

export const { setPageNumber, setUser } = ParentSlice.actions;

export default ParentSlice.reducer;
