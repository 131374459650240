import React from 'react';

const step3 = () => {
  const generatedQuestions = {
    questionsPerLine: 2,
    answerPerLine: 4,
    marksPerQuestion: 3,
    questions: [
      {id: '1', question: "Write a note on circumstances which led to the birth of Pakistan. Your note should not exceed 200 words."},
      {id: '2', question: "To Liaquat Ali Khan, the demand for Pakistan was the most reasonable and entirely practicable."},
      {id: '3', question: "What kind of similarity occurs between the beginning of Pakistan and the early days of America as pointed out by Liaquat Ali Khan?"},
      {id: '4', question: "How can America contribute to the development of Pakistan?"},
      {id: '5', question: "Do you think that Liaquat Ali Khan laid down the outline of Pakistan's foreign policy by visiting America in 1950?"},
      {id: '6', question: "The creation of Pakistan has by itself dissolved what would have been a perpetual danger zone in Asia. Write a note on it."},
      {id: '7', question: "What are the views of Ali Khan on freedom?"},
      {id: '8', question: "Describe in brief the writer's comparison of the situation in England before, during, and after the solar eclipse."},
      {id: '9', question: "Describe the experience and feelings of the people before and during the solar eclipse."},
      {id: '10', question: "Describe the excitement and thrill of people going north."},
    ]
  };

  return (
    <div>
      <ol className="flex flex-wrap p-0">
        {generatedQuestions.questions.map((questionObj) => (
          <li key={questionObj.id} className={`${generatedQuestions.questionsPerLine === 2 ? 'w-1/2' : 'w-full'} list-none`}>
            <div className='h-[15vh] ml-5'>
              <h3>{questionObj.question} ({generatedQuestions.marksPerQuestion} marks)</h3>
            </div>
            {Array.from({ length: generatedQuestions.answerPerLine }).map((_, i) => (
              <div key={i} className="border-b border-black mb-2.5 py-2 w-90 mx-auto"></div>
            ))}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default step3;