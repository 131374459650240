import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import NcAssementModal from "../../../modals/admin/NcAssementModal";

function TeacherObservation({ ncCognitive, setNcCognitive }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const handleAddNew = () => {
    setEditingData(null);
    setIsModalOpen(true);
  };

  const handleSave = (data) => {
    if (editingData) {
      const updated = ncCognitive.map((item, index) =>
        index === editingData.index ? data : item
      );
      setNcCognitive(updated);
    } else {
      setNcCognitive([...ncCognitive, data]);
    }
    setIsModalOpen(false);
    setEditingData(null);
  };

  const handleEdit = (index) => {
    setEditingData({ ...ncCognitive[index], index });
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    setNcCognitive(ncCognitive.filter((_, i) => i !== index));
  };


  return (
    <div>
      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr className="bg-gray-100 border-b">
              <th>Student ID</th>
              <th>Student Name</th>
              <th>Class</th>
              <th>Section</th>
              <th>Subject</th>
              <th>Teacher Name</th>
              <th>Observation Report</th>
              <th>Actions</th>
            </tr>
          </thead>
          {/* <tbody className="text-sm">
            {ncCognitive.length > 0 ? (
              ncCognitive.map((survey, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{survey.surveyName}</td>
                    <td>{survey.audience}</td>
                    <td>{survey.campus}</td>
                    <td>{survey.cls}</td>
                    <td>-</td>{" "}
                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(index)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(index)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                    </td>
                  </tr>
               
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Surveys Found
                </td>
              </tr>
            )}
          </tbody> */}
        </table>
      </div>
    </div>
  );
}

export default TeacherObservation;
