import React from 'react'

const CashDistributionList = () => {
  return (
    <div>
      kjkjjl
    </div>
  )
}

export default CashDistributionList
