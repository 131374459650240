import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { postRequest } from "../services";
import { message } from 'antd';

const ViewMcQsStudent = (props) => {
  const { onRequestClose, questionsData, selectedExamId, fetchDataExam} = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(questionsData[currentIndex].time * 60); // Convert to seconds
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const currentQuestion = questionsData[currentIndex];

  useEffect(() => {
    if (isTimerRunning && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleTimeOut();
    }
  }, [timeLeft, isTimerRunning]);

  const handleOptionSelect = (option) => {
    setUserAnswers({
      ...userAnswers,
      [currentQuestion.id]: option,  // Store the selected option for this question
    });
  };

  const handleTimeOut = () => {
    if (currentIndex + 1 < questionsData.length) {
      setCurrentIndex((prev) => prev + 1);
      setTimeLeft(questionsData[currentIndex + 1].time * 60); // Convert next question time to seconds
    } else {
      onSubmit();
    }
  };

  const onSubmit = () => {
    const payload = Object.entries(userAnswers).map(([examId, selectedOption]) => ({
      examId: selectedExamId,  
      questionId: examId,
      selectedoption: selectedOption
    }));
    fetchData(payload);
  };
  
  const fetchData = async (payload) => {
    try {
      const response = await postRequest(`/api/Submissions/submit-mcq`, payload);
      if (response.status === 200 && response.data.success) {
        message.success(response.data.message || "McQs submitted successfully");
        onRequestClose();
        fetchDataExam();
      } else {
        message.error("Failed to submit answers");
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };
  

  if (!questionsData || questionsData.length === 0) {
    return <div>No questions available.</div>;
  }

  return (
    <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">Question Details</h4>
            <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
              <X />
            </div>
          </div>
        </div>
      </div>
      <div className="modalBody">
        <div className="row px-4 m-0 gap-5 mt-4">
          <div className="col-md-4 m-0 p-0">
            <label>{currentIndex + 1}/{questionsData.length}</label>
            <div className="questionBarProgress">
              {questionsData.map((_, index) => (
                <div className={`dot ${index <= currentIndex ? "dotWhite" : ""}`} key={index}></div>
              ))}
              <div
                className="progressBar"
                style={{ width: `${((currentIndex + 1) / questionsData.length) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className="col-md-7 d-flex justify-content-between align-items-end">
            <p>Marks: {currentQuestion.marks}</p>
            <p>Time Left: {timeLeft}s</p>
          </div>
        </div>
        <div className="row px-4 mt-5">
          <div className="col-md-12 mb-4">
            <p className="fw-bold">{currentQuestion.question}</p>
          </div>

          {["A", "B", "C", "D"].map((opt, index) => {
            const optionValue = currentQuestion[`option${opt}`];
            const isChecked = userAnswers[currentQuestion.id] === opt;

            return (
              <div className="col-md-6 mb-4" key={index}>
                <div className="input-group">
                  <div className="input-group-text">
                    <input
                      className="form-check-input mt-0"
                      type="radio"
                      name={`radioGroup-${currentIndex}`}
                      checked={isChecked}
                      onChange={() => handleOptionSelect(opt)}
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={optionValue}
                    readOnly
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row px-4 m-0 mt-3">
        <div className="col-md-12 d-flex justify-content-end gap-3">
          <button
            className="btn btn-primary px-4 py-2"
            onClick={() => {
              if (currentIndex + 1 === questionsData.length) {
                onSubmit(); // Trigger submission on Finish
              } else {
                setCurrentIndex((prev) => Math.min(prev + 1, questionsData.length - 1));
                setTimeLeft(questionsData[currentIndex + 1].time * 60);
              }
            }}
          >
            {currentIndex + 1 === questionsData.length ? "Finish" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewMcQsStudent;
