import React, { useState, useEffect } from 'react';
import { X } from "lucide-react";
import Counter from "../../Components/Common/Counter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FlexBtn from "../../Components/Common/FlexBtn";
import { updateRequest } from "../../services";
import { message } from "antd";

const UpdateQuestionModal = ({ onRequestClose, updateData, fetchData, questionID }) => {
    const [click, setClick] = useState(0);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language;

    // Transform updateData to required format
    const transformedData = updateData?.map(item => ({
        id: item.id,
        question: item.question,
        correctOption: item.correctOption,
        marks: item.marks,
        time: item.time,
        mcqs: [
            { id: 'A', answer: item.optionA, isChecked: item.correctOption === 'A' },
            { id: 'B', answer: item.optionB, isChecked: item.correctOption === 'B' },
            { id: 'C', answer: item.optionC, isChecked: item.correctOption === 'C' },
            { id: 'D', answer: item.optionD, isChecked: item.correctOption === 'D' }
        ]
    })) || [];

    const [questionData, setQuestionData] = useState(transformedData);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [question, setQuestion] = useState("");
    const [mcqs, setMcqs] = useState([]);
    const [correctOption, setCorrectOption] = useState("A");
    const [myMarks, setMyMarks] = useState(0);

    const totalQuestions = questionData.length;
    const percentage = ((currentQuestionIndex + 1) * 100) / totalQuestions;

    // Load question data when currentQuestionIndex changes
    useEffect(() => {
        if (questionData[currentQuestionIndex]) {
            const currentQuestion = questionData[currentQuestionIndex];
            setQuestion(currentQuestion.question);
            setMcqs(currentQuestion.mcqs);
            setCorrectOption(currentQuestion.correctOption);
            setMyMarks(currentQuestion.marks);
        }
    }, [currentQuestionIndex, questionData]);

    const saveCurrentQuestion = () => {
        const updatedData = [...questionData];
        updatedData[currentQuestionIndex] = {
            ...updatedData[currentQuestionIndex],
            question,
            mcqs,
            correctOption,
            marks: myMarks
        };
        setQuestionData(updatedData);
    };
    console.log(questionData);

    const handleClickNext = async (status) => {
        if (status === "Next") {
            setClick(prev => prev + 1);
            saveCurrentQuestion();
            if (currentQuestionIndex < totalQuestions - 1) {
                setCurrentQuestionIndex(prev => prev + 1);
            }
        } else if (status === "Save" || status === "Publish") {
            saveCurrentQuestion();
            try {
                const payload = questionData.map(item => ({
                    id: item.id,
                    examId: questionID,
                    question: item.question,
                    optionA: item.mcqs[0].answer,
                    optionB: item.mcqs[1].answer, 
                    optionC: item.mcqs[2].answer,
                    optionD: item.mcqs[3].answer,
                    correctOption: item.correctOption,
                    marks: item.marks,
                    time: item.time
                }));

                const response = await updateRequest(
                    `/api/Exam/update-mcqs`,
                    payload
                );
                if (response.data.success) {
                    if (status === "Publish") {
                        await updateRequest(`/api/Exam/examStatus?id=${questionID}`);
                        message.success("Exam is published successfully");
                    }
                    else {
                        message.success(response.data.message || "Exam save successfully");
                    }
                    onRequestClose();
                    fetchData();
                } else {
                    message.error("Failed to save the exam");
                }
            } catch (error) {
                message.error("An error occurred while saving the exam");
            }
        }

    };

    const handleClickPrevious = () => {
        setClick(prev => prev - 1);
        saveCurrentQuestion();
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prev => prev - 1);
        }
    };

    const handleOptionSelect = (selectedId) => {
        setCorrectOption(selectedId);
        setMcqs(prev => prev.map(mcq => ({
            ...mcq,
            isChecked: mcq.id === selectedId
        })));
    };

    const handleOptionChange = (index, e) => {
        setMcqs(prev => {
            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                answer: e.target.value
            };
            return updated;
        });
    };

    const handleCounterClick = (counter, actionType) => {
        const updatedData = [...questionData];
        const currentQuestion = updatedData[currentQuestionIndex];

        if (counter === 'marks') {
            if (actionType === 'increment') {
                setMyMarks(prev => prev + 1);
                currentQuestion.marks++;
            } else if (actionType === 'decrement') {
                setMyMarks(prev => Math.max(0, prev - 1));
                currentQuestion.marks = Math.max(0, currentQuestion.marks - 1);
            }
        } else if (counter === 'time') {
            if (actionType === 'increment') {
                currentQuestion.time++;
            } else if (actionType === 'decrement') {
                currentQuestion.time = Math.max(0, currentQuestion.time - 1);
            }
        }
        setQuestionData(updatedData);
    };

    return (
        <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
            <div className="row d-flex justify-content-center p-0 m-0">
                <div className="col-md-12 examModalWrapper p-0 m-0">
                    <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
                        <h4 style={{ color: "#060317" }} className="fw-bold">
                            Question Details
                        </h4>
                        <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
                            <X />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row px-4 m-0 gap-5 mt-4">
                <div className="col-md-4 m-0 p-0">
                    <div className="w-100 d-flex flex-column gap-2 justify-content-center">
                        <label>
                            {currentQuestionIndex + 1}/{totalQuestions}
                        </label>
                        <div className="questionBarProgress">
                            {Array.from({ length: totalQuestions }).map((_, index) => (
                                <div
                                    className={`dot ${index <= currentQuestionIndex ? "dotWhite" : ""}`}
                                    key={index}
                                ></div>
                            ))}
                            <div className="progressBar" style={{ width: `${percentage}%` }}></div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>Marks</label>
                        <Counter
                            value={myMarks}
                            isFullWidth={true}
                            counter="question"
                            handleClick={(actionType) => handleCounterClick('marks', actionType)}
                        />
                    </div>
                </div>

                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>Time</label>
                        <Counter
                            value={questionData[currentQuestionIndex]?.time}
                            isFullWidth={true}
                            counter="mins"
                            handleClick={(actionType) => handleCounterClick('time', actionType)}
                        />
                    </div>
                </div>
            </div>

            <div className="row px-4 mt-5">
                <div className="col-md-12 mb-4">
                    <textarea
                        rows={3}
                        placeholder="Please provide question"
                        onChange={(e) => setQuestion(e.target.value)}
                        className="form-control"
                        value={question}
                    ></textarea>
                </div>

                {mcqs.map((mcq, mcqIndex) => (
                    <div className="col-md-6 mb-4" key={mcqIndex}>
                        <div className="input-group">
                            <div className="input-group-text">
                                <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    checked={mcq.isChecked}
                                    onChange={() => handleOptionSelect(mcq.id)}
                                />
                            </div>
                            <input
                                type="text"
                                placeholder={`Option ${mcq.id}`}
                                className="form-control"
                                value={mcq.answer}
                                onChange={(e) => handleOptionChange(mcqIndex, e)}
                            />
                        </div>
                    </div>
                ))}

                <div className="row px-4 m-0 mt-3">
                    <div className="col-md-12 d-flex justify-content-end gap-3">
                        <Link onClick={handleClickPrevious}>
                            <FlexBtn label={t("Previous")} color="#463C77" bgColor="#EDEBF1" disabled={click === 0}></FlexBtn>
                        </Link>
                        <button
                            className="btn btn-primary px-4 py-2"
                            onClick={() => handleClickNext(click + 1 === questionData.length ? "Save" : "Next")}
                        >
                            {click + 1 === questionData.length ? "Save" : "Next"}
                        </button>
                        {click + 1 === questionData.length && (
                            <button
                                className="btn btn-primary px-4 py-2"
                                onClick={() => handleClickNext("Publish")}
                            >
                                Publish
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateQuestionModal;
