import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import { getDocument } from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist";
import { Spin } from "antd";
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.js");

const LibraryModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  watch,
  reset,
  setValue,
  currentCover,
  handleBulkUpload,
  uploadType,
  onModalClose,
}) => {
  const [newPicture, setNewPicture] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPDFFile, setSelectedPDFFile] = useState(null);
  const [bulkFile, setBulkFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isEdit && currentCover) {
      setNewPicture(currentCover);
    }
  }, [isEdit, currentCover]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setNewPicture(URL.createObjectURL(file));
    }
  };

  const handlePDFChange = async (event) => {
    const file = event.target.files[0];
    const selectedFormat = watch("format"); // Get the selected format from the dropdown

    if (file) {
      setSelectedPDFFile(file);

      if (selectedFormat === "eBook") {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await getDocument({ data: arrayBuffer }).promise;
        setValue("Pages", pdf.numPages);
      } else {
        setValue("audioDuration", 0);
      }
    }
  };

  const handleFormSubmit = async (data) => {
    setLoading(true);
    await onSubmit(data, selectedFile, selectedPDFFile);
    setLoading(false);
  };

  const handleBulkFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBulkFile(file);
    }
  };

  const handleBulkSubmit = async () => {
    if (!bulkFile) {
      toast.error("Please upload a file first!");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const uploadSimulation = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadSimulation);
          setIsUploading(false);
          toast.success("File has been uploaded!");
          setBulkFile(null);
          setIsModalOpen(false);
          onModalClose();
        }
        return prev + 10; // Increment progress
      });
    }, 300); // Adjust interval speed as needed
  };

  const handleClose = () => {
    reset();
    setIsModalOpen(false);
    setUploadProgress(0);
    setBulkFile(null);
    setNewPicture(null);
    setSelectedFile(null);
    setSelectedPDFFile(null);
    setIsUploading(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleClose}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <X />
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Book" : "Add Book"}
        </h2>

        <Spin spinning={loading || isUploading}>
          {isEdit && currentCover && (
            <div className="mb-4">
              <label className="block font-semibold mb-1">Current Cover:</label>
              <img
                src={newPicture || currentCover}
                alt="Book Cover"
                className="w-20 h-20 rounded mb-4"
              />
            </div>
          )}

          {isEdit || uploadType === "single" ? (
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="space-y-4"
            >
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block">Title</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    {...register("bookName")}
                    required
                  />
                </div>
                <div>
                  <label className="block">Author</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    {...register("author")}
                    required
                  />
                </div>
                <div>
                  <label className="block">Detail</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    {...register("detail")}
                    required
                  />
                </div>
                <div>
                  <label className="block">Format</label>
                  <select
                    className="w-full p-2 border rounded"
                    {...register("format")}
                    required
                  >
                    <option value="">Select Format</option>
                    <option value="eBook">eBook</option>
                    <option value="Audio">Audio</option>
                    <option value="Video">Video</option>
                  </select>
                </div>
                <div>
                  <label className="block">
                    {isEdit ? "Edit Cover Image" : "Upload Cover Image"}
                  </label>
                  <input
                    type="file"
                    className="w-full p-2 border rounded"
                    {...register("CoverImage")}
                    onChange={handlePictureChange}
                    accept="image/*"
                    required={!isEdit}
                  />
                  {newPicture && (
                    <img
                      src={newPicture}
                      alt="Book Cover Preview"
                      className="w-20 h-20 rounded mt-2"
                    />
                  )}
                </div>
                <div>
                  <label className="block">
                    {isEdit ? "Edit book" : "Upload book"}
                  </label>
                  <input
                    type="file"
                    className="w-full p-2 border rounded"
                    {...register("UploadBooK")}
                    onChange={handlePDFChange}
                  />
                </div>
                <div>
                  <label className="block">Category</label>
                  <select
                    className="w-full p-2 border rounded"
                    {...register("category")}
                    required
                  >
                    <option value="">Select Category</option>
                    <option value="Fiction">Fiction</option>
                    <option value="Non-Fiction">Non-Fiction</option>
                    <option value="Comedy">Comedy</option>
                  </select>
                </div>
                <div>
                  <label className="block">PublicationDate</label>
                  <input
                    type="date"
                    className="w-full p-2 border rounded"
                    {...register("publicationDate")}
                    required
                  />
                </div>
                <div>
                  <label className="block">No. of Pages</label>
                  <input
                    type="number"
                    className="w-full p-2 border rounded"
                    {...register("Pages")}
                  />
                </div>
                
                {watch("format") === "Audio" && (
                  <div>
                    <label className="block">Audio Duration</label>
                    <input
                      type="text"
                      placeholder="e.g., 01:30:00"
                      className="w-full p-2 border rounded"
                      {...register("audioDuration", {
                        validate: (value) => {
                          if (!value) return true; // Allow empty
                          const regex = /^(\d{1,2}):([0-5]\d):([0-5]\d)$/;
                          return (
                            regex.test(value) ||
                            "Invalid time format (use HH:mm:ss)"
                          );
                        },
                      })}
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-4 mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#241763] text-white rounded"
                >
                  {isEdit ? "Update" : "Add"}
                </button>
              </div>
            </form>
          ) : (
            <div className="space-y-4">
              <h1 className="text-lg font-bold">Bulk Upload</h1>
              <input
                type="file"
                className="w-full p-2 border rounded"
                onChange={handleBulkFileChange}
                accept=".xlsx, .xls"
                disabled={isUploading}
              />
              {isUploading && (
                <div className="mt-4">
                  <label className="block font-medium mb-2">
                    Upload Progress
                  </label>
                  <div className="relative w-full h-4 bg-gray-200 rounded">
                    <div
                      className="absolute top-0 left-0 h-4 bg-green-500 rounded"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                  <span className="text-sm text-gray-600">
                    {uploadProgress}%
                  </span>
                </div>
              )}
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={handleBulkSubmit}
                  className={`px-4 py-2 rounded ${
                    isUploading
                      ? "bg-gray-400 text-white cursor-not-allowed"
                      : "bg-[#241763] text-white"
                  }`}
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Submit"}
                </button>
              </div>
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default LibraryModal;
