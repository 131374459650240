import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const FeeBillsModal = ({ isOpen, onClose, onSave, editingData }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  // Prefill form when editing existing data
  useEffect(() => {
    if (editingData) {
      reset(editingData);
    }
  }, [editingData, reset]);

  const onSubmit = (data) => {
    onSave(data); // Pass form data back to parent
    reset(); // Clear the form after submission
    onClose(); // Close the modal
  };

  if (!isOpen) return null; // Do not render if modal is not open

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-3/4 max-h-[90vh] overflow-y-auto rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Fee Structure Management</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Row 1 */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block mb-1 font-medium">Student Id </label>
              <input
                {...register("studentId", { required: "This field is required" })}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Fee Structure Name"
              />
              {errors.feeStructureName && (
                <p className="text-red-500">{errors.feeStructureName.message}</p>
              )}
            </div>
            <div>
              <label className="block mb-1 font-medium">Student Name</label>
              <input
                {...register("studentName")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Description"
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block mb-1 font-medium">Fee Code</label>
              <input
                {...register("feeCode")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Fee Code"
                type="number"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Billing Cycle Date</label>
              <input
                {...register("billingCycleDate")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Billing Cycle Date"
                type="date"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block mb-1 font-medium">Fee Bills</label>
              <input
                {...register("feeBills")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Fee Bills"
                type="number"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Status</label>
              <input
                {...register("status")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Status"
                type="text"
              />
            </div>
          </div>

          {/* Submit and Close Buttons */}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white px-6 py-2 rounded"
            >
              Close
            </button>
            <button
              type="submit"
              className="bg-[#241763] text-white px-6 py-2 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeeBillsModal;
