// Chart Data
export const chartData = [
    { name: 'Jan', uv: 19, pv: 7 },
    { name: 'Feb', uv: 15, pv: 11 },
    { name: 'Mar', uv: 22, pv: 14 },
    { name: 'Apr', uv: 24, pv: 18 },
    { name: 'May', uv: 31, pv: 26 },
    { name: 'Jun', uv: 19, pv: 13 },
    { name: 'Jul', uv: 25, pv: 16 },
    { name: 'Aug', uv: 24, pv: 25 },
    { name: 'Sep', uv: 25, pv: 31 },
  ];
  
 // constants.js

export const financialData = [
  { name: 'Sep-24', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Oct-24', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Nov-24', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Dec-24', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Jan-25', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Feb-25', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Mar-25', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'Apr-25', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
  { name: 'May-25', "Salary vs Revenue": 57.4, revenue: 76061400, badDebt: 10, "Capacity vs  Occupancy": 72.71 },
];

export const volumesData = [
  { name: 'Sep-24', student: 1201, newAdmission: 209, dropOut: 12, teachers: 123, turnover: 20.33 },
  { name: 'Oct-24', student: 1260, newAdmission: 209, dropOut: 12, teachers: 123, turnover: 1.63 },
  { name: 'Nov-24', student: 1277, newAdmission: 209, dropOut: 11, teachers: 123, turnover: 0.81 },
  { name: 'Dec-24', student: 1375, newAdmission: 209, dropOut: 11, teachers: 123, turnover: 2.44 },
  { name: 'Jan-25', student: 1450, newAdmission: 209, dropOut: 10, teachers: 123, turnover: 2.44 },
  { name: 'Feb-25', student: 1500, newAdmission: 209, dropOut: 10, teachers: 123, turnover: 0.81 },
  { name: 'Mar-25', student: 1550, newAdmission: 209, dropOut: 9, teachers: 123, turnover: 0.81 },
  { name: 'Apr-25', student: 1550, newAdmission: 209, dropOut: 9, teachers: 123, turnover: 0.81 },
  { name: 'May-25', student: 1550, newAdmission: 209, dropOut: 9, teachers: 123, turnover: 1.63 },
];

export const attendanceData = [
  { name: 'Sep-24', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Oct-24', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Nov-24', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Dec-24', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Jan-25', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Feb-25', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Mar-25', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'Apr-25', teachersAttendance: 95.44, learnersAttendance: 90.16 },
  { name: 'May-25', teachersAttendance: 95.44, learnersAttendance: 90.16 },
];

export const academicResultsData = [
  { name: '1st Term', score: 85 },
  { name: 'Mid Term', score: 90 },
  { name: 'Final Term', score: 95 },
  { name: 'Overall Results', score: 90 },
];

export const teacherObservationData = [
  { name: '1st Term', score: 70 },
  { name: 'Mid Term', score: 80 },
  { name: 'Final Term', score: 90 },
  { name: 'Overall Results', score: 80 },
];

export const parentSatisfactionData = [
  { name: '1st Term', score: 85 },
  { name: 'Mid Term', score: 90 },
  { name: 'Final Term', score: 95 },
  { name: 'Overall Results', score: 90 },
];

export const teacherSatisfactionData = [
  { name: '1st Term', score: 85 },
  { name: 'Mid Term', score: 90 },
  { name: 'Final Term', score: 95 },
  { name: 'Overall Results', score: 90 },
];

// constants/graphData.js

export const dummyClassData = [
  {
    id: 1,
    standard: 'Class 1',
    totalStudents: 40,
    sections: [
      {
        name: 'A',
        students: 20,
        subjects: [
          { name: 'Math', studentCount: 15 },
          { name: 'Science', studentCount: 18 },
        ],
      },
      {
        name: 'B',
        students: 20,
        subjects: [
          { name: 'Math', studentCount: 14 },
          { name: 'Science', studentCount: 16 },
        ],
      },
    ],
  },
  {
    id: 2,
    standard: 'Class 2',
    totalStudents: 45,
    sections: [
      {
        name: 'A',
        students: 22,
        subjects: [
          { name: 'Math', studentCount: 16 },
          { name: 'Science', studentCount: 17 },
        ],
      },
      {
        name: 'B',
        students: 23,
        subjects: [
          { name: 'Math', studentCount: 20 },
          { name: 'Science', studentCount: 19 },
        ],
      },
    ],
  },
  // Add more class data as needed...
];

