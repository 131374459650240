import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/assignLeave`;

console.log("API URL:", API_URL);

// Fetch Assigned Leaves
export const fetchAssignedLeaves = createAsyncThunk("assignLeave/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Assign Leave
export const assignLeave = createAsyncThunk("assignLeave/assign", async (leaveData) => {
  const response = await axios.post(API_URL, leaveData);
  return response.data;
});

// Update Assigned Leave
export const updateAssignedLeave = createAsyncThunk(
  "assignLeave/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Assigned Leave
export const deleteAssignedLeave = createAsyncThunk("assignLeave/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const assignLeaveSlice = createSlice({
  name: "assignLeave",
  initialState: {
    assignedLeaves: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedLeaves.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssignedLeaves.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assignedLeaves = action.payload;
      })
      .addCase(fetchAssignedLeaves.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(assignLeave.fulfilled, (state, action) => {
        state.assignedLeaves.push(action.payload);
      })
      .addCase(updateAssignedLeave.fulfilled, (state, action) => {
        const index = state.assignedLeaves.findIndex((leave) => leave.id === action.payload.id);
        if (index !== -1) {
          state.assignedLeaves[index] = action.payload;
        }
      })
      .addCase(deleteAssignedLeave.fulfilled, (state, action) => {
        state.assignedLeaves = state.assignedLeaves.filter((leave) => leave.id !== action.payload);
      });
  },
});

export default assignLeaveSlice.reducer;
