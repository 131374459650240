import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchExamReport } from "../../../redux/examSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

const ViewReportModal = ({ isOpen, onClose, studentId }) => {
  const reportRef = useRef();
  const dispatch = useDispatch();
  const { examReport, loading, error } = useSelector((state) => state.exam);

  useEffect(() => {
    if (isOpen && studentId) {
      dispatch(fetchExamReport(studentId));
    }
  }, [isOpen, studentId, dispatch]);

  const downloadPDF = () => {
    const input = reportRef.current;

    const buttons = input.querySelectorAll('.no-print');
    buttons.forEach(button => button.style.display = 'none');

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("Exam_Report.pdf");

      buttons.forEach(button => button.style.display = '');
    });
  };

  const downloadExcel = () => {
    if (!examReport) return;

    // Check if examReport is an array and get the first item if it is
    const report = Array.isArray(examReport) ? examReport[0] : examReport;
    if (!report) return;

    const worksheetData = [
      ["Student Name", report.studentName],
      ["Class", report.className],
      ["Section", report.sectionName],
      [],
      ["Subject", "Total Marks", "Obtained Marks", "Percentage", "Grade"],
    ];

    // Only add subjects if they exist
    if (report.subjects && Array.isArray(report.subjects)) {
      worksheetData.push(
        ...report.subjects.map((subject) => [
          subject.subjectName,
          subject.totalMarks,
          subject.obtainedMarks,
          subject.percentage,
          subject.grade,
        ])
      );
    }

    worksheetData.push(["Overall", "", "", report.overallPercentage, report.overallGrade]);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exam Report");
    XLSX.writeFile(workbook, "Exam_Report.xlsx");
  };

  if (!isOpen) return null;

  // Handle the case where examReport is an array
  const reportData = examReport ? (Array.isArray(examReport) ? examReport[0] : examReport) : null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div ref={reportRef} className="bg-white p-6 rounded-lg shadow-lg w-[50%] relative border border-gray-300">
        {loading && <p>Loading report...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}

        {reportData && (
          <>
            {/* Header and Student Details */}
            <div className="text-center mb-4 border-b pb-4">
              <h2 className="text-xl font-bold">Wiser Bee</h2>
              <h3 className="text-lg font-semibold mt-2">Exam Report</h3>
            </div>

            <div className="grid grid-cols-2 text-sm mb-4 border-b pb-4">
              <p><strong>Student Name:</strong> {reportData.studentName}</p>
              <p><strong>Class:</strong> {reportData.className}</p>
              <p><strong>Section:</strong> {reportData.sectionName}</p>
            </div>

            {/* Exam Results Table */}
            <table className="w-full border-collapse border text-sm">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border p-2">Subject</th>
                  <th className="border p-2">Total Marks</th>
                  <th className="border p-2">Obtained Marks</th>
                  <th className="border p-2">Percentage</th>
                  <th className="border p-2">Grade</th>
                </tr>
              </thead>
              <tbody>
                {reportData.subjects && reportData.subjects.length > 0 ? (
                  reportData.subjects.map((subject, index) => (
                    <tr key={index} className="text-center">
                      <td className="border p-2">{subject.subjectName}</td>
                      <td className="border p-2">{subject.totalMarks}</td>
                      <td className="border p-2">{subject.obtainedMarks}</td>
                      <td className="border p-2">{subject.percentage}%</td>
                      <td className="border p-2">{subject.grade}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="5" className="border p-2">No subjects found</td>
                  </tr>
                )}
                <tr className="bg-gray-100 font-semibold text-center">
                  <td className="border p-2" colSpan="3">Overall</td>
                  <td className="border p-2">{reportData.overallPercentage}%</td>
                  <td className="border p-2">{reportData.overallGrade}</td>
                </tr>
              </tbody>
            </table>

            {/* Download Buttons */}
            <div className="no-print flex justify-end mt-6 space-x-4">
              <button onClick={downloadPDF} className="bg-blue-500 px-4 py-2 rounded text-white">
                Download as PDF
              </button>
              <button onClick={downloadExcel} className="bg-green-500 px-4 py-2 rounded text-white">
                Download as Excel
              </button>
              <button onClick={onClose} className="bg-gray-400 px-4 py-2 rounded text-white">
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewReportModal;