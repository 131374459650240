import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest, updateRequest, deleteRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  campuses: [],
  campusDetails: [],
  loading: false,
  error: null,
};

// Fetch Campuses
export const fetchCampuses = createAsyncThunk(
  "campuses/fetchCampuses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/Campus");
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch campuses");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch Campus Details
export const fetchCampusDetails = createAsyncThunk(
  "campuses/fetchCampusDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/Campus/GetCampusDetail");
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch campus details");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add Campus
export const addCampus = createAsyncThunk(
  "campuses/addCampus",
  async (campus, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/Campus", campus);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update Campus
export const updateCampus = createAsyncThunk(
  "campuses/updateCampus",
  async ({ id, campus }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/Campus/${id}`, campus);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete Campus
export const deleteCampus = createAsyncThunk(
  "campuses/deleteCampus",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/Campus/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const campusesSlice = createSlice({
  name: "campuses",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampuses.fulfilled, (state, action) => {
        state.loading = false;
        state.campuses = action.payload;
      })
      .addCase(fetchCampuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(state.error);
      })
      .addCase(fetchCampusDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampusDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.campusDetails = action.payload;
      })
      .addCase(fetchCampusDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(state.error);
      })
      .addCase(addCampus.fulfilled, (state, action) => {
        state.campuses = [...state.campuses, action.payload];
        toast.success("Campus added successfully!");
      })
      .addCase(updateCampus.fulfilled, (state, action) => {
        const index = state.campuses.findIndex((c) => c.id === action.payload.id);
        if (index !== -1) state.campuses[index] = action.payload;
        toast.success("Campus updated successfully!");
      })
      .addCase(deleteCampus.fulfilled, (state, action) => {
        state.campuses = state.campuses.filter((c) => c.id !== action.payload);
        toast.success("Campus deleted successfully!");
      });
  },
});

export default campusesSlice.reducer;