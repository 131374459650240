import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/attendance`;

// Fetch Attendance
export const fetchAttendance = createAsyncThunk("attendance/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Attendance
export const addAttendance = createAsyncThunk(
  "attendance/add",
  async (attendanceData) => {
    const response = await axios.post(API_URL, attendanceData);
    return response.data;
  }
);

// Update Attendance
export const updateAttendance = createAsyncThunk(
  "attendance/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Attendance
export const deleteAttendance = createAsyncThunk("attendance/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    attendanceRecords: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.attendanceRecords = action.payload;
      })
      .addCase(fetchAttendance.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addAttendance.fulfilled, (state, action) => {
        state.attendanceRecords.push(action.payload);
      })
      .addCase(updateAttendance.fulfilled, (state, action) => {
        const index = state.attendanceRecords.findIndex((a) => a.id === action.payload.id);
        if (index !== -1) {
          state.attendanceRecords[index] = action.payload;
        }
      })
      .addCase(deleteAttendance.fulfilled, (state, action) => {
        state.attendanceRecords = state.attendanceRecords.filter((a) => a.id !== action.payload);
      });
  },
});

export default attendanceSlice.reducer;
