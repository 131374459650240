import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Plus, ListPlus } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import AddExamModal from "../teacher/AddExamModal";

const CoursesModal = ({ currentClass, onSubmit }) => {
  const { register, control, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      courseName: "",
      creditHours: "",
      startedSession: "",
      endSession: "",
      courseType: "Online",
      weeks: [],
    },
  });
  const { i18n } = useTranslation();
  const isArabic = i18n.language;
  const [examModal, setExamModal] = useState(false);
  const [examData, setExamData] = useState([]);
  const [newPicture, setNewPicture] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newVideo, setNewVideo] = useState(null);
  const [isForStudent, setIsForStudent] = useState(true); // Checkbox state

  const {
    fields: weekFields,
    append: appendWeek,
    remove: removeWeek,
  } = useFieldArray({
    control,
    name: "weeks",
  });

  useEffect(() => {
    if (currentClass) {
      reset({
        name: currentClass.name || "",
        creditHours: currentClass.creditHours || "",
        startedSession: currentClass.startedSession
          ? new Date(currentClass.startedSession).toISOString().split("T")[0]
          : "",
        endSession: currentClass.endSession
          ? new Date(currentClass.startedSession).toISOString().split("T")[0]
          : "",
        courseType: currentClass.type || "Online",
        actualPrice: currentClass.price || 0,
        discountedPrice: currentClass.discountedPrice || 0,
        coverImageUrl: currentClass.coverImageUrl,
        videoUrl: currentClass.videoUrl,
        description: currentClass.description || "",
        weeks:
          currentClass.plans?.map((plan) => ({
            weekName: plan.weekName || "", // Use weekName directly
            activities: plan.activities.map((activity) => ({
              activityName: activity.activityName || "",
              activityType: activity.activityType || "",
              file: activity.filePath || null,
              description: activity.activityDescription || "", // Include activity description if available
            })),
          })) || [],
      });
    }
  }, [currentClass, reset]);

  const handleCheckboxChange = () => {
    setIsForStudent((prev) => !prev);
    console.log(
      `Checkbox toggled: ${
        !isForStudent ? "For Student" : "For Teacher"
      } selected.`
    );
  };

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setNewPicture(URL.createObjectURL(file));
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
      setNewVideo(URL.createObjectURL(file));
    }
  };


  const addActivity = (weekIndex) => {
    const activities = watch(`weeks.${weekIndex}.activities`);
    setValue(`weeks.${weekIndex}.activities`, [
      ...activities,
      { activityName: "", activityType: "", file: null },
    ]);
  };

  const removeActivity = (weekIndex, activityIndex) => {
    const activities = watch(`weeks.${weekIndex}.activities`);
    activities.splice(activityIndex, 1);
    setValue(`weeks.${weekIndex}.activities`, [...activities]);
  };

  const openExamModal = (event) => {
    event?.preventDefault();
    setExamModal(true);
  };

  const onSubmitForm = (data) => {
    console.log("Submitted Data:", data);
    console.log(`Course is for: ${isForStudent ? "Student" : "Teacher"}`);
    toast.success("Form submitted successfully!");
    onSubmit(data, selectedFile, selectedVideo);
  };
  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="w-full px-4">
          <div className="flex justify-center py-2 font-bold text-lg">
            Course Information
          </div>
          <div className="flex items-center space-x-4 justify-center mb-4">
            <label className="block ">Create Course For</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={isForStudent}
                onChange={handleCheckboxChange}
              />
              <span>{isForStudent ? "Student" : "Teacher"}</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Course Name</label>
              <input
                type="text"
                className="w-full border border-gray-300 rounded p-2"
                placeholder="Course name"
                {...register("name")}
              />
            </div>
            <div>
              <label className="block mb-2">Course Type</label>
              <select
                className="w-full border border-gray-300 rounded p-2"
                {...register("type")}
              >
                <option>Online</option>
                <option>Physical</option>
                <option>Both</option>
              </select>
            </div>
            <div>
              <label className="block mb-2">Credit Hours</label>
              <input
                type="number"
                className="w-full border border-gray-300 rounded p-2"
                {...register("creditHours")}
              />
            </div>
            <div>
              <label className="block mb-2">Start Time</label>
              <input
                type="date"
                className="w-full border border-gray-300 rounded p-2"
                {...register("startedSession")}
              />
            </div>
            <div>
              <label className="block mb-2">End Time</label>
              <input
                type="date"
                className="w-full border border-gray-300 rounded p-2"
                {...register("endSession")}
              />
            </div>

            <div>
              <label className="block"> Actual Price</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                {...register("actualPrice")}
              />
            </div>
            <div>
              <label className="block">Discounted Price</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                {...register("discountedPrice")}
              />
            </div>

            <div>
              <label className="block">Upload Cover Image</label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("coverImageUrl")}
                onChange={handlePictureChange}
                accept="image/*"
              />
              {newPicture && (
                <div className="mt-2">
                  <img
                    src={newPicture}
                    alt="Cover Image Preview"
                    className="w-20 h-20 rounded"
                  />
                </div>
              )}
              {currentClass && !newPicture && currentClass.coverImageUrl && (
                <div className="mt-2">
                  <img
                    src={currentClass.coverImageUrl}
                    alt="Existing Cover Image"
                    className="w-20 h-20 rounded"
                  />
                </div>
              )}
            </div>

            <div>
              <label className="block">Upload Video</label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("videoUrl")}
                onChange={handleVideoChange}
                accept="video/*"
              />
              {newVideo && (
                <div className="mt-2">
                  <video
                    src={newVideo}
                    alt="Video Preview"
                    className="w-20 h-20 rounded"
                    controls
                  />
                </div>
              )}
              {currentClass && !newVideo && currentClass.videoUrl && (
                <div className="mt-2">
                  <video
                    src={currentClass.videoUrl}
                    alt="Existing Video"
                    className="w-20 h-20 rounded"
                    controls
                  />
                </div>
              )}
            </div>

            <div>
              <label className="block">Course Description</label>
              <textarea
                type="text"
                placeholder="Course Description"
                className="w-full p-2 border rounded"
                {...register("description")}
              />
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="flex justify-between items-center mb-2">
              <label className="font-bold text-lg">Plan Your Course</label>
              <button
                type="button"
                className="bg-gray-600 text-white py-1 px-4 rounded"
                onClick={() => appendWeek({ weekName: "", activities: [] })}
              >
                <div className="flex items-center">
                  <Plus className="mr-2" /> Add Week
                </div>
              </button>
            </div>
            <div className="space-y-4">
              {weekFields.map((week, weekIndex) => (
                <div key={week.id}>
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      className="border border-gray-300 rounded p-2 flex-grow"
                      placeholder="Week Title"
                      {...register(`weeks.${weekIndex}.weekName`)}
                    />
                    <button
                      type="button"
                      className="bg-red-500 text-white rounded p-2"
                      onClick={() => removeWeek(weekIndex)}
                    >
                      &#x2715;
                    </button>
                    <ListPlus
                      height={40}
                      width={40}
                      onClick={() => addActivity(weekIndex)}
                      className="cursor-pointer text-gray-600"
                    />
                  </div>
                  {watch(`weeks.${weekIndex}.activities`)?.map(
                    (activity, activityIndex) => {
                      const activityType = watch(
                        `weeks.${weekIndex}.activities.${activityIndex}.activityType`
                      );
                      const filePath = watch(
                        `weeks.${weekIndex}.activities.${activityIndex}.file`
                      );

                      return (
                        <>
                          <div
                            key={activityIndex}
                            className="flex items-center gap-2 mt-2 ml-8"
                          >
                            <input
                              type="text"
                              className="border border-gray-300 rounded p-2 w-64"
                              placeholder="Activity Name"
                              {...register(
                                `weeks.${weekIndex}.activities.${activityIndex}.activityName`
                              )}
                              defaultValue={activity.activityName}
                            />
                            <select
                              className="border border-gray-300 rounded p-2 w-52"
                              {...register(
                                `weeks.${weekIndex}.activities.${activityIndex}.activityType`
                              )}
                              defaultValue={activity.activityType}
                            >
                              <option value="">Select Activity Type</option>
                              <option value="Document">Reading</option>
                              <option value="Video">Video</option>
                              <option value="exam/quiz">Exam/Quiz</option>
                            </select>

                            {activityType === "Document" ||
                            activityType === "Video"? (
                              <div className="flex flex-col">
                                <input
                                  type="file"
                                  className="border border-gray-300 rounded p-2"
                                  accept={
                                    activityType === "Video"
                                      ? "video/*"
                                      : activityType === "Document"
                                      ? ".pdf,.doc,.docx,.txt"
                                      : "image/*"
                                  }
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                      const activities = watch(
                                        `weeks.${weekIndex}.activities`
                                      );
                                      activities[activityIndex].file = [file];
                                      setValue(
                                        `weeks.${weekIndex}.activities`,
                                        [...activities]
                                      );
                                    }
                                  }}
                                />

                                {filePath && (
                                  <a
                                    href={filePath}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 underline mt-1 text-sm"
                                  >
                                    View Current File
                                  </a>
                                )}
                              </div>
                            ) : activityType === "exam/quiz" ? (
                              <button
                                className={`text-capitalize fs-6 gap-3 w-96 flex align-items-center btnWithIcon bg-main
                    ${isArabic === "sa" ? "flex-row-reverse" : ""}
                  `}
                                onClick={openExamModal}
                              >
                                <span className="px-1 py-1 fw-4 rounded p-0 addButtonSty">
                                  <Plus />
                                </span>
                                <span>Add New Exam</span>
                              </button>
                            ) : null}

                            <button
                              type="button"
                              className="bg-red-500 text-white rounded p-2"
                              onClick={() =>
                                removeActivity(weekIndex, activityIndex)
                              }
                            >
                              &#x2715;
                            </button>
                          </div>
                          <div>
                            <input
                              type="text"
                              className="border border-gray-300 mt-2 ml-8 w-[92%] rounded p-2 flex-grow"
                              placeholder="Activity Description"
                              {...register(
                                `weeks.${weekIndex}.activities.${activityIndex}.description`
                              )}
                            />
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="mt-6 text-white py-2 px-6 rounded bg-[#241763] transition duration-200 ease-in-out"
          >
            Submit
          </button>
        </div>
        <Modal
          open={examModal}
          onCancel={() => setExamModal(false)}
          footer={null}
        >
          <AddExamModal
            onRequestClose={() => setExamModal(false)}
            setExamData={setExamData}
            isCourse
          />
        </Modal>
      </form>
    </>
  );
};

export default CoursesModal;
