import { Star, StarOff, StarHalf } from "lucide-react";
import { postRequest } from "../services";

export const arrayBufferToBase64 = (buffer) => {
	let binary = '';
	const bytes = new Uint8Array(buffer);
	for (let i = 0; i < bytes.byteLength; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return btoa(binary);
};

export const renderStars = (rating) => {
    const stars = [];
    const wholeStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 1; i <= 5; i++) {
      if (i <= wholeStars) {
        stars.push(<Star key={i} className="text-yellow-500" />);
      } else if (i === wholeStars + 1 && hasHalfStar) {
        stars.push(<StarHalf key={i} className="text-yellow-500 opacity-50" />);
      } else {
        stars.push(<StarOff key={i} className="text-gray-400" />);
      }
    }
    return stars;
};

export const uploadImage = async (image) => {
  try {
    const response = await postRequest("/api/Document/image-upload", {
      request: image
    }, true);
    if (response.status !== 200) {
      return false
    }
    return response.data;
  } catch (error) {
    return false
  }
}

export const uploadDocuments = async (documentFiles) => {
  try {
    const formData = new FormData();
    documentFiles.forEach((file) => formData.append("files", file)); // Append all files

    const response = await postRequest("/api/Document/documents-upload", formData, true);
    if (response.status !== 200) {
      return false
    }
    return response.data;
  } catch (error) {
    return false
  }
}

export const uploadVideos = async (videoFiles) => {
  try {
    const formData = new FormData();
    videoFiles.forEach((file) => formData.append("files", file)); // Append all files

    const response = await postRequest("/api/Document/videos-upload", formData, true);
    if (response.status !== 200) {
      return false
    }
    return response.data;
  } catch (error) {
    return false
  }
}