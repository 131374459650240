import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp, SendHorizonal } from "lucide-react";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import NotificationsModal from "../Common/NotificationModal";
import toast, { Toaster } from "react-hot-toast";

function Notifications({ overDue, setFeeBills }) {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isNotificationsModal, setIsNotificationsModal] = useState(false);
  const [filters, setFilters] = useState({
    studentName: "",
    feeCode: "",
    reminderNotifications: "",
  });

  const dummyFeeBills = [
    {
      studentId: "001",
      studentName: "John Doe",
      feeCode: "FC101",
      billingCycleDate: "2024-01-15",
      overDue: "Monthly",
      reminderNotifications: true, // Sent
    },
    {
      studentId: "002",
      studentName: "Jane Smith",
      feeCode: "FC102",
      billingCycleDate: "2024-02-15",
      overDue: "Quarterly",
      reminderNotifications: false, // Unsent
    },
    {
      studentId: "003",
      studentName: "Sam Wilson",
      feeCode: "FC103",
      billingCycleDate: "2024-03-15",
      overDue: "Yearly",
      reminderNotifications: false, // Unsent
    },
  ];

  const [feeBillsData, setFeeBillsData] = useState(dummyFeeBills);

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredFeeBills = feeBillsData.filter(
    (bill) =>
      (filters.studentName === "" || bill.studentName.includes(filters.studentName)) &&
      (filters.feeCode === "" || bill.feeCode.includes(filters.feeCode)) &&
      (filters.reminderNotifications === "" ||
        (filters.reminderNotifications === "Sent" && bill.reminderNotifications) ||
        (filters.reminderNotifications === "Unsent" && !bill.reminderNotifications))
  );

  const handleStudentSelection = (studentId, studentName) => {
    setSelectedStudents((prev) => {
      const isSelected = prev.some((student) => student.id === studentId);
      if (isSelected) {
        return prev.filter((student) => student.id !== studentId);
      } else {
        return [...prev, { id: studentId, name: studentName }];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedStudents.length === dummyFeeBills.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(
        dummyFeeBills.map((student) => ({
          id: student.studentId,
          name: student.studentName,
        }))
      );
    }
  };

  const onSendNotification = (studentIds) => {
    console.log("Sending notification to:", studentIds);
    toast.success("Notification sent successfully!");
  
    // Update feeBillsData to mark notifications as sent
    setFeeBillsData((prevData) =>
      prevData.map((bill) =>
        studentIds.includes(bill.studentId) ? { ...bill, reminderNotifications: true } : bill
      )
    );
  };
  

  const handleSubmitNotification = () => {
    // Extracting only the student IDs from selectedStudents
    const studentIds = selectedStudents.map((student) => student.id);
  
    onSendNotification(studentIds);
    setSelectedStudents([]);
  };
  

  return (
    <div>
      <Toaster position="top-center" />
      <div className="mb-4 flex gap-4 w-full">
        <div className="flex w-4/6">
          <input
            type="text"
            placeholder="Search Student Name..."
            className="border border-gray-300 rounded p-2 w-full"
            value={filters.studentName}
            onChange={(e) => handleFilterChange("studentName", e.target.value)}
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["FC101", "FC102", "FC103"]}
            value={filters.feeCode}
            onChange={(value) => handleFilterChange("feeCode", value)}
            placeholder="Select Fee Code"
            className="w-full"
          />
        </div>
        <div className="w-2/12">
          <CustomDropdown
            options={["Sent", "Unsent"]}
            value={filters.reminderNotifications}
            onChange={(value) => handleFilterChange("reminderNotifications", value)}
            placeholder="Select Status"
            className="w-full"
          />
        </div>
        <div
          className="bg-[#241763] p-2 rounded"
          onClick={() => {
            if(selectedStudents.length === 0){
              toast.error("Please select at least one student");
            } else {
              setIsNotificationsModal(true);
            }
          }}
        >
          <SendHorizonal className="text-white w-6 h-6 group-hover:text-white" />
        </div>
      </div>

      {isNotificationsModal && (
        <NotificationsModal
          selectedItems={selectedStudents}
          toggleItemSelection={handleStudentSelection}
          handleSubmitNotification={handleSubmitNotification}
          onClose={() => setIsNotificationsModal(false)}
        />
      )}

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr className="bg-gray-100 border-b">
              <th>
                <input
                  type="checkbox"
                  checked={selectedStudents.length > 0 && selectedStudents.length === dummyFeeBills.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th>Student Id</th>
              <th>Student Name</th>
              <th>Fee Code</th>
              <th>Billing Cycle Date</th>
              <th>Overdue</th>
              <th>Notification Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {filteredFeeBills.length > 0 ? (
              filteredFeeBills.map((bill, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedStudents.some((student) => student.id === bill.studentId)}
                        onChange={() => handleStudentSelection(bill.studentId, bill.studentName)}
                      />
                    </td>
                    <td>{bill.studentId}</td>
                    <td>{bill.studentName}</td>
                    <td>{bill.feeCode}</td>
                    <td>{bill.billingCycleDate}</td>
                    <td>{bill.overDue}</td>
                    <td>{bill.reminderNotifications ? "Sent" : "Unsent"}</td>
                    <td className="space-x-2 flex items-center py-2">
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                         onClick={() => {
                          if(selectedStudents.length === 0){
                            toast.error("Please select at least one student");
                          } else {
                            setIsNotificationsModal(true);
                          }
                        }}
                      >
                        <SendHorizonal className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="7">
                        <div className="bg-gray-100 p-4">
                          <div>Additional details about the bill...</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Fee Bills Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Notifications;
