import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "http://localhost:5001/api";
const API_URL = `${BASE_URL}/welfareAllowance`;

// Fetch Welfare Allowance
export const fetchWelfareAllowances = createAsyncThunk("welfareAllowance/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Welfare Allowance
export const addWelfareAllowance = createAsyncThunk(
  "welfareAllowance/add",
  async (allowanceData) => {
    const response = await axios.post(API_URL, allowanceData);
    return response.data;
  }
);

// Update Welfare Allowance
export const updateWelfareAllowance = createAsyncThunk(
  "welfareAllowance/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Welfare Allowance
export const deleteWelfareAllowance = createAsyncThunk("welfareAllowance/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const welfareAllowanceSlice = createSlice({
  name: "welfareAllowance",
  initialState: {
    welfareAllowances: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWelfareAllowances.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWelfareAllowances.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.welfareAllowances = action.payload;
      })
      .addCase(fetchWelfareAllowances.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addWelfareAllowance.fulfilled, (state, action) => {
        state.welfareAllowances.push(action.payload);
      })
      .addCase(updateWelfareAllowance.fulfilled, (state, action) => {
        const index = state.welfareAllowances.findIndex((wa) => wa.id === action.payload.id);
        if (index !== -1) {
          state.welfareAllowances[index] = {
            ...state.welfareAllowances[index], // Preserve existing data
            ...action.payload, // Overwrite with updated data
          };
        }
      })
      
      .addCase(deleteWelfareAllowance.fulfilled, (state, action) => {
        state.welfareAllowances = state.welfareAllowances.filter((wa) => wa.id !== action.payload);
      });
  },
});

export default welfareAllowanceSlice.reducer;
