import { CalendarCheck2, Trash2, EditIcon } from "lucide-react";
import { clockSvg, bulbSvg } from "../../Constant/svgs";
import { useTranslation } from "react-i18next";
import {  Modal as AntdModal, notification } from "antd";
import { deleteRequest } from '../../services';
import { calculateDateTimeDifference } from '../../Constant/helpers';

const ExamCards = ({ exam, handleClickQuestions, fetchData }) => {


  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;

  const confirmDelete = (id) => {
    AntdModal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Exam?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await deleteMyExam(id); // Call the delete function
      },
      onCancel: () => {
        notification.info({
          message: "Deletion Canceled",
          description: "You have canceled the deletion of the Exam.",
        });
      },
    });
  };
  const deleteMyExam = async (id) => {
    try {
      const response = await deleteRequest(`/api/Exam/${id}`);
      if (response.data.success) {
        fetchData();
        notification.success({
          message: "Success",
          description: "The Exam was deleted successfully.",
        });
      }
    } catch (error) {
      console.error("Error deleting Exam:", error.message);
      notification.error({
        message: "Error",
        description: "An error occurred while deleting the Exam. Please try again.",
      });
    }
  };

  
  return (
    <div className="col-md-6 col-lg-4 mb-3 cursor-pointer">
      <div className="d-flex flex-column align-items-between examDataWrapper px-2 py-3">

        <div className={`d-flex justify-content-between ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
          <h4 className={`fw-bold p-0 m-0 fs-6 cursor-pointer ${isArabic === "sa" ? "text-end" : "text-left"}`}>
            {exam.title}
          </h4>
          {exam.status ? "" :
            <div className={`d-flex justify-content-start gap-2 ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
              <EditIcon size={20} color="#fff" onClick={() => handleClickQuestions(exam.questionCount, exam.id, exam.examType, exam.duration)} />
              <Trash2 size={20} color="#fff" onClick={() => confirmDelete(exam.id)} />
            </div>
          }
        </div>
        <div className={`d-flex justify-content-start gap-3 examChipsWrapper mt-3 ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
          <div className="examChip">{exam.subjectDetails.subjectName}</div>
          <div className="examChip">
            {exam.subjectDetails?.sectionDetails?.sectionName?.startsWith("Section")
              ? exam.subjectDetails.sectionDetails.sectionName
              : `Section ${exam.subjectDetails?.sectionDetails?.sectionName}`}
          </div>
          <div className="examChip">{exam.subjectDetails.sectionDetails.classDetails.className}</div>
        </div>
        <div className={`d-flex gap-5 mt-3 align-items-center examSvgsText ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
          <div className={`d-flex gap-1 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
            {clockSvg}
            <span>
              {exam.examType === "Assignment" ?
                calculateDateTimeDifference(exam.startDate, exam.endDate, exam.startTime, exam.endTime)
                :
                `${exam.duration} ${t("Mins")}`
              }
              {/* {exam.duration}
              {t("Mins")} */}
            </span>
          </div>
          <div className={`d-flex gap-1 align-items-center cursor-pointer ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
            <span>
              {bulbSvg}
            </span>
            <span className={`d-flex ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
              <span>{exam.examType} {t(exam.category)}</span>
            </span>
          </div>
        </div>
        <div className={`d-flex  mt-3 ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
          <div className="d-flex align-items-center gap-2 fs-6 ">
            <button
              className="text-capitalize fs-6 d-flex gap-2 align-items-center"
              style={{
                backgroundColor: "#EDEBF1",
                padding: "6px 10px",
                borderRadius: "8px",
                color: "#463C77",
                width: "auto",
                border: "none",
              }}
            >
              <CalendarCheck2 style={{ height: "16px", width: "16px" }} />
              <span className="p-0 m-0" style={{ fontSize: "12px" }}>
                {t("Scheduled")}
              </span>
            </button>
            <span
              className="p-0 m-0"
              style={{ color: "#fff", fontSize: "10px" }}
            >
              {`${exam.startDate} - ${exam.endDate}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamCards;
