import React, { useState, useEffect } from "react";

const InterviewStatusModal = ({ isOpen, onClose, candidate, stageName, onUpdateStatus }) => {
  const [status, setStatus] = useState("Scheduled");
  const [notes, setNotes] = useState("");
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(3);
  
  // Reset form when modal opens with a new candidate
  useEffect(() => {
    if (isOpen && candidate) {
      setStatus("Scheduled"); // Default to scheduled status
      setNotes("");
      setFeedback("");
      setRating(3);
    }
  }, [isOpen, candidate]);
  
  if (!isOpen || !candidate) return null;
  
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  
  const handleSubmit = () => {
    onUpdateStatus(candidate.id, status, {
      notes,
      feedback,
      rating
    });
    onClose();
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Update {stageName} Status</h2>
        
        <p className="text-gray-600 mb-2">
          Candidate: <strong>{candidate.name}</strong>
        </p>
        <p className="text-gray-600 mb-2">
          Post Applied: <strong>{candidate.currentDesignation || "N/A"}</strong>
        </p>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Interview Status</label>
          <select
            className="w-full p-2 border rounded-md"
            value={status}
            onChange={handleStatusChange}
          >
            <option value="Scheduled">Scheduled</option>
            <option value="Passed">Passed</option>
            <option value="Failed">Failed</option>
            <option value="Skip">Skip</option>
          </select>
        </div>
        
        {status === "Passed" || status === "Failed" ? (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 mb-1">Notes</label>
              <textarea
                className="w-full p-2 border rounded-md"
                rows="2"
                placeholder="Enter any notes about the interview"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 mb-1">Feedback</label>
              <textarea
                className="w-full p-2 border rounded-md"
                rows="2"
                placeholder="Enter feedback for the candidate"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 mb-1">Rating (1-5)</label>
              <select
                className="w-full p-2 border rounded-md"
                value={rating}
                onChange={(e) => setRating(Number(e.target.value))}
              >
                <option value="1">1 - Poor</option>
                <option value="2">2 - Below Average</option>
                <option value="3">3 - Average</option>
                <option value="4">4 - Good</option>
                <option value="5">5 - Excellent</option>
              </select>
            </div>
          </>
        ) : null}
        
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="px-4 py-2 bg-gray-400 text-white rounded-md mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
            onClick={handleSubmit}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default InterviewStatusModal;