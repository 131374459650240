import React, { useState } from "react";
import ExamsModal from "../../modals/admin/reports/ExamsModal";
import AttendanceModal from "../../modals/admin/reports/AttendanceModal";

const reportCategories = [
  {
    title: 'Results',
    links: ['Exams', 'Quizes', 'Assignments / Homework', 'Overall Results'],
  },
  {
    title: 'Attendance Report',
    links: ['Student Attendance', 'Teacher Attendance', 'Administration Staff', 'Overall Attendance'],
  },
  {
    title: 'Financial Report',
    links: ['Fee Management', 'Bad Debt'],
  },
  {
    title: 'Wiser-Adviser',
    links: ['AI Model Report', 'Student Utilization Report', 'Teacher Utilization Report', 'Overall Utilization Report'],
  },
  {
    title: 'Teacher Performancs Report',
    links: ['Lesson Plan Created Vs Delivered', 'Student Observation (WHO)', 'Overall Teacherwise Results', 'AI Recommendation for improvement'],
  },
  {
    title: 'Regulatory Compliance',
    links: ['Curriculam Progress', 'Compliance Parameterwise', 'AI Recommendation to improve Compliance'],
  },
  {
    title: 'Parent Engagement Report',
    links: ['Survey Participation', 'Feedback Report', 'Portal Utilization Report'],
  },
  {
    title: 'Incident & Behaviour',
    links: ['Incident reports', 'Behavioral Assessments', 'SNE Survey'],
  },
  {
    title: 'Courses & Certification Reports',
    links: ['Courseswise progress', 'Certification Issued', 'Content Usage Report'],
  },
];

const Reports = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (report) => {
    setOpenModal(report);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {reportCategories.map((category, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-bold text-indigo-700 mb-2">{category.title}</h3>
            <ul className="space-y-1">
              {category.links.map((link, i) => (
                <li key={i}>
                  <button
                    onClick={() => handleOpenModal(link)}
                    className="text-blue-600 hover:underline text-sm"
                  >
                    {link}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Conditionally Render Modals */}
      {openModal === "Exams" && <ExamsModal isOpen={true} onClose={handleCloseModal} />}
      {openModal === "Student Attendance" && <AttendanceModal isOpen={true} onClose={handleCloseModal} />}
    </div>
  );
};

export default Reports;
