import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/loanAdvances`;

// Fetch Loan Advances
export const fetchLoanAdvances = createAsyncThunk("loanAdvances/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Apply for Loan Advance
export const applyLoanAdvance = createAsyncThunk("loanAdvances/apply", async (loanData) => {
  const response = await axios.post(API_URL, loanData);
  return response.data;
});

// Update Loan Advance
export const updateLoanAdvance = createAsyncThunk(
  "loanAdvances/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Loan Advance
export const deleteLoanAdvance = createAsyncThunk("loanAdvances/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const loanAdvanceSlice = createSlice({
  name: "loanAdvances",
  initialState: {
    loanAdvances: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanAdvances.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoanAdvances.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loanAdvances = action.payload;
      })
      .addCase(fetchLoanAdvances.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(applyLoanAdvance.fulfilled, (state, action) => {
        state.loanAdvances.push(action.payload);
      })
      .addCase(updateLoanAdvance.fulfilled, (state, action) => {
        const index = state.loanAdvances.findIndex((loan) => loan.id === action.payload.id);
        if (index !== -1) {
          state.loanAdvances[index] = action.payload;
        }
      })
      .addCase(deleteLoanAdvance.fulfilled, (state, action) => {
        state.loanAdvances = state.loanAdvances.filter((loan) => loan.id !== action.payload);
      });
  },
});

export default loanAdvanceSlice.reducer;
