import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { postRequest } from "../services";
import { message } from 'antd';

const ViewQuestionStudent = (props) => {

  const { onRequestClose, questionsData, selectedExamId, fetchDataExam } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({}); 
  const [timeLeft, setTimeLeft] = useState(questionsData[currentIndex].time * 60); 
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const currentQuestion = questionsData[currentIndex];

  useEffect(() => {
    if (isTimerRunning && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleTimeOut();
    }
  }, [timeLeft, isTimerRunning]);

  const handleAnswerChange = (event) => {
    setUserAnswers({
      ...userAnswers,
      [currentQuestion.id]: event.target.value,
    });
  };

  const handleTimeOut = () => {
    if (currentIndex + 1 < questionsData.length) {
      setCurrentIndex((prev) => prev + 1);
      setTimeLeft(questionsData[currentIndex + 1].time * 60);
    } else {
      onSubmit();
    }

  };
  const onSubmit = () => {
    const payload = Object.entries(userAnswers).map(([examId, userAnswer]) => ({
      examId: selectedExamId,  
      questionId: examId,
      answer: userAnswer
    }));
    fetchData(payload);
  };

  const fetchData = async (payload) => {
    try {
      const response = await postRequest(`/api/Submissions/submit-question`, payload);
      if (response.status === 200 && response.data.success) {
        message.success(response.data.message || "Question submitted successfully");
        onRequestClose();
        fetchDataExam();
      } else {
        message.error("Failed to submit answers");
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">Question Details</h4>
            <div className="iconWrapper cursor-pointer" onClick={onRequestClose}><X /></div>
          </div>
        </div>
      </div>
      <div className="modalBody">
        <div className="row px-4 m-0 gap-5 mt-4 ">
          <div className="col-md-4 m-0 p-0">
            <label>{currentIndex + 1}/{questionsData.length}</label>
            <div className="questionBarProgress">
              {questionsData.map((_, index) => (
                <div className={`dot ${index <= currentIndex ? "dotWhite" : ""}`} key={index}></div>
              ))}
              <div
                className="progressBar"
                style={{ width: `${((currentIndex + 1) / questionsData.length) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className="col-md-7 d-flex justify-content-between align-items-end">
            <p>Marks: {currentQuestion.marks}</p>
            <p>Time Left: {timeLeft}s</p>
          </div>
        </div>
        <div className="row px-4 mt-5">
          <div className="col-md-12 mb-4">
            <p className="fw-bold">{currentQuestion.question}</p>
          </div>

          <div className="col-md-12 mb-4">
            <textarea
              className="form-control"
              rows="4"
              placeholder="Type your answer here"
              value={userAnswers[currentQuestion.id] || ""}
              onChange={handleAnswerChange}
            />
          </div>
        </div>
      </div>
      <div className="row px-4 m-0 mt-3">
        <div className="col-md-12 d-flex justify-content-end gap-3">
          <button
            className="btn btn-primary px-4 py-2"
            onClick={() => {
              if (currentIndex + 1 === questionsData.length) {
                handleTimeOut(); // Handle last question timeout
              } else {
                setCurrentIndex((prev) => Math.min(prev + 1, questionsData.length - 1));
                setTimeLeft(questionsData[currentIndex + 1].time * 60);
              }
            }}
          >
            {currentIndex + 1 === questionsData.length ? "Finish" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewQuestionStudent;
