import React, { useState } from "react";
import SurveysT from "../../Components/tabs/SurveysTabs/SurveysT";
import Feedback from "../../Components/tabs/SurveysTabs/Feedback";
import NcAssesment from "../../Components/tabs/SurveysTabs/NcAssesment";
import SNE from "../../Components/tabs/SurveysTabs/SNE";
import TeacherObservation from "../../Components/tabs/SurveysTabs/TeacherObservation";

function Surveys() {
  const tabs = [
    "Surveys",
    "Feedbacks",
    "Neuro Cognitive Assessment",
    "SNE Surveys With Parents",
    "Teachers ClassRoom Observations",
  ];
  const [activeTab, setActiveTab] = useState("Surveys"); // Default tab
  const [surveys, setSurveys] = useState([]);
  const [ncCognitive, setNcCognitive] = useState([]);
  const [sNE, setSNE] = useState([]);
  const [feeBills, setFeeBills] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Tabs */}
      <div className="flex space-x-4 border-2 border-gray-100 shadow-xl mb-4 pb-3 pt-2 rounded-b-xl">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 ${
              activeTab === tab ? "text-white bg-[#241763]" : "bg-gray-200 text-gray-700"} rounded-t-md` }
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div>
        {activeTab === "Surveys" && (
          <SurveysT
            surveys={surveys}
            setSurveys={setSurveys}
          />
        )}

        {activeTab === "Feedbacks" && (
          <Feedback feeBills={feeBills} setFeeBills={setFeeBills} />
        )}

        {activeTab === "Neuro Cognitive Assessment" && (
          <NcAssesment
          ncCognitive = {ncCognitive}
          setNcCognitive = {setNcCognitive}  
          />
        )}

        {activeTab === "SNE Surveys With Parents" && (
          <SNE
          sNE = {sNE}
          setSNE = {setSNE}

          />
        )}
        {activeTab === "Teachers ClassRoom Observations" && (
          <TeacherObservation/>
        )}
      </div>
    </div>
  );
}

export default Surveys;
