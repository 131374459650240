import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const TaxHoldingModal = ({ isOpen, onClose, onSubmit, initialData, employees }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const handleFormSubmit = (data) => {
    onSubmit({
      ...data,
      employeeId: parseInt(data.employeeId, 10), // Store Employee ID, not Name
    });
    reset();
    onClose();
  };

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((key) => {
        setValue(key, initialData[key]);
      });
    } else {
      reset();
    }
  }, [initialData, setValue, reset]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-medium">
            {initialData ? "Edit Tax for Employee" : "Apply Tax to Employee"}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">×</button>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium">Session Name</label>
            <input
              type="text"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              defaultValue="March-2024"
              {...register("sessionName")}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Apply Date *</label>
            <input
              type="date"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("appliedDate", { required: true })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Employee Name *</label>
            <select
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("employeeId", { required: true })}
            >
              <option value="" disabled>Select an employee</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))}
            </select>
          </div>
           {/* Tax Type */}
           <div>
            <label className="block text-sm font-medium">Tax Type</label>
            <input
              type="text"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              defaultValue="Income Tax"
              {...register("taxType")}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Amount</label>
            <input
              type="number"
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("amount", { required: true })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Description</label>
            <textarea
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              {...register("description", { required: true })}
              placeholder="Enter description"
            ></textarea>
          </div>
          <div className="flex justify-end space-x-2">
            <button type="button" onClick={onClose} className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300">
              Cancel
            </button>
            <button type="submit" className="bg-[#231763] text-white px-4 py-2 rounded">
              {initialData ? "Update Tax" : "Apply Tax"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaxHoldingModal;
