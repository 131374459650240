import React, { useEffect, useState } from "react";
import Select from "react-select";
import { X } from "lucide-react";

const ManagementModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  options,
  campuses,
  selectedCampuses: initialSelectedCampuses, // Passed from parent component
}) => {
  const [selectedCampuses, setSelectedCampuses] = useState([]);

  useEffect(() => {
    if (isEdit && initialSelectedCampuses && initialSelectedCampuses.length > 0) {
      const selectedCampusOptions = initialSelectedCampuses.map((campus) => ({
        value: campus.id,
        label: campus.name,
      }));
      setSelectedCampuses(selectedCampusOptions);
    } else {
      setSelectedCampuses([]); // Reset when adding a new entry
    }
  }, [isEdit, initialSelectedCampuses]);

  const campusOptions = campuses.map((campus) => ({
    value: campus.id,
    label: campus.name,
  }));

  const handleFormSubmit = (data) => {
    const selectedPermissions = Object.keys(data)
      .filter((key) => key.startsWith("permission_") && data[key])
      .map((key) => key.replace("permission_", ""));

    data.permissions = selectedPermissions;
    data.campusIds = selectedCampuses.map((campus) => campus.value); 

    onSubmit({ ...data, campusIds: data.campusIds }); 
    reset();
    setSelectedCampuses([]);
    setIsModalOpen(false);
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <span>
              <X />
            </span>
          </button>
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
          <h1 className="text-lg font-bold">Personal Details</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">First Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("firstName", { required: true })}
              />
            </div>
            <div>
              <label className="block">Last Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("lastName", { required: true })}
              />
            </div>
            <div>
              <label className="block">Email</label>
              <input
                type="email"
                className="w-full p-2 border rounded"
                {...register("email", { required: true })}
              />
            </div>
            <div>
              <label className="block">Department</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("department")}
              />
            </div>
            <div>
              <label className="block">Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                {...register("password", { required: !isEdit })}
              />
            </div>
            <div>
              <label className="block">Confirm Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                {...register("confirmPassword", { required: !isEdit })}
              />
            </div>
          </div>
          <h1 className="text-lg font-bold mt-4">Select Campuses</h1>
          <div>
            <label className="block mb-2">Campuses</label>
            <Select
              options={campusOptions}
              isMulti
              value={selectedCampuses}
              onChange={(selectedOptions) => setSelectedCampuses(selectedOptions)}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <h1 className="text-lg font-bold mt-4">Assign Permissions</h1>
          <div className="grid grid-cols-3 gap-4">
            {options.length > 0 ? (
              options.map((permission) => (
                <div key={permission.id}>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      {...register(`permission_${permission.name}`)}
                    />
                    <span>{permission.name}</span>
                  </label>
                </div>
              ))
            ) : (
              <p>Loading permissions...</p>
            )}
          </div>

          <div className="flex justify-end space-x-4 mt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-[#241763] text-white rounded"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManagementModal;