import React, { useEffect, useRef } from "react";
import nodeVideo from "../../assets/videos/node.mp4";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";

const VideoChat = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get camera permission and stream
    const getCamera = async () => {
      try {
        // First ensure any existing tracks are stopped
        if (videoRef.current && videoRef.current.srcObject) {
          const tracks = videoRef.current.srcObject.getTracks();
          tracks.forEach(track => track.stop());
        }

        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera:", err);
        // Show user-friendly error message for camera in use
        if (err.name === "NotReadableError" || err.message.includes("Camera")) {
          alert("Camera is being used by another application. Please close other apps using the camera and try again.");
        } else {
          alert("Error accessing camera. Please check camera permissions.");
        }
      }
    };

    getCamera();

    // Cleanup function to stop camera stream
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, []);

  const handleDisconnect = () => {
    // Stop camera stream
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
    // Navigate to home page
    navigate('/home');
  };

  return (
    <div className="d-flex vh-80">
      <div className="w-50 position-relative">
        <video 
          ref={videoRef}
          autoPlay
          playsInline
          className="w-100 h-85 object-fit-cover"
          style={{height: '85vh', border: '3px solid #2a0d60'}}
        />
        <button
          id="f"
          onClick={handleDisconnect}
          className="position-absolute bottom-0 start-50 translate-middle-x mb-3"
          style={{
            backgroundColor: '#ff0000',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          <FontAwesomeIcon icon={faVideoSlash} className="me-2" />
          Disconnect
        </button>
      </div>
      <div className="w-50">
        <video
          src={nodeVideo}
          autoPlay
          controls
          className="w-100 h-85 object-fit-cover"
          style={{height: '85vh'}}
        />
      </div>
    </div>
  );
};

export default VideoChat;
