import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOvertime, updateOvertimeStatus } from "../../../redux/overtimeSlice";
import { fetchEmployees } from "../../../redux/employeeSlice";

const EmployeesOverTime = () => {
  const dispatch = useDispatch();
  const { overtimeRecords, status } = useSelector((state) => state.overtime);
  const { employees } = useSelector((state) => state.employees);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  useEffect(() => {
    dispatch(fetchOvertime());
    dispatch(fetchEmployees());
  }, [dispatch]);

  const handleApprove = (id) => {
    dispatch(updateOvertimeStatus({ id, status: "Approved", approvedBy: 1 }));
  };
  
  const handleReject = (id) => {
    dispatch(updateOvertimeStatus({ id, status: "Rejected", approvedBy: 1 }));
  };

  return (
    <div className="p-4">
      {/* Header Section */}
      <div className="mb-4">
        <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-2">
          Department Name <span className="text-red-500">*</span>
        </label>
        <div className="flex items-center space-x-4">
          <select
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 w-1/3"
          >
            <option value="">All Departments</option>
            {employees &&
              [...new Set(employees.map((emp) => emp.department))].map((dept) => (
                <option key={dept} value={dept}>
                  {dept}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Table Section */}
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-200">
          <thead className="bg-gray-100 py-4 border-b">
            <tr>
              <th className="py-2">EMPLOYEE NAME</th>
              <th>DEPARTMENT</th>
              <th>DATE</th>
              <th>TOTAL HOURS</th>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  Loading...
                </td>
              </tr>
            ) : (
              overtimeRecords
                .filter((o) =>
                  selectedDepartment
                    ? employees.find((e) => e.id === o.employeeId)?.department === selectedDepartment
                    : true
                )
                .map((overtime) => {
                  const employee = employees.find((emp) => emp.id === overtime.employeeId);
                  return (
                    <tr key={overtime.id} className="text-sm border-b">
                      <td className="py-2">
                        {employee?.firstName} {employee?.lastName}
                      </td>
                      <td>{employee?.department}</td>
                      <td>{new Date(overtime.date).toLocaleDateString()}</td>
                      <td>{overtime.totalHours} hrs</td>
                      <td>{overtime.status}</td>
                      <td className="flex space-x-2">
                        <button
                          className="bg-green-500 text-white px-2 py-1 rounded text-xs"
                          onClick={() => handleApprove(overtime.id)}
                          disabled={overtime.status !== "Pending"}
                        >
                          Approve
                        </button>
                        <button
                          className="bg-red-500 text-white px-2 py-1 rounded text-xs"
                          onClick={() => handleReject(overtime.id)}
                          disabled={overtime.status !== "Pending"}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeesOverTime;
