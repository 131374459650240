import React from 'react'

const BankDistributionSlip = () => {
  return (
    <div>
      
      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
          <tr className=" border-b bg-[#241763]/10 ">
              <th className="p-2">Emp code</th>
              <th>Emp Name</th>
              <th>Total payable</th>
              <th>Bank Name</th>
              <th>Account Name</th>
              <th>Account Number</th>
              <th>Bank Transfer Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {/* {filteredFeeBills.length > 0 ? (
              filteredFeeBills.map((bill, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{bill.studentId}</td>
                    <td>{bill.studentName}</td>
                    <td>{bill.feeCode}</td>
                    <td>{bill.billingCycleDate}</td>
                    <td>{bill.overDue}</td>
                    <td>{bill.paymentStatus}</td>
                    <td className="space-x-2 flex items-center py-2">
                     
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="7">
                        <div className="bg-gray-100 p-4">
                          <div>Additional details about the bill...</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Fee Bills Found
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BankDistributionSlip
