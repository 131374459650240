import React from 'react'

const WellnessSuite = () => {
  return (
    <div>
      wellness suit
    </div>
  )
}

export default WellnessSuite
