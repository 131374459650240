import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "lucide-react"; 

const CustomFilter = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const isArabic = i18n.language;
  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const truncateText = (text) => {
    if (text.length > 12) {
      return `${text.slice(0, 12)}...`;
    }
    return text;
  };

  return (
    <div className="relative w-full">
      <button
        className={`w-full p-2 border rounded flex justify-between items-center bg-white ${isArabic === "sa" ? "flex-row-reverse" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate" title={value || placeholder}>
          {truncateText(value || placeholder)}
        </span>
        <ChevronDown className={`ml-2 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <ul
          className="absolute z-10 bg-white border rounded mt-2 w-full shadow-lg max-h-48 overflow-y-auto"
          style={{ maxHeight: '12rem' }} 
        >
          
          {placeholder && (
            <li
              className={`p-2 hover:bg-gray-200 cursor-pointer ${!value ? 'font-bold' : ''} ${isArabic === "sa" ? "text-end" : "text-start"}`}
              onClick={() => handleSelect('')}
            >
              {placeholder}
            </li>
          )}

          {options.map((option, index) => (
            <li
              key={index}
              className={`p-2 hover:bg-gray-200 cursor-pointer ${value === option ? 'font-bold' : ''} ${isArabic === "sa" ? "text-end" : "text-start"}`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomFilter;
