import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/stipends`;

// Fetch Stipends
export const fetchStipends = createAsyncThunk("stipends/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Add Stipend
export const addStipend = createAsyncThunk("stipends/add", async (stipendData) => {
  const response = await axios.post(API_URL, stipendData);
  return response.data;
});

// Update Stipend
export const updateStipend = createAsyncThunk("stipends/update", async ({ id, updatedData }) => {
  const response = await axios.put(`${API_URL}/${id}`, updatedData);
  return response.data;
});

// Delete Stipend
export const deleteStipend = createAsyncThunk("stipends/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const stipendSlice = createSlice({
  name: "stipends",
  initialState: {
    stipends: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStipends.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStipends.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stipends = action.payload;
      })
      .addCase(fetchStipends.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addStipend.fulfilled, (state, action) => {
        state.stipends.push(action.payload);
      })
      .addCase(updateStipend.fulfilled, (state, action) => {
        const index = state.stipends.findIndex((s) => s.id === action.payload.id);
        if (index !== -1) {
          state.stipends[index] = action.payload;
        }
      })
      .addCase(deleteStipend.fulfilled, (state, action) => {
        state.stipends = state.stipends.filter((s) => s.id !== action.payload);
      });
  },
});

export default stipendSlice.reducer;
