import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxes, addTax, updateTax, deleteTax } from "../../redux/taxSlice";
import { fetchEmployees } from "../../redux/employeeSlice"; // Import employee slice
import { Pencil, Trash2 } from "lucide-react";
import TaxHoldingModal from "../../modals/Hrms/TaxHoldingModal";

const TaxHolding = () => {
  const dispatch = useDispatch();
  
  // Fetch data from Redux store
  const { taxes, status } = useSelector((state) => state.taxes);
  const { employees } = useSelector((state) => state.employees);

  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [editingTax, setEditingTax] = useState(null);

  useEffect(() => {
    dispatch(fetchTaxes());
    dispatch(fetchEmployees()); // Fetch employees on component mount
  }, [dispatch]);

  // Handle Add or Update Tax
  const handleApplyTax = (data) => {
    if (editingTax) {
      dispatch(updateTax({ id: editingTax.id, updatedData: data }));
    } else {
      dispatch(addTax(data));
    }
    setIsApplyModalOpen(false);
    setEditingTax(null);
  };

  // Handle Delete Tax
  const handleDeleteTax = (id) => {
    dispatch(deleteTax(id));
  };

  // Handle Edit Tax
  const handleEditTax = (tax) => {
    setEditingTax(tax);
    setIsApplyModalOpen(true);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Session Name: March-2024</h1>
        <button
          onClick={() => setIsApplyModalOpen(true)}
          className="bg-[#241763] text-white px-4 py-2 rounded"
        >
          Apply Tax
        </button>
      </div>
      {status === "loading" ? (
        <p>Loading taxes...</p>
      ) : (
        <div className="overflow-x-auto overflow-visible">
          <table className="table-auto w-full mt-6 text-sm">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>EMP. CODE</th>
                <th>EMPLOYEE NAME</th>
                <th>DATE</th>
                <th>DESCRIPTION</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {taxes.map((tax) => (
                <tr key={tax.id}>
                  <td>{tax.Employee?.id || "N/A"}</td>
                  <td>
                    <div>
                      <div className="font-medium">
                        {`${tax.Employee?.firstName} ${tax.Employee?.lastName}`}
                      </div>
                      <div className="text-sm text-gray-500">
                        {tax.Employee?.department || "N/A"}
                      </div>
                    </div>
                  </td>
                  <td>{new Date(tax.appliedDate).toLocaleDateString()}</td>
                  <td>{tax.description}</td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEditTax(tax)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteTax(tax.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <TaxHoldingModal
        isOpen={isApplyModalOpen}
        onClose={() => {
          setEditingTax(null);
          setIsApplyModalOpen(false);
        }}
        onSubmit={handleApplyTax}
        initialData={editingTax}
        employees={employees} // Pass employees to the modal
      />
    </div>
  );
};

export default TaxHolding;
