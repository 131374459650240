import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, SendHorizonal, ChevronUp, ChevronDown } from "lucide-react";
import ParentModal from "../../modals/admin/ParentModal";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import {
  addParent,
  updateParent,
  deleteParent,
  fetchParents,
  setPageNumber,
} from "../../redux/ParentSlice";
import NotificationsModal from "../../Components/Common/NotificationModal";
import { uploadImage } from "../../utils";
import { postRequest } from "../../services";

const Parents = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const parents = useSelector((state) => state.parents.parents);
  const loading = useSelector((state) => state.parents.loading);
  const error = useSelector((state) => state.parents.error);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationsModal, setIsNotificationsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [selectedParents, setSelectedParents] = useState([]);
  const [currentParentId, setcurrentParentId] = useState(null);
  const [currentPicture, setCurrentPicture] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [locationFilter, setLocationFilter] = useState("");

  const totalCount = useSelector((state) => state.parents.totalCount);
  const pageSize = useSelector((state) => state.parents.pageSize);
  const pageNumber = useSelector((state) => state.parents.pageNumber);

  useEffect(() => {
    dispatch(fetchParents({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  const onSubmit = async (data, selectedFile) => {
    let fileUrl = null;
    if (selectedFile) {
      fileUrl = await uploadImage(selectedFile);
    } else if (isEdit) {
      fileUrl = currentPicture;
    }

    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    const transformedPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      email: data.email,
      phone: data.phone,
      password: data.password,
      confirmPassword: data.confirmPassword,
      location: data.location,
      dob: data.dob
    };
    
    if (fileUrl) {
      transformedPayload["profilePicture"] = fileUrl || "";
    }

    if (isEdit) {
      dispatch(updateParent({ id: currentParentId, updatedParent: transformedPayload }));
    } else {
      dispatch(addParent(transformedPayload)); // Send FormData to backend
    }

    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const parent = parents.find((parent) => parent.id === id);
    if (!parent) {
      console.error("Parent not found for ID:", id);
      return;
    }

    // Pre-fill the form fields for editing
    setValue("firstName", parent.firstName);
    setValue("lastName", parent.lastName);
    setValue("gender", parent.gender);
    setValue("email", parent.email);
    setValue("phone", parent.phone);
    setValue("location", parent.location);
    setValue(
      "dob",
      parent.dob ? new Date(parent.dob).toISOString().split("T")[0] : ""
    );
    setValue("password", "");
  

    // Set the current parent ID for the edit operation
    setcurrentParentId(id);
    setCurrentPicture(parent.profilePicture);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleBulkUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch action to upload the bulk file
    // dispatch(addParentBulk(formData))
    //   .then(() => toast.success("Bulk upload successful!"))
    //   .catch(() => toast.error("Bulk upload failed!"));
  };
  
  const handleModalClose = () => {
    setUploadType(""); // Reset the dropdown value
    setIsEdit(false);
  };

  const handleUploadTypeChange = (type) => {
    setUploadType(type);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteParent(id)).unwrap();
      toast.success("Parent deleted successfully");
    } catch (error) {
      toast.error("Failed to delete parent");
    }
  };

  const uniqueLocation = Array.from(
    new Set(parents.map((parent) => parent.location))
  );

  const toggleParentSelection = (parentId, parentName) => {
    setSelectedParents((prev) => {
      const isSelected = prev.some((parent) => parent.id === parentId);
      if (isSelected) {
        return prev.filter((parent) => parent.id !== parentId);
      } else {
        return [...prev, { id: parentId, name: parentName }];
      }
    });
  };

  const toggleSelectAll = () => {
    if (selectedParents.length === parents.length) {
      setSelectedParents([]); // Deselect all
    } else {
      setSelectedParents(parents.map((parent) => ({
        id: parent.id,
        name: `${parent.firstName} ${parent.lastName}`
      })
      ));
    }
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const onSendNotification = async (payload) => {
    try {
      // Create the request data according to the API's expected format
      const requestData = {
        UserIds: payload.recipients.map(recipient => recipient.id),
        Title: payload.title,
        Body: payload.message
      };
      
      // Pass the requestData directly to postRequest
      const response = await postRequest('/api/Notifications/send', requestData);
      
      if (response.status === 200) {
        toast.success("Notification sent successfully!");
      } else {
        let errorMessage = "Failed to send notification";
        
        // Try to extract specific error messages
        if (response.data?.errors) {
          const errorMessages = Object.values(response.data.errors)
            .flat()
            .join(", ");
          errorMessage += ": " + errorMessages;
        }
        
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      
      // Handle axios error responses
      if (error.status === 400 && error.data?.errors) {
        const errorMessages = Object.values(error.data.errors)
          .flat()
          .join(", ");
        toast.error("Failed to send notification: " + errorMessages);
      } else {
        toast.error("Failed to send notification: " + (error.message || "Unknown error"));
      }
    }
  };

  const handleSubmitNotification = (payload) => {
    onSendNotification(payload);
    setIsModalOpen(false);
    setSelectedParents([]);
  };

  const filterParents = (parents, query) => {
    if (!query) {
      return parents;
    }
    return parents.filter((parent) => {
      const usernameMatch = parent.firstName
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = parent.id && parent.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredParents = filterParents(parents, searchQuery);
  const parentsToDisplay = filteredParents.filter((parent) => {
    const matchesLocation = locationFilter
      ? parent.location === locationFilter
      : true;
    return matchesLocation;
  });

  const handlePageChange = (newPage) => {
    if (newPage !== pageNumber) {
      dispatch(setPageNumber(newPage));
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);
  const currentParents = parentsToDisplay;

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
         <h2 className="text-lg font-semibold">
        Total Parents: {totalCount || 0}
      </h2>

      <div className="mb-4 flex gap-4">
        <div className="flex flex-grow items-end">
          <input
            type="text"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              dispatch(fetchParents({ pageNumber: 1, pageSize }));
            }}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueLocation}
            value={locationFilter}
            onChange={(value) => setLocationFilter(value)}
            placeholder="All locations"
          />
        </div>
        <div>
          {/* Dropdown for Upload Type */}
          <select
            className="flex items-center justify-center w-full bg-[#241763] text-white p-2 rounded space-x-2"
            onChange={(e) => handleUploadTypeChange(e.target.value)}
            value={uploadType}
          >
            <option value="" disabled selected>
              Add Parent
            </option>
            <option value="single">Add Parent</option>
            <option value="bulk">Bulk Upload Parents</option>
          </select>
        </div>
        <div
          className="bg-[#241763] p-2 rounded"
          onClick={() => setIsNotificationsModal(true)}
        >
          <SendHorizonal className="text-white w-6 h-6 group-hover:text-white" />
        </div>
      </div>

      {/* Modal for Adding/Editing parent */}
      <ParentModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        setValue={setValue}
        reset={reset}
        currentPicture={currentPicture}
        handleBulkUpload={handleBulkUpload}
        uploadType={uploadType}
        onModalClose={handleModalClose}
      />

      {isNotificationsModal && (
        <NotificationsModal
          selectedItems={selectedParents}
          toggleItemSelection={toggleParentSelection}
          handleSubmitNotification={handleSubmitNotification}
          onClose={() => setIsNotificationsModal(false)}
        />
      )}

      <div className="overflow-x-auto overflow-visible">
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-[#241763]"></div>
            <span className="ml-2 text-[#241763]">Loading...</span>
          </div>
        ) : (
          <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
            <table className="table-auto w-full mt-6">
              <thead className="py-4 border-b font-bold">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedParents.length > 0 && selectedParents.length === parents.length}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>DOB</th>
                  <th>location</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {currentParents.map((parent, index) => (
                  <>
                    <tr
                      key={parent.id}
                      className="p-2 py-2 hover:bg-gray-200 space-y-2"
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedParents.some((p) => p.id === parent.id)}
                          onChange={() =>
                            toggleParentSelection(
                              parent.id,
                              `${parent.firstName} ${parent.lastName}`
                            )
                          }
                        />
                      </td>
                      <td>
                        <img
                          src={parent.profilePicture}
                          alt="parent Profile"
                          className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                        />
                      </td>
                      <td>{`${parent.firstName} ${parent.lastName}`}</td>
                      <td>{parent.gender}</td>
                      <td>{parent.email}</td>

                      <td className="text-blue-500">{parent.phone}</td>
                      <td>{new Date(parent.dob).toLocaleDateString()}</td>
                      <td>{parent.location}</td>
                      <td>{parent.isActive ? "Yes" : "No"}</td>

                      <td className="space-x-2 flex items-center py-2">
                        <button
                          className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                          onClick={() => handleEdit(parent.id)}
                        >
                          <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                        </button>
                        <button
                          className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                          onClick={() => handleDelete(parent.id)}
                        >
                          <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                        </button>

                        <div
                          onClick={() => toggleView(index)}
                          className="cursor-pointer  hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                        >
                          {expandedRowIndex === index ? (
                            <ChevronUp
                              size={16}
                              className="group-hover:text-white"
                            />
                          ) : (
                            <ChevronDown
                              size={16}
                              className="group-hover:text-white"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    {expandedRowIndex === index && (
                      <tr>
                        <td colSpan="10">
                          {parent.childerns?.length ? (
                            <div className="bg-gray-50 p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                              <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-lg font-bold text-blue-500 mb-4">
                                  Children
                                </h2>
                                <table className="w-full text-sm">
                                  <tbody>
                                    {parent.childerns?.map(children => {
                                      return (<tr>
                                        <td className="font-medium text-gray-600">
                                          <img
                                            src={children.profilePicture}
                                            alt="profile"
                                            className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                                          />
                                        </td>
                                        <td>{children.name}</td>
                                      </tr>);
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            <div className="bg-gray-50 flex justify-center">No childrens for this parent</div>
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={pageNumber === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`px-3 py-1 mx-1 border rounded ${
                pageNumber === i + 1 ? "bg-[#241763] text-white" : "bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={pageNumber === totalPages}
        >
          Next
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default Parents;
