import React, { useState } from "react";
import WarningModal from "../../modals/Hrms/WarningModal";
import HolidayModal from "../../modals/Hrms/HolidayModal";
import LeaveModal from "../../modals/Hrms/LeaveModal";
import FineModal from "../../modals/Hrms/FineModal";
import { useForm } from "react-hook-form";

const HrController = () => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [isHolidayModalOpen, setHolidayModalOpen] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const [warnings, setWarnings] = useState([
    {
      reason: "No Show",
      description: "Absence from work without informing the manager",
    },
    {
      reason: "Misconduct",
      description: "Inadequate behavior shown by the employee",
    },
  ]);

  const [holidays, setHolidays] = useState([
    {
      name: "EID UL FITR",
      start: "2024-04-08",
      end: "2024-04-12",
      description: "Festival holiday",
    },
  ]);

  const [leaveTypes, setLeaveTypes] = useState([
    { leaveType: "Compensatory Leave" },
    { leaveType: "Annual Leaves" },
    { leaveType: "Maternity Leave" },
    { leaveType: "Sick Leave" },
    { leaveType: "Casual Leave" },
  ]);

  const [isFineModalOpen, setFineModalOpen] = useState(false);
  const [fines, setFines] = useState([
    {
      empCode: "EMP001",
      empName: "John Doe",
      fineReason: "Late Submission",
      fineAmount: "$50",
      fineDate: "2025-01-10",
    },
    {
      empCode: "EMP002",
      empName: "Jane Smith",
      fineReason: "Policy Violation",
      fineAmount: "$100",
      fineDate: "2025-01-09",
    },
  ]);

  const defaultValues = {
    gratuityStart: "365",
    maxLoanAllowed: "5",
    minutesPerDeduction: "15",
    maxInstallment: "12",
    annualLeaves: "21",
    sickLeaves: "10",
    casualLeaves: "7",
  };

  // Use React Hook Form
  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  // Function to handle form submission
  const onSubmit = (data) => {
    console.log("Updated Values:", data);
    alert("HR Configuration updated successfully!");
  };

  const addWarning = (data) => setWarnings([...warnings, data]);
  const addHoliday = (data) => setHolidays([...holidays, data]);
  const addLeaveType = (data) => setLeaveTypes([...leaveTypes, data]);
  const addFine = (data) => {setFines([...fines, data]);};

  return (
    <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white rounded-lg shadow p-4 h-full md:h-[30vh]">
        <h2 className="font-semibold mb-4">Sessions Detail</h2>
        <table className="w-full text-sm text-left border-collapse mt-2">
          <thead className="bg-[#241763]/10">
            <tr>
              <th className="border-b p-2">Session Name</th>
              <th className="border-b p-2">Start</th>
              <th className="border-b p-2">End</th>
              <th className="border-b p-2">Status</th>
              <th className="border-b p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-b p-3">March-2024</td>
              <td className="border-b p-2">2024-02-21</td>
              <td className="border-b p-2">2024-03-20</td>
              <td className="border-b p-2">
                <span className="text-green-500 font-medium">Running</span>
              </td>
              <td className="border-b p-2">
                <button className="text-red-500">🗑</button>
              </td>
            </tr>
            <tr>
              <td className="border-b p-2">February-2024</td>
              <td className="border-b p-2">2024-01-21</td>
              <td className="border-b p-2">2024-02-20</td>
              <td className="border-b p-2">
                <span className="text-orange-500 font-medium">Closed</span>
              </td>
              <td className="border-b p-2">
                <button className="text-red-500">🗑</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Warning Reasons Detail Card */}
      <div className=" overflow-y-auto h-[40vh] bg-white rounded-lg shadow p-4">
        <div className="">
          <div className="flex justify-between items-center mb-4">
            <h2 className=" font-semibold">Warning Reasons Detail</h2>
            <button
              onClick={() => setWarningModalOpen(true)}
              className="bg-[#241763] text-white px-4 py-2 rounded"
            >
              ⚠️ Add New
            </button>
          </div>
          <table className="w-full text-left text-sm border-collapse">
            <thead className="bg-[#241763]/10">
              <tr>
                <th className="border-b p-2">Warning Reason</th>
                <th className="border-b p-2">Description</th>
                <th className="border-b p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {warnings.map((warning, index) => (
                <tr key={index}>
                  <td className="border-b p-3">{warning.reason}</td>
                  <td className="border-b p-2">{warning.description}</td>
                  <td className="border-b p-2">
                    <button
                      onClick={() =>
                        setWarnings(warnings.filter((_, i) => i !== index))
                      }
                      className="text-red-500"
                    >
                      🗑
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Holidays Detail Card */}
      <div className="bg-white rounded-lg shadow p-4 h-[30vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-semibold">Holidays Detail</h2>
          <button
            onClick={() => setHolidayModalOpen(true)}
            className="bg-[#241763] text-white px-4 py-2 rounded"
          >
            🎁 Include Holiday
          </button>
        </div>
        <table className="w-full text-left text-sm border-collapse">
          <thead className="bg-[#241763]/10 mb-2">
            <tr>
              <th className="border-b p-2">Holiday Name</th>
              <th className="border-b p-2">Start</th>
              <th className="border-b p-2">End</th>
              <th className="border-b p-2">Description</th>
              <th className="border-b p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {holidays.map((holiday, index) => (
              <tr key={index}>
                <td className="border-b p-3">{holiday.name}</td>
                <td className="border-b p-2">{holiday.start}</td>
                <td className="border-b p-2">{holiday.end}</td>
                <td className="border-b p-2">{holiday.description}</td>
                <td className="border-b p-2">
                  <button
                    onClick={() =>
                      setHolidays(holidays.filter((_, i) => i !== index))
                    }
                    className="text-red-500"
                  >
                    🗑
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Leave Types Card */}
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-semibold">Leave Types</h2>
          <button
            onClick={() => setLeaveModalOpen(true)}
            className="bg-[#241763] text-white px-4 py-2 rounded"
          >
            <span className="text-lg">🤒</span> Add Leave Type
          </button>
        </div>
        <table className="w-full text-sm text-left border-collapse">
          <thead className="bg-[#241763]/10">
            <tr>
              <th className="border-b p-2">Leave Type</th>
              <th className="border-b p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {leaveTypes.map((leave, index) => (
              <tr key={index}>
                <td className="border-b p-3">{leave.leaveType}</td>
                <td className="border-b p-2">
                  <button
                    onClick={() =>
                      setLeaveTypes(leaveTypes.filter((_, i) => i !== index))
                    }
                    className="text-red-500"
                  >
                    🗑
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* HR Configuration Card */}
      <div className="bg-white rounded-lg shadow p-4 h-auto  md:col-span-2">
      <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-4 h-auto md:col-span-2"
    >
      <h2 className="font-semibold mb-4">HR Configuration</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {/* Input Fields */}
        <div>
          <label className="block text-sm font-medium mb-2">
            Gratuity Start (Days)
          </label>
          <input
            type="text"
            {...register("gratuityStart")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">
            Max Loan Allowed (Times of Salary)
          </label>
          <input
            type="text"
            {...register("maxLoanAllowed")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">
            Minutes Per Deduction
          </label>
          <input
            type="text"
            {...register("minutesPerDeduction")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">
            Max Installment
          </label>
          <input
            type="text"
            {...register("maxInstallment")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">
            Annual Leaves (After Probation)
          </label>
          <input
            type="text"
            {...register("annualLeaves")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">
            Sick Leaves (Per Fiscal Year)
          </label>
          <input
            type="text"
            {...register("sickLeaves")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">
            Casual Leaves (Per Fiscal Year)
          </label>
          <input
            type="text"
            {...register("casualLeaves")}
            className="w-full border rounded px-3 py-2 text-sm"
          />
        </div>
        <div className="md:mt-6">
          <button
            type="submit"
            className="bg-[#241763] w-full text-white px-6 py-2 rounded"
          >
            Update
          </button>
        </div>
      </div>
    </form>
      </div>
      
{/* Fine Detail Card */}
<div className="bg-white rounded-lg shadow p-4 md:col-span-2">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-semibold">Fine Detail</h2>
          <button
            onClick={() => setFineModalOpen(true)}
            className="bg-[#241763] text-white px-4 py-2 rounded"
          >
            <span className="text-lg">💰</span> Add Fine
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full text-sm text-left border-collapse">
            <thead className="bg-[#241763]/10">
              <tr>
                <th className="border-b p-2">Emp Code</th>
                <th className="border-b p-2">Emp Name</th>
                <th className="border-b p-2">Fine Reason</th>
                <th className="border-b p-2">Fine Amount</th>
                <th className="border-b p-2">Date</th>
                <th className="border-b p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {fines.map((fine, index) => (
                <tr key={index}>
                  <td className="border-b p-3">{fine.empCode}</td>
                  <td className="border-b p-2">{fine.empName}</td>
                  <td className="border-b p-2">{fine.fineReason}</td>
                  <td className="border-b p-2">{fine.fineAmount}</td>
                  <td className="border-b p-2">{fine.fineDate}</td>
                  <td className="border-b p-2">
                    <button
                      className="text-red-500"
                      onClick={() =>
                        setFines(fines.filter((_, i) => i !== index))
                      }
                    >
                      🗑
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    

      {/* Modals */}
      <WarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setWarningModalOpen(false)}
        onSubmit={addWarning}
      />
      <HolidayModal
        isOpen={isHolidayModalOpen}
        onClose={() => setHolidayModalOpen(false)}
        onSubmit={addHoliday}
      />
      <LeaveModal
        isOpen={isLeaveModalOpen}
        onClose={() => setLeaveModalOpen(false)}
        onSubmit={addLeaveType}
      />
      <FineModal
        isOpen={isFineModalOpen}
        onClose={() => setFineModalOpen(false)}
        onSubmit={addFine}
      />
    </div>
  );
};

export default HrController;
