import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addJob, updateJob } from '../../redux/jobsSlice';

const PostJobModal = ({ onClose, initialData = {} }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
      defaultValues: {
        PostTitle: '',
        NumberOfPost: '',
        PostingDate: new Date().toISOString().split('T')[0],
        description: '',
        educationalRequirement: '',
        skillSet: '',
        dutiesAndResponsibilities: ''
      }
    });
  
    useEffect(() => {
      if (initialData) {
        // Populate form fields with initial data for editing
        Object.keys(initialData).forEach((key) => {
          // Map keys to match API response
          const apiKey = key === 'title' ? 'PostTitle' : 
                         key === 'vacancies' ? 'NumberOfPost' : 
                         key === 'lastDate' ? 'PostingDate' : 
                         key;
          setValue(apiKey, initialData[key]);
        });
      }
    }, [initialData, setValue]);
  
    const handleFormSubmit = (data) => {
      const jobData = {
        PostTitle: data.PostTitle,
        NumberOfPost: parseInt(data.NumberOfPost, 10),
        PostingDate: data.PostingDate || new Date().toISOString(),
        description: data.description,
        educationalRequirement: data.educationalRequirement,
        skillSet: data.skillSet,
        dutiesAndResponsibilities: data.dutiesAndResponsibilities,
      };
  
      // If editing an existing job, dispatch updateJob
      if (initialData && initialData.id) {
        dispatch(updateJob({ id: initialData.id, updatedData: jobData }));
      } else {
        // If creating a new job, dispatch addJob
        dispatch(addJob(jobData));
      }
  
      onClose();
      reset();
    };
  
    return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-md w-full max-w-2xl overflow-y-auto max-h-full">
          <h2 className="text-2xl font-bold mb-4">
            {initialData && initialData.id ? 'Edit Job' : 'Post a New Job'}
          </h2>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <input
                type="text"
                {...register('PostTitle', { required: 'Posting title is required' })}
                placeholder="Posting Title"
                className="p-2 border border-gray-300 rounded-md w-full"
              />
              <input
                type="number"
                {...register('NumberOfPost', { 
                  required: 'Number of posts is required',
                  min: { value: 1, message: 'At least one post is required' }
                })}
                placeholder="Number of Posts"
                className="p-2 border border-gray-300 rounded-md w-full"
              />
              <input
                type="date"
                {...register('PostingDate')}
                className="p-2 border border-gray-300 rounded-md w-full"
              />
              <textarea
                {...register('educationalRequirement', { required: 'Educational requirement is required' })}
                placeholder="Educational Requirement"
                className="p-2 border border-gray-300 rounded-md w-full"
              />
              <textarea
                {...register('skillSet', { required: 'Skill set is required' })}
                placeholder="Skill Set"
                className="p-2 border border-gray-300 rounded-md w-full"
              />
              <textarea
                {...register('dutiesAndResponsibilities', { required: 'Duties and responsibilities are required' })}
                placeholder="Duties and Responsibilities"
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <textarea
              {...register('description', { required: 'Description is required' })}
              placeholder="Description"
              className="p-2 border border-gray-300 rounded-md w-full mb-4"
              rows="4"
            />
            {Object.values(errors).map((error) => (
              <p key={error.message} className="text-red-500 text-sm">{error.message}</p>
            ))}
            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
              >
                Discard
              </button>
              <button
                type="submit"
                className="bg-[#241763] text-white px-4 py-2 rounded-md"
              >
                Post Job
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default PostJobModal;