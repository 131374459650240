import React, { useState, useEffect } from "react";
import FlexBtn from "../../Components/Common/FlexBtn";
import { exportSvg } from "../../Constant/svgs";
import GradeMcQs from "../../Components/Student/GradeMcQs";
import GradeQuestion from "../../Components/Student/GradeQuestion";
import GradeAssignment from "../../Components/Student/GradeAssignment";
import { getRequest } from "../../services";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";

const GradeBook = () => {

  const [tab, setTab] = useState("McQs");
  const [examData, setExamData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();

 const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(`/api/Grades/subject-Grades?subjectId=${state.subjectId}`);
      const data = response.data.data;
      setExamData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const filteredExamsId = examData.filter(exam => exam.examType === tab);
  console.log("filteredExamsId",filteredExamsId)
  return (
    <div className="container-fluid px-2 py-2 shadow-md bg-white rounded">
      <div className="row">
        <div className="col-md-9  ">
          <h4 className="fw-bold heading24px">Gradebook Overview</h4>
        </div>
        <div className="col-md-3 ">
          <div className="w-100  d-flex align-items-center h-100 gap-3  justify-content-end">
            <FlexBtn
              icon={exportSvg}
              label="Export"
              color="white"
              bgColor="#241763"
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="d-flex align-items-center gap-3 mb-3">
          <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "McQs" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("McQs")}>McQs</button>
          <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "Question" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("Question")}>Question</button>
          <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "Assignment" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("Assignment")}>Assignment</button>
        </div>
      </div>
      <Spin spinning={loading}>
      <div className="row mt-3">
        <div className="col-md-12">
          {tab === "McQs" ?
            <GradeMcQs filteredExamsId={filteredExamsId} />
            :
            tab === "Question" ?
              <GradeQuestion filteredExamsId={filteredExamsId} />
              :
              <GradeAssignment filteredExamsId={filteredExamsId} />
          }
        </div>
      </div>
      </Spin>
    </div>
  );
};

export default GradeBook;