import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HRMS_API_URL || "";
const API_URL = `${BASE_URL}/applyLeave`;
console.log("API URL:", API_URL);


// Fetch Leaves
export const fetchLeaves = createAsyncThunk("leaves/fetch", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

// Apply for Leave
export const applyLeave = createAsyncThunk("leaves/apply", async (leaveData) => {
  const response = await axios.post(API_URL, leaveData);
  return response.data;
});

// Update Leave
export const updateLeave = createAsyncThunk(
  "leaves/update",
  async ({ id, updatedData }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedData);
    return response.data;
  }
);

// Delete Leave
export const deleteLeave = createAsyncThunk("leaves/delete", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

const applyLeaveSlice = createSlice({
  name: "leaves",
  initialState: {
    leaves: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaves.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeaves.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leaves = action.payload;
      })
      .addCase(fetchLeaves.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(applyLeave.fulfilled, (state, action) => {
        state.leaves.push(action.payload);
      })
      .addCase(updateLeave.fulfilled, (state, action) => {
        const index = state.leaves.findIndex((leave) => leave.id === action.payload.id);
        if (index !== -1) {
          state.leaves[index] = action.payload;
        }
      })
      .addCase(deleteLeave.fulfilled, (state, action) => {
        state.leaves = state.leaves.filter((leave) => leave.id !== action.payload);
      });
  },
});

export default applyLeaveSlice.reducer;
