import React, { useState } from "react";
import { Checkbox } from "antd";

const Step1 = () => {

const [selectedChapters, setSelectedChapters] = useState([]);
const [selectedSubLessons, setSelectedSubLessons] = useState([]);
const [selectAll, setSelectAll] = useState(false);

  const syllabus = [
    {
      chapter: "Pakistan and the modern world",
      subLessons: [
        "Introduction to Pakistan",
        "Historical Background",
        "Cultural Aspects",
        "Economic Development",
        "Political Structure",
        "International Relations",
        "Challenges and Opportunities",
        "Future Prospects",
      ],
    },
    {
      chapter: "The Eclipse",
      subLessons: [
        "Understanding Eclipses",
        "Types of Eclipses",
        "Historical Significance",
        "Scientific Explanation",
        "Cultural Interpretations",
        "Eclipse Myths",
        "Observing Eclipses",
        "Eclipse Safety",
      ],
    },
  ];

  
  const handleChapterSelect = (chapter, subLessons) => {
    setSelectedChapters((prevSelected) =>
      prevSelected.includes(chapter)
        ? prevSelected.filter((ch) => ch !== chapter)
        : [...prevSelected, chapter]
    );

    if (!selectedChapters.includes(chapter)) {
      setSelectedSubLessons((prevSelected) => [
        ...prevSelected,
        ...subLessons.filter((lesson) => !prevSelected.includes(lesson)),
      ]);
    } else {
      setSelectedSubLessons((prevSelected) =>
        prevSelected.filter((lesson) => !subLessons.includes(lesson))
      );
    }
  };

  const handleSubLessonSelect = (subLesson) => {
    setSelectedSubLessons((prevSelected) =>
      prevSelected.includes(subLesson)
        ? prevSelected.filter((sl) => sl !== subLesson)
        : [...prevSelected, subLesson]
    );
  };
  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedChapters(syllabus.map((item) => item.chapter));
      setSelectedSubLessons(syllabus.flatMap((item) => item.subLessons));
    } else {
      setSelectedChapters([]);
      setSelectedSubLessons([]);
    }
  };

    return <>
        <Checkbox onChange={handleSelectAll} checked={selectAll}>
            <h4 className="fs-5 fw-bold">Select All Chapters</h4>
        </Checkbox>
        <div className="row g-3 mt-3">
            {syllabus.map((item, index) => (
                <div className="col-md-6" key={index}>
                    <div className="card examDataWrapper">
                        <div className="card-body">
                            <Checkbox
                                onChange={() => handleChapterSelect(item.chapter, item.subLessons)}
                                checked={selectedChapters.includes(item.chapter)}
                            >
                                <h5 className="card-title text-white">{index + 1}. {item.chapter}</h5>
                            </Checkbox>
                            <ul>
                                {item.subLessons.map((lesson, idx) => (
                                    <li key={idx}>
                                        <Checkbox
                                            onChange={() => handleSubLessonSelect(lesson)}
                                            checked={selectedSubLessons.includes(lesson)}
                                        >
                                            <p className="text-white">{index + 1}.{idx + 1}. {lesson}</p>
                                        </Checkbox>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>;
};

export default Step1;
