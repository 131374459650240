import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest, updateRequest, deleteRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  lessons: [],
  loading: false,
  error: null,
};

// Fetch Lessons
export const fetchLessons = createAsyncThunk(
  "lessons/fetchLessons",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/LessonPlan");
      if (response.status !== 200) throw new Error("Failed to fetch lessons");
      return response.data?.data || [] ; // Adjust based on your API response
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add Lesson
export const addLesson = createAsyncThunk(
  "lessons/addLesson",
  async (lesson, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/LessonPlan", lesson);
      if (response.status !== 200) {
        return rejectWithValue("Failed to add  lesson");
      }
      toast.success("Lesson added successfully!");
      return response.data?.data;
    } catch (error) {
      toast.error(error.message || "Failed to add lesson");
      return rejectWithValue(error.message);
    }
  }
);

// Update Lesson
export const updateLesson = createAsyncThunk(
  "lessons/updateLesson",
  async ({ id, updatedLesson }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/LessonPlan/${id}`, updatedLesson);
      if (response.status !== 200) throw new Error("Failed to update lesson");
      toast.success("Lesson updated successfully!");
      return response.data?.data;
    } catch (error) {
      toast.error(error.message || "Failed to update lesson");
      return rejectWithValue(error.message);
    }
  }
);

// Delete Lesson
export const deleteLesson = createAsyncThunk(
  "lessons/deleteLesson",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/LessonPlan/${id}`);
      toast.success("Lesson deleted successfully!");
      return id;
    } catch (error) {
      toast.error(error.message || "Failed to delete lesson");
      return rejectWithValue(error.message);
    }
  }
);

// Lessons Slice
const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessons.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLessons.fulfilled, (state, action) => {
        state.loading = false;
        state.lessons = action.payload;
      })
      .addCase(fetchLessons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || "Failed to fetch lessons");
      })
      .addCase(addLesson.fulfilled, (state, action) => {
        state.lessons.push(action.payload);
      })
      .addCase(updateLesson.fulfilled, (state, action) => {
        const index = state.lessons.findIndex((lesson) => lesson.id === action.payload.id);
        if (index !== -1) {
          state.lessons[index] = action.payload;
        }
      })
      .addCase(deleteLesson.fulfilled, (state, action) => {
        state.lessons = state.lessons.filter((lesson) => lesson.id !== action.payload);
      });
  },
});

export default lessonsSlice.reducer;
