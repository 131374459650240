import React, { useState, useEffect } from "react";
import { Input, Spin } from "antd";
import { ChevronUp, ChevronDown } from "lucide-react";
import { getRequest, postRequest } from "../../services";
import NameAvatar from "../Common/Avatar";
import { message } from 'antd';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const GradeTableQuestion = (props) => {
  const { headingsGrades, filteredExamsId, setExamPublish, setPublishStatus } = props;
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [myQuestion, setMyQuestion] = useState([]);

  const handlePagination = (direction) => {
    setCurrentPage((prev) => (direction === "next" ? prev + 1 : prev - 1));
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(
        `/api/Grades/question-submission-details?examId=${filteredExamsId[currentPage].id}`
      );
      const status = await getRequest(`/api/Grades/ResultStatus?examId=${filteredExamsId[currentPage].id}`);
      setPublishStatus(status.data)
      const data = response.data;
      setExamPublish({
        Exam: filteredExamsId[currentPage],
        Question: data
      });
      setMyQuestion(data || []);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, filteredExamsId[currentPage]?.id]);

  const toggleRow = (index) => {
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(myQuestion.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleSaveMarks = async (item) => {

    const payload = {
      userId: item.student.studentId,
      subjectId: filteredExamsId[currentPage]?.subjectDetails?.subjectId || "",
      examId: filteredExamsId[currentPage]?.id || "",
      remarks: review || "",
      answers: item.questions.map((question) => ({
        questionId: question.questionId || "",
        marks: question.getMarks || 0,
      })),
    };
    // console.log("pa",payload)
    try {
      const response = await postRequest(`/api/Grades/evaluate-questions`, payload);
      if (response.status === 200 && response.statusText) {
        message.success(response.data.message || "Update successfully");
        fetchData();
      } else {
        message.error("Failed to save lesson");
      }
    } catch (error) {
      console.error("Error:", error.message);
      message.error("An error occurred while saving the lesson");

    }
  };

  const handleInputChange = (index, questionIndex, value) => {
    const updatedQuestions = [...myQuestion];
    updatedQuestions[index].questions[questionIndex].getMarks = value;
    setMyQuestion(updatedQuestions);
  };

  const handleInputreview = (e) => {
    setReview(e.target.value);
  };

  return (
    <div className="container-table100">
      <div className="wrap-table100">
        <div className="table100 ver5 m-b-110">
          <div className="table100-head">
            <table>
              <thead>
                <tr className="row100 head">
                  <th className="cell100 column0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  {headingsGrades.map((head, index) => (
                    <th className={`cell100 column${index + 1}`} key={index}>
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <Spin spinning={loading}>
            <div className="table100-body">
              <table>
                <tbody>
                  {(!loading && myQuestion?.length === 0) || myQuestion === null ? (
                    <tr className="row100 body">
                      <td colSpan={8} className="text-center">
                        No data available
                      </td>
                    </tr>
                  ) : (
                    myQuestion.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className="row100 body">
                          <td className="cell100 column0">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={selectedRows.includes(index)}
                              onChange={() => handleRowSelect(index)}
                            />
                          </td>
                          <td className="cell100 column1">
                            <div className="d-flex align-items-center attendanceUser gap-2">
                              {item.student.profilePicture ? (
                                <img src={item.student.profilePicture} alt="user" />
                              ) : (
                                <NameAvatar name={item.student.studentName} rounded />
                              )}
                              {item.student.studentName}
                            </div>
                          </td>
                          <td className="cell100 column2">PP</td>
                          <td className="cell100 column3">Exam Title</td>
                          <td className="cell100 column4">{`${Math.round(
                            (item.questions.reduce((acc, q) => acc + q.marksObtained, 0) /
                              item.questions.reduce((acc, q) => acc + q.marks, 0)) * 100
                          )}%`}</td>
                          <td className="cell100 column5">Yes</td>
                          <td className="cell100 column6">
                            {!item.student.marksAlloted.alloted ?
                              '-'
                              :
                              item.student.marksAlloted.remarks}
                          </td>
                          <td className="cell100 column7">
                            <div
                              onClick={() => toggleRow(index)}
                              className="cursor-pointer d-flex justify-content-end p-2"
                            >
                              {expandedRowIndex === index ? (
                                <ChevronUp size={16} className="group-hover:text-white" />
                              ) : (
                                <ChevronDown size={16} className="group-hover:text-white" />
                              )}
                            </div>
                          </td>
                        </tr>
                        {expandedRowIndex === index && (
                          <tr>
                            <td colSpan={8} style={{ background: "#fff", padding: "5px" }}>
                              <div className="bg-gray-50 rounded p-6">
                                {item.questions.length > 0 ? (
                                  <>
                                    {item.questions.map((question, questionIndex) => (
                                      <div
                                        key={questionIndex}
                                        className="mb-4 border-b pb-4 border-gray-200"
                                      >
                                        <p>
                                          <strong>{questionIndex + 1}:</strong>{" "}
                                          {question.questionText}
                                        </p>
                                        <p>
                                          <strong>Answer:</strong> {question.submissionAnswer}
                                        </p>
                                        <p>
                                          <strong>Total Marks:</strong> {question.marks}
                                        </p>
                                        {!item.student.marksAlloted.alloted ?
                                          <p className="w-25">
                                            <Input
                                              placeholder="Enter marks"
                                              value={question.getMarks || ""}
                                              onChange={(e) =>
                                                handleInputChange(index, questionIndex, e.target.value)
                                              }
                                            />
                                          </p>
                                          :
                                          <p>  <strong>Obtained Marks: {question.marksObtained}</strong> </p>
                                        }
                                      </div>
                                    ))}
                                    {!item.student.marksAlloted.alloted ?
                                      <>
                                        <p className="w-25 mb-3">
                                          <Input
                                            placeholder="Review"
                                            value={review}
                                            onChange={handleInputreview}
                                          />
                                        </p>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => handleSaveMarks(item)}
                                        >
                                          Save Marks
                                        </button>
                                      </>
                                      :
                                      null
                                    }
                                  </>
                                ) : (
                                  <p>No questions available.</p>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
              {filteredExamsId.length === 0 ? null : (
                <div className="flex justify-center mt-4 pb-4 gap-3 paginationStyle">
                  <button
                    onClick={() => handlePagination("previous")}
                    disabled={currentPage < 1}
                    className={`px-3 py-1 bg-[#241763] text-white rounded ${currentPage < 1
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-[#241763] text-white hover:bg-[#241763]"
                      }`}
                  >
                    <LeftOutlined />
                  </button>
                  <span className="bg-[#241763] text-white px-3 py-1 mx-1 border rounded d-flex align-items-center">
                    {`${currentPage + 1}/${filteredExamsId.length}`}
                  </span>
                  <button
                    onClick={() => handlePagination("next")}
                    disabled={currentPage >= filteredExamsId.length - 1}
                    className={`px-4 py-2 rounded ${currentPage >= filteredExamsId.length - 1
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-[#241763] text-white hover:bg-[#241763]"
                      }`}
                  >
                    <RightOutlined />
                  </button>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default GradeTableQuestion;
