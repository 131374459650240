import React from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const MultiLineChart = () => {
  const data = [
    {
      month: "Jan",
      assesment: 10,
      attendance: 35,
      performance: 65
    },
    {
      month: "Feb", 
      assesment: 75,
      attendance: 88,
      performance: 82
    },
    {
      month: "Mar",
      assesment: 45,
      attendance: 50,
      performance: 38
    },
    {
      month: "Apr",
      assesment: 80,
      attendance: 87,
      performance: 85
    },
    {
      month: "May",
      assesment: 90,
      attendance: 92,
      performance: 90
    },
    {
      month: "Jun",
      assesment: 70,
      attendance: 65,
      performance: 22
    }
  ];

  return (
    <ResponsiveContainer>
      <LineChart width={800} height={400} data={data}>
        <Line
          type="monotone"
          dataKey="assesment"
          stroke="#241763"
          strokeWidth={4}
          dot={{ fill: "white" }}
        />
        <Line
          type="monotone"
          dataKey="attendance"
          stroke="#F50788"
          strokeWidth={4}
          dot={{ fill: "white" }}
        />
        <Line
          type="monotone"
          dataKey="performance"
          stroke="#0CBA97"
          strokeWidth={4}
          dot={{ fill: "white" }}
        />

        <XAxis dataKey="month" />
        <YAxis ticks={[0, 20, 40, 60, 80, 100]} />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MultiLineChart;
