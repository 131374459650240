import React, { useState } from "react";
import ViewReportModal from "./ViewReportModal";

const PerformanceModal = ({ isOpen, onClose }) => {
  const [viewReportOpen, setViewReportOpen] = useState(false);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-[60%] relative">
          <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">✖</button>
          <h2 className="text-xl font-semibold mb-4">Employee Performance Report</h2>
          <p className="text-gray-700">Apply filters for the Performance report.</p>

          {/* Filters */}
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <label htmlFor="department" className="block mb-2">Department</label>
              <select id="department" className="border p-2 rounded w-full">
                <option value="" disabled selected>Choose a department</option>
                <option>HR</option>
                <option>Finance</option>
                <option>IT</option>
              </select>
            </div>
            <div>
              <label htmlFor="designation" className="block mb-2">Designation</label>
              <select id="designation" className="border p-2 rounded w-full">
                <option value="" disabled selected>Choose a designation</option>
                <option>Manager</option>
                <option>Team Lead</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button onClick={onClose} className="bg-gray-400 px-4 py-2 rounded text-white">Close</button>
            <button onClick={() => setViewReportOpen(true)} className="bg-indigo-600 px-4 py-2 rounded text-white">View Report</button>
          </div>
        </div>
      </div>

      <ViewReportModal isOpen={viewReportOpen} onClose={() => setViewReportOpen(false)} reportType="Employee Performance" />
    </>
  );
};

export default PerformanceModal;
