import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCampuses, addCampus, updateCampus, deleteCampus } from "../../redux/campusesSlice";
import CampusesModal from "../../modals/admin/CampusesModal";
import { Toaster, toast } from "react-hot-toast";
import { Pencil, Trash2, Plus } from "lucide-react";
import { useForm } from "react-hook-form";

const Campuses = () => {
  const { campuses, loading, error } = useSelector((state) => state.campuses);
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, setValue } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentCampusId, setCurrentCampusId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchCampuses());
  }, [dispatch]);

  const onSubmit = (data) => {
    const newCampus = {
      name: data.name,
      location: data.location,
      area: data.area,
      capacity: data.capacity,
      contactNumber: data.contactNumber,
      contactEmail: data.contactEmail,
      isActive: true,
    };

    if (isEdit) {
      dispatch(updateCampus({ id: currentCampusId, campus: newCampus }));
    } else {
      dispatch(addCampus(newCampus));
    }

    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (campus) => {
    setCurrentCampusId(campus.id);
    setIsEdit(true);
    setIsModalOpen(true);
    setValue("name", campus.name || "");
    setValue("location", campus.location || "");
    setValue("area", campus.area || "");
    setValue("capacity", campus.capacity || 0);
    setValue("contactNumber", campus.contactNumber || "");
    setValue("contactEmail", campus.contactEmail || "");
  };

  const handleDelete = (id) => {
    dispatch(deleteCampus(id));
  };

  const filteredCampuses = campuses.filter(
    (campus) =>
      campus.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      campus.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      campus.area.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <Toaster />
      <div className="mb-4 flex flex-wrap justify-between space-x-4">
        <input
          type="text"
          placeholder="Search by name, location, or area"
          className="flex-grow p-2 border rounded"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="w-full lg:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2">Add Campus</span>
        </button>
      </div>

      <CampusesModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        register={register}
        reset={reset}
        setValue={setValue}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
      />

      <div className="overflow-x-auto overflow-visible mt-6">
        <table className="table-auto w-full">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Campus Name</th>
              <th>Location</th>
              <th>Campus Area</th>
              <th>Capacity</th>
              <th>Contact Info</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {filteredCampuses.length > 0 ? (
              filteredCampuses.map((campus) => (
                <tr key={campus.id} className="p-2 py-2 hover:bg-gray-200">
                  <td>{campus.name}</td>
                  <td>{campus.location}</td>
                  <td>{campus.area}</td>
                  <td>{campus.capacity}</td>
                  <td>{campus.contactNumber} / {campus.contactEmail}</td>
                  <td className="space-x-2 flex items-center py-2">
                    <button className="bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60" onClick={() => handleEdit(campus)}>
                      <Pencil className="text-[#241763] w-4 h-4" />
                    </button>
                    <button className="bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60" onClick={() => handleDelete(campus.id)}>
                      <Trash2 className="text-[#241763] w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr><td colSpan="6" className="text-center py-4">No campuses found.</td></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Campuses;
