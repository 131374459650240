import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import {
  fetchManagement,
  addManagement,
  updateManagement,
  deleteManagement,
  fetchPermissions,
} from "../../redux/managementSlice";
import { fetchCampuses } from "../../redux/campusesSlice";
import ManagementModal from "../../modals/admin/ManagementModel";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import { Pencil, Trash2, Plus } from "lucide-react";

const Management = () => {
  const dispatch = useDispatch();
  const { management } = useSelector((state) => state.management);
  const { campuses } = useSelector((state) => state.campuses);
  const { register, handleSubmit, reset, setValue } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentManagementId, setCurrentManagementId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const { permissions } = useSelector((state) => state.management);

  useEffect(() => {
    dispatch(fetchManagement());
    dispatch(fetchCampuses());
    dispatch(fetchPermissions());
  }, [dispatch]);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    const selectedPermissions = Object.keys(data)
      .filter((key) => key.startsWith("permission_") && data[key])
      .map((key) => key.replace("permission_", "")); // Extract permission names

    const newManagement = {
      ...data,
      permissions: selectedPermissions, // Pass correct permissions array
      campusIds: data.campusIds || [],
      id: isEdit ? currentManagementId : Date.now(),
    };

    if (isEdit) {
      dispatch(updateManagement({ id: currentManagementId, management: newManagement })).then(() => dispatch(fetchManagement()));;
    } else {
      dispatch(addManagement(newManagement)).then(() => {
        dispatch(fetchManagement());
      });
      toast.success("Management added successfully!");
    }

    reset();
    setValue("campuses", []);
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const managementItem = management.find((item) => item.id === id);
    if (!managementItem) return;

    permissions.forEach((perm) => setValue(`permission_${perm.name}`, false));
   
    

    setValue("firstName", managementItem.firstName);
    setValue("lastName", managementItem.lastName);
    setValue("email", managementItem.email);
    setValue("department", managementItem.department);
 
    
    
    managementItem.permissions.forEach((permission) => {
      setValue(`permission_${permission}`, true);
    });

    setCurrentManagementId(id);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteManagement(id));
    toast.success("Management deleted successfully!");
  };

  const filteredManagement = management.filter((item) => {
    const matchesSearch = `${item.firstName} ${item.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesLocation = locationFilter
      ? item.location === locationFilter
      : true;

    return matchesSearch && matchesLocation;
  });

  const uniqueLocations = Array.from(
    new Set(management.map((item) => item.location))
  );

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="mb-4 flex flex-wrap gap-4">
    
        <div className="flex flex-grow items-end">
          <input
            type="text"
            placeholder="Search by name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

       
        <div>
          <CustomDropdown
            options={uniqueLocations}
            value={locationFilter}
            onChange={setLocationFilter}
            placeholder="All locations"
          />
        </div>

      
        <div className="w-full md:w-1/2 lg:w-auto">
          <button
            className="w-full lg:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
            onClick={() => {
              setIsModalOpen(true);
              setIsEdit(false);
              reset();
            }}
          >
            <Plus className="h-4 w-4" />
            <span className="ml-2">Add Admin</span>
          </button>
        </div>
      </div>

      <ManagementModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        setValue={setValue}
        options={permissions}
        campuses={campuses}
        selectedCampuses={isEdit ? management.find((item) => item.id === currentManagementId)?.campuses || [] : []}
      />

      <table className="table-auto w-full mt-6">
        <thead className="py-4 border-b font-bold">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>User Role</th>
            <th>Permissions</th>
            <th>Campuses</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {filteredManagement.map((parent) => (
            <tr
              key={parent.id}
              className="p-2 py-2 hover:bg-gray-200 space-y-2"
            >
              <td>{`${parent.firstName} ${parent.lastName}`}</td>
              <td>{parent.email}</td>
              <td>{parent.userRole}</td>
              <td>
                {parent.permissions ? parent.permissions.join(", ") : "N/A"}
              </td>

              <td>
                {parent.campuses && parent.campuses.length > 0
                  ? parent.campuses.map((campus) => campus.name).join(", ")
                  : "N/A"}
              </td>

              <td className="space-x-2 flex items-center py-2">
                <button
                  className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                  onClick={() => handleEdit(parent.id)}
                >
                  <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                </button>
                <button
                  className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                  onClick={() => handleDelete(parent.id)}
                >
                  <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Toaster />
    </div>
  );
};

export default Management;
