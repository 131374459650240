import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  postRequest,
  updateRequest,
} from "../services";
import toast from "react-hot-toast";

const initialState = {
  classes: [],
  loading: false,
  error: null,
  pageSize: 100,
  totalCount: 10,
  pageNumber: 1,
};

export const fetchClasses = createAsyncThunk(
  "classes/fetchClasses",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await getRequest(
        `/api/Class/Get/Pagination?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch classes data.");
      }
      return {
        classes: response.data?.data?.items || [],
        totalCount: response.data?.data?.totalCount || 0,
        pageNumber,
        pageSize,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchClassesV1 = createAsyncThunk(
  "classes/fetchClassesV1",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await getRequest(
        `/api/Class/GetV2/PaginationV2?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      if (response.status !== 200) {
        return rejectWithValue(
          "Failed to fetch classes data from V1 endpoint."
        );
      }
      return {
        classes: response.data?.data?.items || [],
        totalCount: response.data?.data?.totalCount || 0,
        pageNumber,
        pageSize,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addClassAsync = createAsyncThunk(
  "classes/addClass",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/Class/AddNewClass", payload);
      if (response.status !== 200) {
        return rejectWithValue("Failed to add class.");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const updateClassAsync = createAsyncThunk(
  "classes/updateClass",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(
        `/api/Class/UpdateClass/${id}`,
        payload
      );
      if (response.status !== 200) {
        return rejectWithValue(
          response.data?.message || "Failed to update class."
        );
      }
      return { id, ...payload };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "An error occurred."
      );
    }
  }
);

export const deleteClass = createAsyncThunk(
  "classes/deleteClass",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(`/api/Class/Delete/${id}`);
      if (response.status === 422) {
        return rejectWithValue(
          "This class is assigned to a student or teacher and cannot be deleted."
        );
      }
      return id; // Successful deletion
    } catch (error) {
      // Return a general error message if it's not the specific 422 error.
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete class."
      );
    }
  }
);

const classesSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    addClass(state, action) {
      state.classes.push(action.payload);
    },
    updateClass(state, action) {
      const index = state.classes.findIndex(
        (cls) => cls.id === action.payload.id
      );
      if (index !== -1) {
        state.classes[index] = { ...state.classes[index], ...action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassesV1.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassesV1.fulfilled, (state, action) => {
        state.loading = false;
        state.classes = action.payload.classes || [];
        state.totalCount = action.payload.totalCount;
        state.pageNumber = action.payload.pageNumber;
        state.pageSize = action.payload.pageSize;
      })
      .addCase(fetchClassesV1.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(
          action.payload || "Failed to fetch classes data from V1 endpoint."
        );
      })
      .addCase(fetchClasses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.loading = false;
        state.classes = action.payload.classes || [];
        state.totalCount = action.payload.totalCount;
        state.pageNumber = action.payload.pageNumber;
        state.pageSize = action.payload.pageSize;
      })
      .addCase(fetchClasses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || "Failed to fetch classes data.");
      })

      .addCase(addClassAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addClassAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.classes = [action.payload, ...state.classes];
        toast.success("Class added successfully!");
      })
      .addCase(addClassAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || "Failed to add class.");
      })

      .addCase(updateClassAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClassAsync.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.classes.findIndex(
          (cls) => cls.id === action.payload.id
        );
        if (index !== -1) {
          state.classes[index] = { ...state.classes[index], ...action.payload };
        }
        toast.success("Class updated successfully!");
      })
      .addCase(updateClassAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || "Failed to update class.");
      })

      .addCase(deleteClass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        state.loading = false;
        state.classes = state.classes.filter(
          (cls) => cls.id !== action.payload
        );
        toast.success("Class deleted successfully!");
      })
      .addCase(deleteClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // toast.error(action.payload || "Failed to delete class.");
      });
  },
});

export const { setPageNumber, addClass, updateClass } = classesSlice.actions;
export default classesSlice.reducer;
