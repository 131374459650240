import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendance } from "../../../redux/attendanceSlice";
import { fetchEmployees } from "../../../redux/employeeSlice";

const OverAllAttendance = () => {
  const dispatch = useDispatch();
  const { attendanceRecords, status, error } = useSelector((state) => state.attendance);
  const { employees } = useSelector((state) => state.employees || { employees: [] });

  useEffect(() => {
    dispatch(fetchAttendance());
    dispatch(fetchEmployees());
  }, [dispatch]);

  // Function to get employee details from the employees list
  const getEmployeeDetails = (employeeId) => {
    return employees.find((emp) => emp.id === employeeId) || {};
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold text-sm bg-[#241763]/10 uppercase">
            <tr>
              <th className="py-2">Emp. Code</th>
              <th>Emp. Name</th>
              <th>Father Name</th>
              <th>Gender</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Date</th>
              <th>Check IN</th>
              <th>Check Out</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {status === "loading" && (
              <tr>
                <td colSpan="10" className="text-center py-4">
                  Loading...
                </td>
              </tr>
            )}
            {status === "failed" && (
              <tr>
                <td colSpan="10" className="text-center text-red-500">
                  {error}
                </td>
              </tr>
            )}
            {status === "succeeded" &&
              attendanceRecords.map((record) => {
                const employee = getEmployeeDetails(record.employeeId);

                return (
                  <tr key={record.id} className="hover:bg-gray-200">
                    <td className="py-4">{record.employeeId}</td>
                    <td>{`${employee.firstName || "N/A"} ${employee.lastName || ""}`}</td>
                    <td>{employee.fatherName || "N/A"}</td>
                    <td>{employee.gender || "N/A"}</td>
                    <td>{employee.designation || "N/A"}</td>
                    <td>{employee.department || "N/A"}</td>
                    <td>{record.date}</td>
                    <td>{record.checkIn || "N/A"}</td>
                    <td>{record.checkOut || "N/A"}</td>
                    <td>{record.status}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverAllAttendance;
