import React from 'react'

const PayrollModal = () => {
  return (
    <div>
      payroll
    </div>
  )
}

export default PayrollModal
