import {
  dashboardSvg,
  coursesSvg,
  classScheduleSvg,
  gradeBookSvg,
  examsSvg,
  communitySvg,
  messagesSvg,
  settingsSvg,
  librarySvg,
  adminSvg,
  lessonPlanSvg,
  classManagementSvg,
  singleUserBoldSvg,
  examAutomation,
  ExamsSvg,
  gradesSvg,
  performanceSvg,
  feesSvg,
  parentCalenderSvg,
  myClass,
  myCourses,
  myStudent,
  myParent,
  curriculumDesign
  // surverySvg,
  // teachericon,
  // userSvg,
} from "./svgs";
export const studentLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  {
    label: t("MyClasses"),
    path: "/my-classes",
    icon: classManagementSvg,
  },
  {
    label: t("courses"),
    path: "/courses",
    icon: coursesSvg,
  },
  {
    label: t("classSchedule"),
    path: "/class-schedule",
    icon: classScheduleSvg,
  },
  {
    label: t("gradeBook"),
    path: "/grade-book",
    icon: gradeBookSvg,
  },
  {
    label: t("exams"),
    path: "/exams",
    icon: examsSvg,
  },
  {
    label: t("community"),
    path: "/community",
    icon: communitySvg,
  },
  {
    label: t("library"),
    path: "/library",
    icon: librarySvg,
  },
  {
    label: t("accountSettings"),
    path: "/account-settings",
    icon: settingsSvg,
  }
];

export const teacherLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  {
    label: t("lessonPlan"),
    path: "/lesson-plan",
    icon: lessonPlanSvg,
    childRoute: "/add-lesson-plan",
  },
  {
    label: t("curriculumDesign"),
    path: "/curriculum-design",
    icon: curriculumDesign,
  },
  {
    label: t("classManagement"),
    path: "/class-management",
    icon: classManagementSvg,
    childRoute: "/attendance",
  },
  {
    label: t("examsManagement"),
    path: "/exams-management",
    icon: ExamsSvg,
  },
  {
    label: t("generatePaper"),
    path: "/generate-paper", 
    icon: examAutomation,
  },
  {
    label: t("classSchedule"),
    path: "/class-schedule",
    icon: classScheduleSvg,
  },
  {
    label: t("gradesManagement"),
    path: "/grades-management",
    icon: gradesSvg,
  },
  {
    label: t("community"),
    path: "/community",
    icon: communitySvg,
  },
  {
    label: t("library"),
    path: "/library",
    icon: librarySvg,
  },
  {
    label: t("personalProfile"),
    path: "/account-settings",
    icon: settingsSvg,
  },
];


export const parentLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  {
    label: t("exam"),
    path: "/exam",
    icon: ExamsSvg,
  },
  {
    label: t("feesDues"),
    path: "/fees-dues",
    icon: feesSvg,
  },
  {
    label: t("performance"),
    path: "/performance",
    icon: performanceSvg,
  },
  {
    label: t("parentalSurvey"),
    path: "/parent-survey",
    icon: feesSvg,
  },
  {
    label: t("calendar"),
    path: "/calendar",
    icon: parentCalenderSvg,
  },
  {
    label: t("accountSetting"),
    path: "/account-setting",
    icon: settingsSvg,
  },
];


export const adminLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
    permission: "Dashboard"
  },

  {
    label: t("Teachers"),
    path: "/teachers",
    icon: classScheduleSvg,
    permission: "Teachers",
  },
  {
    label: t("Students"),
    path: "/students",
    icon: myStudent,
    permission: "Students",
  },
  {
    label: t("Parents"),
    path: "/parents",
    icon: myParent,
    permission: "Parents",
  },
  {
    label: t("Classes"),
    path: "/classes",
    icon: myClass,
    permission: "Classes",
  },
  {
    label: t("Library"),
    path: "/libraries",
    icon: librarySvg,
    permission: "Library",
  },
  {
    label: t("Courses"),
    path: "/courses",
    icon: myCourses,
    permission: "Courses",
  },
  {
    label: t("User Management"),
    path: "/management",
    icon: myClass,
    permission: "UserManagement",
  },
  {
    label: t("Fee Management"),
    path: "/feeManagement",
    icon: myClass,
    permission: "FeeManagement",
  },
  {
    label: t("Campuses"),
    path: "/campuses",
    icon: myClass,
    permission: "Campuses",
  },
  {
    label: t("Wellness Suite"),
    path: "/wellnessDasboard",
    icon: myClass,
    permission: "WellnessSuite",
    children: [
      { label: t("Surveys Feedback"), path: "/surveys&feedback" },
     
    ],
  },
  {
    label: t("Reports"),
    path: "/reports",
    icon: myClass,
    permission: "Reports",
  },
];


export const hrLinks = (t) => [
  {
    label: t("HRMS"),
    path: "/hrms",
    icon: myClass, 
    children: [
      { label: t("Emloyee Details"), path: "/employeeDetails" },
      { label: t("Warning Details"), path: "/warningDetails" },
      { label: t("Leave Details"), path: "/leaveDetails" },
      { label: t("Attendance"), path: "/attendance" },
      { label: t("Reports"), path: "/hrReports" },
    
    ],
    permission: "HRMS",
  },
  {
    label: t("Payroll"),
    path: "/payroll",
    icon: myClass, 
    children: [
      { label: t("Salries"), path: "/salaries" },
      { label: t("Loan & Advance"), path: "/loanAdvance" },
      { label: t("Arreas & Bonus"), path: "/arreasBonus" },
      { label: t("Final Settlement"), path: "/finalSettlement" },
      { label: t("Tax Holding"), path: "/taxHolding" },
      { label: t("Stipend"), path: "/stipend" },
      { label: t("WelfareAllownce"), path: "/welfareAllownce" },
      
    
    ],
  },
  {
    label: t("Recruitment"),
    path: "/recruitment",
    icon: myClass, 
    children: [
      { label: t("Jobs Opening"), path: "/jobsOpening" },
      { label: t("Candidates"), path: "/candidates" },
      { label: t("Interviews"), path: "/interviews" },
    ],
  },
  {
    label: t("HrConfiguration"),
    path: "/hrConfiguration",
    icon: myClass, 
    children: [
      { label: t("HR Controller"), path: "/hrController" },
      { label: t("HR Departments"), path: "/hrDepartment" },
      { label: t("Designation Detail"), path: "/designationDetail" },
      { label: t("Location Detail"), path: "/locationDetail" },
    ],
  },
  {
    label: t("Settings"),
    path: "#",
    icon: myClass, 
    children: [
      { label: t("User List"), path: "/userList" },
    ],
  },
];
