import React, { useState, useRef } from "react";
import { X, Upload, FileText, Paperclip, Trash2 } from "lucide-react";

const NotificationsModal = ({ 
  selectedItems, 
  toggleItemSelection, 
  handleSubmitNotification, 
  onClose 
}) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [attachment, setAttachment] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate form
    const newErrors = {};
    if (!title.trim()) newErrors.title = "Title is required";
    if (!message.trim()) newErrors.message = "Message is required";
    if (selectedItems.length === 0) newErrors.recipients = "Select at least one recipient";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    // Call the parent handler with the notification data
    handleSubmitNotification({
      recipients: selectedItems,
      title: title,
      message: message,
      attachment: attachment
    });
    
    // Reset form
    setTitle("");
    setMessage("");
    setAttachment(null);
    setErrors({});
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAttachment(e.target.files[0]);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const removeAttachment = () => {
    setAttachment(null);
    fileInputRef.current.value = "";
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Send Notification</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={`w-full p-2 border rounded ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Notification Title"
            />
            {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={`w-full p-2 border rounded min-h-[100px] ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Notification Message"
            />
            {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
          </div>

          {/* File Attachment Section */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Attachment</label>
            <input 
              type="file" 
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden" 
            />
            
            {attachment ? (
              <div className="flex items-center justify-between p-3 border border-gray-300 rounded bg-gray-50">
                <div className="flex items-center">
                  <FileText className="text-[#241763] mr-2" size={20} />
                  <div>
                    <p className="text-sm font-medium truncate max-w-xs">{attachment.name}</p>
                    <p className="text-xs text-gray-500">{formatFileSize(attachment.size)}</p>
                  </div>
                </div>
                <button 
                  type="button" 
                  onClick={removeAttachment}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            ) : (
              <button
                type="button"
                onClick={triggerFileInput}
                className="w-full p-3 border border-dashed border-gray-300 rounded flex items-center justify-center hover:bg-gray-50"
              >
                <Paperclip className="mr-2" size={20} />
                <span>Attach a file</span>
              </button>
            )}
            <p className="text-xs text-gray-500 mt-1">Supported file types: PDF, Word, Excel, Images, etc.</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Recipients ({selectedItems.length})</label>
            {errors.recipients && <p className="text-red-500 text-sm mt-1">{errors.recipients}</p>}
            <div className="max-h-48 overflow-y-auto border border-gray-300 rounded p-2">
              {selectedItems.length > 0 ? (
                <ul>
                  {selectedItems.map((item) => (
                    <li key={item.id} className="flex justify-between items-center py-1">
                      <span>{item.name}</span>
                      <button
                        type="button"
                        onClick={() => toggleItemSelection(item.id, item.name)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <X size={16} />
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500 text-center py-2">No recipients selected</p>
              )}
            </div>
          </div>
          
          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2 hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#241763] text-white rounded hover:bg-[#241763]/80"
            >
              Send Notification
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NotificationsModal;