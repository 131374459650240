import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [auth, setAuth] = useState(() => JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("user"));
    setUserData(auth);
  }, [auth]);

  const login = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setAuth(user);
  };
    
  const value = { userData, login};

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
