import React, { useState } from "react";
import { Pencil, Trash2 } from "lucide-react";
import AllowancedetailModal from "../../../modals/Hrms/AllowancedetailModal";
import ApprovalAllowanceModal from "../../../modals/Hrms/ApprovalAllowanceModal";

const  AllowanceDetail = () => {
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [dues, setDues] = useState([]);
  const [editingDues, setEditingDues] = useState(null);
  const [status, setStatus] = useState("Pending");
  const [selectedRecord, setSelectedRecord] = useState(null);

  // Handle Add or Update Dues
  const handleApplyDues = (data) => {
    if (editingDues) {
      // Update existing dues
      setDues((prevDues) =>
        prevDues.map((due) => (due.id === editingDues.id ? { ...due, ...data } : due))
      );
      setEditingDues(null);
    } else {
      // Add new dues
      setDues((prevDues) => [
        ...prevDues,
        { id: Date.now(), ...data, status: "Pending" },
      ]);
    }
    setIsApplyModalOpen(false);
  };

  // Handle Delete Dues
  const handleDeleteDues = (id) => {
    setDues((prevDues) => prevDues.filter((due) => due.id !== id));
  };
  

  // Handle Edit Dues
  const handleEditDues = (due) => {
    setEditingDues(due);
    setIsApplyModalOpen(true);
  };

  // Handle Approve Dues
  const handleApprove = () => {
    if (editingDues) {
      setDues((prevDues) =>
        prevDues.map((due) =>
          due.id === editingDues.id ? { ...due, status: "Approved" } : due
        )
      );
      setEditingDues(null);
    }
    setIsApprovalModalOpen(false);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Session Name: March-2024</h1>
        <button
          onClick={() => setIsApplyModalOpen(true)}
          className="bg-[#241763] text-white px-4 py-2 rounded"
        >
          Apply Allownce
        </button>
      </div>
      <div className="overflow-x-auto overflow-visible">
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6 text-sm">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>EMP. CODE</th>
                <th>EMPLOYEE NAME</th>
                <th>DATE</th>
                <th>Salary</th>
                <th>SESSION</th>
                <th>Allownce TYPE</th>
                <th>AMOUNT</th>
                <th>DESCRIPTION</th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {dues.map((due) => (
                <tr key={due.id}>
                  <td>{due.employeeCode || "SAS-015"}</td>
                  <td>
                    <div>
                      <div className="font-medium">{due.employeeName}</div>
                      <div className="text-sm text-gray-500">
                        {due.department || "Engineering & Production"}
                      </div>
                    </div>
                  </td>
                  <td>{due.applyDate}</td>
                  <td>{due.salary}</td>
                  <td>{due.sessionName}</td>
                  <td>{due.duesType}</td>
                  <td>{due.duesAmount}</td>
                  <td>{due.description}</td>
                  <td
                    className="cursor-pointer"
                    onClick={() => {
                      setEditingDues(due);
                      setIsApprovalModalOpen(true);
                    }}
                  >
                    <span
                      className={`px-2 py-1 rounded ${
                        due.status === "Pending"
                          ? "bg-orange-100 text-orange-600"
                          : "bg-green-100 text-green-600"
                      }`}
                    >
                      {due.status}
                    </span>
                  </td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEditDues(due)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteDues(due.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AllowancedetailModal
        isOpen={isApplyModalOpen}
        onClose={() => {
          setEditingDues(null);
          setIsApplyModalOpen(false);
        }}
        onSubmit={handleApplyDues}
        initialData={editingDues}
      />
      <ApprovalAllowanceModal
        isOpen={isApprovalModalOpen}
        onClose={() => setIsApprovalModalOpen(false)}
        onApprove={handleApprove}
      />
    </div>
  );
};

export default  AllowanceDetail;
