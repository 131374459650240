import React from "react";
import { CheckCircle, AlertTriangle, Info, XCircle, X } from "lucide-react";

const PushNotification = ({ alert, onRemove }) => {
  // Function to render the appropriate icon based on notification type
  const renderIcon = (type) => {
    switch (type) {
      case "success":
        return <CheckCircle size={20} color="#2E7D32" />;
      case "warn":
        return <AlertTriangle size={20} color="#ED6C02" />;
      case "error":
        return <XCircle size={20} color="#D32F2F" />;
      case "info":
      default:
        return <Info size={20} color="#0288D1" />;
    }
  };

  // Get background color based on type
  const getBackgroundColor = (type) => {
    switch (type) {
      case "success":
        return "#E8F5E9";
      case "warn":
        return "#FFF3E0";
      case "error":
        return "#FFEBEE";
      case "info":
      default:
        return "#E3F2FD";
    }
  };

  return (
    <div
    className={`mb-3 p-3 rounded relative border-l-4 ${
      alert.type === "success" ? "bg-green-50 border-green-700" : 
      alert.type === "warn" ? "bg-orange-50 border-orange-600" : 
      alert.type === "error" ? "bg-red-50 border-red-700" : 
      "bg-blue-50 border-blue-600"
    }`}
  >
    <button
      className="absolute top-0 right-0 p-1 m-1 bg-transparent border-none"
      onClick={onRemove}
      aria-label="Remove notification"
    >
      <X size={16} />
    </button>
    
    <div className="flex items-start">
      <div className="mr-3 mt-1">
        {renderIcon(alert.type)}
      </div>
      <div className="flex-grow-1">
        {alert.title && (
          <div className="font-bold mb-1">{alert.title}</div>
        )}
        <div>{alert.message}</div>
        {alert.timestamp && (
          <div className="text-gray-500 mt-1 text-xs">
            {alert.timestamp}
          </div>
        )}
      </div>
    </div>
  </div>
  );
};

export default PushNotification;