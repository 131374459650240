import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTimeAdjustments,
  updateTimeAdjustmentStatus,
} from "../../../redux/timeAdjustmentSlice";
import { fetchEmployees } from "../../../redux/employeeSlice";
import TimeAdjustmentModal from "../../../modals/Hrms/TimeAdjustmentModal";

const TimeAdjustment = () => {
  const dispatch = useDispatch();

  const { adjustments, status } = useSelector((state) => state.timeAdjustments);
  const { employees } = useSelector((state) => state.employees);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  
  useEffect(() => {
    dispatch(fetchTimeAdjustments()); // Fetch time adjustments
    dispatch(fetchEmployees()); // Fetch employee details
  }, [dispatch]);

  const handleOpenModal = (adjustment) => {
    setSelectedEmployee(adjustment);
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
  };

  const handleUpdateStatus = (id, newStatus) => {
    dispatch(updateTimeAdjustmentStatus({ id, newStatus }));
    setSelectedEmployee(null);
  };

  // Find employee details for each time adjustment
  const getEmployeeDetails = (employeeId) =>
    employees.find((emp) => emp.id === employeeId);

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center justify-end w-full">
        <input
          type="text"
          placeholder="Search by employee ID"
          className="w-1/3 py-2 border px-2"
        />
      </div>

      {status === "loading" ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full mt-6">
            <thead className="border-b font-bold">
              <tr>
                <th>Emp. Code</th>
                <th>Employee Name</th>
                <th>Adjustment In</th>
                <th>Date</th>
                <th>Time</th>
                <th>Manager Status</th>
                <th>HR Status</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {adjustments.map((adjustment) => {
                const employee = getEmployeeDetails(adjustment.employeeId);
                return (
                  <tr key={adjustment.id} className="hover:bg-gray-200">
                    <td>{employee?.id || "N/A"}</td>
                    <td>
                      <div className="flex items-center">
                        <img
                          src={employee?.profilePicture || "https://via.placeholder.com/40"}
                          alt="Profile"
                          className="w-8 h-8 rounded-full mr-2"
                        />
                        <div>
                          <span>{`${employee?.firstName} ${employee?.lastName}`}</span>
                          <br />
                          <span className="text-gray-500 text-xs">
                            {employee?.department || "N/A"}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{adjustment.checkType}</td>
                    <td>{adjustment.date}</td>
                    <td>{adjustment.requestedTime}</td>
                    <td>
                      <span
                        className={`px-2 py-1 rounded ${
                          adjustment.managerStatus === "Pending"
                            ? "bg-orange-100 text-orange-600"
                            : "bg-green-100 text-green-600"
                        }`}
                      >
                        {adjustment.managerStatus}
                      </span>
                    </td>
                    <td>
                      <button
                        className={`px-2 py-1 rounded ${
                          adjustment.hrStatus === "Pending"
                            ? "bg-orange-100 text-orange-600"
                            : "bg-green-100 text-green-600"
                        }`}
                        onClick={() => handleOpenModal(adjustment)}
                      >
                        {adjustment.hrStatus}
                      </button>
                    </td>
                    <td>{adjustment.reason}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <TimeAdjustmentModal
        isOpen={!!selectedEmployee}
        onClose={handleCloseModal}
        employee={selectedEmployee}
        onUpdateStatus={handleUpdateStatus}
      />
    </div>
  );
};

export default TimeAdjustment;
