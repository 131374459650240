import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { CalendarCheck2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../services";
import { message, Spin } from "antd";
import { clockSvg, bulbSvg } from '../../Constant/svgs';
import { calculateDateTimeDifference } from '../../Constant/helpers';
import ViewMcQsStudent from '../../modals/ViewMcQsStudent';
import ViewQuestionStudent from '../../modals/ViewQuestionStudent';
import ViewAssignmentStudent from '../../modals/ViewAssignmentStudent';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        border: "none",
    },
};

const ExamsManagement = () => {
    const [examData, setExamData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedExam, setSelectedExam] = useState();
    const [openExamModal, setOpenExamModal] = useState(false);
    const [questionsData, setQuestionsData] = useState([]);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "sa";
    const { id } = useParams();

    const fetchData = async () => {
        setLoading(true);
        try {
            const examResponse = await getRequest(`/api/Exam/student-exams?subjectId=${id}`);
            const examData = examResponse.data.data;
            setExamData(examData);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataQuestion = async (examId, examType) => {
        const endPoint = examType === "McQs"
            ? `/api/Exam/mcqs?examId=${examId}`
            : examType === "Question"
                ? `/api/Exam/questions?examId=${examId}`
                : `/api/Exam/assignment?examId=${examId}`;
        try {
            const response = await getRequest(`${endPoint}`);
            const data = response.data.data;
            setQuestionsData(data || []);
        } catch (error) {
            console.error("Error fetching questions data:", error.message);
            setQuestionsData([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleClickViewExam = async (exam) => {
        setModalLoading(true);
        setLoading(true);
        setSelectedExam(exam);
        const now = new Date();
        const currentTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
        if((currentTime >= exam.startTime && currentTime <= exam.endTime)|| exam.examType === 'Assignment')
        {
            await fetchDataQuestion(exam.id, exam.examType);
            setModalLoading(false);
            setLoading(false);
            setOpenExamModal(true);
        }
        else{
            message.warning(`Exam time start ${exam.startTime} - ${exam.endTime}`)
            setLoading(false);
        }
        
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0); 
    const filteredExamData = examData.filter(exam => {
        const startDate = new Date(exam.startDate);
        const endDate = new Date(exam.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        return (
            exam.status === true &&
            exam.examSubmittedStatus === false &&
            today >= startDate &&
            today <= endDate
        );
    });    
    
    return (
        <>
            <Modal
                isOpen={openExamModal}
                onRequestClose={() => setOpenExamModal(false)}
                style={customStyles}
                contentLabel="Update Modal"
                shouldCloseOnOverlayClick={false}
            >
                {modalLoading ? (
                    <div className="text-center py-5">
                        <Spin spinning={modalLoading} size="large" />
                    </div>
                ) : selectedExam?.examType === "McQs" ? (
                    <ViewMcQsStudent
                        onRequestClose={() => setOpenExamModal(false)}
                        questionsData={questionsData}
                        selectedExamId={selectedExam?.id}
                        fetchDataExam={fetchData}
                    />
                ) : selectedExam?.examType === "Question" ? (
                    <ViewQuestionStudent
                        onRequestClose={() => setOpenExamModal(false)}
                        questionsData={questionsData}
                        selectedExamId={selectedExam?.id}
                        fetchDataExam={fetchData}
                    />
                ) : (
                    <ViewAssignmentStudent
                        onRequestClose={() => setOpenExamModal(false)}
                        questionsData={questionsData}
                        selectedExamId={selectedExam?.id}
                        fetchDataExam={fetchData}
                    />
                )}
            </Modal>

            <div className="container-fluid bg-white rounded px-2 py-2">
                <Spin spinning={loading}>
                    <div className={`row text-capitalize mt-3 ${isArabic ? "flex-row-reverse" : ""}`}>
                        {!loading && filteredExamData.length === 0 ? (
                            <div className="text-center w-100 py-5">
                                <h4>{t("No Data Available")}</h4>
                            </div>
                        ) : (
                            filteredExamData.map((exam, index) => (
                                <div
                                    className="col-md-6 col-lg-4 mb-3 cursor-pointer"
                                    key={index}
                                    onClick={() => handleClickViewExam(exam)}
                                >
                                    <div className="d-flex flex-column align-items-between examDataWrapper px-2 py-3">
                                        <div className={`d-flex justify-content-between ${isArabic ? "flex-row-reverse" : ""}`}>
                                            <h4
                                                className={`fw-bold p-0 m-0 fs-6 cursor-pointer ${isArabic ? "text-end" : "text-left"}`}
                                            >
                                                {exam.title}
                                            </h4>
                                        </div>
                                        <div className={`d-flex justify-content-start gap-3 examChipsWrapper mt-3 ${isArabic ? "flex-row-reverse" : ""}`}>
                                            <div className="examChip">{exam.subjectDetails.subjectName}</div>
                                            <div className="examChip">
                                                {exam.subjectDetails?.sectionDetails?.sectionName?.startsWith("Section")
                                                    ? exam.subjectDetails.sectionDetails.sectionName
                                                    : `Section ${exam.subjectDetails?.sectionDetails?.sectionName}`}
                                            </div>
                                            <div className="examChip">{exam.subjectDetails.sectionDetails.classDetails.className}</div>
                                        </div>
                                        <div
                                            className={`d-flex gap-5 mt-3 align-items-center examSvgsText ${isArabic ? "flex-row-reverse" : ""
                                                }`}
                                        >
                                            <div className={`d-flex gap-1 align-items-center ${isArabic ? "flex-row-reverse" : ""}`}>
                                                {clockSvg}
                                                <span>
                                                    {exam.examType === "Assignment"
                                                        ? calculateDateTimeDifference(
                                                            exam.startDate,
                                                            exam.endDate,
                                                            exam.startTime,
                                                            exam.endTime
                                                        )
                                                        : `${exam.duration} ${t("Mins")}`}
                                                </span>
                                            </div>
                                            <div
                                                className={`d-flex gap-1 align-items-center cursor-pointer ${isArabic ? "flex-row-reverse" : ""
                                                    }`}
                                            >
                                                <span>{bulbSvg}</span>
                                                <span
                                                    className={`d-flex ${isArabic ? "flex-row-reverse" : ""}`}
                                                >
                                                    <span>
                                                        {exam.examType} {t(exam.category)}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={`d-flex  mt-3 ${isArabic ? "flex-row-reverse" : ""}`}>
                                            <div className="d-flex align-items-center gap-2 fs-6 ">
                                                <button
                                                    className="text-capitalize fs-6 d-flex gap-2 align-items-center"
                                                    style={{
                                                        backgroundColor: "#EDEBF1",
                                                        padding: "6px 10px",
                                                        borderRadius: "8px",
                                                        color: "#463C77",
                                                        width: "auto",
                                                        border: "none",
                                                    }}
                                                >
                                                    <CalendarCheck2
                                                        style={{ height: "16px", width: "16px" }}
                                                    />
                                                    <span
                                                        className="p-0 m-0"
                                                        style={{ fontSize: "12px" }}
                                                    >
                                                        {t("Scheduled")}
                                                    </span>
                                                </button>
                                                <span
                                                    className="p-0 m-0"
                                                    style={{ color: "#fff", fontSize: "10px" }}
                                                >
                                                    {`${exam.startDate} - ${exam.endDate}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default ExamsManagement;
