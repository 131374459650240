import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendance } from "../../../redux/attendanceSlice";
import { fetchEmployees } from "../../../redux/employeeSlice";

const LiveAttendance = () => {
  const dispatch = useDispatch();
  const { attendanceRecords, status, error } = useSelector((state) => state.attendance);
  const { employees } = useSelector((state) => state.employees || { employees: [] });

  useEffect(() => {
    dispatch(fetchAttendance());
    dispatch(fetchEmployees());
  }, [dispatch]);

  // Function to get employee details by employeeId
  const getEmployeeDetails = (employeeId) => {
    return employees.find((emp) => emp.id === employeeId) || {};
  };

  // Extract date from first attendance record
  const attendanceDate = attendanceRecords.length > 0 
    ? new Date(attendanceRecords[0].date).toISOString().split("T")[0] 
    : "No Records";

  // Function to generate today's summary
  const summary = {
    present: 0,
    late: 0,
    onLeave: 0,
    absent: 0,
    shiftAwaiting: 0,
    onHoliday: 0,
  };

  attendanceRecords.forEach((record) => {
    switch (record.status?.toLowerCase()) {
      case "present":
        summary.present++;
        break;
      case "late":
        summary.late++;
        break;
      case "on leave":
        summary.onLeave++;
        break;
      case "absent":
        summary.absent++;
        break;
      case "shift awaiting":
        summary.shiftAwaiting++;
        break;
      case "on holiday":
        summary.onHoliday++;
        break;
      default:
        break;
    }
  });

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-6">
      {/* Main container */}
      <div className="max-w-7xl mx-auto">
        {/* Layout container */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Attendance Section */}
          <div className="md:col-span-2 bg-white rounded-lg shadow-md p-4 md:p-6">
            <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center mb-4 space-y-2 md:space-y-0">
            <h2 className="text-lg md:text-xl font-semibold text-gray-800">
                Today's Attendance <span className="text-gray-500">({attendanceDate})</span>
              </h2>
              <div className="flex flex-col sm:flex-row w-full md:w-auto space-y-2 sm:space-y-0 sm:space-x-2">
                <input
                  type="text"
                  placeholder="Search By Emp. Name/Code"
                  className="border border-gray-300 rounded-lg px-3 py-2 text-sm w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button className="bg-[#241763] text-white rounded-lg px-4 py-2 text-sm w-full sm:w-auto">
                  Sync Attendance
                </button>
              </div>
            </div>

            {/* Attendance Table */}
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr className="bg-gray-100 text-left text-sm text-gray-700">
                    <th className="py-2 px-3">EMP.CODE</th>
                    <th className="px-3">EMPLOYEE NAME</th>
                    <th className="px-3">LOCATION</th>
                    <th className="px-3">CHECK IN</th>
                    <th className="px-3">CHECK OUT</th>
                    <th className="px-3">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {status === "loading" && (
                    <tr>
                      <td colSpan="6" className="text-center py-4">Loading...</td>
                    </tr>
                  )}
                  {status === "failed" && (
                    <tr>
                      <td colSpan="6" className="text-center text-red-500">{error}</td>
                    </tr>
                  )}
                  {status === "succeeded" && attendanceRecords.length === 0 && (
                    <tr>
                      <td className="px-4 py-2 text-center text-gray-500" colSpan="6">
                        No data available
                      </td>
                    </tr>
                  )}
                  {status === "succeeded" &&
                    attendanceRecords.map((record) => {
                      const employee = getEmployeeDetails(record.employeeId);
                      return (
                        <tr key={record.id} className="hover:bg-gray-200">
                          <td className="py-4 px-3">{record.employeeId}</td>
                          <td className="px-3">
                            {`${employee.firstName || "N/A"} ${employee.lastName || ""}`}
                          </td>
                          <td className="px-3">{employee.location || "N/A"}</td>
                          <td className="px-3">{record.checkIn || "N/A"}</td>
                          <td className="px-3">{record.checkOut || "N/A"}</td>
                          <td className="px-3">{record.status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Today's Summary Section */}
          <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
            <h2 className="text-lg md:text-xl font-semibold text-gray-800 mb-4">
              Today's Summary
            </h2>
            <ul className="space-y-2">
              {[
                { label: "Shift Awaiting", count: summary.shiftAwaiting },
                { label: "On Holiday", count: summary.onHoliday },
                { label: "Present", count: summary.present },
                { label: "Late", count: summary.late },
                { label: "On Leave", count: summary.onLeave },
                { label: "Absent", count: summary.absent },
              ].map((item) => (
                <li
                  key={item.label}
                  className="flex justify-between items-center text-sm text-gray-700 border-b border-gray-200 pb-2"
                >
                  <span>{item.label}</span>
                  <span className="w-24 h-4 bg-gray-200 rounded-full flex items-center justify-center">
                    {item.count}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveAttendance;
