import React, { useState } from "react";
import { X } from "lucide-react";
import { Select, Button, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import FlexBtn from "../../Components/Common/FlexBtn";
import { useTranslation } from "react-i18next";

const AddBook = ({ onRequestClose }) => {
  const [bookTitle, setBookTitle] = useState("");
  const [bookDescription, setBookDescription] = useState("");
  const [selectedClass, setSelectedClass] = useState(null);
  const [bookImage, setBookImage] = useState(null);
  const [bookFile, setBookFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const classes = [
    { value: "10", label: "Class 10" },
    { value: "9", label: "Class 9" },
    { value: "8", label: "Class 8" }
  ];

  const handleClose = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleImageUpload = (info) => {
    if (info.file.status === 'done') {
      setBookImage(info.file.originFileObj);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleBookFileUpload = (info) => {
    if (info.file.status === 'done') {
      setBookFile(info.file.originFileObj);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Add submit logic here
      
      handleClose();
    } catch (error) {
      console.error("Error submitting book:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4">
      <div className="row d-flex justify-contents-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">Add New Book</h4>
            <div className="iconWrapper cursor-pointer" onClick={handleClose}><X /></div>
          </div>
        </div>
      </div>
      <div className="modalBody p-4">
        <div className="mb-4">
          <div className="row g-3">
            <div className="col-md-12">
              <label htmlFor="bookTitle" className="form-label">Book Title</label>
              <input
                type="text"
                className="form-control"
                id="bookTitle"
                value={bookTitle}
                onChange={(e) => setBookTitle(e.target.value)}
                placeholder="Enter book title"
              />
            </div>

            <div className="col-md-12">
              <label htmlFor="bookDescription" className="form-label">Book Description</label>
              <textarea
                className="form-control"
                id="bookDescription"
                rows="3"
                value={bookDescription}
                onChange={(e) => setBookDescription(e.target.value)}
                placeholder="Enter book description"
              ></textarea>
            </div>

            <div className="col-md-12">
              <label htmlFor="classSelect" className="form-label">Select Class</label>
              <Select
                placeholder="Select Class"
                style={{ width: '100%' }}
                options={classes}
                onChange={(value) => setSelectedClass(value)}
              />
            </div>

            <div className="col-md-12">
              <Upload
                maxCount={1}
                accept=".jpg,.jpeg,.png,.gif,.bmp,.webp"
                beforeUpload={(file) => {
                  const isImage = file.type.startsWith('image/');
                  if (!isImage) {
                    message.error('You can only upload image files!');
                    return Upload.LIST_IGNORE;
                  }
                  setBookImage(file);
                  return false; // Prevent auto-upload
                }}
                listType="picture"
                onChange={handleImageUpload}
              >
                <Button type="primary" icon={<UploadOutlined />}>
                  {t("UploadBookImg")}
                </Button>
              </Upload>
            </div>

            <div className="col-md-12">
              <Upload
                maxCount={1}
                accept=".pdf,.doc,.docx,.ppt,.pptx"
                beforeUpload={(file) => {
                  const isValidFile = /\.(pdf|doc|docx|ppt|pptx)$/.test(file.name.toLowerCase());
                  if (!isValidFile) {
                    message.error('You can only upload PDF, Word or PowerPoint files!');
                    return Upload.LIST_IGNORE;
                  }
                  setBookFile(file);
                  return false; // Prevent auto-upload
                }}
                listType="picture"
                onChange={handleBookFileUpload}
              >
                <Button type="primary" icon={<UploadOutlined />}>
                  {t("UploadBookFile")}
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 px-4 mt-3 py-3">
        <div className="d-flex gap-3 justify-content-end">
          <div onClick={handleClose}>
            <FlexBtn label={t("Cancel")} color="#463C77" bgColor="#EDEBF1" />
          </div>
          <Button type="primary" loading={loading} onClick={handleSubmit}>
            {t("Create")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBook;