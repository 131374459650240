import React, { useState } from "react";

function HRApprovalModal({ salary, onClose, onUpdateStatus }) {
  const [newStatus, setNewStatus] = useState(salary.hrApproval || "Pending");

  const handleSave = () => {
    onUpdateStatus(newStatus);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        <h2 className="text-lg font-bold mb-4">Update HR Approval Status</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Select Status
          </label>
          <select
            className="border border-gray-300 rounded p-2 w-full"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-400 text-white rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default HRApprovalModal;
