import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import SurveyModal from "../../../modals/admin/SurveyModal";

function SurveysT({ surveys, setSurveys }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const handleAddNew = () => {
    setEditingData(null);
    setIsModalOpen(true);
  };

  const handleSave = (data) => {
    if (editingData) {
      const updated = surveys.map((item, index) =>
        index === editingData.index ? data : item
      );
      setSurveys(updated);
    } else {
      setSurveys([...surveys, data]);
    }
    setIsModalOpen(false);
    setEditingData(null);
  };

  const handleEdit = (index) => {
    setEditingData({ ...surveys[index], index });
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    setSurveys(surveys.filter((_, i) => i !== index));
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <div>
      <div className="flex w-full items-center justify-between mt-4 space-x-2">
        <input
          type="text"
          placeholder="Search..."
          className="border border-gray-300 rounded p-2 w-5/6"
        />
        <button
          onClick={handleAddNew}
          className="px-4 py-2 bg-[#241763] w-1/6 text-white rounded"
        >
          <span className="text-lg">🧑🏻‍💻</span> Add Surveys
        </button>
      </div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr className="bg-gray-100 border-b">
              <th>Survey Name</th>
              <th>Audience</th>
              <th>Campus</th>
              <th>Class</th>
              <th>Section</th>
              <th>Survey Report</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {surveys.length > 0 ? (
              surveys.map((survey, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{survey.surveyName}</td>
                    <td>{survey.audience}</td>
                    <td>{survey.campus}</td>
                    <td>{survey.cls}</td>
                    <td>-</td>{" "}
                    {/* Placeholder for additional fields if needed */}
                    <td>
                      <button
                        className="text-blue-500 underline"
                        onClick={() => toggleView(index)}
                      >
                        View Details
                      </button>
                    </td>
                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(index)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(index)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="7">
                        <div className="bg-gray-100 p-4">
                          <h3 className="font-semibold text-lg">Questions:</h3>
                          <ul>
                            {survey.questions && survey.questions.length > 0 ? (
                              survey.questions.map((q, i) => (
                                <li key={i}>
                                  <strong>Q:</strong> {q.question} <br />
                                  <strong>A:</strong> {q.answer}
                                </li>
                              ))
                            ) : (
                              <li>No questions available</li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Surveys Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <SurveyModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
          editingData={editingData}
        />
      )}
    </div>
  );
}

export default SurveysT;
