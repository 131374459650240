import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Pencil, Trash2 } from "lucide-react";

const DesignationDetail = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [designations, setDesignations] = useState([
    { id: 1, name: "Audit Controls", status: "Disabled" },
    { id: 2, name: "Software House", status: "Active" },
    { id: 3, name: "Procurement", status: "Active" },
    { id: 4, name: "IT Support", status: "Disabled" },
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  // Add or Update Department
  const onSubmit = (data) => {
    if (isEditing) {
      setDesignations((prev) =>
        prev.map((desig) =>
          desig.id === editId ? { ...desig, name: data.departmentName } : desig
        )
      );
      setIsEditing(false);
      setEditId(null);
    } else {
      setDesignations((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          name: data.departmentName,
          status: "Disabled",
        },
      ]);
    }
    reset();
  };

  // Delete Department
  const handleDelete = (id) => {
    setDesignations((prev) => prev.filter((desig) => desig.id !== id));
  };

  // Edit Department
  const handleEdit = (id) => {
    const desig = designations.find((desig) => desig.id === id);
    if (desig) {
      setValue("departmentName", desig.name);
      setIsEditing(true);
      setEditId(id);
    }
  };

  // Activate Department
  const handleActivate = (id) => {
    setDesignations((prev) =>
      prev.map((desig) =>
        desig.id === id
          ? {
              ...desig,
              status: desig.status === "Active" ? "Disabled" : "Active",
            }
          : desig
      )
    );
  };

  return (
    <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Add / Edit Department Form */}
      <div className="bg-white shadow-md rounded-lg p-4 mb-6 h-60">
        <h2 className="text-lg font-semibold mb-4">
          {isEditing ? "Edit Designation" : "Add New Designation"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 text-sm">
          {/* Fields Wrapper */}
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            {/* Department Name Field */}
            <div className="flex-1">
              <label className="block text-sm font-medium mb-1">
                Designation Name *
              </label>
              <input
                type="text"
                {...register("departmentName", { required: true })}
                className="w-full border border-gray-300 rounded-md p-2"
                placeholder="Must be Unique"
              />
            </div>
          </div>
          {/* Buttons */}
          <div className="flex space-x-4">
            <button
              type="submit"
              className="bg-[#241763] text-white px-4 py-2 rounded-md"
            >
              {isEditing ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              onClick={() => {
                reset();
                setIsEditing(false);
                setEditId(null);
              }}
              className="bg-[#241763]/10 text-black px-4 py-2 rounded-md"
            >
              Reset
            </button>
          </div>
        </form>
      </div>

      {/* Departments Table */}
      <div className="bg-white shadow-md rounded-lg p-4 overflow-x-auto text-sm">
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search By Designation name"
            className="border border-gray-300 rounded-md p-2 w-1/2"
          />
          <button className="bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400">
            Clear
          </button>
        </div>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="py-2">Designation NAME</th>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {designations.map((desig) => (
              <tr key={desig.id} className="hover:bg-gray-50">
                <td className="">{desig.name}</td>
                <td>
                  <span
                    className={`px-2 py-1 rounded-md text-sm ${
                      desig.status === "Active"
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    }`}
                  >
                    {desig.status}
                  </span>
                </td>
                <td className="flex space-x-2 py-3">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                    onClick={() => handleEdit(desig.id)}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDelete(desig.id)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>

                  <button
                    onClick={() => handleActivate(desig.id)}
                    className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
                  >
                    {desig.status === "Active" ? "Disable" : "Activate"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DesignationDetail;
