import React, { useState, useEffect } from "react";
import { X, Pencil } from "lucide-react";
import toast from "react-hot-toast";

const ParentModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  setValue,
  reset,
  currentPicture,
  handleBulkUpload,
  uploadType,
  onModalClose, // New callback function from the parent component
}) => {
  const [newPicture, setNewPicture] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // Store the selected file
  const [bulkFile, setBulkFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    if (isEdit) {
      setSelectedFile(null); // Clear selected file when editing to avoid conflicts
      setNewPicture(currentPicture); // Set the current picture on modal open
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file); // Store the selected file
      const imageUrl = URL.createObjectURL(file); // Preview the image
      setNewPicture(imageUrl);
    }
  };

  const handleBulkFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBulkFile(file);
    }
  };

  const handleBulkSubmit = async () => {
    if (!bulkFile) {
      toast.error("Please upload a file first!");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const uploadSimulation = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadSimulation);
          setIsUploading(false);
          toast.success("File has been uploaded!");
          setBulkFile(null);
          setIsModalOpen(false);
          onModalClose();
        }
        return prev + 10; // Increment progress
      });
    }, 300); // Adjust interval speed as needed
  };

  const handleFormSubmit = (data) => {
    onSubmit(data, selectedFile);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleClose = () => {
    reset();
    setIsModalOpen(false);
    setUploadProgress(0);
    setBulkFile(null);
    setIsUploading(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleClose}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <span>
              <X />
            </span>
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Parent" : "Add Parent"}
        </h2>

        <div className="mb-4 flex justify-center">
          <div className="relative inline-block group">
            <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center cursor-pointer">
              {newPicture ? (
                <img
                  src={newPicture}
                  alt="Profile Preview"
                  className="w-full h-full object-cover"
                />
              ) : (
                <span className="text-gray-500">No image</span>
              )}
            </div>
            <button
              type="button"
              className="absolute bottom-1 right-1 bg-white p-1.5 rounded-full shadow-md hover:bg-gray-100 transition-colors"
              onClick={handleImageClick}
            >
              <Pencil className="w-4 h-4 text-gray-700" />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handlePictureChange}
              accept="image/*"
              className="hidden"
              required={!isEdit}
            />
          </div>
        </div>

        {isEdit || uploadType === "single" ? (
          <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
            <h1 className="text-lg font-bold">Personal Details</h1>
            <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
              <div>
                <label className="block">First Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("firstName")}
                  required
                />
              </div>
              <div>
                <label className="block">Last Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("lastName")}
                  required
                />
              </div>
              <div>
                <label className="block">Gender</label>
                <select
                  className="w-full p-2 border rounded"
                  {...register("gender")}
                  required
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div>
                <label className="block">Email</label>
                <input
                  type="email"
                  className="w-full p-2 border rounded"
                  {...register("email")}
                  required={!isEdit}
                  readOnly={isEdit}
                />
              </div>
              <div>
                <label className="block">Phone</label>
                <input
                  type="tel"
                  className="w-full p-2 border rounded"
                  {...register("phone")}
                  required
                />
              </div>
              <div>
                <label className="block">Location</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("location")}
                  required
                />
              </div>
              <div>
                <label className="block">Password</label>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  {...register("password")}
                  required={!isEdit}
                />
              </div>
              <div>
                <label className="block">Confirm Password</label>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  {...register("confirmPassword")}
                  required={!isEdit}
                />
              </div>
              {/* <div>
              <label className="block">CNIC</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("cnic")}
                
              />
            </div> */}
              <div>
                <label className="block">DOB</label>
                <input
                  type="date"
                  className="w-full p-2 border rounded"
                  {...register("dob")}
                  required
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                className="px-4 py-2 bg-[#241763] text-white rounded"
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>
          </form>
        ) : (
          <div className="space-y-4">
            <h1 className="text-lg font-bold">Bulk Upload</h1>
            <input
              type="file"
              className="w-full p-2 border rounded"
              onChange={handleBulkFileChange}
              accept=".xlsx, .xls"
              disabled={isUploading}
            />
            {isUploading && (
              <div className="mt-4">
                <label className="block font-medium mb-2">
                  Upload Progress
                </label>
                <div className="relative w-full h-4 bg-gray-200 rounded">
                  <div
                    className="absolute top-0 left-0 h-4 bg-green-500 rounded"
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
                <span className="text-sm text-gray-600">{uploadProgress}%</span>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <button
                type="button"
                onClick={handleBulkSubmit}
                className={`px-4 py-2 rounded ${
                  isUploading
                    ? "bg-gray-400 text-white cursor-not-allowed"
                    : "bg-[#241763] text-white"
                }`}
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentModal;
