import React from "react";
import { useForm } from "react-hook-form";

const HolidayModal = ({ isOpen, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const submitForm = (data) => {
    onSubmit(data); // Pass the data to parent component
    reset(); // Reset the form
    onClose(); // Close the modal
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
       <div className=" flex items-center justify-between mb-4">
       <h2 className="text-xl font-semibold ">Add Holiday</h2>
        <button
              type="button"
              onClick={onClose}
              className="mr-4 px-4 py-2 rounded bg-gray-300"
            >
              X
            </button>
       </div>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Holiday Name</label>
            <input
              type="text"
              {...register("name", { required: "Holiday name is required" })}
              className="w-full border rounded px-3 py-2"
            />
            {errors.name && (
              <span className="text-red-500 text-sm">{errors.name.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Start Date</label>
            <input
              type="date"
              {...register("start", { required: "Start date is required" })}
              className="w-full border rounded px-3 py-2"
            />
            {errors.start && (
              <span className="text-red-500 text-sm">{errors.start.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">End Date</label>
            <input
              type="date"
              {...register("end", { required: "End date is required" })}
              className="w-full border rounded px-3 py-2"
            />
            {errors.end && (
              <span className="text-red-500 text-sm">{errors.end.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Description</label>
            <textarea
              {...register("description", { required: "Description is required" })}
              className="w-full border rounded px-3 py-2"
            ></textarea>
            {errors.description && (
              <span className="text-red-500 text-sm">{errors.description.message}</span>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 rounded bg-blue-500 text-white"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HolidayModal;
