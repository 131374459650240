import React from "react";
import { feesHeadings, feesData } from "../../Constant/grades";
import {users as userData} from '../../Constant/usersChild';
import FeesTable from "../../Components/parent/FeesTable";

const Fees = () => {
  // Using static data instead of Redux selector
  const activeUser = userData.find(user => user.isActive === true);
  const table = activeUser?.fees;

  const newData = table?.map((item, index) => ({
    ...item,
    avatar: userData[index]?.avatar
  }));

  return (
    <>
    <div className="container-fluid shadow px-4 mt-4 py-3 bg-white rounded">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center fw-bold py-4">List of Student Fees</h4>
        </div>
      </div>
     
      <div className="row mt-3">
        <FeesTable headings={feesHeadings} data={newData} />
      </div>
    </div>
    </>
  );
};

export default Fees;
