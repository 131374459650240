import React, { useState } from "react";
import ViewReportModal from "./ViewReportModal";

const AttendanceModal = ({ isOpen, onClose }) => {
  const [viewReportOpen, setViewReportOpen] = useState(false);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
          <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">✖</button>
          <h2 className="text-xl font-semibold mb-4">Attendance Report</h2>
          <p className="text-gray-700">Select filters for Attendance Report.</p>

          {/* Filters */}
          <div className="grid grid-cols-2 gap-4 mt-4">
            <select className="border p-2 rounded">
              <option>Type</option>
              <option>Student</option>
              <option>Teacher</option>
            </select>
            <input type="month" className="border p-2 rounded" />
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button onClick={onClose} className="bg-gray-400 px-4 py-2 rounded text-white">Close</button>
            <button onClick={() => setViewReportOpen(true)} className="bg-indigo-600 px-4 py-2 rounded text-white">
              View Report
            </button>
          </div>
        </div>
      </div>

      {/* View Report Modal */}
      <ViewReportModal isOpen={viewReportOpen} onClose={() => setViewReportOpen(false)} reportType="Attendance" />
    </>
  );
};

export default AttendanceModal;
