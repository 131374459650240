import { Pencil, Trash2 } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const LocationDetail = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [locations, setLocations] = useState([
    { id: 1, name: 'Sydney', address: 'Sydney, Australia', headOffice: false },
    { id: 2, name: 'New York', address: 'New York, USA', headOffice: true },
    { id: 3, name: 'Test User', address: 'ahmadse003@gmail.com', headOffice: true },
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [cities, setCities] = useState(['Chicago', 'London', 'New York', 'Sydney']);
  const [newCityInput, setNewCityInput] = useState("");
  
  const onSubmit = (data) => {
    if (isEditing) {
      setLocations((prev) =>
        prev.map((location) =>
          location.id === editId
            ? { ...location, ...data, headOffice: data.headOffice === 'on' }
            : location
        )
      );
      setIsEditing(false);
      setEditId(null);
    } else {
      setLocations((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          ...data,
          headOffice: data.headOffice === 'on',
        },
      ]);
    }
    reset();
  };

  const handleDelete = (id) => {
    setLocations((prev) => prev.filter((location) => location.id !== id));
  };

  const handleEdit = (id) => {
    const location = locations.find((location) => location.id === id);
    if (location) {
      setValue("name", location.name);
      setValue("address", location.address);
      setValue("headOffice", location.headOffice ? 'on' : 'off');
      setIsEditing(true);
      setEditId(id);
    }
  };

  const handleDeleteCity = (cityToDelete) => {
    setCities(cities.filter((city) => city !== cityToDelete));
  };

  const handleAddCity = () => {
    if (newCityInput.trim() !== "" && !cities.includes(newCityInput.trim())) {
      setCities([...cities, newCityInput.trim()]);
      setNewCityInput("");
    }
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {/* Add New Location Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-medium mb-4">{isEditing ? "Edit Location" : "Add New Location"}</h2>
          <form onSubmit={handleSubmit(onSubmit)} className='text-sm'>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Location Name</label>
              <input type="text" id="name" {...register('name', { required: 'Location Name is required' })} className="border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" />
            </div>
            <div className="mb-4">
              <label htmlFor="address" className="block text-gray-700 font-medium mb-2">Location Address</label>
              <input type="text" id="address" {...register('address', { required: 'Location Address is required' })} className="border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" />
            </div>
            <div className="mb-4">
              <label htmlFor="headOffice" className="inline-flex items-center">
                <input type="checkbox" id="headOffice" {...register('headOffice')} className="form-checkbox h-5 w-5 text-blue-600 rounded" />
                <span className="ml-2 text-gray-700">Head Office</span>
              </label>
            </div>
            <button type="submit" className="bg-[#241763] text-white font-medium py-2 px-4 rounded-md">
              {isEditing ? "Update" : "Submit"}
            </button>
            <button type="button" onClick={() => { reset(); setIsEditing(false); setEditId(null); }} className="ml-2 bg-[#241763]/10 text-gray-700 font-medium py-2 px-4 rounded-md">Reset</button>
          </form>
        </div>

        {/* Location Table Card */}
       
        <div className="bg-white rounded-lg shadow-md p-6 overflow-x-auto">
          <h2 className="text-lg font-medium mb-4">Locations</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location Name</th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Head Office</th>
                <th className="px-6 py-3 bg-gray-50"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {locations.map((location) => (
                <tr key={location.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{location.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{location.address}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{location.headOffice ? 'Yes' : 'No'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex space-x-2">
                    <button className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group" onClick={() => handleEdit(location.id)}>
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group" onClick={() => handleDelete(location.id)}>
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
       {/* City Names Card */}
       <div className="bg-white rounded-lg shadow-md p-6 mt-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-medium">City Names</h2>
          <div className="flex">
            <input
              type="text"
              value={newCityInput}
              onChange={(e) => setNewCityInput(e.target.value)}
              placeholder="Enter city name"
              className="border border-gray-300 rounded-md px-2 py-1 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleAddCity}
              className="bg-[#241763] text-white font-medium py-1 px-2 rounded-md text-sm"
            >
              Add City
            </button>
          </div>
        </div>
        <ul>
          {cities.map((city) => (
            <li
              key={city}
              className="flex justify-between items-center text-sm py-2 border-b border-gray-200"
            >
              <span>{city}</span>
              <button
                onClick={() => handleDeleteCity(city)}
                className="text-red-500 hover:text-red-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LocationDetail;
