import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getRequest } from "../services";

const ClassDetails = () => {
  const [classData, setClassData] = useState();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const tableHeadingTeach = ["SubjectName", "Class", "Section"];

  // Process data to get unique class/section combinations with their subjects
  const processedData = classData ? 
    Object.values(classData.reduce((acc, curr) => {
      const key = `${curr.className}-${curr.sectionName}`;
      if (!acc[key]) {
        acc[key] = {
          class: curr.className,
          section: curr.sectionName,
          subjects: new Set()
        };
      }
      curr.subjects.forEach(subject => {
        acc[key].subjects.add(subject.subjectName);
      });
      return acc;
    }, {})).map(item => ({
      ...item,
      subjects: Array.from(item.subjects).join(", ")
    }))
    : [];

  useEffect(() => {
    const fetchDataClass = async () => {
      try {
        const response = await getRequest(`/api/ClassAssignment/MyClasses`);
        const data = response.data.data;
        setClassData(data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchDataClass();
  }, []);

  return (
    <div className="w-100 py-4 px-4  d-flex flex-column userGenInfoWrapper gap-3 mt-3">
      <h2 className="heading24px fw-bold text-center">{t("ClassesDetails")}</h2>
      <div className="Salary_card">
        <div className="Salary_Report">
        </div>
        <div className="Table_Card" dir={isArabic === "sa" ? "rtl" : "ltr"}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontFamily: "arial, sans-serif",
            }}
          >
            <thead>
              <tr>
                {tableHeadingTeach.map((heading, index) => (
                  <th key={index} className="Salary_Heading">
                    {t(heading)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {processedData.map((data, index) => (
                <tr key={index}>
                  <td className="Salary_Data">{data.subjects}</td>
                  <td className="Salary_Data">{data.class}</td>
                  <td className="Salary_Data">{data.section}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClassDetails;
