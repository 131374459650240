import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWelfareAllowances,
  addWelfareAllowance,
  updateWelfareAllowance,
  deleteWelfareAllowance,
} from "../../redux/welfareAllowanceSlice";
import { fetchEmployees } from "../../redux/employeeSlice";
import { Pencil, Trash2 } from "lucide-react";
import ApprovalDuesModal from "../../modals/Hrms/ApprovaDueslModal";
import ApplyWelfareModal from "../../modals/Hrms/ApplyWelfareModal";

const WelfareAllowance = () => {
  const dispatch = useDispatch();

  // Fetch Welfare Allowances
  const { welfareAllowances, status } = useSelector(
    (state) => state.welfareAllowance
  );

  // Fetch Employees
  const { employees, status: employeeStatus } = useSelector(
    (state) => state.employees
  );

  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [selectedAllowance, setSelectedAllowance] = useState(null);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [editingDues, setEditingDues] = useState(null);

  useEffect(() => {
    dispatch(fetchWelfareAllowances());
    dispatch(fetchEmployees()); // Fetch employees when the component loads
  }, [dispatch]);

  // Apply Welfare Allowance (Create or Update)
  const handleApplyDues = async (data) => {
    if (editingDues) {
      await dispatch(
        updateWelfareAllowance({ id: editingDues.id, updatedData: data })
      );
      setEditingDues(null);
    } else {
      await dispatch(addWelfareAllowance(data));
    }
    setIsApplyModalOpen(false);
  };

  // Delete Welfare Allowance
  const handleDeleteDues = async (id) => {
    await dispatch(deleteWelfareAllowance(id));
  };

  // Approve / Reject Welfare Allowance
  const handleUpdateStatus = async (newStatus) => {
    if (selectedAllowance) {
      await dispatch(
        updateWelfareAllowance({
          id: selectedAllowance.id,
          updatedData: { status: newStatus },
        })
      );
      setSelectedAllowance(null);
      setIsApprovalModalOpen(false);
    }
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Session Name: March-2024</h1>
        <button
          onClick={() => setIsApplyModalOpen(true)}
          className="bg-[#241763] text-white px-4 py-2 rounded"
        >
          Apply Welfare Allowance
        </button>
      </div>

      {status === "loading" ? (
        <p>Loading welfare allowances...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full mt-6 text-sm">
            <thead className="border-b font-bold">
              <tr>
                <th>EMP. CODE</th>
                <th>EMPLOYEE NAME</th>
                <th>DATE</th>
                <th>Salary</th>
                <th>SESSION</th>
                <th>Allowance TYPE</th>
                <th>AMOUNT</th>
                <th>DESCRIPTION</th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {welfareAllowances.map((due) => (
                <tr key={due.id}>
                  <td>{due.Employee?.id || "N/A"}</td>
                  <td>
                    <div>
                      <div className="font-medium">
                        {due.Employee?.firstName} {due.Employee?.lastName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {due.Employee?.department}
                      </div>
                    </div>
                  </td>
                  <td>{due.createdAt.split("T")[0]}</td>
                  <td>{due.salary || "N/A"}</td>
                  <td>{due.sessionName}</td>
                  <td>{due.allowanceType}</td>
                  <td>{due.amount}</td>
                  <td>{due.description}</td>
                  <td
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedAllowance(due);
                      setIsApprovalModalOpen(true);
                    }}
                  >
                    <span
                      className={`px-2 py-1 rounded ${
                        due.status === "Pending"
                          ? "bg-orange-100 text-orange-600"
                          : due.status === "Approved"
                          ? "bg-green-100 text-green-600"
                          : "bg-red-100 text-red-600"
                      }`}
                    >
                      {due.status}
                    </span>
                  </td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => {
                        setEditingDues(due);
                        setIsApplyModalOpen(true);
                      }}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteDues(due.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Apply Welfare Modal */}
      <ApplyWelfareModal
        isOpen={isApplyModalOpen}
        onClose={() => {
          setEditingDues(null);
          setIsApplyModalOpen(false);
        }}
        onSubmit={handleApplyDues}
        initialData={editingDues}
        employees={employees} // Pass employees to the modal
      />

      {/* Approval Modal */}
      {isApprovalModalOpen && (
        <ApprovalDuesModal
          isOpen={isApprovalModalOpen}
          onClose={() => setIsApprovalModalOpen(false)}
          onUpdateStatus={handleUpdateStatus}
          currentStatus={selectedAllowance?.status}
        />
      )}
    </div>
  );
};

export default WelfareAllowance;
