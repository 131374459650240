import React from "react";

const ViewReportModal = ({ isOpen, onClose, reportType }) => {
  if (!isOpen) return null;

  // Dummy HRMS report data (You can replace this with dynamic API data)
  const reportData = {
    employeeName: "John Doe (EMP-1001)",
    department: "IT",
    designation: "Software Engineer",
    reviewPeriod: "Jan - Dec 2024",
    keyMetrics: [
      { metric: "Project Completion", score: "85%" },
      { metric: "Attendance", score: "92%" },
      { metric: "Training Sessions Attended", score: "5" },
      { metric: "Peer Feedback Score", score: "4.5/5" },
    ],
    overallPerformance: "Exceeds Expectations",
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[50%] relative">
        {/* Close Button */}
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 text-xl">
          ✖
        </button>

        {/* Modal Title */}
        <h2 className="text-2xl font-semibold text-indigo-700 mb-4">
          {reportType} Report
        </h2>

        {/* Employee Details */}
        <div className="border p-4 rounded-lg bg-gray-50">
          <p><strong>Employee:</strong> {reportData.employeeName}</p>
          <p><strong>Department:</strong> {reportData.department}</p>
          <p><strong>Designation:</strong> {reportData.designation}</p>
          <p><strong>Review Period:</strong> {reportData.reviewPeriod}</p>
        </div>

        {/* Performance Metrics */}
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Key Performance Metrics</h3>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2 text-left">Metric</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Score</th>
              </tr>
            </thead>
            <tbody>
              {reportData.keyMetrics.map((metric, index) => (
                <tr key={index} className="border border-gray-300">
                  <td className="border border-gray-300 px-4 py-2">{metric.metric}</td>
                  <td className="border border-gray-300 px-4 py-2">{metric.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Summary & Actions */}
        <div className="mt-6">
          <p className="text-lg font-semibold text-indigo-700">
            Overall Performance: {reportData.overallPerformance}
          </p>
        </div>

        <div className="flex justify-end mt-6 space-x-3">
          <button onClick={onClose} className="bg-gray-400 px-4 py-2 rounded text-white">
            Close
          </button>
          <button className="bg-green-600 px-4 py-2 rounded text-white">
            Download Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewReportModal;
