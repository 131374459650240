import React, { useState } from 'react';
import { Plus } from "lucide-react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import AddBook from "../../modals/teacher/AddBook";
import book from "../../assets/images/book.png";
import { Table } from 'antd';
import GenerateExam from "../../modals/teacher/GenerateExams";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto", 
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    border: "none",
  },
};

const examData = [
  {
    id: 1,
    title: "Physics",
    class: "10th",
    image: book,
    alt: "Exam Creation"
  },
  {
    id: 2, 
    title: "Chemistry",
    class: "9th",
    image: book,
    alt: "Question Bank"
  },
  {
    id: 3,
    title: "Biology",
    class: "8th",
    image: book,
    alt: "Results Analysis"
  }
];

const paperData = [
  {
    id: 1,
    subject: "Mathematics",
    class: "10th",
    date: "2024-02-15",
    status: "Published"
  },
  {
    id: 2,
    subject: "Science",
    class: "9th", 
    date: "2024-02-16",
    status: "Draft"
  },
  {
    id: 3,
    subject: "English",
    class: "8th",
    date: "2024-02-17", 
    status: "Published"
  }
];

const ExamAutomation = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [generateExamModal, setGenerateExamModal] = useState(false);
  const [activeTab, setActiveTab] = useState('generate');
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "sa";

  const handleAddNewExamClick = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeGenerateExamModal = () => {
    setGenerateExamModal(false);
  };

  const handleBookClick = () => {
    setGenerateExamModal(true);
  };

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    }
  ];

  return (
    <div className="container">
      <div className={`row justify-content-between ${isArabic ? "flex-row-reverse" : ""}`}>
        <div className="col-lg-4  mb-3">
          <input
            type="text"
            placeholder={t('searchHear')}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className={`w-full p-2 border rounded ${isArabic ? "text-end" : "text-start"}`}
          />
        </div>
        <div className="col-lg-8 mb-3 d-flex gap-3 justify-content-end justify-content-md-start">
          <button
            className={`btn w-50 ${activeTab === 'generate' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => setActiveTab('generate')}
          >
            Generate Paper
          </button>
          <button
            className={`btn w-50 ${activeTab === 'view' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => setActiveTab('view')}
          >
            View Paper
          </button>
          <button
            className={`text-capitalize fs-6 gap-3 w-50  d-flex justify-content-arround align-items-center btnWithIcon bg-main ${isArabic ? "flex-row-reverse" : ""}`}
            onClick={handleAddNewExamClick}
          >
            <span className="px-1 py-1 fw-4 rounded p-0 addButtonSty">
              <Plus />
            </span>
            <span>{t('addNew')}</span>
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Book Modal"
      >
        <AddBook onRequestClose={closeModal} />
      </Modal>

      <Modal
        isOpen={generateExamModal}
        onRequestClose={closeGenerateExamModal}
        style={customStyles}
        contentLabel="Generate Exam Modal"
      >
        <GenerateExam onRequestClose={closeGenerateExamModal} />
      </Modal>

      {activeTab === 'generate' ? (
        <div className="row mt-5">
          {examData.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 cursor-pointer mt-3" key={item.id} >
              <div className="w-100 h-[35vh] relative duration-300 rounded-md" onClick={handleBookClick}>
                <img src={item.image} className="card-img-top w-100 h-100 rounded-md" alt={item.alt} />
                <div className="card-img-overlay d-flex flex-column justify-content-center pt-7">
                  <h5 className="card-title text-white text-center fw-bold" style={{transform: ""}}>{item.title}</h5>
                  <p className="card-text text-white text-center fw-bold" style={{transform: ""}}>{item.class}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Table 
          columns={columns}
          dataSource={paperData}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: paperData.length,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total ${total} items`
          }}
        />
      )}
    </div>
  );
};

export default ExamAutomation;
