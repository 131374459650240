import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { bookSvg, calanderSvg } from "../../Constant/svgs";
import { formatTime } from "../../Constant/date";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CarouselLesson = (props) => {
  const { filteredLessons, state } = props;
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const navigate = useNavigate();
  const [chunkSize, setChunkSize] = useState(3);

  // Determine the chunk size based on screen width
  const updateChunkSize = () => {
    const width = window.innerWidth;
    if (width >= 992) {
      setChunkSize(3); 
    } else if (width >= 768) {
      setChunkSize(2); 
    } else {
      setChunkSize(1); 
    }
  };

  useEffect(() => {
    updateChunkSize(); // Set the initial chunk size
    window.addEventListener("resize", updateChunkSize); // Update on resize
    return () => window.removeEventListener("resize", updateChunkSize);
  }, []);

  // Chunk array based on the determined chunk size
  const chunkArray = (arr, size) =>
    arr.reduce((acc, _, i) => (i % size === 0 ? acc.concat([arr.slice(i, i + size)]) : acc), []);

  const groupedLessons = chunkArray(filteredLessons, chunkSize); // Group items dynamically

  return (
    <>
      <h4 className="fw-bold heading24px mb-3">{`Lesson Plan`}</h4>
      <div id="lessonCarousel" className="carousel slide">
        <div className="carousel-indicators">
          {groupedLessons.map((_, idx) => (
            <button
              key={idx}
              type="button"
              data-bs-target="#lessonCarousel"
              data-bs-slide-to={idx}
              className={idx === 0 ? "active" : ""}
              aria-label={`Slide ${idx + 1}`}
            ></button>
          ))}
        </div>

        {/* Slides */}
        <div className="carousel-inner">
          {groupedLessons.map((group, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`} onClick={() => navigate(`/lesson/${group[index].subjectId}`)}>
              <div className="row w-100 m-auto">
                {group.map((lesson, index) => (
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 d-flex justify-content-center cursor-pointer py-2"
                    key={`${index}`}
                  >
                    <div className="d-flex flex-column align-items-arround bg-white examDataWrapper px-2 py-3 borderRadius_15">
                      <h4
                        className={`fw-bold p-0 m-0 fs-6 cursor-pointer px-2 ${isArabic === "sa" ? "text-end" : ""}`}
                      >
                        {lesson.topic}
                      </h4>
                      <div
                        className={`d-flex justify-content-start gap-3 examChipsWrapper mt-3 px-2 ${isArabic === "sa" ? "flex-row-reverse" : ""}`}
                      >
                        <div className="d-flex examChip">{state.className}</div>
                        <div className="d-flex examChip">{state.sectionName}</div>
                        <div className="d-flex examChip">{state.subjectName}</div>
                      </div>
                      <div
                        className={`d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2 ${isArabic === "sa" ? "flex-row-reverse" : ""}`}
                      >
                        <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                          {calanderSvg}
                          <span className="p-0 m-0">{new Date(lesson.date).toISOString().split("T")[0]} </span>
                        </div>
                        <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                          {calanderSvg}
                          <span className="p-0 m-0">{lesson.day}</span>
                        </div>
                        <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                          {bookSvg}
                          <span>{lesson.lessonPlanType}</span>
                        </div>
                      </div>
                      <div
                        className={`d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2 ${isArabic === "sa" ? "flex-row-reverse" : ""}`}
                      >
                        <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                          <span>{t("StartTime")}</span>
                          <p className="p-0 m-0">{lesson.startTime}</p>
                        </div>
                        <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                          <span>{t("EndTime")}</span>
                          <p className="p-0 m-0">{lesson.endTime}</p>
                        </div>
                        <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                          <span>{t("Duration")}</span>
                          <p className="p-0 m-0">
                            {formatTime(lesson.startTime, lesson.endTime)} {t("Mins")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#lessonCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#lessonCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default CarouselLesson;
