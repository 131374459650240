import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest, updateRequest, deleteRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  management: [],
  permissions: [], // Store fetched permissions
  loading: false,
  error: null,
};

// Fetch Permissions
export const fetchPermissions = createAsyncThunk(
  "management/fetchPermissions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/Management/permissions");
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch permissions");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// Fetch Management Data
export const fetchManagement = createAsyncThunk(
  "management/fetchManagement",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/Management");
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch management data");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add Management
export const addManagement = createAsyncThunk(
  "management/addManagement",
  async (management, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/Management/admin", management);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update Management
export const updateManagement = createAsyncThunk(
  "management/updateManagement",
  async ({ id, management }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/Management/${id}`, management);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete Management
export const deleteManagement = createAsyncThunk(
  "management/deleteManagement",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/Management/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const managementSlice = createSlice({
  name: "management",
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(fetchPermissions.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchPermissions.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    })
    .addCase(fetchPermissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
      .addCase(fetchManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.management = action.payload;
      })
      .addCase(fetchManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(state.error);
      })
      .addCase(addManagement.fulfilled, (state, action) => {
        state.management = [...state.management, action.payload];
        toast.success("Management data added successfully!");
      })
      .addCase(updateManagement.fulfilled, (state, action) => {
        const index = state.management.findIndex((m) => m.id === action.payload.id);
        if (index !== -1) {
          state.management[index] = { ...state.management[index], ...action.payload };
        }
        toast.success("Management data updated successfully!");
      })      
      .addCase(updateManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Failed to update management!");
      })
      .addCase(deleteManagement.fulfilled, (state, action) => {
        state.management = state.management.filter((m) => m.id !== action.payload);
        toast.success("Management data deleted successfully!");
      });
  },
});

export default managementSlice.reducer;
