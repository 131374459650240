import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pencil, Trash2, ChevronDown, X, Plus } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import CoursesModal from "../../modals/admin/CoursesModal";
import {
  fetchCourses,
  addCourse,
  updateCourse,
  deleteCourse,
  setPageNumber,
} from "../../redux/coursesSlice";
import { renderStars, uploadDocuments, uploadImage, uploadVideos } from "../../utils";

const Courses = () => {
  const dispatch = useDispatch();
  const { courses, loading, pageNumber, pageSize, totalCount } = useSelector(
    (state) => state.courses
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [expandedCourseIds, setExpandedCourseIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(fetchCourses({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  console.log("courses", courses);

  const onSubmit = async (courseData, coverImage, video) => {
    let coverImageUrl = null;
    let videoUrl = null;
  
    if (coverImage) {
      coverImageUrl = await uploadImage(coverImage);
    } else if (isEdit) {
      coverImageUrl = currentCourse?.coverImageUrl;
    }
  
    if (video) {
      videoUrl = (await uploadVideos([video]))?.[0];
    } else if (isEdit) {
      videoUrl = currentCourse?.videoUrl;
    }
  
    const uploadFile = async (file, type) => {
      switch (type) {
        case "Document":
          return (await uploadDocuments([file]))?.[0];
        case "Video":
          return (await uploadVideos([file]))?.[0];
        case "Image":
        case "Project":
          return await uploadImage(file);
        default:
          return "";
      }
    };
  
    const plans = await Promise.all(
      courseData.weeks.map(async (week, weekIndex) => ({
        weekName: week.weekName || `Week ${weekIndex + 1}`,
        activities: await Promise.all(
          week.activities.map(async (activity) => {
            const fileUrl = activity.file
              ? await uploadFile(activity.file[0], activity.activityType)
              : "";
  
            return {
              activityName: activity.activityName,
              activityType: activity.activityType,
              file: fileUrl,
            };
          })
        ),
      }))
    );
  
    const payload = {
      name: courseData.name,
      type: courseData.type,
      creditHours: courseData.creditHours,
      startedSession: courseData.startedSession,
      endSession: courseData.endSession,
      actualPrice: courseData.actualPrice,
      discountedPrice: courseData.discountedPrice,
      description: courseData.description,
      coverImageUrl,
      videoUrl,
      plans,
    };
  
    if (isEdit) {
      await dispatch(updateCourse({ id: currentCourse.id, payload }));
    } else {
      await dispatch(addCourse(payload));
    }
  
    toast.success(isEdit ? "Course updated successfully!" : "Course added successfully!");
    setIsModalOpen(false);
    setCurrentCourse(null);
  };
  
  

  const handleEditCourse = (course) => {
    setCurrentCourse(course);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleDeleteCourse = async (id) => {
    await dispatch(deleteCourse(id));
    toast.success("Course deleted successfully!");
  };

  const toggleExpand = (id) => {
    setExpandedCourseIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((courseId) => courseId !== id)
        : [...prevIds, id]
    );
  };

  const filteredCourses = courses.filter((course) =>
    [course.name, course.type].some((field) =>
      field?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const coursesToDisplay = filteredCourses;
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentCourses = coursesToDisplay;

  return (
    <div className="container mx-auto p-6 border border-[#241763]">
      <h2 className="text-lg font-semibold mb-4">
        Total Courses: {filteredCourses.length}
      </h2>

      <div className="flex justify-between mb-4 space-x-4">
        <input
          type="text"
          placeholder="Search by Course Name or Type"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-3/4 p-2 border rounded"
        />
        <button
          className="flex items-center justify-center w-1/4 bg-[#241763] text-white p-2 rounded space-x-2"
          onClick={() => {
            setIsModalOpen(true);
            setCurrentCourse(null);
          }}
        >
          <Plus className="h-4 w-4" />
          <span>Add Course</span>
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white rounded p-2"
                onClick={() => setIsModalOpen(false)}
              >
                <X />
              </button>
            </div>
            <CoursesModal
             currentClass={currentCourse} 
             onSubmit={onSubmit}
             isEdit = {isEdit}
              />
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <table className="table-auto w-full mt-6">
        <thead className="py-4 border-b font-bold">
          <tr>
            <th className="p-2">Cover Image</th>
            <th className="p-2">Course Name</th>
            <th className="p-2">Course Type</th>
            <th className="p-2">Rating</th>
            <th className="p-2">Actual Price</th>
            <th className="p-2">Discounted Price</th>
            <th className="p-2">Video</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {currentCourses.map((course) => (
            <React.Fragment key={course.id}>
              <tr className="hover:bg-gray-200">
                <td className="p-2">
                  {course.coverImageUrl ? (
                    <img src={course.coverImageUrl} alt="Course cover"  className="w-8 h-8 sm:w-10 sm:h-10 rounded cursor-pointer" />
                  ) : (
                    "No Image"
                  )}
                </td>
                <td className="p-2">{course.name}</td>
                <td className="p-2">{course.type}</td>
                <td>
                  <div className="flex items-center">{renderStars(course.rating)}</div>
                </td>
                <td className="p-2">${course.price || "N/A"}</td>
                <td className="p-2">${course.discountedPrice || "N/A"}</td>
                <td className="p-2">
                  {course.videoUrl ? (
                    <a
                      href={course.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Watch Video
                    </a>
                  ) : (
                    "No Video"
                  )}
                </td>
                <td className="p-2 space-x-2 flex items-center">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDeleteCourse(course.id)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => toggleExpand(course.id)}
                  >
                    <ChevronDown
                      className={`w-4 h-4 group-hover:text-white transition-transform ${
                        expandedCourseIds.includes(course.id) ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                </td>
              </tr>
              {expandedCourseIds.includes(course.id) && (
                <tr>
                  <td colSpan="8" className="p-4 bg-gray-100">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                      {course.plans && course.plans.length > 0 ? (
                        course.plans.map((plan, index) => (
                          <div
                            key={index}
                            className="border border-gray-300 shadow-md rounded-lg p-4"
                          >
                            <h5 className="font-bold text-lg mb-2">{plan.weekName} Plan</h5>
                            {plan.activities && plan.activities.length > 0 ? (
                              <ul className="list-disc pl-5">
                                {plan.activities.map((activity, idx) => (
                                  <li key={idx}>
                                    <p>
                                      <span className="font-medium">
                                        {activity.activityName}
                                      </span>{" "}
                                      ({activity.activityType})
                                    </p>
                                    <a
                                      href={activity.filePath}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-600 underline"
                                    >
                                      View Activity
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-gray-500">No activities listed for this plan.</p>
                            )}
                          </div>
                        ))
                      ) : (
                        <p className="col-span-full text-center text-gray-500">
                          No plans added yet.
                        </p>
                      )}
                    </div>
                    <p className="mt-4">{course.description}</p>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      
      )}
      <Toaster />
    </div>
  );
};

export default Courses;
