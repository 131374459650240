import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateDues } from "../../redux/duesSlice"; // Adjust import path as needed

const ApprovalDuesModal = ({ isOpen, onClose, currentDues }) => {
  const dispatch = useDispatch();
  const [newStatus, setNewStatus] = useState("Pending");

  // Update status when modal opens
  useEffect(() => {
    if (currentDues) {
      setNewStatus(currentDues.status || "Pending");
    }
  }, [currentDues]);

  if (!isOpen) return null;

  const handleSave = () => {
    if (currentDues && currentDues.id) {
      // Dispatch action to update dues status
      dispatch(updateDues({
        id: currentDues.id,
        updatedData: {
          status: newStatus
        }
      }));
    }
    onClose(); // Close the modal after updating
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm p-4">
        <h2 className="text-lg font-medium mb-4">Update Dues Status</h2>
        
        {/* Employee Details */}
        {currentDues && (
          <div className="mb-4 bg-gray-100 p-3 rounded">
            <p><strong>Employee:</strong> {currentDues.Employee?.firstName} {currentDues.Employee?.lastName}</p>
            <p><strong>Department:</strong> {currentDues.Employee?.department}</p>
            <p><strong>Dues Type:</strong> {currentDues.duesType}</p>
            <p><strong>Amount:</strong> {currentDues.amount}</p>
            <p><strong>Session:</strong> {currentDues.sessionName}</p>
          </div>
        )}

        {/* Status Dropdown */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Select Status</label>
          <select
            className="border border-gray-300 rounded p-2 w-full"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        
        {/* Buttons */}
        <div className="flex justify-end space-x-2">
          <button 
            onClick={onClose} 
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button 
            onClick={handleSave} 
            className="bg-[#231763] text-white px-4 py-2 rounded hover:bg-[#241763]/80"
          >
            Save Status
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalDuesModal;