import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, updateRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  feeStructures: [],
  loading: false,
  error: null,
};

export const fetchFeeStructures = createAsyncThunk(
  "feeStructures/fetchFeeStructures",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/FeeManagement");
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch fee structures");
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUnpaidOrOverdueBills = createAsyncThunk(
  "feeStructures/fetchUnpaidOrOverdueBills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/FeeManagement/GetUnpaidOrOverdueBills");
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch unpaid or overdue bills");
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addFeeStructure = createAsyncThunk(
  "feeStructures/addFeeStructure",
  async (feeStructure, { rejectWithValue }) => {
    try {
      const response = await postRequest("/api/FeeManagement", feeStructure);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateFeeStructure = createAsyncThunk(
  "feeStructures/updateFeeStructure",
  async ({ id, feeStructure }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/FeeManagement/${id}`, feeStructure);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFeeStructure = createAsyncThunk(
  "feeStructures/deleteFeeStructure",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/FeeManagement/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const feeStructureSlice = createSlice({
  name: "feeStructures",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeStructures.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFeeStructures.fulfilled, (state, action) => {
        state.loading = false;
        state.feeStructures = action.payload?.data?.items || [];

      })
      .addCase(fetchFeeStructures.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch fee structures";
        toast.error(state.error);
      })
      .addCase(fetchUnpaidOrOverdueBills.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnpaidOrOverdueBills.fulfilled, (state, action) => {
        state.loading = false;
        state.unpaidOrOverdueBills = action.payload || [];
      })
      .addCase(fetchUnpaidOrOverdueBills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch unpaid or overdue bills";
        toast.error(state.error);
      })
      .addCase(addFeeStructure.fulfilled, (state, action) => {
     
        state.feeStructures = [...state.feeStructures, action.payload];

      })
      .addCase(updateFeeStructure.fulfilled, (state, action) => {
        const index = state.feeStructures.findIndex((f) => f.id === action.payload.id);
        if (index !== -1) state.feeStructures[index] = action.payload;
      })
      .addCase(deleteFeeStructure.fulfilled, (state, action) => {
        state.feeStructures = state.feeStructures.filter((f) => f.id !== action.payload);
      });
  },
});

export default feeStructureSlice.reducer;
