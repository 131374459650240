import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
    stats: [],  
    loading: false,
    error: null,
};

export const fetchStats = createAsyncThunk(
    "dashboard/fetchStats",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getRequest("/api/AdminDashboard/statics");
            console.log("API Response:", response.data); 
            if (response.status !== 200) throw new Error("Failed to fetch statistics");
            return response.data; 
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const statsSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStats.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStats.fulfilled, (state, action) => {
                state.loading = false;
                state.stats = action.payload?.data || {}; 
            })
            
            .addCase(fetchStats.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                toast.error(action.payload || "Failed to fetch statistics");
            });
    },
});

export default statsSlice.reducer;
