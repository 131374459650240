import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGracePeriods } from "../../../redux/gracePeriodSlice";
import { fetchEmployees } from "../../../redux/employeeSlice";

const GracePeriod = () => {
  const dispatch = useDispatch();

  const { gracePeriods, status } = useSelector((state) => state.gracePeriods);
  const { employees } = useSelector((state) => state.employees);

  useEffect(() => {
    dispatch(fetchGracePeriods());
    dispatch(fetchEmployees());
  }, [dispatch]);

  const getEmployeeDetails = (employeeId) => {
    const employee = employees.find((emp) => emp.id === employeeId);
    return employee
      ? { name: `${employee.firstName} ${employee.lastName}`, department: employee.department, location: employee.location }
      : { name: "N/A", department: "N/A", location: "N/A" };
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Emp. Code</th>
              <th>Emp. Name</th>
              <th>Department</th>
              <th>Location</th>
              <th>Total GP</th>
              <th>Used GP</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {status === "loading" ? (
              <tr>
                <td colSpan="6" className="text-center py-4">Loading...</td>
              </tr>
            ) : gracePeriods.length > 0 ? (
              gracePeriods.map((gp) => {
                const { name, department, location } = getEmployeeDetails(gp.employeeId);
                return (
                  <tr key={gp.id} className="p-2 py-2 hover:bg-gray-200 space-y-2">
                    <td>{gp.employeeId}</td>
                    <td className="capitalize">{name}</td>
                    <td>{department}</td>
                    <td>{location}</td>
                    <td>{gp.totalGraceMinutes}</td>
                    <td>{gp.usedGraceMinutes}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">No records found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GracePeriod;
