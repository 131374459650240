import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pencil, Trash2 } from "lucide-react";
import ApplyDuesModal from "../../modals/Hrms/ApplyDuesModal";
import ApprovalDuesModal from "../../modals/Hrms/ApprovaDueslModal";
import { 
  fetchDues, 
  addDues, 
  updateDues, 
  deleteDues 
} from "../../redux/duesSlice"; // Adjust import path as needed

const DuesDetail = () => {
  const dispatch = useDispatch();
  const { dues, status, error } = useSelector((state) => state.dues);

  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [editingDues, setEditingDues] = useState(null);
  const [selectedDuesForApproval, setSelectedDuesForApproval] = useState(null);

  // Fetch dues on component mount
  useEffect(() => {
    dispatch(fetchDues());
  }, [dispatch]);

  // Handle Add or Update Dues
  const handleApplyDues = (data) => {
    if (editingDues) {
      // Update existing dues
      dispatch(updateDues({ 
        id: editingDues.id, 
        updatedData: { 
          ...data, 
          status: editingDues.status 
        } 
      }));
    } else {
      // Add new dues
      dispatch(addDues({
        ...data,
        status: "Pending"
      }));
    }
    setEditingDues(null);
    setIsApplyModalOpen(false);
  };

  // Handle Delete Dues
  const handleDeleteDues = (id) => {
    dispatch(deleteDues(id));
  };

  // Handle Edit Dues
  const handleEditDues = (due) => {
    setEditingDues(due);
    setIsApplyModalOpen(true);
  };

  // Handle Status Click
  const handleStatusClick = (due) => {
    setSelectedDuesForApproval(due);
    setIsApprovalModalOpen(true);
  };

  // Handle error and loading states
  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Session Name: March-2024</h1>
        <button
          onClick={() => setIsApplyModalOpen(true)}
          className="bg-[#231763] text-white px-4 py-2 rounded"
        >
          Apply Dues
        </button>
      </div>
      <div className="overflow-x-auto overflow-visible">
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6 text-sm">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>EMP. CODE</th>
                <th>EMPLOYEE NAME</th>
                <th>DUES SESSION</th>
                <th>DUES TYPE</th>
                <th>AMOUNT</th>
                <th>DESCRIPTION</th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {dues.map((due) => (
                <tr key={due.id}>
                  <td>{due.employeeId}</td>
                  <td>
                    <div>
                      <div className="font-medium">
                        {due.Employee?.firstName} {due.Employee?.lastName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {due.Employee?.department}
                      </div>
                    </div>
                  </td>
                  <td>{due.sessionName}</td>
                  <td>{due.duesType}</td>
                  <td>{due.amount}</td>
                  <td>{due.description}</td>
                  <td
                    className="cursor-pointer"
                    onClick={() => handleStatusClick(due)}
                  >
                    <span
                      className={`px-2 py-1 rounded ${
                        due.status === "Pending"
                          ? "bg-orange-100 text-orange-600"
                          : due.status === "Approved"
                          ? "bg-green-100 text-green-600"
                          : "bg-red-100 text-red-600"
                      }`}
                    >
                      {due.status}
                    </span>
                  </td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEditDues(due)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDeleteDues(due.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ApplyDuesModal
        isOpen={isApplyModalOpen}
        onClose={() => {
          setEditingDues(null);
          setIsApplyModalOpen(false);
        }}
        onSubmit={handleApplyDues}
        initialData={editingDues}
      />
      <ApprovalDuesModal
        isOpen={isApprovalModalOpen}
        onClose={() => {
          setSelectedDuesForApproval(null);
          setIsApprovalModalOpen(false);
        }}
        currentDues={selectedDuesForApproval}
      />
    </div>
  );
};

export default DuesDetail;