export default function UserModal({ 
    setIsOpen, 
    register, 
    handleSubmit, 
    handleFormSubmit, 
    errors, 
    editingUser 
  }) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
          <h2 className="text-xl font-semibold mb-4">
            {editingUser ? "Edit User" : "Create New User"}
          </h2>
  
          <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
            {/* Name */}
            <div>
              <label className="block font-medium">Name *</label>
              <input
                type="text"
                {...register("name", { required: "Name is required" })}
                className="w-full border p-2 rounded"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
            </div>
  
            {/* Email */}
            <div>
              <label className="block font-medium">Email *</label>
              <input
                type="email"
                {...register("email", { required: "Email is required" })}
                className="w-full border p-2 rounded"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
            </div>
  
            {/* Password */}
            <div>
              <label className="block font-medium">Password *</label>
              <input
                type="password"
                {...register("password", { required: "Password is required", minLength: 8 })}
                className="w-full border p-2 rounded"
              />
              {errors.password && <p className="text-red-500 text-sm">Password must be at least 8 characters</p>}
            </div>
  
            {/* Employee Code */}
            <div>
              <label className="block font-medium">Employee Code *</label>
              <input
                type="text"
                {...register("employeeCode", { required: "Employee Code is required" })}
                className="w-full border p-2 rounded"
              />
              {errors.employeeCode && <p className="text-red-500 text-sm">{errors.employeeCode.message}</p>}
            </div>
  
            {/* Location */}
            <div>
              <label className="block font-medium">Location *</label>
              <input
                type="text"
                {...register("location", { required: "Location is required" })}
                className="w-full border p-2 rounded"
              />
              {errors.location && <p className="text-red-500 text-sm">{errors.location.message}</p>}
            </div>
  
            {/* Role */}
            <div>
              <label className="block font-medium">Role *</label>
              <select 
                {...register("role", { required: "Role is required" })}
                className="w-full border p-2 rounded"
              >
                <option value="">Select Role</option>
                <option value="Admin Manager">Admin Manager</option>
                <option value="Associate Software Engineer">Associate Software Engineer</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Developer">Developer</option>
              </select>
              {errors.role && <p className="text-red-500 text-sm">{errors.role.message}</p>}
            </div>
  
            {/* Status */}
            <div>
              <label className="block font-medium">Status *</label>
              <select 
                {...register("status", { required: "Status is required" })}
                className="w-full border p-2 rounded"
              >
                <option value="Active">Active</option>
                <option value="Not Active">Not Active</option>
              </select>
              {errors.status && <p className="text-red-500 text-sm">{errors.status.message}</p>}
            </div>
  
            {/* Buttons */}
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
                {editingUser ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  