import React, { useState } from "react";

const TimeAdjustmentModal = ({ isOpen, onClose, employee, onUpdateStatus }) => {
  const [status, setStatus] = useState(employee?.hrStatus || "");

  if (!isOpen) return null;

  const handleUpdate = () => {
    if (employee) {
      onUpdateStatus(employee.id, status);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg max-w-lg w-full">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800">Update Time Adjustment Status</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800 text-xl font-bold"
          >
            &times;
          </button>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Employee Name</label>
            <input
              type="text"
              value={`${employee?.name || "N/A"}`}
              disabled
              className="w-full border p-2 bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Request For</label>
            <input
              type="text"
              value={employee?.checkType || "N/A"}
              disabled
              className="w-full border p-2 bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Update Status</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full border p-2"
            >
              <option value="">Select</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">Reason</label>
            <textarea
              disabled
              className="w-full border p-2 bg-gray-100"
              value={employee?.reason || ""}
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={handleUpdate}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Update
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeAdjustmentModal;
